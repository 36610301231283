import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

const Badge = styled.span`
  color: ${props => props.color};
  margin-right: 1rem;
`;

const OrphanBadge = ({ slotId, bookingId, rescheduledFrom, slotTime }) => {
  const isOrphan = !slotId;
  const isReschedule =
    (rescheduledFrom &&
      moment(slotTime).diff(moment(rescheduledFrom), 'minutes') > 10) ||
    moment(slotTime).diff(moment(rescheduledFrom), 'minutes') < 10
      ? true
      : false;
  if (isOrphan || isReschedule) {
    const toolTipText = isOrphan
      ? `Orphan Booking: Call and Reschedule`
      : `Rescheduled from ${moment(rescheduledFrom).format(
          'DD MMM, hh:mm a'
        )} to ${moment(slotTime).format('DD MMM, hh:mm a')}`;
    const color = isOrphan ? 'red' : '#17a2b8';

    const id = isOrphan ? `orphan-${bookingId}` : `reschedule-${bookingId}`;
    return (
      <Badge color={color}>
        <FaExclamationTriangle id={id} />
        <UncontrolledTooltip placement="right" target={id}>
          {toolTipText}
        </UncontrolledTooltip>
      </Badge>
    );
  } else {
    return null;
  }
};

OrphanBadge.propTypes = {
  slotId: PropTypes.string,
  bookingId: PropTypes.string.isRequired,
  rescheduledFrom: PropTypes.string,
  slotTime: PropTypes.string
};

export default OrphanBadge;
