import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UPDATE_MULTIPLE_LABS_DATA } from '../queries';
import { displaySuccess, logAmplitudeEvent } from '../utilities';
const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const EditMultipleLabsModal = props => {
  // eslint-disable-next-line react/prop-types
  const { labsDetails, isOpen, hideModal, onSave } = props;

  const initialValues = {
    itemIds: labsDetails.map(item => item.itemId),
    enable: false,
    homeCollection: false
  };

  return (
    <Mutation
      mutation={UPDATE_MULTIPLE_LABS_DATA}
      onCompleted={data => {
        onSave(data);
        displaySuccess(`${initialValues.itemIds.length} Labs updated`);
        logAmplitudeEvent('Labs_updated', data.updateMultipleLabsData, true);
      }}
      refetchQueries={() => ['GET_LAB_DATA']}
    >
      {(updateLab, { loading: updateLoading, error: updateError }) => {
        const errObj = updateError;
        const displayErr = errObj && errObj.message;
        const displayLoading = updateLoading;
        return (
          <Modal isOpen={isOpen} toggle={hideModal}>
            <ModalHeader toggle={hideModal}>Edit Labs Data</ModalHeader>
            <ModalBody>
              <div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={values => {
                    const inputData = {
                      ...values,
                      itemIds: initialValues.itemIds
                    };
                    updateLab({
                      variables: { input: inputData }
                    });
                  }}
                >
                  {formik => (
                    <div>
                      {errObj && (
                        <div className="error">
                          Error updating lab. {displayErr}
                        </div>
                      )}
                      <div style={styles.formContainer}>
                        <div>Enable</div>
                        <div>
                          <Field
                            type="checkbox"
                            name="enable"
                            checked={formik.values.enable}
                          />
                        </div>
                        <div>Home Collection</div>
                        <div>
                          <Field
                            type="checkbox"
                            name="homeCollection"
                            checked={formik.values.homeCollection}
                          />
                        </div>
                        <div style={styles.buttonRow}>
                          <Button
                            onClick={formik.submitForm}
                            size="sm"
                            color="primary"
                            disabled={displayLoading}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </ModalBody>
          </Modal>
        );
      }}
    </Mutation>
  );
};

EditMultipleLabsModal.propTypes = {
  labsDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default EditMultipleLabsModal;
