import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { registerConfirmationDialog } from './ConfirmationDialogService';

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    registerConfirmationDialog(this);
  }

  get dialogObj() {
    return this.state.dialogObj;
  }

  set dialogObj({
    title = 'Confirmation Required',
    message = 'Are you sure to perform this action?',
    okCallback = () => {},
    cancelCallback = () => {}
  }) {
    this.setState({ title, message, okCallback, cancelCallback, show: true });
  }

  render() {
    const { show, title, message, okCallback, cancelCallback } = this.state;
    return (
      <div>
        {show ? (
          <SweetAlert
            title={title}
            warning
            showCancel
            onConfirm={() => {
              this.setState({ show: false });
              okCallback();
            }}
            onCancel={() => {
              this.setState({ show: false });
              cancelCallback();
            }}
          >
            {message}
          </SweetAlert>
        ) : null}
      </div>
    );
  }
}

export default ConfirmationDialog;
