import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const Tooltip = ({ message, target, placement }) => {
  return (
    <UncontrolledTooltip placement={placement} target={target}>
      {message}
    </UncontrolledTooltip>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  placement: PropTypes.string
};

Tooltip.defaultProps = {
  placement: 'auto'
};

export default Tooltip;
