import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button } from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';
import { CREATE_SLOTS } from '../queries';
import { displayError, displaySuccess } from '../utilities';

const RunSlotCreationJob = ({ entityId, aboutMe, profileStatus }) => (
  <Mutation
    mutation={CREATE_SLOTS}
    onCompleted={() =>
      displaySuccess(
        `Requested slot recreation. ` +
          `It may take a while for this to complete. ` +
          `Please avoid running it again in next half an hour`,
        false
      )
    }
    onError={() =>
      displayError('Something went wrong. Please contact administrator.')
    }
  >
    {(createSlot, { loading }) => (
      <Button
        color="danger"
        size="sm"
        disabled={loading}
        onClick={() => {
          if (!aboutMe || aboutMe.trim().length < 100) {
            displayError(
              `About Me field should have minimum of 100 characters`
            );
            return;
          }
          if (profileStatus !== 'approved') {
            displayError('Doctor is not yet approved to create slots');
            return;
          }
          showConfirmation(
            'DANGER ZONE',
            <span>
              This action will recreate slot, can possibly move or cancel
              bookings. I hope you know what you are doing.
              <br />
              Go ahead?
            </span>,
            () => createSlot({ variables: { entityId } })
          );
        }}
      >
        Force Create Slots
      </Button>
    )}
  </Mutation>
);

RunSlotCreationJob.propTypes = {
  entityId: PropTypes.number.isRequired,
  aboutMe: PropTypes.string,
  profileStatus: PropTypes.string.isRequired
};

export default RunSlotCreationJob;
