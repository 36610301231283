import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AuthRequired from '../components/AuthRequired';
import ViewProtocols from '../components/ViewProtocols';
import ApproveProtocol from '../components/ApproveProtocol';
import AddProtocol from '../components/AddProtocols';
import { useQuery } from 'react-apollo';
import { GET_LOGGED_IN_USER } from '../queries';

const TABS = {
  view: 'View Protocols',
  add: 'Add Protocols',
  approve: 'Approve Protocols'
};

const Protocols = () => {
  const [activeTab, setActiveTab] = useState(TABS.view);
  const [editing, setEditing] = useState(false);
  const [approve, setApprove] = useState(false);
  const [faq, setFaq] = useState({});
  const [hospital, setSelectedHospital] = useState();
  const {
    data: { me }
  } = useQuery(GET_LOGGED_IN_USER);
  function handleEdit(protocol, hospital) {
    setSelectedHospital(hospital);
    setEditing(true);
    setFaq(protocol);
    setActiveTab(TABS.add);
  }
  function handleChange() {
    setEditing(false);
    setApprove(false);
    setActiveTab(TABS.view);
  }
  function handleApprove(protocol, hospital) {
    setSelectedHospital(hospital);
    setApprove(true);
    setFaq(protocol);
    setActiveTab(TABS.add);
  }
  return (
    <>
      <AuthRequired pageName="protocols">
        <div className="settings-page">
          <div className="settings-sidebar">
            <Nav tabs vertical pills className="page-left-menu">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === TABS.view })}
                  onClick={() => setActiveTab(TABS.view)}
                >
                  {TABS.view}
                </NavLink>
              </NavItem>
              {(me.role === 'admin' || me.role === 'gre') && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === TABS.add })}
                    onClick={() => setActiveTab(TABS.add)}
                  >
                    {TABS.add}
                  </NavLink>
                </NavItem>
              )}
              {(me.role === 'admin' ||
                (me.role === 'gre' && me.canApproveProtocols)) && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === TABS.approve
                    })}
                    onClick={() => setActiveTab(TABS.approve)}
                  >
                    {TABS.approve}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
          <div className="settings-main">
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TABS.view}>
                <ViewProtocols editingHandler={handleEdit} role={me.role} />
              </TabPane>
              <TabPane tabId={TABS.add}>
                <AddProtocol
                  hospital={hospital}
                  isEditing={editing}
                  faq={faq}
                  onChange={handleChange}
                  approve={approve}
                />
              </TabPane>
              <TabPane tabId={TABS.approve}>
                <ApproveProtocol approvalHandler={handleApprove} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </AuthRequired>
    </>
  );
};

export default Protocols;
