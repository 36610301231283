import React, { useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import DoctorFormModal from './DoctorFormModal';
import { Query, Mutation } from 'react-apollo';
import { GET_DOCTOR } from '../pages/Doctors';
import { UPDATE_DOCTOR_MUTATION } from '../queries';
import { displayError, displaySuccess } from '../utilities';

const EditDoctorProfile = props => {
  const { doctorId } = props;
  const [showModal, setshowModal] = useState(false);
  const toggle = () => setshowModal(!showModal);
  return (
    <>
      <Button
        onClick={() => setshowModal(true)}
        color="link"
        style={{ padding: 0, margin: 0 }}
      >
        Edit Profile
      </Button>
      <Query query={GET_DOCTOR} variables={{ id: doctorId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          const { selectedDoc, departments } = data;
          return (
            <Mutation
              mutation={UPDATE_DOCTOR_MUTATION}
              refetchQueries={() => ['GET_DOCTOR']}
              onError={e => displayError(e)}
              onCompleted={() => {
                displaySuccess(`Updated Successfully`);
                setshowModal(false);
              }}
            >
              {(updateState, { loading }) => {
                return (
                  <DoctorFormModal
                    doctor={selectedDoc}
                    departments={departments}
                    updateDoc={data => updateState(data)}
                    loading={loading}
                    showModal={showModal}
                    toggle={toggle}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    </>
  );
};

EditDoctorProfile.propTypes = {
  doctorId: PropTypes.object.isRequired
};

export default EditDoctorProfile;
