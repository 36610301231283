import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlotProtoEditForm from './SlotProtoEditForm';
import RenderFutureSlotprotoBookings from './RenderFutureSlotprotoBookings';
import { Button } from 'reactstrap';

const SlotProtoCreateEditWizard = ({
  entityId,
  slotPrototypeId,
  onFinished,
  name
}) => {
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  if (!slotPrototypeId) {
    return (
      <SlotProtoEditForm
        entityId={entityId}
        slotPrototypeId={slotPrototypeId}
        onFinished={onFinished}
        name={name}
      />
    );
  }

  if (!deleteConfirmed) {
    return (
      <div>
        <RenderFutureSlotprotoBookings slotPrototypeId={slotPrototypeId} />
        <div className="modal-footer">
          <Button onClick={() => setDeleteConfirmed(true)} color="danger">
            Yes, I want to continue to edit
          </Button>{' '}
          <Button onClick={() => onFinished()}>Cancel</Button>{' '}
        </div>
      </div>
    );
  } else {
    return (
      <SlotProtoEditForm
        entityId={entityId}
        slotPrototypeId={slotPrototypeId}
        onFinished={onFinished}
        name={name}
      />
    );
  }
};

SlotProtoCreateEditWizard.propTypes = {
  entityId: PropTypes.number.isRequired,
  slotPrototypeId: PropTypes.number,
  onFinished: PropTypes.func,
  name: PropTypes.string
};

export default SlotProtoCreateEditWizard;
