import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { FaEdit } from 'react-icons/fa';
import { Button, Table } from 'reactstrap';
import DeleteMultipleScanMapping from '../pages/DeleteMultipleScanMapping';
import DeleteScanMapping from '../pages/DeleteScanMapping';
import { GET_SCAN_PROCEDURE_MAPPING } from '../queries';
import { handleExportCSV, sortTable } from '../utilities/scanProcedureMapping';
import EditMultipleScanProcedureMappingModal from './EditMultipleScanProcedureMappingModal';
import Loader from './Loader';
import ScanProcedureMappingModal from './ScanProcedureMappingModal';
import { OrderIcon } from './Common';
import { trimisterOptions } from '../utilities';
import moment from 'moment';
const styles = {
  toolbar: {
    marginBottom: '1rem',
    marginTop: '1rem'
  }
};

const ScanDetailsMappingTab = () => {
  const [scanProcedureMapping, setScanProcedureMapping] = useState();
  const [action, setAction] = useState('');
  const [sorting, setSorting] = useState({ column: 'scanName', order: 'asc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteCompleted, setDeleteCompleted] = useState(false);

  useEffect(() => {
    if (deleteCompleted) {
      setSelectedRows([]);
      setDeleteCompleted(false);
    }
  }, [deleteCompleted]);

  const hidescanProcedureMappingModal = () =>
    setScanProcedureMapping(undefined);
  const scanProcedureMappingHandler = data => {
    setScanProcedureMapping(data);
  };

  const isSelected = rowData => {
    return selectedRows.some(row => row.id === rowData.id);
  };

  const toggleRowSelection = rowData => {
    if (isSelected(rowData)) {
      setSelectedRows(selectedRows.filter(row => row.id !== rowData.id));
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const hideBulkEdit = () => {
    setSelectedRows([]);
  };
  const bulkEditHandler = selectedRows => {
    setSelectedRows([...selectedRows]);
  };

  const handleSorting = column => {
    return () => {
      setSorting(prev => ({
        ...prev,
        column,
        order: prev.order === 'asc' ? 'desc' : 'asc'
      }));
    };
  };

  return (
    <div>
      <Query query={GET_SCAN_PROCEDURE_MAPPING}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error: {error}</div>;
          if (!data || !data.getScanProcedureMappings) {
            return <div>No scan data found</div>;
          }
          const finalData = data.getScanProcedureMappings;
          if (finalData.length === 0) {
            return <h3>No Scan Data Available</h3>;
          }
          return (
            <>
              <div style={styles.toolbar}>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    scanProcedureMappingHandler({});
                    setAction('add');
                  }}
                >
                  Add New
                </Button>{' '}
                <Button
                  style={{
                    float: 'right',
                    color: 'black',
                    backgroundColor: 'khaki',
                    fontWeight: 'bold',
                    marginRight: '2rem'
                  }}
                  size="sm"
                  onClick={() => handleExportCSV(finalData)}
                >
                  Export as CSV
                </Button>{' '}
              </div>
              {selectedRows.length > 0 && (
                <div>
                  {selectedRows.length === 1
                    ? '1 entry selected - '
                    : `${selectedRows.length} entries selected - `}
                  <Button
                    color="link"
                    onClick={() => {
                      bulkEditHandler(selectedRows);
                      setEditModalOpen(true);
                    }}
                  >
                    <FaEdit />
                  </Button>{' '}
                  <DeleteMultipleScanMapping
                    scanNames={selectedRows.map(row => row.scanName)}
                    onDeleteCompleted={() => {
                      setDeleteCompleted(true);
                    }}
                  />
                </div>
              )}
              <EditMultipleScanProcedureMappingModal
                isOpen={editModalOpen}
                hideModal={() => {
                  setEditModalOpen(false);
                }}
                ScanProcedureDetails={selectedRows}
                onSave={() => {
                  setEditModalOpen(false);
                  hideBulkEdit();
                }}
              />
              <div>
                <Table size="sm" striped responsive>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={() => {
                            if (selectedRows.length === finalData.length) {
                              setSelectedRows([]);
                            } else {
                              setSelectedRows([...finalData]);
                            }
                          }}
                          checked={selectedRows.length === finalData.length}
                        />
                      </th>
                      <th onClick={handleSorting('scanName')}>
                        Scan Name{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="scanName" />
                        </span>
                      </th>
                      <th onClick={handleSorting('procedureName')}>
                        AMS Procedure Name{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="procedureName" />
                        </span>
                      </th>
                      <th onClick={handleSorting('scanType')}>
                        Scan Type{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="scanType" />
                        </span>
                      </th>
                      <th onClick={handleSorting('recommendationType')}>
                        Recommendation Type{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon
                            {...sorting}
                            sortCol="recommendationType"
                          />
                        </span>
                      </th>
                      <th onClick={handleSorting('trimisterData')}>
                        Trimester{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="trimisterData" />
                        </span>
                      </th>
                      <th onClick={handleSorting('weekData')}>
                        Scan Week{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="weekData" />
                        </span>
                      </th>
                      <th onClick={handleSorting('durationofScan')}>
                        Scan Duration{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="durationofScan" />
                        </span>
                      </th>
                      <th>
                        Short Description{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="durationofScan" />
                        </span>
                      </th>
                      <th>
                        Long Description{' '}
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="durationofScan" />
                        </span>
                      </th>
                      <th onClick={handleSorting('updatedBy')}>
                        UpdatedBy
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="updatedBy" />
                        </span>
                      </th>
                      <th onClick={handleSorting('updatedBy')}>
                        UpdatedAt
                        <span style={{ color: 'grey' }}>
                          <OrderIcon {...sorting} sortCol="updatedAt" />
                        </span>
                      </th>
                      <th md={2}>Action(Delete & Edit)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {finalData ? (
                      sortTable(sorting, finalData).map(data => {
                        const updatedTrimisterData = trimisterOptions.find(
                          opt => opt.value === data.trimisterData
                        );
                        return (
                          <tr key={data.scanName}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => toggleRowSelection(data)}
                                checked={isSelected(data)}
                              />
                            </td>
                            <td>{data.scanName}</td>
                            <td>{data.procedureName}</td>
                            <td>{data.scanType}</td>
                            <td>{data.recommendationType}</td>
                            <td>
                              {updatedTrimisterData &&
                                updatedTrimisterData.label}
                            </td>
                            <td>{data.weekData}</td>
                            <td>{data.durationofScan}</td>
                            <td>{data.descriptionShort}</td>
                            <td>{data.aboutInfo}</td>
                            <td>{data.updatedBy}</td>
                            <td>
                              {data.updatedAt
                                ? moment(data.updatedAt).format(
                                    'MMM Do YYYY, h:mm a'
                                  )
                                : ''}
                            </td>
                            <td>
                              <Button
                                color="link"
                                onClick={() => {
                                  scanProcedureMappingHandler(data);
                                  setAction('edit');
                                }}
                              >
                                <FaEdit />
                              </Button>
                              <DeleteScanMapping scanName={data.scanName} />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <h3>No Data Available</h3>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          );
        }}
      </Query>
      <ScanProcedureMappingModal
        isOpen={!!scanProcedureMapping}
        hideModal={hidescanProcedureMappingModal}
        AMSProcedureDetails={scanProcedureMapping}
        onSave={hidescanProcedureMappingModal}
        action={action}
      />
    </div>
  );
};

export default ScanDetailsMappingTab;
