const convertToCSV = data => {
  const csvRows = [];
  const headers = [
    'Lab Name',
    'Item Id',
    'SiteCode',
    'About Info',
    'Turnaround Time',
    'IonicId',
    'Amount',
    'Enable',
    'Home Collection',
    'Favourite Lab'
  ];
  csvRows.push(headers.join(','));
  for (const item of data) {
    const values = [
      `"${(item.labName || '').replace(/"/g, '""')}"`,
      item.itemId,
      item.siteCode,
      `"${(item.aboutInfo || '').replace(/"/g, '""')}"`,
      `"${(item.turnaroundTime || '').replace(/"/g, '""')}"`,
      item.ionicId,
      item.rate,
      item.enable ? 'true' : 'false',
      item.homeCollection ? 'true' : 'false',
      item.favouriteLab ? 'true' : 'false'
    ];
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

export const handleExportCSV = data => {
  if (data && data.length > 0) {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'lab_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};

const removeSpecialCharacters = str => {
  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

export const sortTable = (sorting, dataToSort) => {
  const sortedData = [...dataToSort].sort((a, b) => {
    const valueA = a[sorting.column];
    const valueB = b[sorting.column];

    // Handling null or undefined values
    if (
      (valueA == null || valueA === '') &&
      (valueB !== null && valueB !== '')
    ) {
      return 1;
    }
    if (
      (valueA !== null || valueA !== '') &&
      (valueB == null || valueB === '')
    ) {
      return -1;
    }
    if (
      (valueA == null || valueA === '') &&
      (valueB == null || valueB === '')
    ) {
      return 0;
    }
    const numericValueA =
      sorting.column === 'rate'
        ? parseFloat(valueA)
        : removeSpecialCharacters(String(valueA));
    const numericValueB =
      sorting.column === 'rate'
        ? parseFloat(valueB)
        : removeSpecialCharacters(String(valueB));
    if (
      typeof numericValueA === 'string' &&
      typeof numericValueB === 'string'
    ) {
      // Compare strings
      return sorting.order === 'asc'
        ? numericValueA.localeCompare(numericValueB)
        : numericValueB.localeCompare(numericValueA);
    } else if (
      typeof numericValueA === 'number' &&
      typeof numericValueB === 'number'
    ) {
      // Compare numbers
      return sorting.order === 'asc'
        ? numericValueA - numericValueB
        : numericValueB - numericValueA;
    } else {
      // useful for booleans
      const stringValueA = String(numericValueA);
      const stringValueB = String(numericValueB);
      return sorting.order === 'asc'
        ? stringValueA.localeCompare(stringValueB)
        : stringValueB.localeCompare(stringValueA);
    }
  });
  return sortedData;
};
