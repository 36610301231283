import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import { GoTasklist } from 'react-icons/go';
import { MdDateRange } from 'react-icons/md';
import Select from 'react-select';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import Loader from '../components/Loader';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  CX_USER_DB_BASE_URL,
  S2S_CX_SERVER_API_KEY,
  S2S_C9USERMORE_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

const procedures = [
  { label: 'Consultation', value: 'consultation' },
  { label: 'Lab Bookings', value: 'lab' },
  { label: 'Scan Bookings', value: 'radiology' },
  { label: 'Bed Bookings', value: 'ipbooking' },
  { label: 'Package Bookings', value: 'oppackage' },
  { label: 'Pharma Billings', value: 'pharma' }
];

const reasons = [
  { label: 'Cancelled by hospital', value: 'Cancelled by hospital' },
  { label: 'Last minute emergency', value: 'Last minute emergency' },
  { label: 'Need to reschedule', value: 'Need to reschedule' },
  {
    label: 'Duplicate appointment booking',
    value: 'Duplicate appointment booking'
  },
  { label: 'Booked wrong procedure', value: 'Booked wrong procedure' },
  { label: 'Booked for wrong person', value: 'Booked for wrong person' },
  { label: 'No longer required', value: 'No longer required' },
  { label: 'Booked by mistake', value: 'Booked by mistake' },
  { label: 'Price too high', value: 'Price too high' },
  {
    label: 'My reason is not listed here',
    value: 'My reason is not listed here'
  }
];

const cancellationModes = [
  { label: 'Online Credit Card', value: 'Online Credit Card' },
  { label: 'Online Debit Card', value: 'Online Debit Card' },
  { label: 'UPI', value: 'UPI' },
  { label: 'Cash', value: 'Cash' }
];

const ShowBookingsModal = props => {
  let loggedInUser = JSON.parse(localStorage.getItem('user'));
  const { isOpen, toggle, hospitalId, selectedHospitalDetails } = props;

  const [selectedProcedure, setSelectedProcedure] = useState({});
  const [selectedReason, setSelectedReason] = useState({});
  const [cancelledInvoiceResp, setCancelledInvoiceResp] = useState();
  const [selectedCancellationMode, setSelectedCancellationMode] = useState({});
  const [bookingList, setBookingList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [cancelInvoiceloader, setCancelInvoiceloader] = useState(false);
  const [csvDataToDownload, setCsvDataToDownload] = useState([]);
  const [cancelInvoiceDetails, setCancelInvoiceDetails] = useState([]);
  const [showSingleInvoiceDetails, setShowSingleInvoiceDetails] = useState(
    false
  );

  useEffect(() => {
    setSelectedProcedure({});
    setSelectedReason({});
    setCancelledInvoiceResp();
    setSelectedCancellationMode({});
    setBookingList([]);
    setSelectedDate(moment());
    setCancelInvoiceloader(false);
    setCancelInvoiceDetails([]);
    setShowSingleInvoiceDetails(false);
  }, [isOpen, toggle, hospitalId, selectedHospitalDetails]);

  // function to cancel lab / scan / pharma / bed booking / package booking invoice in zoho books.
  const cancelPaymentInvoice = (
    invoice_id,
    selectedHospitalDetails,
    cancellation_mode,
    cancellation_reason,
    payment_txn_id,
    cancelled_by
  ) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/cancel_invoice`;

    const req_body = {
      invoice_id: invoice_id,
      hospital_id: hospitalId,
      hospital_name: cancelInvoiceDetails.paymentdetails.hospital_name,
      hospital_address: cancelInvoiceDetails.paymentdetails.hospital_address,
      clinic_address: cancelInvoiceDetails.paymentdetails.clinic_address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      siteCode: selectedHospitalDetails.siteCode,
      cancellation_mode: cancellation_mode,
      cancellation_reason: cancellation_reason,
      payment_txn_id: payment_txn_id,
      cancelled_by: cancelled_by,
      line_items: [cancelInvoiceDetails.productinfo],
      logo_url: selectedHospitalDetails.logoUrl,
      mobile: cancelInvoiceDetails.patientmobile,
      selected_procedure: selectedProcedure.value,
      address: cancelInvoiceDetails.paymentdetails.address
        ? cancelInvoiceDetails.paymentdetails.address
        : ' ',
      cf_gender_age: cancelInvoiceDetails.paymentdetails.cf_gender_age
        ? cancelInvoiceDetails.paymentdetails.cf_gender_age
        : ' '
    };

    if (selectedProcedure.value === 'radiology') {
      req_body.doctor_name = cancelInvoiceDetails.paymentdetails.doctorname;
      req_body.cf_referring_doc_name = cancelInvoiceDetails.paymentdetails
        .cf_referring_doc_name
        ? cancelInvoiceDetails.paymentdetails.cf_referring_doc_name
        : ' ';
    }

    if (selectedProcedure.value === 'pharma') {
      req_body.cf_referring_doc_name = cancelInvoiceDetails.paymentdetails
        .cf_referring_doc_name
        ? cancelInvoiceDetails.paymentdetails.cf_referring_doc_name
        : ' ';
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const handleOnClose = () => {
    setSelectedProcedure({});
    setSelectedReason({});
    setCancelledInvoiceResp();
    setSelectedCancellationMode({});
    setBookingList([]);
    setSelectedDate(moment());
    setCancelInvoiceloader(false);
    setCancelInvoiceDetails([]);
    setShowSingleInvoiceDetails(false);
  };

  const fetchBookingDetails = async (hospitalId, procedure, date) => {
    const response = await fetchDetails(hospitalId, procedure, date);
    if (response && response.status && response.status === 1) {
      setBookingList(response.data);
      let csvData = [];
      let i = 1;
      csvData.push([
        'S.No',
        'Invoice Creation Date',
        'MPID',
        'Mobile Number',
        'Patient Name',
        'Amount',
        'Invoice URL',
        'Booking Done By',
        'Payment Mode',
        'Txn ID',
        'Txn Status',
        'Canceled Invoice',
        'Canceled By'
      ]);
      response.data.forEach(booking => {
        csvData.push([
          i,
          moment(booking.created_at).format('DD MMM YYYY HH:MM A'),
          booking.mpid,
          booking.patientmobile,
          booking.patientname,
          `Rs. ${booking.amount}`,
          booking.invoiceurl,
          booking.detailedinfo.createdby
            ? booking.detailedinfo.createdby
            : 'N/A',
          booking.paymentdetails.mode,
          booking.paymentdetails.paymenttxnid
            ? booking.paymentdetails.paymenttxnid
            : 'N/A',
          booking.status ? booking.status : 'N/A',
          booking.cancelledpdfurl ? booking.cancelledpdfurl : 'N/A',
          booking.productinfo.cancelledby
            ? booking.productinfo.cancelledby
            : 'N/A'
        ]);
        i = i + 1;
      });
      setCsvDataToDownload(csvData);
    } else {
      setBookingList([]);
    }
  };

  // function to fetch booking details from userdb bookings table based on hospitalId, procedure and booking date info.
  const fetchDetails = (hospitalId, procedure, date) => {
    const url = `${CX_USER_DB_BASE_URL}/booking/get?hospitalid=${hospitalId}&servicetype=${procedure}&source=AMS&date=${moment(
      date
    ).format('YYYY-MM-DD')}`;
    const fetchOpts = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    };
    return fetch(url, fetchOpts).then(response => response.json());
  };

  const cancelClinixPaymentInvoice = async () => {
    if (!selectedReason || !selectedReason.value) {
      displayError('Please select a cancellation reason !!!');
      return;
    }
    if (!selectedCancellationMode || !selectedCancellationMode.value) {
      displayError('Please select a cancellation mode !!!');
      return;
    }
    try {
      setCancelledInvoiceResp();
      setCancelInvoiceloader(true);
      const response = await cancelPaymentInvoice(
        cancelInvoiceDetails.productid,
        selectedHospitalDetails,
        selectedCancellationMode.value,
        selectedReason.value,
        cancelInvoiceDetails.paymentdetails.paymenttxnid,
        loggedInUser.name
      );

      if (response && response.status && response.status === 1) {
        logAmplitudeEvent(
          'Invoice_Cancelled_Successfully',
          {
            hospitalId: hospitalId,
            invoiceId: cancelInvoiceDetails.productid,
            cancellationMode: selectedCancellationMode.value,
            cancellationReason: selectedReason.value,
            paymentTxnId: cancelInvoiceDetails.paymentdetails.paymenttxnid,
            s3pdflink: response.data.s3pdflink
          },
          true
        );
        setCancelInvoiceloader(false);
        setCancelledInvoiceResp(response);
        displaySuccess(
          `${selectedProcedure.label} invoice cancelled successfully`
        );
      } else {
        logAmplitudeEvent(
          'Invoice_Cancellation_Failed',
          {
            hospitalId: hospitalId,
            invoiceId: cancelInvoiceDetails.productid,
            cancellationMode: selectedCancellationMode.value,
            cancellationReason: selectedReason.value,
            paymentTxnId: cancelInvoiceDetails.paymentdetails.paymenttxnid,
            response: response
          },
          true
        );
        setCancelInvoiceloader(false);
        setCancelledInvoiceResp();
        displayError(
          `${selectedProcedure.label} invoice cancellation failed !!!`
        );
      }
    } catch (error) {
      logAmplitudeEvent(
        'Invoice_Cancellation_Failed',
        {
          hospitalId: hospitalId,
          hospitalName: selectedHospitalDetails.name,
          cancellationMode: selectedCancellationMode.value,
          cancellationReason: selectedReason.value,
          paymentTxnId: cancelInvoiceDetails.paymentdetails.paymenttxnid,
          error: error
        },
        true
      );
      displayError(
        `${selectedProcedure.label} invoice cancellation failed !!!`
      );
      setCancelInvoiceloader(false);
    }
  };

  // function to cancel clinix consultation invoice.
  const cancelConsultationPaymentInvoice = (
    mpid,
    rate,
    email,
    mobile,
    logo_url,
    first_name,
    invoice_id,
    hsn_or_sac,
    description,
    cf_procedure,
    cancelled_by,
    product_type,
    hospital_name,
    invoice_number,
    hospital_email,
    payment_txn_id,
    hospital_address,
    clinic_address,
    salesperson_name,
    cancellation_mode,
    cancellation_reason,
    cf_gender_age,
    gst_no,
    hospitalId
  ) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/cancel_consult_invoice`;

    const req_body = {
      mpid: mpid,
      email: email,
      mobile: mobile,
      logo_url: logo_url,
      first_name: first_name,
      invoice_id: invoice_id,
      cf_procedure: cf_procedure,
      cancelled_by: cancelled_by,
      hospital_name: hospital_name,
      invoice_number: invoice_number,
      hospital_email: hospital_email,
      payment_txn_id: payment_txn_id,
      hospital_address: hospital_address,
      clinic_address: clinic_address,
      salesperson_name: salesperson_name,
      cancellation_mode: cancellation_mode,
      cancellation_reason: cancellation_reason,
      cf_gender_age: cf_gender_age,
      gst_no: gst_no,
      hospital_id: hospitalId
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to cancel consultation payment invoice.
  const cancelClinixConsultationPaymentInvoice = async () => {
    if (!selectedReason || !selectedReason.value) {
      displayError('Please select a cancellation reason !!!');
      return;
    }
    if (!selectedCancellationMode || !selectedCancellationMode.value) {
      displayError('Please select a cancellation mode !!!');
      return;
    }
    try {
      setCancelledInvoiceResp();
      setCancelInvoiceloader(true);
      const response = await cancelConsultationPaymentInvoice(
        cancelInvoiceDetails.mpid,
        cancelInvoiceDetails.paymentdetails.amount.toString(),
        cancelInvoiceDetails.patientemail,
        cancelInvoiceDetails.patientmobile,
        selectedHospitalDetails.logoUrl,
        cancelInvoiceDetails.patientname,
        cancelInvoiceDetails.productid,
        cancelInvoiceDetails.productinfo.hsn_or_sac,
        cancelInvoiceDetails.productinfo.description,
        cancelInvoiceDetails.productinfo.name,
        loggedInUser.name,
        'service',
        cancelInvoiceDetails.paymentdetails.hospital_name,
        cancelInvoiceDetails.paymentdetails.invoice_number,
        selectedHospitalDetails.appSupportEmail,
        cancelInvoiceDetails.paymentdetails.paymenttxnid,
        cancelInvoiceDetails.paymentdetails.hospital_address,
        cancelInvoiceDetails.paymentdetails.clinic_address,
        loggedInUser.name,
        selectedCancellationMode.value,
        selectedReason.value,
        cancelInvoiceDetails.paymentdetails.cf_gender_age
          ? cancelInvoiceDetails.paymentdetails.cf_gender_age
          : ' ',
        selectedHospitalDetails.gstNo,
        selectedHospitalDetails.id
      );

      if (response && response.status && response.status === 1) {
        logAmplitudeEvent(
          'Consultation_Invoice_Cancelled_Successfully',
          {
            mpid: cancelInvoiceDetails.mpid,
            rate: cancelInvoiceDetails.paymentdetails.amount.toString(),
            email: cancelInvoiceDetails.patientemail,
            mobile: cancelInvoiceDetails.patientmobile,
            first_name: cancelInvoiceDetails.patientname,
            invoice_id: cancelInvoiceDetails.productid,
            hsn_or_sac: cancelInvoiceDetails.productinfo.hsn_or_sac,
            description: cancelInvoiceDetails.productinfo.description,
            cf_procedure: cancelInvoiceDetails.productinfo.name,
            cancelled_by: loggedInUser.name,
            product_type: 'service',
            hospital_name: selectedHospitalDetails.name,
            invoice_number: cancelInvoiceDetails.paymentdetails.invoice_number,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            payment_txn_id: cancelInvoiceDetails.paymentdetails.paymenttxnid,
            hospital_address: selectedHospitalDetails.address,
            salesperson_name: loggedInUser.name,
            cancellation_mode: selectedCancellationMode.value,
            cancellation_reason: selectedReason.value,
            hospital_id: selectedHospitalDetails.id,
            s3pdflink: response.data.s3pdflink
          },
          true
        );
        setCancelInvoiceloader(false);
        setCancelledInvoiceResp(response);
        displaySuccess(
          `${selectedProcedure.label} invoice cancelled successfully`
        );
      } else {
        logAmplitudeEvent(
          'Consultation_Invoice_Cancellation_Failed',
          {
            mpid: cancelInvoiceDetails.mpid,
            rate: cancelInvoiceDetails.paymentdetails.amount.toString(),
            email: cancelInvoiceDetails.patientemail,
            mobile: cancelInvoiceDetails.patientmobile,
            first_name: cancelInvoiceDetails.patientname,
            invoice_id: cancelInvoiceDetails.productid,
            hsn_or_sac: cancelInvoiceDetails.productinfo.hsn_or_sac,
            description: cancelInvoiceDetails.productinfo.description,
            cf_procedure: cancelInvoiceDetails.productinfo.name,
            cancelled_by: loggedInUser.name,
            product_type: 'service',
            hospital_name: selectedHospitalDetails.name,
            invoice_number: cancelInvoiceDetails.paymentdetails.invoice_number,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            payment_txn_id: cancelInvoiceDetails.paymentdetails.paymenttxnid,
            hospital_address: selectedHospitalDetails.address,
            salesperson_name: loggedInUser.name,
            cancellation_mode: selectedCancellationMode.value,
            cancellation_reason: selectedReason.value,
            hospital_id: selectedHospitalDetails.id,
            response: response
          },
          true
        );
        setCancelInvoiceloader(false);
        setCancelledInvoiceResp();
        displayError(
          `${selectedProcedure.label} invoice cancellation failed !!!`
        );
      }
    } catch (error) {
      logAmplitudeEvent(
        'Consultation_Invoice_Cancellation_Failed',
        {
          mpid: cancelInvoiceDetails.mpid,
          rate: cancelInvoiceDetails.paymentdetails.amount.toString(),
          email: cancelInvoiceDetails.patientemail,
          mobile: cancelInvoiceDetails.patientmobile,
          first_name: cancelInvoiceDetails.patientname,
          invoice_id: cancelInvoiceDetails.productid,
          hsn_or_sac: cancelInvoiceDetails.productinfo.hsn_or_sac,
          description: cancelInvoiceDetails.productinfo.description,
          cf_procedure: cancelInvoiceDetails.productinfo.name,
          cancelled_by: loggedInUser.name,
          product_type: 'service',
          hospital_name: selectedHospitalDetails.name,
          invoice_number: cancelInvoiceDetails.paymentdetails.invoice_number,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          payment_txn_id: cancelInvoiceDetails.paymentdetails.paymenttxnid,
          hospital_address: selectedHospitalDetails.address,
          salesperson_name: loggedInUser.name,
          cancellation_mode: selectedCancellationMode.value,
          cancellation_reason: selectedReason.value,
          hospital_id: selectedHospitalDetails.id,
          error: error
        },
        true
      );
      setCancelInvoiceloader(false);
      setCancelledInvoiceResp();
      displayError(
        `${selectedProcedure.label} invoice cancellation failed !!!`
      );
    }
  };

  function getProcedures() {
    if (selectedHospitalDetails && selectedHospitalDetails.siteCode === 'BJH') {
      return [
        ...procedures,
        { label: 'Other Services Billings', value: 'other-services' }
      ];
    } else {
      return procedures;
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      keyboard={false}
      backdrop="static"
      onClosed={handleOnClose}
    >
      <ModalHeader toggle={toggle}>
        Show Bookings
        <br />
      </ModalHeader>
      <ModalBody
        style={{ overflow: 'auto', height: 500 }}
        className="print-modal"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '40%',
              flexDirection: 'column'
            }}
          >
            <label>
              <GoTasklist /> SELECT TYPE OF BOOKING
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              width: '2%',
              flexDirection: 'column'
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              width: '20%',
              flexDirection: 'column'
            }}
          >
            <label>
              <MdDateRange size="16" /> SELECT A DATE
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              width: '38%',
              flexDirection: 'column'
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '30%',
              flexDirection: 'column'
            }}
          >
            <Select
              options={getProcedures()}
              onChange={selected => {
                setShowSingleInvoiceDetails(false);
                setSelectedProcedure(selected);
                fetchBookingDetails(hospitalId, selected.value, selectedDate);
              }}
              selected={selectedProcedure}
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '14.5%',
              flexDirection: 'column'
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              width: '17%',
              flexDirection: 'column'
            }}
          >
            <DatePicker
              selected={selectedDate}
              onSelect={date => {
                setShowSingleInvoiceDetails(false);
                setSelectedDate(date);
                if (selectedProcedure.value) {
                  fetchBookingDetails(
                    hospitalId,
                    selectedProcedure.value,
                    date
                  );
                }
              }}
              dateFormat="D MMMM YYYY"
            />
          </div>
          <div
            style={{
              display: 'flex',
              width: '28%',
              flexDirection: 'column'
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              width: '16%',
              flexDirection: 'column'
            }}
          >
            {selectedProcedure &&
            !showSingleInvoiceDetails &&
            selectedProcedure.value &&
            bookingList &&
            bookingList.length ? (
              <CSVLink
                data={csvDataToDownload}
                filename={`${selectedProcedure.label}.csv`}
                className="btn btn-primary"
                onClick={() => {
                  logAmplitudeEvent(
                    'Booking_Details_Downloaded_As_Csv',
                    {
                      hospitalId: hospitalId,
                      selectedDate: moment(selectedDate).format('DD-MM-YYYY'),
                      selectedProcedure: selectedProcedure.label
                    },
                    true
                  );
                }}
              >
                DOWNLOAD AS CSV
              </CSVLink>
            ) : null}
          </div>
        </div>
        {selectedProcedure && selectedProcedure.value ? (
          showSingleInvoiceDetails ? (
            <div>
              <Button
                size="sm"
                className="patient-badge-action-button"
                color="primary"
                title="SHOW ALL INVOICES"
                style={{ marginTop: 32, marginLeft: -0.5 }}
                onClick={() => {
                  setShowSingleInvoiceDetails(false);
                  fetchBookingDetails(
                    hospitalId,
                    selectedProcedure.value,
                    selectedDate
                  );
                }}
              >
                SHOW ALL INVOICES
              </Button>
              <h4 style={{ marginTop: 18, marginBottom: 18 }}>
                Single Invoice Details
              </h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '25%',
                    flexDirection: 'column'
                  }}
                >
                  <h6>MPID: {cancelInvoiceDetails.mpid}</h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '31.40%',
                    flexDirection: 'column'
                  }}
                >
                  <h6>Cancellation Reason:</h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '43.60%',
                    flexDirection: 'column'
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '25%',
                    flexDirection: 'column'
                  }}
                >
                  <h6 style={{ marginTop: 10 }}>
                    Patient Name: {cancelInvoiceDetails.patientname}
                  </h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '33.30%',
                    marginTop: -8,
                    flexDirection: 'column'
                  }}
                >
                  <Select
                    maxMenuHeight={200}
                    options={reasons}
                    onChange={selected => {
                      setSelectedReason(selected);
                    }}
                    selected={selectedReason}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '41.70%',
                    flexDirection: 'column'
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '25%',
                    flexDirection: 'column'
                  }}
                >
                  <h6 style={{ marginTop: 10 }}>
                    Patient Mobile: {cancelInvoiceDetails.patientmobile}
                  </h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '31.40%',
                    flexDirection: 'column',
                    marginTop: 8
                  }}
                >
                  <h6>Cancellation Mode:</h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '43.60%',
                    flexDirection: 'column'
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '25%',
                    flexDirection: 'column'
                  }}
                >
                  <h6 style={{ marginTop: 10 }}>
                    Amount: &#x20B9; {cancelInvoiceDetails.amount}
                  </h6>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '33.30%',
                    flexDirection: 'column',
                    marginTop: -8
                  }}
                >
                  <Select
                    options={cancellationModes}
                    onChange={selected => {
                      setSelectedCancellationMode(selected);
                    }}
                    selected={selectedCancellationMode}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '41.70%',
                    flexDirection: 'column'
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 12
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '44.30%',
                    flexDirection: 'column'
                  }}
                ></div>
                <div
                  style={{
                    display: 'flex',
                    width: '14%',
                    flexDirection: 'column'
                  }}
                >
                  {cancelledInvoiceResp && cancelledInvoiceResp.data ? (
                    <Button
                      size="sm"
                      className="patient-badge-action-button"
                      color="success"
                      title="CANCELLED INVOICE"
                      onClick={() => {
                        window.open(
                          cancelledInvoiceResp.data.s3pdflink,
                          '_blank'
                        );
                        logAmplitudeEvent(
                          'View_Cancelled_Invoice_Btn_Pressed',
                          {
                            hospitalId: hospitalId,
                            cancelledInvoiceUrl:
                              cancelledInvoiceResp.data.s3pdflink,
                            mpid: cancelInvoiceDetails.mpid,
                            patientName: cancelInvoiceDetails.patientname
                          },
                          true
                        );
                      }}
                    >
                      CANCELLED INVOICE
                    </Button>
                  ) : cancelInvoiceloader ? (
                    <div style={{ marginTop: 20, marginLeft: 70 }}>
                      <Loader size={5} />
                    </div>
                  ) : (
                    <Button
                      size="sm"
                      className="patient-badge-action-button"
                      color="primary"
                      title="CANCEL INVOICE"
                      style={{ marginTop: 20 }}
                      onClick={() => {
                        if (
                          selectedProcedure.value === 'consultation' &&
                          selectedHospitalDetails.settings.chargeMaster
                            .invoiceGeneratorForConsultation === 'users_service'
                        ) {
                          cancelClinixConsultationPaymentInvoice();
                        } else {
                          cancelClinixPaymentInvoice();
                        }
                        logAmplitudeEvent(
                          'Cancel_Invoice_Btn_Pressed',
                          {
                            hospitalId: hospitalId,
                            mpid: cancelInvoiceDetails.mpid,
                            patientName: cancelInvoiceDetails.patientname,
                            invoiceId: cancelInvoiceDetails.productid
                          },
                          true
                        );
                      }}
                    >
                      CANCEL INVOICE
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '41.70%',
                    flexDirection: 'column'
                  }}
                ></div>
              </div>
            </div>
          ) : bookingList && bookingList.length ? (
            <div style={{ marginTop: 30, width: '145%', paddingRight: 15 }}>
              <Table bordered size="sm" className="appts">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Invoice Creation Date</th>
                    <th>MPID</th>
                    <th>Mobile Number</th>
                    <th>Patient Name</th>
                    <th>Amount</th>
                    <th>Discount(if any)</th>
                    <th>Discount Notes</th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Invoice URL
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Booking Done By
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Payment Mode
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Txn ID
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Txn Status
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Canceled Invoice
                    </th>
                    <th
                      style={{ textAlign: 'center', verticalAlign: 'middle' }}
                    >
                      Canceled By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookingList.map((bookingData, index) => (
                    <tr key={bookingData.index}>
                      <td>
                        <strong>{index + 1}</strong>
                      </td>
                      <td>
                        {moment(bookingData.created_at).format(
                          'DD MMM YYYY HH:MM A'
                        )}
                      </td>
                      <td>{bookingData.mpid}</td>
                      <td>{bookingData.patientmobile}</td>
                      <td>{bookingData.patientname}</td>
                      <td>&#x20B9; {bookingData.amount}</td>
                      <td>
                        &#x20B9;{' '}
                        {bookingData.detailedinfo.discountAmount || '0'}
                      </td>
                      <td>{bookingData.detailedinfo.discountNotes || ''}</td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData && bookingData.invoiceurl ? (
                          <Button
                            size="sm"
                            className="patient-badge-action-button"
                            color="primary"
                            title="VIEW INVOICE"
                            style={{ textAlign: 'right' }}
                            onClick={() => {
                              window.open(bookingData.invoiceurl, '_blank');
                              logAmplitudeEvent(
                                'View_Invoice_Btn_Pressed',
                                {
                                  hospitalId: hospitalId,
                                  invoiceUrl: bookingData.invoiceurl,
                                  mpid: bookingData.mpid,
                                  patientName: bookingData.patientname
                                },
                                true
                              );
                            }}
                          >
                            VIEW INVOICE
                          </Button>
                        ) : null}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData.detailedinfo.createdby
                          ? bookingData.detailedinfo.createdby
                          : 'N/A'}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData.paymentdetails
                          ? bookingData.paymentdetails.mode
                          : ' '}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData.paymentdetails.paymenttxnid
                          ? bookingData.paymentdetails.paymenttxnid
                          : 'N/A'}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData.status ? bookingData.status : 'N/A'}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData &&
                        bookingData.productid &&
                        !bookingData.cancelledpdfurl ? (
                          <Button
                            size="sm"
                            className="patient-badge-action-button"
                            color="primary"
                            title="CANCEL INVOICE"
                            onClick={() => {
                              setCancelledInvoiceResp();
                              setCancelInvoiceDetails(bookingData);
                              setShowSingleInvoiceDetails(true);
                            }}
                          >
                            CANCEL INVOICE
                          </Button>
                        ) : bookingData.cancelledpdfurl ? (
                          <Button
                            size="sm"
                            className="patient-badge-action-button"
                            color="success"
                            title="CANCELLED INVOICE"
                            onClick={() => {
                              window.open(
                                bookingData.cancelledpdfurl,
                                '_blank'
                              );
                              logAmplitudeEvent(
                                'View_Cancelled_Invoice_Btn_Pressed',
                                {
                                  hospitalId: hospitalId,
                                  cancelledInvoiceUrl:
                                    bookingData.cancelledpdfurl,
                                  mpid: bookingData.mpid,
                                  patientName: bookingData.patientname
                                },
                                true
                              );
                            }}
                          >
                            CANCELLED INVOICE
                          </Button>
                        ) : null}
                      </td>
                      <td
                        style={{ textAlign: 'center', verticalAlign: 'middle' }}
                      >
                        {bookingData.productinfo.cancelledby
                          ? bookingData.productinfo.cancelledby
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 350,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 13
              }}
            >
              <h6>
                No &quot;{selectedProcedure.label}&quot; available on{' '}
                {moment(selectedDate).format('DD MMM YYYY')}
              </h6>
            </div>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 350,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 13
            }}
          >
            <h6>Please select a booking type to view booking details</h6>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" size="sm" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShowBookingsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  hospitalId: PropTypes.number,
  selectedHospitalDetails: PropTypes.object
};

export default ShowBookingsModal;
