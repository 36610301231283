import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import classnames from 'classnames';
import fetch from 'isomorphic-fetch';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';
import Loader from '../components/Loader';
import { logAmplitudeEvent } from '../utilities';

const TABS = {
  LABS_PRESCRIBED: 1,
  SCANS_PRESCRIBED: 2
};

const LabsAndScansPrescribedModal = ({
  isOpen,
  toggle,
  userMPID,
  appointmentId
}) => {
  const [selectedTab, setSelectedTab] = useState(TABS.LABS_PRESCRIBED);
  const [labsPrescribed, setLabsPrescribed] = useState([]);
  const [scansPrescribed, setScansPrescribed] = useState([]);
  const [loaderIcon, setLoaderIcon] = useState(true);

  useEffect(() => {
    getLabsAndScansPrescribed(userMPID, appointmentId);
    setSelectedTab(TABS.LABS_PRESCRIBED);
    setLabsPrescribed([]);
    setScansPrescribed([]);
    setLoaderIcon(true);

    if (isOpen) {
      logAmplitudeEvent(
        'Lab_And_Scan_Icon_Pressed',
        {
          userMPID: userMPID,
          selectedTab: 'LABS PRESCRIBED',
          appointmentId: appointmentId
        },
        true
      );
    }
    // eslint-disable-next-line
  }, [isOpen, toggle, userMPID, appointmentId]);

  // function to fetch customer's labs and scans prescribed details.
  const fetchLabsAndScansDetails = (userMPID, appointmentId) => {
    const url = `${CX_USER_DB_BASE_URL}/dx/prescription?appointmentid=${appointmentId}`;

    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
      // body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and store customer's labs and scans prescribed information in state to show it to the users.
  const getLabsAndScansPrescribed = async (userMPID, appointmentId) => {
    setLoaderIcon(true);
    try {
      const response = await fetchLabsAndScansDetails(userMPID, appointmentId);
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data
      ) {
        if (
          response.data[0] &&
          response.data[0].lab &&
          response.data[0].lab.data
        ) {
          setLabsPrescribed(response.data[0].lab.data);
        }
        if (
          response.data[0] &&
          response.data[0].radiology &&
          response.data[0].radiology.data
        ) {
          setScansPrescribed(response.data[0].radiology.data);
        }
        setLoaderIcon(false);
      } else if (response) {
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  return (
    <div>
      <Modal size="lg" scrollable={true} isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Labs And Scans Prescribed</ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.LABS_PRESCRIBED
                })}
                onClick={() => {
                  setSelectedTab(TABS.LABS_PRESCRIBED);
                  getLabsAndScansPrescribed(userMPID, appointmentId);
                  logAmplitudeEvent(
                    'Lab_And_Scan_Icon_Pressed',
                    {
                      userMPID: userMPID,
                      selectedTab: 'LABS PRESCRIBED',
                      appointmentId: appointmentId
                    },
                    true
                  );
                }}
              >
                LABS PRESCRIBED
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.SCANS_PRESCRIBED
                })}
                onClick={() => {
                  setSelectedTab(TABS.SCANS_PRESCRIBED);
                  getLabsAndScansPrescribed(userMPID, appointmentId);
                  logAmplitudeEvent(
                    'Lab_And_Scan_Icon_Pressed',
                    {
                      userMPID: userMPID,
                      selectedTab: 'SCANS PRESCRIBED',
                      appointmentId: appointmentId
                    },
                    true
                  );
                }}
              >
                SCANS PRESCRIBED
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={selectedTab}>
            <TabPane tabId={TABS.LABS_PRESCRIBED}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.LABS_PRESCRIBED &&
                (labsPrescribed.length ? (
                  <div
                    style={{
                      marginTop: 18
                    }}
                  >
                    <ListGroup>
                      {labsPrescribed.map(labDetails => (
                        <ListGroupItem key={labDetails.name}>
                          {labDetails.name}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Labs Prescribed</p>
                  </div>
                ))
              )}
            </TabPane>
            <TabPane tabId={TABS.SCANS_PRESCRIBED}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.SCANS_PRESCRIBED &&
                (scansPrescribed.length ? (
                  <div
                    style={{
                      marginTop: 18
                    }}
                  >
                    <ListGroup>
                      {scansPrescribed.map(scanDetails => (
                        <ListGroupItem key={scanDetails.name}>
                          {scanDetails.name}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Scans Prescribed</p>
                  </div>
                ))
              )}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  );
};

LabsAndScansPrescribedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userMPID: PropTypes.string.isRequired,
  appointmentId: PropTypes.string.isRequired
};

export default LabsAndScansPrescribedModal;
