import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

// component to select an item / items from zoho books get items API response for clinix (lab / scan / bed booking / package).
const SelectClinixProcedure = props => {
  const {
    onProcedureSelect,
    enteredSearchText,
    selectedProcedure,
    procedureList,
    procedureType
  } = props;

  let listOfProcedures = [];
  if (procedureList) {
    listOfProcedures = [...procedureList];
  }

  let procedureListArr = listOfProcedures.map(p => ({
    label: p,
    value: p
  }));

  const selectedOption =
    procedureListArr.find(({ value }) => value === selectedProcedure) || null;

  let placeholderValue;

  if (procedureType === 'Lab') {
    placeholderValue = 'Select lab / labs';
  }

  if (procedureType === 'Scan') {
    placeholderValue = 'Select a scan';
  }

  if (procedureType === 'Bedbooking') {
    placeholderValue = 'Select bed booking';
  }

  if (procedureType === 'Package') {
    placeholderValue = 'Select a package';
  }

  if (procedureType === 'Consultation') {
    placeholderValue = 'Select procedure / procedures';
  }

  if (procedureType === 'Other services') {
    placeholderValue = 'Select service / services';
  }

  return (
    <>
      {['Lab', 'Consultation', 'Scan', 'Other services'].includes(
        procedureType
      ) ? (
        <Select
          placeholder={placeholderValue}
          onInputChange={e => {
            enteredSearchText(e);
          }}
          isMulti
          onChange={e => {
            onProcedureSelect(e);
          }}
          options={procedureListArr}
        />
      ) : (
        <Select
          placeholder={placeholderValue}
          onInputChange={e => {
            enteredSearchText(e);
          }}
          value={selectedOption}
          onChange={e => {
            onProcedureSelect(e.value);
          }}
          options={procedureListArr}
        />
      )}
    </>
  );
};

SelectClinixProcedure.propTypes = {
  onProcedureSelect: PropTypes.func,
  enteredSearchText: PropTypes.func,
  selectedProcedure: PropTypes.string,
  procedureList: PropTypes.array,
  selectedHospitalDetails: PropTypes.object,
  procedureType: PropTypes.string
};

export default SelectClinixProcedure;
