import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, Button, Collapse } from 'reactstrap';
import moment from 'moment';
import DateTimeRangeSelector from './DateTimeRangeSelector';
import { Query, Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import Loader from './Loader';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import classNames from 'classnames';
import BookingsAffectedByVacation from './BookingsAffectedByVacation';
import { GET_VACATIONS } from '../queries';
import HospitalNameUsingId from './HospitalNameUsingId';
import ApproverNames from './ApproverNames';

const fmtDate = dStr => moment(dStr).format('DD MMM YYYY hh:mm A');

export const DELETE_VACATION = gql`
  mutation DELETE_VACATION($vacationId: Int) {
    deleteVacation(vacationId: $vacationId)
  }
`;

class DocSettingMultiDayScheduleTab extends Component {
  state = {
    editVacation: undefined,
    //Note: newOrUpdatedVacation will be
    // removed after 5s to avoid showing animation
    // every time cDU is called
    newOrUpdatedVacation: undefined
  };

  handleEditVacation = editVacation => {
    //TODO: ScrollTo the form is not working! :(
    this.setState({ editVacation });
  };

  componentDidUpdate() {
    // This may not be the best idea to avoid
    // repeated highlight animation
    if (this.state.newOrUpdatedVacation) {
      setTimeout(
        () => this.setState({ newOrUpdatedVacation: undefined }),
        3500
      );
    }
  }

  render() {
    const { entityId, doctorName } = this.props;
    const {
      editVacation,
      newOrUpdatedVacation,
      openBookingsVacationId
    } = this.state;
    const newVacId = newOrUpdatedVacation && newOrUpdatedVacation.id;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <div
              ref={r => {
                this.editTimeRangeRow = r;
              }}
            >
              <DateTimeRangeSelector
                entityId={entityId}
                doctorName={doctorName}
                vacation={editVacation}
                onRangeAdd={newOrUpdatedVacation =>
                  this.setState({ newOrUpdatedVacation })
                }
              />
            </div>
            <Row>
              <Query
                query={GET_VACATIONS}
                variables={{
                  entityId,
                  approvalStatus: 'ALL'
                }}
                notifyOnNetworkStatusChange={true}
                fetchPolicy="network-only"
              >
                {({ loading: loadingList, error, data, networkStatus }) => {
                  return (
                    <Col>
                      {networkStatus === 4 ? (
                        <div style={{ position: 'absolute' }}>
                          <Loader size={5} />
                        </div>
                      ) : null}
                      <Table size="sm" striped style={{ marginTop: '1rem' }}>
                        <thead>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Hospital</th>
                            <th>Reason</th>
                            <th>Action</th>
                            <th>Status</th>
                            {/* <th>Logs</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {loadingList && networkStatus !== 4 ? (
                            <tr>
                              <td span={4}>
                                <Loader size={5} />
                              </td>
                            </tr>
                          ) : null}
                          {error ? <span>{error}</span> : null}
                          {data && data.getVacations
                            ? data.getVacations.map(v => {
                                const isBookingListOpen =
                                  openBookingsVacationId === v.id;
                                return (
                                  <Mutation
                                    key={v.id}
                                    mutation={DELETE_VACATION}
                                    onCompleted={() => {
                                      displaySuccess('Vacation deleted.');
                                      const eventProps = {
                                        doctorName,
                                        startDate: v.startTime
                                          ? moment(v.startTime).format(
                                              'DD-MM-YYYY, hh:mm A'
                                            )
                                          : '',
                                        endDate: v.endTime
                                          ? moment(v.endTime).format(
                                              'DD-MM-YYYY, hh:mm A'
                                            )
                                          : ''
                                      };
                                      logAmplitudeEvent(
                                        'Delete_Vacation',
                                        eventProps,
                                        true
                                      );
                                    }}
                                    onError={e => displayError(e)}
                                    refetchQueries={() => [
                                      'GET_VACATIONS',
                                      'SEARCH_DOCTORS_EXTENDED'
                                    ]}
                                  >
                                    {(deleteVacation, { loading }) => {
                                      return (
                                        <>
                                          <tr
                                            className={classNames({
                                              anneal: v.id === newVacId,
                                              'disabled-bg': loading
                                            })}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: 'middle'
                                              }}
                                            >
                                              {fmtDate(v.startTime)}
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: 'middle'
                                              }}
                                            >
                                              {fmtDate(v.endTime)}
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: 'middle'
                                              }}
                                            >
                                              <HospitalNameUsingId
                                                id={v.hospitalId}
                                              />
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: 'middle'
                                              }}
                                            >
                                              {v.reason}
                                            </td>
                                            <td>
                                              <Button
                                                disabled={loading}
                                                color="link"
                                                onClick={() =>
                                                  this.handleEditVacation(v)
                                                }
                                              >
                                                Change
                                              </Button>
                                              |
                                              <Button
                                                disabled={loading}
                                                color="link"
                                                onClick={() =>
                                                  deleteVacation({
                                                    variables: {
                                                      vacationId: v.id
                                                    }
                                                  })
                                                }
                                              >
                                                Delete
                                              </Button>
                                              |
                                              <Button
                                                disabled={loading}
                                                color="link"
                                                onClick={() =>
                                                  isBookingListOpen
                                                    ? this.setState({
                                                        openBookingsVacationId: undefined
                                                      })
                                                    : this.setState({
                                                        openBookingsVacationId:
                                                          v.id
                                                      })
                                                }
                                              >
                                                {isBookingListOpen
                                                  ? 'Hide Bookings'
                                                  : 'Show Bookings'}
                                              </Button>
                                            </td>
                                            <td
                                              style={{
                                                verticalAlign: 'middle'
                                              }}
                                            >
                                              {v.isAutoApproved
                                                ? 'Auto Approved'
                                                : v.approvalStatus === 0
                                                ? 'Approval Pending on'
                                                : v.approvalStatus === 1
                                                ? 'Approved'
                                                : 'Declined'}
                                              {v.approvalStatus === 0 ? (
                                                <ApproverNames
                                                  value={v.hospitalId}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                            {/* <td
                                              style={{
                                                verticalAlign: 'middle',
                                                textAlign: 'center'
                                              }}
                                            >
                                              <AuditLogModal
                                                entityId={v.entityId}
                                                type="VACATION"
                                              />
                                            </td> */}
                                          </tr>
                                          <tr>
                                            <td colSpan={4}>
                                              <Collapse
                                                isOpen={isBookingListOpen}
                                              >
                                                {isBookingListOpen && (
                                                  <BookingsAffectedByVacation
                                                    vacationId={v.id}
                                                  />
                                                )}
                                              </Collapse>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }}
                                  </Mutation>
                                );
                              })
                            : null}
                        </tbody>
                      </Table>
                    </Col>
                  );
                }}
              </Query>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

DocSettingMultiDayScheduleTab.propTypes = {
  entityId: PropTypes.number.isRequired,
  date: PropTypes.object.isRequired,
  doctorName: PropTypes.string
};

export default DocSettingMultiDayScheduleTab;
