import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';

import SelectCountry from './SelectCoutry';

const styles = {
  formLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

const guardianRelOpts = [
  'Father',
  'Mother',
  'Brother',
  'Sister',
  'Extended Family',
  'Grandparent'
].map(r => ({ label: r, value: r }));

const EmergencyApptBookingLongForm = props => {
  // console.log('props', props);
  const { isNewPatient, hasMPI, bookingData, onChange, onSubmit } = props;
  const {
    userName,
    userCountry,
    userMobile,
    userEmail,
    // userGender,
    guardianName,
    userDoB
  } = bookingData || {};

  let showGuardian = false;

  if (userDoB && moment().diff(moment(userDoB), 'years') < 18) {
    showGuardian = true;
  }

  return (
    <React.Fragment>
      <Row className="form-row">
        <Col xs={4} className="text-right" style={styles.formLabel}>
          Mobile<div style={{ color: 'red' }}>*</div>:
        </Col>
        <Col xs={8}>
          <input
            onChange={e => onChange('userMobile', e.target.value)}
            readOnly
            defaultValue={userMobile}
          />
        </Col>
      </Row>
      <Row className="form-row">
        <Col xs={4} className="text-right" style={styles.formLabel}>
          Patient Name<div style={{ color: 'red' }}>*</div>:
        </Col>
        <Col xs={8}>
          <input
            onChange={e => onChange('userName', e.target.value)}
            readOnly={!isNewPatient}
            defaultValue={userName}
          />
        </Col>
      </Row>
      {!hasMPI && (
        <Fragment>
          <Row className="form-row">
            <Col xs={4} className="text-right" style={styles.formLabel}>
              Nationality:
            </Col>
            <Col xs={8}>
              <SelectCountry
                isSearchable
                defaultValue={userCountry}
                onChange={v => onChange('userCountry', v)}
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col xs={4} className="text-right" style={styles.formLabel}>
              Email:
            </Col>
            <Col xs={8}>
              <input
                onChange={e => onChange('userEmail', e.target.value)}
                defaultValue={userEmail}
              />
            </Col>
          </Row>
          {showGuardian && (
            <Fragment>
              <Row className="form-row">
                <Col xs={4} className="text-right" style={styles.formLabel}>
                  Guardian Name:
                </Col>
                <Col xs={8}>
                  <input
                    onChange={e => onChange('guardianName', e.target.value)}
                    defaultValue={guardianName}
                  />
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={4} className="text-right" style={styles.formLabel}>
                  Guardian Relationship:
                </Col>
                <Col xs={8}>
                  <Select
                    onChange={v => onChange('guardianRel', v.value)}
                    options={guardianRelOpts}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
      <Row className="form-row">
        <Col className="text-right">
          <Button
            color="primary"
            size="sm"
            type="submit"
            onClick={e => {
              e.preventDefault();
              onSubmit(bookingData);
            }}
          >
            Book Slot
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

EmergencyApptBookingLongForm.propTypes = {
  isNewPatient: PropTypes.bool,
  hasMPI: PropTypes.bool,
  bookingData: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default EmergencyApptBookingLongForm;
