import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import { FaSort, FaUserMd, FaUserClock, FaRegClock } from 'react-icons/fa';
import moment from 'moment';
import { logAmplitudeEvent } from '../utilities';

export const APPT_FILTER = {
  doc: 'Doctor',
  WaitTime: 'Wait Time',
  slotTime: 'Appointment Time'
};

/**
 * Wait Time = max (0, min(current time, ifnull(consultation start time,current time)) - max(appt time, check in time))
 * @param {*} bookings array of booking
 *
 */

const displayPriorityByState = {
  Booked: 2,
  'Checked In': 1,
  'Start Consultation': 0,
  'Checked Out': 4,
  Cancelled: 5,
  'No Show': 3
};
export const updateWithWaitTime = (bookings, date) => {
  const isListingForToday = moment(date)
    .startOf('day')
    .isSame(moment().startOf('day'));

  if (!isListingForToday) {
    return bookings.map(b => ({
      ...b,
      waitTime: 0,
      displayPriorityByState: displayPriorityByState[b.appointmentState]
    }));
  }

  const now = moment();
  const bookingWithWaitTime = [];
  bookings.forEach(booking => {
    const {
      slotTime,
      checkinTS,
      noShowTS,
      startConsultationTS,
      endConsultationTS,
      checkoutTS,
      cancelledTS,
      appointmentState
    } = booking;

    if (cancelledTS || noShowTS || endConsultationTS || checkoutTS) {
      // people who are done with have zero wait time
      bookingWithWaitTime.push({
        ...booking,
        waitTime: 0,
        displayPriorityByState: displayPriorityByState[appointmentState]
      });
    } else if (checkinTS || startConsultationTS) {
      // Wait Time = max (0,
      // a= min(current time, consultation start time) -
      // b= max(appt time, check in time))
      // console.log(3);

      const consultStartTime = startConsultationTS
        ? moment(startConsultationTS)
        : moment(now);
      const apptTime = moment(slotTime);
      const checkInTime = moment(checkinTS);
      const a = now.isBefore(consultStartTime) ? now : consultStartTime;
      const b = apptTime.isAfter(checkInTime) ? apptTime : checkInTime;
      const diff = a.diff(b, 'minutes');
      const waitTime = diff > 0 ? diff : 0;
      const newb = {
        ...booking,
        waitTime,
        displayPriorityByState: displayPriorityByState[appointmentState]
      };
      bookingWithWaitTime.push(newb);
    } else {
      //only booked but not checked in has zero wait time
      bookingWithWaitTime.push({
        ...booking,
        waitTime: 0,
        displayPriorityByState: displayPriorityByState[appointmentState]
      });
    }
  });

  return bookingWithWaitTime;
};

export const sortBooking = (bookingArray, sortBy, date) => {
  if (!bookingArray || bookingArray.length < 1) {
    return bookingArray;
  }

  bookingArray = updateWithWaitTime(bookingArray, date);

  const apptFilter = sortBy;
  if (apptFilter === APPT_FILTER.slotTime) {
    //comes this way from server
    return bookingArray;
  }

  if (apptFilter === APPT_FILTER.doc) {
    return bookingArray.sort((b1, b2) =>
      `${b1.objectName}`.localeCompare(b2.objectName, 'en', {
        sensitivity: 'base'
      })
    );
  }

  if (apptFilter === APPT_FILTER.WaitTime) {
    return bookingArray.sort((b1, b2) => {
      const o1 = b1.displayPriorityByState - b2.displayPriorityByState;
      if (o1 !== 0) {
        return o1;
      } else {
        return b2.waitTime - b1.waitTime;
      }
    });
  }
};

const SortFilterAppt = ({ onSelect, filter }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [selectedSort, setSelectedSort] = useState(defaultFilter);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const itemClicked = sortBy => {
    const eventType =
      sortBy === 'Doctor'
        ? 'Sorting_Doctor'
        : sortBy === 'Wait Time'
        ? 'Sorting_Wait_Time'
        : sortBy === 'Appointment Time'
        ? 'Sorting_Appointment_Time'
        : null;
    // console.log(eventType);
    logAmplitudeEvent(eventType, {}, true);
    onSelect(sortBy);
  };
  return (
    <div>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} direction="bottom">
        <DropdownToggle color="link">
          {filter} <FaSort />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>SORT BY</DropdownItem>
          <DropdownItem onClick={() => itemClicked(APPT_FILTER.slotTime)}>
            <FaRegClock style={{ fontSize: '1.2rem' }} /> {APPT_FILTER.slotTime}
          </DropdownItem>
          <DropdownItem onClick={() => itemClicked(APPT_FILTER.doc)}>
            <FaUserMd style={{ fontSize: '1.2rem' }} /> {APPT_FILTER.doc}
          </DropdownItem>
          <DropdownItem onClick={() => itemClicked(APPT_FILTER.WaitTime)}>
            <FaUserClock style={{ fontSize: '1.2rem' }} />{' '}
            {APPT_FILTER.WaitTime}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

SortFilterAppt.propTypes = {
  onSelect: PropTypes.func,
  filter: PropTypes.string.isRequired
};

SortFilterAppt.defaultProps = {
  onSelect: () => {},
  filter: APPT_FILTER.slotTime
};

export default SortFilterAppt;
