import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { Button, Col, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
  CREATE_SITECODE,
  GET_ALL_SITECODES,
  UPDATE_SITECODE
} from '../queries';

const AddOrEditSiteCode = props => {
  const { mode, sitecode, handleClose } = props;

  const [createSiteCode] = useMutation(CREATE_SITECODE, {
    refetchQueries: [{ query: GET_ALL_SITECODES }],
    onCompleted: handleUpdateComplete,
    onError: error => handleErrorWhileUpdating(error)
  });
  const [updateSiteCode] = useMutation(UPDATE_SITECODE, {
    refetchQueries: [{ query: GET_ALL_SITECODES }],
    onCompleted: handleUpdateComplete,
    onError: error => handleErrorWhileUpdating(error)
  });

  const validationSchema = Yup.object().shape({
    sitecode: Yup.string()
      .trim()
      .test('is-not-only-spaces', 'Please enter a valid sitecode', function(
        value
      ) {
        return value === undefined || value.trim() !== '';
      })
      .required('Please enter the sitecode'),
    description: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      sitecode: '',
      description: ''
    },

    onSubmit: values => {
      if (mode === 'edit')
        updateSiteCode({
          variables: { sitecodeId: sitecode.value, sitecode: values }
        });
      else createSiteCode({ variables: { sitecode: values } });
    },
    validationSchema
  });
  function handleUpdateComplete() {
    formik.setSubmitting(false);
    const successMessage = `SiteCode ${
      mode === 'edit' ? 'updated' : 'created'
    } successfully`;
    toast.success(successMessage, {
      autoClose: 3000
    });
    if (mode !== 'edit') {
      handleClose();
    }
  }

  const handleErrorWhileUpdating = error => {
    formik.setSubmitting(false);
    const errorMessage =
      error.graphQLErrors && error.graphQLErrors.length > 0
        ? error.graphQLErrors[0].message
        : 'Something went wrong!';
    toast.error(errorMessage, {
      autoClose: 3000
    });
  };

  const handleResetForm = () => {
    formik.resetForm();
  };

  useEffect(() => {
    if (mode === 'edit') {
      if (!isEmpty(sitecode) && sitecode)
        formik.setFieldValue('sitecode', sitecode.label);
      else formik.setFieldValue('name', '');
    }
    // eslint-disable-next-line
  }, [sitecode]);

  return (
    <div>
      <h4>{mode === 'edit' ? 'Edit ' : 'Add '} SiteCode</h4>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>
              SiteCode: <span style={{ fontSize: '1em', color: 'red' }}>*</span>
            </label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              style={{ maxWidth: '30rem' }}
              name="sitecode"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.sitecode}
              onBlur={formik.handleBlur}
            />
            {formik.errors.sitecode && formik.touched.sitecode ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.sitecode}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Description:</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              style={{ maxWidth: '30rem' }}
              name="description"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.description}
              onBlur={formik.handleBlur}
            />
            {formik.errors.description && formik.touched.description ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.description}
              </div>
            ) : null}
          </Col>
        </Row>

        <Row
          className="justify-content-end mt-4"
          style={{ padding: '0px 15px' }}
        >
          <Button type="button" color="primary" onClick={handleResetForm}>
            RESET
          </Button>
          <Button
            className="ml-3"
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
          >
            SAVE
          </Button>
        </Row>
      </form>
    </div>
  );
};

AddOrEditSiteCode.propTypes = {
  sitecode: PropTypes.object,
  mode: PropTypes.oneOf(['edit', 'add']).isRequired,
  handleClose: PropTypes.func
};

export default AddOrEditSiteCode;
