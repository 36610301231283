import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { GET_LOGGED_IN_USER, LOG_IN } from '../queries';
import Loader from './Loader';
import { Modal, ModalBody, Button, Collapse } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import {
  getDefaultHospitalId,
  setAmplitudeUser,
  logAmplitudeEvent,
  getGraphQLErrString,
  displaySuccess,
  displayError
} from '../utilities';
import { IoIosLogIn } from 'react-icons/io';
import ForgotPasswordForm from './ForgotPasswordForm';
import { canSeeSubPage } from '../permissions';
import UnauthorizedBanner from './UnauthorizedBanner';
import { FormikPasswordField } from './LoggedInUserSettings';
import { CX_SERVER_BASE_URL, S2S_CX_SERVER_API_KEY } from '../constants';

const getErrorTag = (errors, name) => {
  if (!errors[name]) {
    // return <div className="form-row">&nbsp;</div>;
    return null;
  }
  return <div className="error form-row">{errors[name]}</div>;
};

const LoginForm = props => {
  const [showSignUp, setShowSignUp] = useState(false);
  const toggleShowSignUp = () =>
    setShowSignUp(!showSignUp);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { loading, loginAction, gqlError } = props;
  // function to send email verification link.
  const sendEmailVerificationLink = (email) => {
    let emailID = email ? email.trim() : '';
    let validateEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      validateEmailRegex.test(String(emailID).toLowerCase()) &&
      !email.includes('*')
    ) {
      const url = `${CX_SERVER_BASE_URL}/ve/send_fp_verification_link`;
      const req_body = {
        email: email
      };
      const fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
        },
        body: JSON.stringify(req_body)
      };
      fetch(url, fetchOpts)
        .then(response => response.json())
        .then(response => {
          if (response && response.status && response.status === 1) {
            displaySuccess('Please check email to verify email and get new password');
            logAmplitudeEvent(
              'FP_Verification_Link_Sent_Success', { email: email }, false
            );
          }
          else {
            displayError('Verification link not sent, try again !!!');
            logAmplitudeEvent(
              'FP_Verification_Link_Sending_Failed',
              {
                email: email,
                response: response
              }, false
            );
          }
        })
        .catch(error => {
          displayError('Verification link not sent, try again !!!');
          logAmplitudeEvent(
            'FP_Verification_Link_Sending_Error',
            {
              email: email,
              error: error
            }, false
          );
        });
    }
    else {
      displayError('Please enter a valid email !!!');
    }
  };
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={values => {
        if (showForgotPassword) {
          sendEmailVerificationLink(values.email)
        } else {
          logAmplitudeEvent('User_Login_Intiated', { email: values.email });
          loginAction({ variables: values });
        }
      }}
    >
      {({ props, errors }) => (
        <Form autoComplete="off">
          <div className="error form-row">
            {gqlError &&
              gqlError.graphQLErrors &&
              gqlError.graphQLErrors[0].message}
          </div>
          <label>EMAIL</label>
          <Field
            type="email"
            name="email"
            placeholder="Email"
            className="form-row"
            autoComplete="off"
            onKeyPress={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
          {getErrorTag(errors, 'email')}
          <label>PASSWORD</label>
          {/* <Field
            type="password"
            name="password"
            placeholder="Password"
            className="form-row"
          /> */}
          <Field
            type="text"
            name="password"
            placeholder="Password"
            // className="form-row passwordFont"
            component={FormikPasswordField}
            autoComplete="new-password"
            onKeyPress={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
          {getErrorTag(errors, 'password')}

          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button
              color="link"
              style={{ paddingLeft: 0 }}
              onClick={() => setShowForgotPassword(true)}
              type="submit"
            >
              Forgot Password
            </Button>
            <Button
              color="link"
              style={{ paddingLeft: 0 }}
              onClick={toggleShowSignUp}
              type="button"
            >
              Sign Up
            </Button>
            <Button onClick={() => setShowForgotPassword(false)} type="submit" disabled={loading} color="primary">
              <IoIosLogIn /> Log In
            </Button>
          </div>
          <Collapse isOpen={showSignUp}>
            <ForgotPasswordForm />
          </Collapse>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  loginAction: PropTypes.func,
  gqlError: PropTypes.object
};

const AuthRequired = ({ pageName, ...props }) => {
  return (
    <Query
      query={GET_LOGGED_IN_USER}
      onCompleted={data => {
        const {
          me: { email, name }
        } = data;
        setAmplitudeUser(email);
        if (name.includes('deleted')) {
          localStorage.clear();
          window.location.reload();
        }
      }}
    >
      {({ refetch, loading, data: { me, hospitals } = {}, client }) => {
        if (loading) return <Loader />;
        // console.log(`me: ${JSON.stringify(me)}}`);
        if (!me) {
          return (
            <Modal isOpen>
              <div className="modal-header">
                <h5 style={{ margin: 0, padding: 0 }}>CloudNine</h5>
                <small>LOG IN TO PROCEED</small>
              </div>
              <ModalBody>
                <Mutation
                  mutation={LOG_IN}
                  onCompleted={data => {
                    const {
                      adminLogin: { name = '', email = '' }
                    } = data;
                    logAmplitudeEvent('User_Login_Success', { name, email });
                  }}
                  onError={err => {
                    logAmplitudeEvent('User_Login_Failure', {
                      failureReason: getGraphQLErrString(err)
                    });
                  }}
                >
                  {(logMeIn, { loading, data, error }) => {
                    if (data && data.adminLogin && data.adminLogin.token) {
                      localStorage.setItem('token', data.adminLogin.token);
                      localStorage.setItem(
                        'user',
                        JSON.stringify(data.adminLogin)
                      );
                      refetch();
                    }
                    return (
                      <LoginForm
                        loading={loading}
                        data={data}
                        gqlError={error}
                        loginAction={logMeIn}
                      />
                    );
                  }}
                </Mutation>
              </ModalBody>
            </Modal>
          );
        }

        const { hospitals: userHospitals, canApproveVacations } = me;
        let selectedHospitals = [];
        const defaultHospitalIdStr = getDefaultHospitalId();
        let defaultHospitalId = null;
        if (defaultHospitalIdStr && defaultHospitalIdStr.length > 0) {
          defaultHospitalId = parseInt(defaultHospitalIdStr);
        }

        // Set the first hospital as default
        if (userHospitals && userHospitals.length === 1) {
          selectedHospitals = [userHospitals[0]];
        } else if (userHospitals && userHospitals.length > 1) {
          if (
            defaultHospitalId !== null &&
            userHospitals.includes(defaultHospitalId)
          ) {
            selectedHospitals = [defaultHospitalId];
          } else {
            selectedHospitals = [userHospitals[0]];
          }
        } else {
          selectedHospitals =
            defaultHospitalId !== null
              ? [defaultHospitalId]
              : [hospitals[0].id];
        }

        client.writeData({
          data: {
            userHospitals,
            allHospitals: hospitals,
            selectedHospitals
          }
        });

        if (!canSeeSubPage(pageName, canApproveVacations)) {
          return <UnauthorizedBanner />;
        }

        return props.children;
      }}
    </Query>
  );
};

AuthRequired.propTypes = {
  pageName: PropTypes.string
};

export default AuthRequired;
