import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMutation } from 'react-apollo';
import { UPDATE_DOCTOR_RANKING } from '../queries/index';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  userSelect: 'none',
  padding: grid,
  margin: `${grid}px 0 0 0`,
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightblue',
  padding: grid,
  width: '75%'
});

const DoctorList = ({ list, hospitalId }) => {
  const [items, setItems] = useState([]);

  const [yourMutationFunction] = useMutation(UPDATE_DOCTOR_RANKING);

  useEffect(() => {
    setItems(list);
  }, [list]);

  const onDragEnd = result => {
    if (!result.destination) return;
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderedItems);
  };

  if (!items || items.length === 0) {
    return <h3>No Doctors in this hospital</h3>;
  }

  const handleSubmit = async () => {
    let newRankingsArr = items.map(val => {
      return {
        doctorId: val.doctorId
      };
    });

    try {
      const data = await yourMutationFunction({
        variables: {
          input: {
            hospId: hospitalId,
            newRankings: newRankingsArr
          }
        }
      });

      if (data.data && data.data.updateDoctorsRanking) {
        toast.success('Ranking Updated!', { autoClose: 2000 });
        return;
      }

      toast.error('👎 Failed to Update Ranking!', { autoClose: 2000 });
    } catch (error) {
      toast.error('👎 Failed to Update Ranking!', { autoClose: 2000 });
    }
  };

  const calculateExperience = dateSincePracticing => {
    // Parse the date string into a Date object
    const dateSince = new Date(dateSincePracticing);

    // Calculate the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const yearsOfExperience =
      currentDate.getFullYear() - dateSince.getFullYear();
    return yearsOfExperience;
  };

  return (
    <>
      <div className="drag_drop_heading">
        <h4>DRAG and DROP to Reorder</h4>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
      <div className="main_content">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item.doctorId}
                    draggableId={item.doctorId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="card"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div style={{ flex: 1.75 }}>
                          <h5>{item.doctorName}</h5>
                          <p>{item.department}</p>
                        </div>
                        <div style={{ flex: 1.5 }}>
                          <p>
                            Years of Experience:{' '}
                            {item.dateSincePracticing
                              ? calculateExperience(item.dateSincePracticing)
                              : 'NA'}
                          </p>
                          <p>
                            Specialty:{' '}
                            {item.primarySpecialty
                              ? item.primarySpecialty
                              : item.secondarySpecialty
                              ? item.secondarySpecialty
                              : '_'}
                          </p>
                        </div>
                        <div style={{ flex: 0.5 }}>
                          <h4>Rank {index + 1}</h4>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

DoctorList.propTypes = {
  list: PropTypes.object,
  hospitalId: PropTypes.number
};

export default DoctorList;
