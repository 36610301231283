/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { Button, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';

const deleteCouponDialog = coupon => (
  <>
    <span>
      Are you sure you want to delete/expire coupon
      <strong style={{ color: 'red' }}>
        {''} {coupon.couponcode}
      </strong>
      ?<br />
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
    </span>
  </>
);

const CouponDetail = ({
  user,
  allCouponsDetails,
  toggleCouponDetail,
  setToggleCouponDetails,
  handleDeleteCoupon
}) => {
  const truncateComment = (comment, maxLength) => {
    if (comment.length <= maxLength) {
      return comment;
    }
    return comment.substring(0, maxLength - 3) + '...';
  };

  const confirm = coupon => {
    showConfirmation(
      'Expire/Delete Coupon?',
      deleteCouponDialog(coupon),
      () => handleDeleteCoupon(coupon),
      () => {}
    );
  };

  return (
    <div>
      <br />
      <p className="coupon-code">Available Coupon Codes</p>
      <Card className="coupon-card">
        <div className="d-flex p-3 border-bottom">
          <div className="coupon-list-heading">
            User Name: {user.first_name}
          </div>
          <div className="coupon-list-heading">MPID: {user.mpid}</div>
          <div className="coupon-list-heading" style={{ textAlign: 'right' }}>
            Number of Coupons:{' '}
            {allCouponsDetails && allCouponsDetails.length
              ? allCouponsDetails.length
              : '0'}
          </div>
        </div>
        <div className="d-flex p-3 border-bottom" style={{ border: 'none' }}>
          <div style={{ width: '33%' }}>Mobile No: {user.mobile}</div>
          <div style={{ width: '46%' }}>Email: {user.email}</div>
          <div style={{ width: '20%', textAlign: 'right' }}>
            {allCouponsDetails && allCouponsDetails.length ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setToggleCouponDetails(!toggleCouponDetail)}
              >
                {toggleCouponDetail ? 'Hide Details' : 'Show Details'}
              </Button>
            ) : (
              <p
                className="full-width"
                style={{
                  textAlign: 'center'
                }}
              >
                No coupon code available for current MPID.
              </p>
            )}
          </div>
        </div>
      </Card>
      <br />
      {toggleCouponDetail && allCouponsDetails && allCouponsDetails.length ? (
        <Card className="coupon-table">
          <CardBody>
            <div className="d-flex p-2 border-bottom">
              <div className="width-12 font-weight-bold">Coupon Code</div>
              <div className="width-11 font-weight-bold">Created On</div>
              <div className="width-11 font-weight-bold">Created By</div>
              <div className="width-11 font-weight-bold">Expiry Date</div>
              <div className="width-11 font-weight-bold">Redeemed</div>
              <div className="width-11 font-weight-bold">Redeemed On</div>
              <div className="font-weight-bold" style={{ width: '22%' }}>
                Comment
              </div>
              <div className="width-11 font-weight-bold">Expire</div>
            </div>

            {allCouponsDetails.map((coupon, index) => (
              <div key={index} className="d-flex p-2 border-bottom">
                <div className="width-12">{coupon.couponcode}</div>
                <div className="width-11">
                  {moment.utc(coupon.created_at).format('DD MMM YYYY')}
                </div>
                <div className="width-11">{coupon.createdby}</div>
                <div className="width-11">
                  {moment.utc(coupon.expirydate).format('DD MMM YYYY')}
                </div>
                <div className="width-11">
                  {coupon.isredeemable ? 'No' : 'Yes'}
                </div>
                <div className="width-11">
                  {coupon.redeemedat
                    ? moment.utc(coupon.redeemedat).format('DD MMM YYYY')
                    : '-'}
                </div>
                <div
                  style={{
                    width: '22%'
                  }}
                  id={`comment-${index}`}
                >
                  {truncateComment(coupon.comment, 30)}
                </div>
                {coupon.comment.length > 30 && (
                  <UncontrolledTooltip
                    placement="top"
                    target={`comment-${index}`}
                  >
                    {coupon.comment}
                  </UncontrolledTooltip>
                )}

                <div className="width-11">
                  {coupon.isredeemable ? (
                    <Button
                      color="link"
                      onClick={() => confirm(coupon)}
                      style={{ padding: '0' }}
                    >
                      <FaTrash />
                    </Button>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            ))}
          </CardBody>
        </Card>
      ) : null}
    </div>
  );
};

export default CouponDetail;
