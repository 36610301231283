import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Query } from 'react-apollo';
import { SEARCH_BOOKINGS } from '../queries';
import Loader from './Loader';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { FaStopwatch, FaWalking, FaCrown } from 'react-icons/fa';
import styled from 'styled-components';

const Name = styled.span`
  text-decoration: ${props => (props.cancelledTS ? 'line-through' : 'none')};
  & > svg {
    vertical-align: baseline;
  }
`;

const getWaitTime = booking => {
  const checkinTime = booking.checkinTS;
  if (!checkinTime) {
    return 'No wait';
  }
  const checkinTimeMoment = moment(checkinTime);
  const apptTime = moment(booking.slotTime);
  const expectedStartTime = apptTime.isAfter(checkinTimeMoment)
    ? apptTime
    : checkinTimeMoment;

  let actualStartTime = moment(); //well, we hope it starts now
  if (booking.startConsultationTS) {
    actualStartTime = moment(booking.startConsultationTS);
  }

  if (actualStartTime.isBefore(expectedStartTime)) {
    return 'No wait';
  } else {
    const diff = actualStartTime.diff(expectedStartTime, 'minutes');
    return moment.duration(diff, 'minutes').humanize();
  }
};

const getText = (booking, extraAttrObj) => {
  if (extraAttrObj.label === 'Wait Time') {
    return getWaitTime(booking);
  } else if (extraAttrObj.isDate) {
    const d = moment(booking[extraAttrObj.value]);
    if (d.isValid()) {
      return d.format('D MMM hh:mm A');
    } else {
      return 'Not applicable';
    }
  }
  if (extraAttrObj.value === 'mpid') {
    const m = booking[extraAttrObj.value];
    return !m || m.trim().length < 1 ? '________________' : `${m}`.slice(-6);
  } else {
    return booking[extraAttrObj.value];
  }
};

export default class BookingsPrintAreaGrouped extends Component {
  render() {
    const {
      searchCriteria,
      fields = [],
      extraAttrs,
      showCanceled,
      emptyRowsCount,
      fromTime,
      toTime
    } = this.props;
    const extraAttrTitles = extraAttrs.map(a => a.label);
    return (
      <Query query={SEARCH_BOOKINGS} variables={{ input: searchCriteria }}>
        {({ loading, data: { getBookings }, error }) => {
          if (error) return <span>Something went wrong!</span>;
          if (loading) return <Loader />;
          if (!getBookings || getBookings.length < 1)
            return <span>No BOOKINGS</span>;

          const date = moment(getBookings[0].slotTime).format('D MMM YYYY');
          const groupedBooking = groupBy(getBookings, 'objectName');
          const doctorsCount = Object.entries(groupedBooking).length;

          const print = Object.entries(groupedBooking).map(
            ([docName, bookings], docIndex) => {
              const bookingDate = moment(bookings[0].slotTime).format(
                'DD-MM-YYYY'
              );
              const from = moment(
                `${bookingDate} ${fromTime}`,
                'DD-MM-YYYY hh:mm a'
              );
              const to = moment(
                `${bookingDate} ${toTime}`,
                'DD-MM-YYYY hh:mm a'
              );
              const displayableBookings = bookings
                // if showCanceled, then show all, else only without cancelledTS
                .filter(b => showCanceled || !b.cancelledTS)
                .filter(b =>
                  moment(b.slotTime).isBetween(from, to, null, '[]')
                );
              if (displayableBookings.length) {
                return (
                  <div key={docName}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h4>{docName}</h4>
                      <h4>{date}</h4>
                    </div>
                    <Table bordered size="sm" className="print-appts">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Patient</th>
                          {extraAttrTitles.map(t => (
                            <th key={t}>{t}</th>
                          ))}
                          {fields.map((f, i) => (
                            <th style={{ minWidth: '3rem' }} key={`field-${i}`}>
                              {f}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {displayableBookings.map((b, bidx) => (
                          <tr key={b.id}>
                            <td>
                              <strong>{bidx + 1}</strong>
                            </td>
                            <td>
                              <Name>
                                {b.userName} {b.isTatkal && <FaStopwatch />}{' '}
                                {b.isWalkIn && <FaWalking />}{' '}
                                {b.isReserved && <FaCrown />}
                              </Name>
                            </td>
                            {extraAttrs.map(ea => (
                              <td key={`ea-${b.id}-${ea.value}`}>
                                {getText(b, ea)}
                              </td>
                            ))}
                            {fields.map((_, i) => (
                              <td key={`fv-${b.id}-${i}`} />
                            ))}
                          </tr>
                        ))}
                        {emptyRowsCount > 0 &&
                          Array(emptyRowsCount)
                            .fill()
                            .map((v, eri) => (
                              <tr key={`extra-${docName}-${eri}`}>
                                <td>
                                  <strong>
                                    {displayableBookings.length + eri + 1}
                                  </strong>
                                </td>
                                <td>&nbsp;</td>
                                {Array(fields.length + extraAttrs.length)
                                  .fill()
                                  .map((v, erii) => (
                                    <td
                                      key={`erii-${docName}-${eri}-${erii}`}
                                    />
                                  ))}
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                    {doctorsCount > docIndex + 1 && (
                      <div className="scissors">
                        <img src="/scissors.png" alt="scissors" />
                      </div>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            }
          );

          return (
            <div style={{ padding: '2rem' }} className="print-appt">
              {print}
            </div>
          );
        }}
      </Query>
    );
  }
}

BookingsPrintAreaGrouped.propTypes = {
  searchCriteria: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string),
  extraAttrs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
    )
  ),
  showCanceled: PropTypes.bool,
  emptyRowsCount: PropTypes.number,
  fromTime: PropTypes.string,
  toTime: PropTypes.string
};

BookingsPrintAreaGrouped.defaultProps = {
  fields: [],
  extraAttrs: [],
  showCanceled: false,
  emptyRowsCount: 0
};
