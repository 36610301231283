import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const collectValues = (selectedOpts, isMulti, onChange) => {
  if (!isMulti) {
    onChange(selectedOpts.value.toLowerCase());
    return;
  }
  onChange(selectedOpts.map(o => o.value.toLowerCase()));
};
const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const SelectLanguages = ({ onChange, isMulti, value }) => {
  console.log('vv' + typeof value);
  const vmapped = value.map(v => ({
    label: capitalize(v),
    value: capitalize(v)
  }));
  return (
    <Select
      options={opts}
      onChange={v => collectValues(v, isMulti, onChange)}
      isMulti={isMulti}
      value={vmapped}
    />
  );
};

SelectLanguages.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string)
};

SelectLanguages.defaultProps = {
  onChange: () => {},
  isMulti: true,
  value: []
};

const opts = [
  { label: 'Afrikanns', value: 'Afrikanns' },
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Basque', value: 'Basque' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Catalan', value: 'Catalan' },
  { label: 'Cambodian', value: 'Cambodian' },
  { label: 'Mandarin', value: 'Mandarin' },
  { label: 'Croation', value: 'Croation' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'Georgian', value: 'Georgian' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Gujarati', value: 'Gujarati' },
  { label: 'Hebrew', value: 'Hebrew' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Irish', value: 'Irish' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Javanese', value: 'Javanese' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Macedonian', value: 'Macedonian' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malayalam', value: 'Malayalam' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Maori', value: 'Maori' },
  { label: 'Marathi', value: 'Marathi' },
  { label: 'Mongolian', value: 'Mongolian' },
  { label: 'Nepali', value: 'Nepali' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Persian', value: 'Persian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Punjabi', value: 'Punjabi' },
  { label: 'Quechua', value: 'Quechua' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Samoan', value: 'Samoan' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swahili', value: 'Swahili' },
  { label: 'Swedish ', value: 'Swedish' },
  { label: 'Tamil', value: 'Tamil' },
  { label: 'Tatar', value: 'Tatar' },
  { label: 'Telugu', value: 'Telugu' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Tibetan', value: 'Tibetan' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Ukranian', value: 'Ukranian' },
  { label: 'Urdu', value: 'Urdu' },
  { label: 'Uzbek', value: 'Uzbek' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Xhosa', value: 'Xhosa' }
];

export default SelectLanguages;
