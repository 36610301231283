import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaListAlt } from 'react-icons/fa';
import WeeklySchedule from './WeeklySchedule';

const WeeklySchedulePopover = ({ entityId, entityName, buttonText }) => {
  const [showSch, setShowSch] = useState(false);
  const toggle = () => setShowSch(!showSch);
  return (
    <>
      <Button
        color="link"
        id="docTiming"
        onClick={() => setShowSch(!showSch)}
        style={{ padding: 0, margin: 0 }}
      >
        {buttonText || <FaListAlt />}
      </Button>
      <Modal isOpen={showSch} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Weekly Schedule
          {entityName && `${entityName}`.length > 0 ? `: ${entityName}` : ''}
        </ModalHeader>
        <ModalBody className="weekly-sch-modal">
          <WeeklySchedule entityId={entityId} />
        </ModalBody>
      </Modal>
    </>
  );
};

WeeklySchedulePopover.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityName: PropTypes.string,
  buttonText: PropTypes.string
};
WeeklySchedulePopover.defaultProps = {
  entityName: ''
};

export default WeeklySchedulePopover;
