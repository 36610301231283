import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Select from 'react-select';
import { LOCAL_USER_HOSPITALS, GET_ENTITY_SCH } from '../queries';
import moment, { Moment } from 'moment';
import Loader from './Loader';
import DatePicker from 'react-datepicker';
import { useQuery } from '@apollo/react-hooks';
import { toMoment } from './ModifySlotContainer';
import styled from 'styled-components';

const Row = styled.tr`
  background-color: ${props => (props.isAdhoc ? '#b8daff' : null)};
`;

const TimeRange = ({ timerange, actionOpts, setWizardState, dateMoment }) => {
  const {
    start,
    end,
    // slotPrototypeId,
    isAltered,
    alteredStart,
    alteredEnd,
    isAdhoc,
    // hospitalName,
    // isTatkalEnabled,
    // isBlockAll,
    userName,
    updatedAt,
    actionLog,
    hasTatkalSlots
  } = timerange;
  /*


        start
        end
        slotPrototypeId
        isAltered
        alteredStart
        alteredEnd
        isAdhoc
        hospitalName
        isTatkalEnabled
        isBlockAll
        userName
        updatedAt


  */
  const originalStart = moment(start, 'HHmm');
  const originalEnd = moment(end, 'HHmm');
  const s = isAltered
    ? toMoment(alteredStart, dateMoment)
    : toMoment(start, dateMoment);
  const e = isAltered
    ? toMoment(alteredEnd, dateMoment)
    : toMoment(end, dateMoment);

  const adhocNotSet = !isAltered && isAdhoc;
  const getActionOptions = () => {
    let filteredActionOpts = actionOpts;
    if (adhocNotSet) {
      filteredActionOpts = filteredActionOpts.map(o =>
        o.value !== 'editTime' ? { ...o, isDisabled: true } : o
      );
    }

    if (!hasTatkalSlots) {
      filteredActionOpts = [...filteredActionOpts].filter(
        o => o.value !== 'blockTatkal'
      );
    }
    return filteredActionOpts;
  };
  return (
    <Row isAdhoc={isAdhoc}>
      {adhocNotSet ? (
        <td>ADHOC SCHEDULE</td>
      ) : (
        <td>
          {s.format('h:mm A')} - {e.format('h:mm A')}
          <br />
          {isAltered && (
            <span style={{ fontSize: '0.8rem' }}>
              Originally: {originalStart.format('h:mm A')} -{' '}
              {originalEnd.format('h:mm A')}
            </span>
          )}
        </td>
      )}
      <td>
        {actionLog && (
          <ul>
            {actionLog.map(m => (
              <li key={m}>{m}</li>
            ))}
          </ul>
        )}
      </td>
      <td>
        {isAltered && (
          <span>
            {userName}
            <br />
            {moment(updatedAt).format('DD/MM/YYYY h:mm A')}
          </span>
        )}
      </td>
      <td>
        <div style={{ minWidth: '10rem' }}>
          <Select
            options={getActionOptions()}
            onChange={selectedOpt =>
              setWizardState({
                screen: selectedOpt.value,
                alteredSchedule: timerange
              })
            }
          />
        </div>
      </td>
    </Row>
  );
};

TimeRange.propTypes = {
  timerange: PropTypes.object.isRequired,
  actionOpts: PropTypes.object.isRequired,
  setWizardState: PropTypes.func.isRequired,
  dateMoment: PropTypes.instanceOf(Moment).isRequired
};

const ModifySlotShowSchedule = ({
  actionOpts,
  setWizardState,
  entityId,
  date,
  setDate
}) => {
  const { data: hospitalData } = useQuery(LOCAL_USER_HOSPITALS);
  const hospitalId = hospitalData.selectedHospitals[0];
  const { data: { schedule } = {}, loading, error } = useQuery(GET_ENTITY_SCH, {
    variables: {
      entityId,
      on: moment(date).format('YYYY-MM-DD'),
      hospitalId
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <span className="error">Something went wrong</span>;
  }

  /*

YYYYMMDD
      timeRanges {
        start
        end
        slotPrototypeId
        isAltered
        alteredStart
        alteredEnd
        isAdhoc
        hospitalName
        isTatkalEnabled
        isBlockAll
        userName
        updatedAt
      }

      */

  console.log(schedule);

  return (
    <>
      <div style={{ width: '15rem', marginBottom: '1rem' }}>
        <DatePicker
          selected={date}
          onChange={setDate}
          dateFormat="ddd, DD MMM YYYY"
        />
      </div>
      <Table>
        <thead>
          <tr>
            <th>Current Timing</th>
            <th>Modification</th>
            <th>Modified By</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {schedule.timeRanges.map((t, i) => (
            <TimeRange
              timerange={t}
              key={`tr-${i}`}
              actionOpts={actionOpts}
              setWizardState={setWizardState}
              dateMoment={date}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

ModifySlotShowSchedule.propTypes = {
  actionOpts: PropTypes.arrayOf(PropTypes.object),
  setWizardState: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  date: PropTypes.any.isRequired,
  setDate: PropTypes.func.isRequired
};

export default ModifySlotShowSchedule;
