import React from 'react';
import { Mutation } from 'react-apollo';
import { UPLOAD_QWBOOKINGS_JSON } from '../queries/index';
import Loader from '../components/Loader';
import { Table } from 'reactstrap';

const UploadQWBookings = () => {
  return (
    <div>
      <h1>Upload bookings json data</h1>
      <Mutation mutation={UPLOAD_QWBOOKINGS_JSON}>
        {(fileUpload, { loading, data }) => {
          const res =
            data && data.uploadQwBookingJson ? data.uploadQwBookingJson : [];
          return (
            <>
              <input
                type="file"
                onChange={event => {
                  const {
                    target: {
                      validity,
                      files: [file]
                    }
                  } = event;
                  // console.log(`fkkiles: ${JSON.stringify(file.name)}`);
                  validity.valid && fileUpload({ variables: { file } });
                }}
              />
              {loading && <Loader />}

              {
                <>
                  <h3>Total: {res.totalRecords}</h3>
                  <h3>Success: {res.success}</h3>
                  <h3>Failure: {res.failure}</h3>
                  <h3>Cancelled Bookings: {res.cancelledBookings}</h3>
                </>
              }
              <h3>Errors:</h3>

              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Message</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {res.errors &&
                      res.errors.map((e, i) => (
                        <tr key={i}>
                          <td>{e.message}</td>
                          <td>
                            <pre>{JSON.stringify(e.data, null, 2)}</pre>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          );
        }}
      </Mutation>
    </div>
  );
};

export default UploadQWBookings;
