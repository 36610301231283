import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DoctorList from './DoctorList';
import { getDoctorRankings } from '../utilities';
import PropTypes from 'prop-types';

const DoctorRankingList = ({ hospital, isActiveTab }) => {
  const [rankings, setRankings] = useState();

  useEffect(() => {
    if (!isActiveTab) return;
    async function fetchData() {
      try {
        const data = await getDoctorRankings(hospital.id);
        if (data && data.data && data.data.getDoctorsRanking) {
          setRankings(data.data.getDoctorsRanking);
          return;
        }
        if (data.errors && data.errors[0] && data.errors[0].message) {
          toast.error(`${data.errors[0].message}`, { autoClose: 1500 });
        } else toast.error('Something went wrong!', { autoClose: 1500 });
      } catch (err) {
        toast.error(`Something went wrong! , ${err}`, { autoClose: 1500 });
      }
    }
    fetchData();
  }, [isActiveTab, hospital]);

  if (hospital && rankings && rankings.length === 0) {
    return <h3>No Doctors in this hospital</h3>;
  }

  return (
    <>
      <DoctorList list={rankings} hospitalId={hospital.id} />
    </>
  );
};

DoctorRankingList.propTypes = {
  hospital: PropTypes.object,
  isActiveTab: PropTypes.bool
};

export default DoctorRankingList;
