import React from 'react';
import PropTypes from 'prop-types';
import {
  FaUserMd,
  FaPhone,
  FaTooth,
  FaTasks,
  FaUserGraduate,
  FaAddressCard,
  FaAward,
  FaInfoCircle,
  FaRegCalendarAlt,
  FaLanguage,
  FaBlackTie,
  FaRegHospital
} from 'react-icons/fa';
import { IoIosMail, IoIosMale, IoIosFemale } from 'react-icons/io';
import { GiBiohazard, GiRank3 } from 'react-icons/gi';
import moment from 'moment';

const DoctorProfile = ({ doctor }) => {
  const {
    name,
    email,
    phoneNumber,
    gender,
    primarySpecialty,
    secondarySpecialty,
    department,
    services,
    tags,
    qualification,
    medicalCouncilRegNo,
    awards,
    aboutMe,
    dateOfJoining,
    languages,
    membership,
    dateSincePracticing,
    getHospitals,
    hasOp,
    hasIp,
    hasOt,
    isEnabled,
    isVisible
  } = doctor || {};

  const dateSincePracticingM = dateSincePracticing
    ? moment(dateSincePracticing)
    : null;
  let duration = 'NA';
  if (dateSincePracticingM) {
    const diff = moment().diff(dateSincePracticingM, 'days');
    duration = moment.duration(diff, 'days').humanize();
  }

  return (
    <div className="doc-detail-banner">
      <div>
        <FaUserMd title="Name" /> {name}
      </div>
      <div>
        <IoIosMail title="E-mail" /> {email}
      </div>
      <div>
        <FaPhone title="Phone Number" /> {phoneNumber}
      </div>
      <div>
        {gender === 'Male' ? (
          <IoIosMale title="Gender Male" />
        ) : (
          <IoIosFemale title="Gender Female" />
        )}{' '}
        {gender}
      </div>
      <div>
        <GiBiohazard title="Specialities" /> {primarySpecialty}
        {secondarySpecialty ? `, ${secondarySpecialty}` : ''}
      </div>
      <div>
        <FaTooth title="Department" /> {department}
      </div>
      <div>
        <FaTasks title="Services" /> {services}
      </div>
      <div>
        <FaTasks title="Tags" /> {tags}
      </div>
      <div>
        <FaUserGraduate title="Qualification" /> {qualification}
      </div>
      <div>
        <FaAddressCard title="Medical Council Registration Number" />{' '}
        {medicalCouncilRegNo}
      </div>
      <div>
        <FaAward title="Awards" /> {awards}
      </div>
      <div>
        <FaInfoCircle title="About Me" /> {aboutMe}
      </div>
      <div>
        <FaRegCalendarAlt title="Date of Joining" />{' '}
        {dateOfJoining
          ? moment(dateOfJoining, 'YYYY-MM-DD').format('D MMMM YYYY')
          : 'Date of joining not set'}
      </div>
      <div>
        <FaLanguage title="Languages" />{' '}
        {languages ? languages.join(', ') : 'Languages not set'}
      </div>
      <div>
        <FaBlackTie title="Membership" /> {membership}
      </div>
      <div>
        <GiRank3 title="Experience" /> {duration}
      </div>
      <div>
        <FaRegHospital />{' '}
        {getHospitals ? getHospitals.map(h => h.name).join(', ') : 'NA'}
      </div>
      <div>OP ACCESS? {hasOp ? 'Yes' : 'No'}</div>
      <div>IP ACCESS? {hasIp ? 'Yes' : 'No'}</div>
      <div>OT ACCESS? {hasOt ? 'Yes' : 'No'}</div>
      <div>IS VISIBLE PUBLICLY? {isVisible ? 'Yes' : 'No'}</div>
      <div>IS ACCOUNT ENABLED? {isEnabled ? 'Yes' : 'No'}</div>
    </div>
  );
};

DoctorProfile.propTypes = {
  doctor: PropTypes.object.isRequired
};

export default DoctorProfile;
