import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Button } from 'reactstrap';
import { RESCHEDULE_BOOKING } from '../queries';
import Loader from './Loader';
import { Mutation } from 'react-apollo';
import { displayError, displaySuccess } from '../utilities';

const DATE_FORMAT = 'D MMM YYYY, h:mm A';

const RescheduleBar = ({ bookingId, slotId, slotTime, onRescheduleDone }) => {
  const isSlotSelected = !!slotId;

  return (
    <Mutation
      mutation={RESCHEDULE_BOOKING}
      onError={e => {
        onRescheduleDone();
        displayError(e);
      }}
      onCompleted={({ rescheduleBooking: { slotTime, rescheduledFrom } }) => {
        onRescheduleDone();
        displaySuccess(
          `The booking has been reschedule from ${moment(
            rescheduledFrom
          ).format(DATE_FORMAT)} to ${moment(slotTime).format(DATE_FORMAT)}`
        );
      }}
    >
      {(reschedule, { loading }) => {
        // console.log('data', data);
        return (
          <Alert color="primary">
            <strong>
              {!isSlotSelected
                ? 'Select a slot'
                : `Reschedule to ${moment(slotTime).format(DATE_FORMAT)}? `}
            </strong>
            <span>&nbsp;</span>
            {isSlotSelected && (
              <Button
                size="sm"
                color="info"
                disabled={loading}
                onClick={() =>
                  reschedule({
                    variables: { bookingId, slotId }
                  })
                }
              >
                Reschedule
              </Button>
            )}
            {loading && <Loader size={5} inline />}
          </Alert>
        );
      }}
    </Mutation>
  );
};

RescheduleBar.propTypes = {
  bookingId: PropTypes.string.isRequired,
  slotId: PropTypes.string,
  slotTime: PropTypes.instanceOf(Date),
  onRescheduleDone: PropTypes.func
};

export default RescheduleBar;
