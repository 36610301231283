import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import { GET_PROTOCOLS, GET_ALL_HOSPITALS } from '../queries';
import { useLazyQuery, Query } from 'react-apollo';
import Select from 'react-select';

const ApproveProtocol = ({ approvalHandler }) => {
  const [
    getAllProtocols,
    { data: getProtocols, loading: isLoading }
  ] = useLazyQuery(GET_PROTOCOLS);
  const [hospital, setSelectedHospital] = useState();

  function getOptionLabel({ name }) {
    return name;
  }

  function getOptionValue({ id }) {
    return id;
  }

  function onHospitalChange(value) {
    setSelectedHospital(value);
    getAllProtocols({
      variables: {
        hospitalId: value.id,
        approved: false
      }
    });
  }

  return (
    <>
      <h1>Approve Protocols</h1>
      <Row>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ minWidth: '30rem' }}>
              <Query query={GET_ALL_HOSPITALS}>
                {({ loading, data: { hospitals } = {} }) => {
                  return (
                    <Select
                      value={hospital}
                      options={hospitals}
                      isLoading={loading || isLoading}
                      onChange={onHospitalChange}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={getOptionValue}
                    />
                  );
                }}
              </Query>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 20, flexDirection: 'column' }}>
        {getProtocols &&
          (getProtocols.getProtocols.length > 0 ? (
            getProtocols.getProtocols.map(protocol => (
              <div
                key={protocol.id}
                style={{
                  backgroundColor: '#dee2e6',
                  padding: 20,
                  borderRadius: 5,
                  marginBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h3>Title : {protocol.title}</h3>

                  <Button
                    color="primary"
                    onClick={() => {
                      approvalHandler(protocol, hospital);
                    }}
                    style={{ width: 100 }}
                  >
                    View
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10
                  }}
                >
                  <h5>Type : Edit/New </h5>
                  <h5>Submitted By: {protocol.updatedBy}</h5>
                </div>
              </div>
            ))
          ) : (
            <h3
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              No Protocol found for Approval for the selected hospital
            </h3>
          ))}
      </div>
    </>
  );
};

ApproveProtocol.propTypes = {
  approvalHandler: PropTypes.func
};

export default ApproveProtocol;
