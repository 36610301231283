import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Tooltip from './Tooltip';
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';
import { isPermitted, permissions } from '../permissions';
import { authAPI, displayError } from '../utilities';
import Loader from './Loader';
import RunSlotCreationJob from './RunSlotCreationJob';
import SlotProtoContainer from './SlotProtoContainer';
import SlotPrototypeFormModal from './SlotPrototypeFormModal';
import DocForm from './docForm';
import EducationExperienceForm from './EduAndExpTab';

const tabs = {
  info: 'info',
  education: 'education',
  timing: 'timing',
  authorization: 'authorization'
};

const DoctorEditTabs = ({ doctor, departments, specializations }) => {
  const [selectedTab, setSelectedTab] = useState(tabs.info);
  const [isEditSlotprotoModalOpen, toggleEditSlotprotoModal] = useState(false);
  const [editSlotPrototypeId, setEditSlotPrototypeId] = useState(null);
  const [docDetails, setDocDetails] = useState({
    loading: false,
    status: '',
    enabled: '',
    date: '',
    userId: '',
    buttondisabled: true,
    onboardButton: true,
    email: '',
    mobile: ''
  });
  const { id, entityId, name, aboutMe, profileStatus } = doctor;

  useEffect(() => {
    if (id) {
      handleUserDetails();
    }
    // eslint-disable-next-line
  }, [id]);

  async function handleUserDetails() {
    setDocDetails(prev => ({ ...prev, loading: true }));

    const res = await authAPI({
      method: 'GET',
      query: `?username=${doctor.id}`
    });

    if (!res.status) {
      return setDocDetails(prev => ({
        ...prev,
        status: res.message,
        onboardButton: false,
        loading: false
      }));
    }

    const {
      Username,
      UserStatus,
      Enabled,
      UserCreateDate,
      Attributes
    } = res.data;
    const phoneNumberAttr = Attributes.find(
      attr => attr.Name === 'phone_number'
    );
    const emailAttr = Attributes.find(attr => attr.Name === 'email');

    setDocDetails(prev => ({
      ...prev,
      status: UserStatus,
      enabled: Enabled,
      date: new Date(UserCreateDate).toLocaleString(),
      userId: Username,
      buttondisabled: false,
      loading: false,
      mobile: phoneNumberAttr ? phoneNumberAttr.Value : '',
      email: emailAttr ? emailAttr.Value : ''
    }));
  }

  async function handleOnBoarding() {
    const req_body = {
      username: doctor.id,
      email: doctor.email,
      mobile: doctor.phoneNumber,
      doctorid: doctor.id,
      groups: ['doctors']
    };

    const res = await authAPI({ method: 'POST', body: req_body });

    if (!res.status) {
      return toast.info(res.message, { autoClose: 3000 });
    }

    const mobile = res.data.Attributes.filter(
      attribute => attribute.Name === 'phone_number'
    );
    const email = res.data.Attributes.filter(
      attribute => attribute.Name === 'email'
    );

    setDocDetails(prev => ({
      ...prev,
      status: res.data.UserStatus,
      enabled: res.data.Enabled,
      date: new Date(res.data.UserCreateDate).toLocaleString(),
      userId: res.data.Username,
      mobile: mobile[0].Value,
      email: email[0].Value,
      buttondisabled: false,
      onboardButton: true
    }));
    toast.success('User on-Boarded successfully', { autoClose: 3000 });
    return true;
  }
  async function handleAccess() {
    const req_body = {
      username: doctor.id,
      status: docDetails.enabled ? 'disable' : 'enable'
    };

    const res = await authAPI({ method: 'PATCH', body: req_body });

    setDocDetails(prev => ({
      ...prev,
      enabled: !docDetails.enabled
    }));

    if (!res.status) {
      return toast.info(res.message, { autoClose: 3000 });
    }

    toast.warn(res.message, { autoClose: 3000 });
  }
  async function handleDelete() {
    const req_body = {
      username: doctor.id
    };
    const res = await authAPI({
      method: 'DELETE',
      body: req_body
    });

    if (!res.status) {
      return toast.info(res.message, { autoClose: 3000 });
    }

    setDocDetails(prev => ({
      ...prev,
      status: '',
      enabled: '',
      date: '',
      userId: '',
      mobile: '',
      email: '',
      buttondisabled: !docDetails.buttondisabled,
      onboardButton: false
    }));
    toast.error(res.message, { autoClose: 3000 });
    return true;
  }

  async function handleForceSync() {
    const deleteResp = await handleDelete();
    if (!deleteResp) {
      return;
    }
    const onboardResp = await handleOnBoarding();
    if (!onboardResp) {
      return;
    }
    handleUserDetails();
  }

  function handleConfirm(event) {
    let element;
    let callback;
    switch (event.target.id) {
      case 'forceSync':
        element = (
          <h5>This action will delete and re-create the user in cognito?</h5>
        );
        callback = handleForceSync;
        break;
      case 'deleteBtn':
        element = <h5>Are you sure to delete the access?</h5>;
        callback = handleDelete;
        break;
      default:
        element = (
          <h5>
            Are you sure to {!docDetails.enabled ? 'enable' : 'disable'} the
            access?
          </h5>
        );
        callback = handleAccess;
        break;
    }
    showConfirmation('Confirm', element, callback);
  }

  return (
    <>
      <h2>{name}</h2>
      <Nav tabs style={{ marginBottom: '1rem' }}>
        <NavItem>
          <NavLink
            style={{
              fontWeight: selectedTab === tabs.info ? 'bold' : 'normal'
            }}
            className={classnames({
              activeTab: selectedTab === tabs.info,
              active: selectedTab === tabs.info,
              inactiveTab: selectedTab !== tabs.info
            })}
            onClick={() => setSelectedTab(tabs.info)}
          >
            PERSONAL INFO
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              fontWeight: selectedTab === tabs.education ? 'bold' : 'normal'
            }}
            className={classnames({
              activeTab: selectedTab === tabs.education,
              active: selectedTab === tabs.education,
              inactiveTab: selectedTab !== tabs.education
            })}
            onClick={() => setSelectedTab(tabs.education)}
          >
            EDUCATION & EXPERIENCE
          </NavLink>
        </NavItem>
        {isPermitted(permissions.CAN_VIEW_SLOT_PROTO) && (
          <>
            <NavItem>
              <NavLink
                style={{
                  fontWeight: selectedTab === tabs.timing ? 'bold' : 'normal'
                }}
                className={classnames({
                  activeTab: selectedTab === tabs.timing,
                  active: selectedTab === tabs.timing,
                  inactiveTab: selectedTab !== tabs.timing
                })}
                onClick={() => setSelectedTab(tabs.timing)}
              >
                SCHEDULE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{
                  fontWeight:
                    selectedTab === tabs.authorization ? 'bold' : 'normal'
                }}
                className={classnames({
                  activeTab: selectedTab === tabs.authorization,
                  active: selectedTab === tabs.authorization,
                  inactiveTab: selectedTab !== tabs.authorization
                })}
                onClick={() => setSelectedTab(tabs.authorization)}
              >
                ACCESS MANAGEMENT
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
      <TabContent activeTab={selectedTab}>
        <TabPane tabId={tabs.info}>
          <Row>
            <Col sm="12">
              <DocForm
                doc={doctor}
                departments={departments}
                className="doc-form"
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={tabs.education}>
          <Row>
            <Col>
              <EducationExperienceForm
                doctor={doctor}
                departments={departments}
                specializations={specializations}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={tabs.timing}>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <RunSlotCreationJob
                  entityId={entityId}
                  aboutMe={aboutMe}
                  profileStatus={profileStatus}
                />
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => {
                    if (!aboutMe || aboutMe.trim().length < 100) {
                      displayError(
                        `About Me field should have minimum 100 characters`
                      );
                      return;
                    }
                    if (profileStatus !== 'approved') {
                      displayError(
                        'Doctor is not yet approved to create schedule'
                      );
                      return;
                    }
                    setEditSlotPrototypeId(null);
                    toggleEditSlotprotoModal(true);
                  }}
                >
                  Add New Schedule
                </Button>
              </div>
            </Col>
          </Row>

          <SlotProtoContainer
            entityId={entityId}
            onEdit={slotPrototypeId => {
              setEditSlotPrototypeId(slotPrototypeId);
              toggleEditSlotprotoModal(true);
            }}
          />

          <SlotPrototypeFormModal
            entityId={entityId}
            slotPrototypeId={editSlotPrototypeId}
            isOpen={isEditSlotprotoModalOpen}
            toggle={() => toggleEditSlotprotoModal(!isEditSlotprotoModalOpen)}
            name={name}
          />
        </TabPane>
        <TabPane tabId={tabs.authorization}>
          <Card color="light" className="p-2">
            <div className="d-flex justify-content-between">
              <div>
                {docDetails.loading ? (
                  <Loader inline />
                ) : (
                  <Table className="user-details" borderless>
                    <tbody>
                      <tr>
                        <td>
                          Status : <span>{docDetails.status}</span>
                        </td>
                        <td>
                          Enabled :{' '}
                          <span>
                            {docDetails.enabled === true && (
                              <FaRegCheckCircle color="green" size={28} />
                            )}
                            {docDetails.enabled === false && (
                              <FaRegTimesCircle color="red" size={28} />
                            )}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          UserId : <span>{docDetails.userId}</span>
                        </td>
                        <td>
                          On-Board Date :<span> {docDetails.date}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Mobile : <span>{docDetails.mobile}</span>
                        </td>
                        <td>
                          Email : <span>{docDetails.email}</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </div>
              <div className="d-flex align-items-center mr-2">
                <Button
                  id="forceSync"
                  color="primary"
                  onClick={handleConfirm}
                  disabled={docDetails.buttondisabled}
                >
                  Force Sync User
                </Button>
                <Tooltip
                  target="forceSync"
                  message="This action will delete and re-create the user in cognito"
                  placement="right"
                />
              </div>
            </div>
          </Card>
          <Row>
            <Card color="light" body className="text-center outer">
              <CardTitle>
                <h5>Doctor On-boarding</h5>
              </CardTitle>
              <Button
                color="success"
                style={{ marginBottom: '15px' }}
                onClick={handleOnBoarding}
                disabled={docDetails.onboardButton}
              >
                On-Board
              </Button>
              <CardText>
                <i>This action will allow user to login in DxApp</i>
              </CardText>
            </Card>
            <Card color="light" body className="text-center outer">
              <CardTitle>
                <h5>{!docDetails.enabled ? 'Enable' : 'Disable'} Access</h5>
              </CardTitle>
              <Button
                id="enableBtn"
                color="warning"
                style={{ marginBottom: '15px' }}
                onClick={handleConfirm}
                disabled={docDetails.buttondisabled}
              >
                {!docDetails.enabled ? 'Enable' : 'Disable'}
              </Button>
              <CardText>
                <i>
                  This action will {!docDetails.enabled ? 'enable' : 'disable'}{' '}
                  user login access from DxApp
                </i>
              </CardText>
            </Card>
            <Card color="light" body className="text-center outer">
              <CardTitle>
                <h5>Delete Doctor</h5>
              </CardTitle>
              <Button
                id="deleteBtn"
                color="danger"
                style={{ marginBottom: '15px' }}
                onClick={handleConfirm}
                disabled={docDetails.buttondisabled}
              >
                Delete
              </Button>
              <CardText>
                <i>This action will delete the user access from DxApp</i>
              </CardText>
            </Card>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
};

DoctorEditTabs.propTypes = {
  doctor: PropTypes.object.isRequired,
  departments: PropTypes.arrayOf(PropTypes.object).isRequired,
  specializations: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DoctorEditTabs;
