import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { SEND_BOOKING_REMINDER } from '../queries';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import { Button } from 'reactstrap';
import { FaPaperPlane } from 'react-icons/fa';

const SendBookingReminder = ({ bookingId, disabled }) => {
  return (
    <Mutation
      mutation={SEND_BOOKING_REMINDER}
      onCompleted={() => displaySuccess('Reminder Sent')}
      onError={e => displayError(e)}
      refetchQueries={() => ['BOOKING_MESSAGE_HISTORY']}
    >
      {(sendReminder, { loading }) => (
        <Button
          color="link"
          title="Send Reminder Message"
          size="sm"
          disabled={disabled || loading}
          onClick={() => {
            sendReminder({ variables: { bookingId } });
            logAmplitudeEvent('Send_Reminder_Tab_Pressed', {}, true);
          }}
        >
          <FaPaperPlane />
        </Button>
      )}
    </Mutation>
  );
};

SendBookingReminder.propTypes = {
  bookingId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

SendBookingReminder.defaultProps = {
  disabled: false
};

export default SendBookingReminder;
