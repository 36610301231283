import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Select from 'react-select';
import { LOOKUP_PROCEDURES } from '../queries';

const SelectProcedureV3 = ({ excludeProcedures, value, onChange }) => (
  <Query query={LOOKUP_PROCEDURES}>
    {({ loading, data: { procedures } = {} }) => {
      const opts = (procedures || [])
        .filter(p => !excludeProcedures.includes(p.name))
        .map(({ name, id }) => ({ label: name, value: id }));

      const selectedOpt = opts.find(item => item.value === value);

      return (
        <Select
          isLoading={loading}
          options={opts}
          value={selectedOpt}
          onChange={o => onChange(o.value)}
        />
      );
    }}
  </Query>
);

SelectProcedureV3.propTypes = {
  excludeProcedures: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.number,
  onChange: PropTypes.func
};

SelectProcedureV3.defaultProps = {
  excludeProcedures: [],
  onChange: () => {}
};

export default SelectProcedureV3;
