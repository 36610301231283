import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-toggle-switch';
import { Button } from 'reactstrap';
import { FaCheckSquare, FaTimes } from 'react-icons/fa';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import { displayError, logAmplitudeEvent } from '../utilities';
import Loader from './Loader';
import InOutBadge from './InOutBadge';

export const UPDATE_ROOM_STATUS = gql`
  mutation UPDATE_ROOM_STATUS($input: EntityInput) {
    updateEntity(input: $input) {
      id
      objectId
      type
      departmentId
      roomNo
      isInRoom
    }
  }
`;

const styles = {
  roomRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  roomLabel: { marginRight: '1em' },
  roomNumber: { display: 'flex', flexDirection: 'row' },
  roomInput: {
    marginRight: '1em',
    paddingTop: 0,
    paddingBottom: 0,
    width: '3em'
  },
  switchRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  }
};

const RoomStatus = props => {
  const [isEditing, setIsEditing] = useState(false);
  const [roomStatus, setRoomStatus] = useState({});
  const { entityId, doctorName } = props;
  const updateRoomNo = e => {
    const roomNo = e.target.value;
    setRoomStatus({ ...roomStatus, roomNo });
  };
  const saveRoomNo = (e, saveFn) => {
    e.preventDefault();
    saveFn({
      variables: {
        input: { id: entityId, roomNo: roomStatus.roomNo }
      }
    });
    setIsEditing(!isEditing);
  };

  const toggleEdit = e => {
    e.preventDefault();
    if (isEditing) {
      setRoomStatus({ ...roomStatus, roomNo: undefined });
    }
    setIsEditing(!isEditing);
  };

  const toggleInOut = saveFn => {
    const currStts =
      roomStatus.isInRoom === undefined ? props.isInRoom : roomStatus.isInRoom;
    const isInRoomVal = !currStts;
    logAmplitudeEvent(
      isInRoomVal ? 'Doctor_IN_Tab_Pressed' : 'Doctor_OUT_Tab_Pressed',
      { doctorName },
      true
    );
    setRoomStatus({ ...roomStatus, isInRoom: isInRoomVal });
    saveFn({
      variables: {
        input: { id: entityId, isInRoom: isInRoomVal }
      }
    });
  };

  const handleSaveRoomNoError = err => {
    displayError('Error updating room number.');
    // eslint-disable-next-line
    console.error(`Error updating room number`, err);
    //rollback!
    setRoomStatus({
      ...roomStatus,
      roomNo: props.roomNo,
      isInRoom: props.isInRoom
    });
  };

  const roomNo =
    roomStatus.roomNo !== undefined ? roomStatus.roomNo : props.roomNo;
  const isInRoom =
    roomStatus.isInRoom === undefined ? props.isInRoom : roomStatus.isInRoom;

  const roomStr = roomNo || 'Not Set';
  //   console.log(`isInRoom: ${isInRoom}`);
  return (
    <Mutation
      mutation={UPDATE_ROOM_STATUS}
      onError={handleSaveRoomNoError}
      refetchQueries={() => [
        'ENTITY_SCH_QUERY',
        'SEARCH_DOCTORS',
        'SEARCH_DOCTORS_EXTENDED'
      ]}
    >
      {(updateStatus, { loading, error }) => {
        if (error) {
          // eslint-disable-next-line
          console.error('Error updating room status', error);
        }
        // eslint-disable-next-line
        // console.log('data returned from update status', data);
        return (
          <React.Fragment>
            <div style={styles.roomRow}>
              <div style={styles.roomNumber}>
                <h6 className="inline-force" style={styles.roomLabel}>
                  Room:
                </h6>
                {isEditing ? (
                  <React.Fragment>
                    <input
                      disabled={loading}
                      type="text"
                      defaultValue={roomNo}
                      style={styles.roomInput}
                      onChange={updateRoomNo}
                    />
                    <Button
                      color="link"
                      onClick={e => saveRoomNo(e, updateStatus)}
                      className="button-as-link"
                    >
                      <h4 className="inline-force">
                        <FaCheckSquare />
                      </h4>
                    </Button>
                    <Button
                      color="link"
                      onClick={toggleEdit}
                      className="button-as-link"
                    >
                      <h4 className="inline-force">
                        <FaTimes />
                      </h4>
                    </Button>
                  </React.Fragment>
                ) : (
                  <h6 className="inline-force">
                    {loading ? (
                      <span> {roomStr}</span>
                    ) : (
                      <Button
                        color="link"
                        onClick={toggleEdit}
                        className="button-as-link"
                      >
                        {roomStr}
                      </Button>
                    )}
                  </h6>
                )}
              </div>
              <InOutBadge isInRoom={isInRoom} />
            </div>
            <div style={styles.switchRow}>
              <Switch
                onClick={() => toggleInOut(updateStatus)}
                on={isInRoom}
                enabled={!loading}
              />
              {loading && <Loader size={5} />}
            </div>
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};

RoomStatus.propTypes = {
  entityId: PropTypes.number,
  isInRoom: PropTypes.bool,
  roomNo: PropTypes.string,
  doctorName: PropTypes.string
};

export default RoomStatus;
