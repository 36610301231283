import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ModifySlotAffectedBookings from './ModifySlotAffectedBookings';
import styled from 'styled-components';

const Toolbar = styled.div`
  display: flex;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  border: 1px solid #ccc;
`;

const ModifySlotAffectedBookingsToolBar = ({
  modification,
  slotPrototypeId,
  date,
  leaveOrphans,
  delayFrom,
  delayInMinutes,
  newStartTime,
  newEndTime,
  enabled
}) => {
  const [isShowBookings, setIsShowBookings] = useState(false);
  return (
    <Container>
      <Toolbar>
        <Button
          disabled={!enabled && !isShowBookings}
          color="link"
          onClick={() => setIsShowBookings(!isShowBookings)}
        >
          {isShowBookings ? 'Hide Affected Bookings' : 'Show Affected Bookings'}
        </Button>
      </Toolbar>
      {isShowBookings && (
        <div>
          <ModifySlotAffectedBookings
            modification={modification}
            slotPrototypeId={slotPrototypeId}
            date={date}
            leaveOrphans={leaveOrphans}
            delayFrom={delayFrom}
            delayInMinutes={delayInMinutes}
            newStartTime={newStartTime}
            newEndTime={newEndTime}
          />
        </div>
      )}
    </Container>
  );
};

ModifySlotAffectedBookingsToolBar.propTypes = {
  modification: PropTypes.oneOf(
    'blockAll',
    'blockTatkal',
    'delay',
    'changeTiming'
  ).isRequired,
  slotPrototypeId: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  leaveOrphans: PropTypes.bool.isRequired,
  delayFrom: PropTypes.instanceOf(Date),
  delayInMinutes: PropTypes.number,
  newStartTime: PropTypes.instanceOf(Date),
  newEndTime: PropTypes.instanceOf(Date),
  enabled: PropTypes.bool.isRequired
};

export default ModifySlotAffectedBookingsToolBar;
