import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Container, Row, Col } from 'reactstrap';
import { GET_APPROVING_AUTHORITY } from '../queries';

const ApproverNames = ({ value }) => {
    let hospitalId = value;
    return (
        <Container>
            {(
                <Query
                    query={GET_APPROVING_AUTHORITY}
                    variables={{ hospitalId }}
                >
                    {({ loading, error, data }) => {
                        if (error) return <span>Error: Please select atleast one hospital</span>;
                        let updatedList = <Row></Row>
                        if (data && data.getVacationAuthorizers) {
                            updatedList = data.getVacationAuthorizers.map((item, idx) => {
                                return (
                                    <Row
                                        key={idx}
                                        style={{ paddingBottom: '0.2rem' }}>
                                        <Col>
                                            <div>{item.name}</div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                        return (
                            <div>{updatedList}</div>
                        );
                    }}
                </Query>
            )}
        </Container >
    )
};

ApproverNames.propTypes = {
    value: PropTypes.number
};

export default ApproverNames;