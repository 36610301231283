import React from 'react';
import AuthRequired from '../components/AuthRequired';
import { Query } from 'react-apollo';
import { ME, GET_DOCTOR_WITH_HOSP } from '../queries';
import Veil from '../components/Veil';
import ReportBugModal from '../components/ReportBugModal';
import DoccHomeInner from './DoccHomeInner';

const DocHome = () => {
  return (
    <AuthRequired pageName="dochome">
      <Query query={ME}>
        {({ loading: meloading, data: medata, error: meerror }) => {
          if (meloading) {
            return <Veil />;
          }
          if (meerror) {
            return (
              <span className="error">
                Something went wrong, please contact administrator.{' '}
                {JSON.stringify(meerror)}
              </span>
            );
          }

          let objectId;
          if (medata && medata.me && medata.me.entity) {
            objectId = medata.me.entity.objectId;
          }

          if (!objectId) {
            return (
              <span className="error">
                ERROR: Object Id is not found. If you think this was a mistake,{' '}
                <ReportBugModal buttonText="contact administrator" />.
              </span>
            );
          }

          return (
            <Query query={GET_DOCTOR_WITH_HOSP} variables={{ id: objectId }}>
              {({ loading: loadingDoc, data: docData, error: docerror }) => {
                if (loadingDoc) {
                  return <Veil />;
                }
                if (docerror) {
                  return (
                    <span className="error">
                      Something went wrong, please{' '}
                      <ReportBugModal buttonText="contact administrator" />.
                    </span>
                  );
                }

                if (docData && docData.doctor) {
                  // return <pre>{JSON.stringify(docData.doctor, null, 2)}</pre>;
                  return <DoccHomeInner doctor={docData.doctor} />;
                }

                return (
                  <span className="error">
                    Something went wrong, please{' '}
                    <ReportBugModal buttonText="contact administrator" />.
                  </span>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </AuthRequired>
  );
};

export default DocHome;
