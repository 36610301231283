import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Button } from 'reactstrap';
import { Form, Formik, Field, FieldArray } from 'formik';
import FieldErrorMessage from './FieldErrorMessage';
import { Query, Mutation } from 'react-apollo';
import {
  GET_SLOT_PROTOTYPE,
  CREATE_SLOT_PROTOTYPE,
  UPDATE_SLOT_PROTOTYPE
} from '../queries';
import TimeSelector from './TimeSelector';
import * as Yup from 'yup';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import moment from 'moment';
import SelectHospital from './SelectHospital';
import omit from 'lodash/omit';
import DaysConfInput from './DaysConfInput';
import Loader from './Loader';
import { showConfirmation } from '../ConfirmationDialogService';
import DateSelectorFormik from './DateSelectorFormik';
import { FaTrash } from 'react-icons/fa';
import SlotPattern from './SlotPattern';
import HelpBubble from './HelpBubble';
import SelectProcedureLookup from './SelectProcedureLookup';

const DaysSelector = ({ field, form, onBlur, ...props }) => (
  <DaysConfInput
    daysConf={field.value}
    onChange={daysConf => form.setFieldValue(field.name, daysConf)}
    onBlur={() => onBlur(field.name, true)}
    {...props}
  />
);

DaysSelector.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  onBlur: PropTypes.func
};

const FormikProcedureSelector = ({ field, form, ...props }) => (
  <SelectProcedureLookup
    value={field.value}
    onChange={v => form.setFieldValue(field.name, v)}
    {...props}
  />
);

FormikProcedureSelector.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

const validationSchema = Yup.object().shape({
  startTime: Yup.date('Start time is required')
    .required('Start time is required')
    .typeError('Start time is required'),
  endTime: Yup.date('End time is required')
    .required('End time is required')
    .typeError('End time is required'),
  hospitalId: Yup.number()
    .positive()
    .integer()
    .required('Hospital is required'),
  expiresOn: Yup.date('Expiry is required')
    .required('Expiry is required')
    .typeError('Expiry is required'),

  daysConf: Yup.object().required('Days are required'),

  /*
    .shape({
      sun: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      mon: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      tue: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      wed: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      thu: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      fri: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      ),
      sat: Yup.array().of(
        Yup.number()
          .min(1)
          .max(5)
          .integer()
      )
    })*/ isReserved: Yup.bool(),
  isWalkInEnabled: Yup.bool(),
  isTatkalEnabled: Yup.bool(),
  isEnabled: Yup.bool(),
  entityId: Yup.number()
    .positive()
    .integer(),
  tatkalWindow: Yup.number(
    'Tatkal window should be postive integer between 1 and 720'
  )
    .positive('Tatkal window should be postive integer between 1 and 720')
    .integer('Tatkal window should be postive integer between 1 and 720')
    .max(720, 'Tatkal window should be postive integer between 1 and 720')
    .min(1, 'Tatkal window should be postive integer between 1 and 720')
    .required('Tatkal window should be postive integer between 1 and 720'),
  regularToTatkalRatio: Yup.number('Ratio should be between 1 and 10')
    .positive('Ratio should be between 1 and 10')
    .integer('Ratio should be between 1 and 10')
    .max(720, 'Ratio should be between 1 and 10')
    .min(1, 'Ratio should be between 1 and 10')
    .required('Ratio should be between 1 and 10'),

  maxBookAheadDays: Yup.number(
    'Farthest slot should be postive integer between 1 and 120'
  )
    .positive('Farthest slot should be postive integer between 1 and 120')
    .integer('Farthest slot should be postive integer between 1 and 120')
    .max(120, 'Farthest slot should be postive integer between 1 and 120')
    .min(1, 'Farthest slot should be postive integer between 1 and 120')
    .required('Farthest slot should be postive integer between 1 and 120'),
  leverageRatio: Yup.number(
    'Leverage ratio should be postive integer between 1 and 10'
  )
    .positive('Leverage ratio should be postive integer between 1 and 10')
    .integer('Leverage ratio should be postive integer between 1 and 10')
    .min(1, 'Leverage ratio should be postive integer between 1 and 10')
    .max(10, 'Leverage ratio should be postive integer between 1 and 10')
    .required('Leverage ratio should be postive integer between 1 and 10'),

  companionLeverageRatio: Yup.number(
    'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
  )
    .positive(
      'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
    )
    .integer(
      'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
    )
    .min(
      1,
      'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
    )
    .max(
      10,
      'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
    )
    .required(
      'Tatkal/Walk-in Leverage ratio should be postive integer between 1 and 10'
    ),
  procedures: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string('Procedure should be at least 2 character long')
          .min(2, 'Procedure should be at least 2 character long')
          .required('Procedure should be at least 2 character long'),
        maxBookings: Yup.number('Must be 0 or more')
          .typeError('Must be 0 or more')
          .integer()
          .moreThan(-1, 'Must be 0 or more'),
        slotDuration: Yup.number('Slot span should be between 4 and 120')
          .positive('Slot span should be between 4 and 120')
          .min(4, 'Slot span should be between 4 and 120')
          .max(120, 'Slot span should be between 4 and 120')
          .typeError('Slot span should be between 4 and 120')
      })
    )
    .required('At least one procedure should be present'),
  slotPattern: Yup.array() //('Slot pattern should be an array')
    .of(
      Yup.number()
        .integer()
        .moreThan(0)
    )
    .min(1, 'Please add procedures in slot pattern')
});

const validateAndCreateMutationObj = values => {
  const { startTime, endTime, slotPattern, procedures } = values;

  if (!startTime || !endTime) {
    displayError('Illegal start or end time');
    return;
  }

  let startTimeMoment = startTime;
  let endTimeMoment = endTime;
  if (typeof startTime === 'string') {
    startTimeMoment = moment(startTime, 'HHmm', true);
  }
  if (typeof endTime === 'string') {
    endTimeMoment = moment(endTime, 'HHmm', true);
  }

  if (
    !startTimeMoment.isValid() ||
    !endTimeMoment.isValid() ||
    endTimeMoment.isBefore(startTimeMoment)
  ) {
    displayError('Illegal start or end time');
    return;
  }

  if (endTimeMoment.diff(startTimeMoment, 'm') < 15) {
    displayError('Too small time range for the schedule');
    return;
  }

  if (!procedures) {
    displayError('Procedures are required');
    return;
  }
  if (procedures.find(p => p.slotDuration < 4)) {
    displayError('A procedure cannot be smaller than 4 minutes');
    return;
  }

  if (!slotPattern || slotPattern.length < 1) {
    displayError('Please set up a slot pattern');
    return;
  }

  const allProcedureTimingSet = new Set(
    procedures.map(({ slotDuration }) => slotDuration)
  );

  const missingTimingFromPattern = Array.from(allProcedureTimingSet).some(
    timing => !slotPattern.includes(timing)
  );

  if (missingTimingFromPattern) {
    displayError('Some slot timing is not in pattern.');
    return;
  }

  let expiresOn;
  if (values.expiresOn) {
    expiresOn = moment(values.expiresOn).format('YYYY-MM-DD');
  }
  let applicableSince;
  if (values.applicableSince) {
    applicableSince = moment(values.applicableSince).format('YYYY-MM-DD');
  }
  const slotProto = {
    isReserved: !!values.isReserved,
    isWalkInEnabled: !!values.isWalkInEnabled,
    isTatkalEnabled: !!values.isTatkalEnabled,
    isEnabled: !!values.isEnabled,
    hospitalId: values.hospitalId,
    startTime: startTimeMoment.format('HHmm'),
    endTime: endTimeMoment.format('HHmm'),
    daysConf: values.daysConf,
    entityId: values.entityId,
    tatkalWindow: values.tatkalWindow,
    regularToTatkalRatio: values.regularToTatkalRatio,
    maxBookAheadDays: values.maxBookAheadDays,
    leverageRatio: values.leverageRatio,
    expiresOn,
    applicableSince,
    slotPattern,
    procedures,
    companionLeverageRatio: values.companionLeverageRatio
  };

  slotProto.procedures = values.procedures.map(v => omit(v, '__typename'));

  return slotProto;
};

const TheForm = ({
  initialValues,
  isNew,
  entityId,
  fetchingInitialData,
  onFinished,
  name
}) => {
  const mutation = isNew ? CREATE_SLOT_PROTOTYPE : UPDATE_SLOT_PROTOTYPE;
  return (
    <Mutation
      mutation={mutation}
      onCompleted={() => {
        // console.log(isNew, data, name);
        logAmplitudeEvent(
          isNew ? 'Slots_Created' : 'Slots_Modified',
          { DoctorName: name },
          true
        );
        displaySuccess(isNew ? 'Created successfully' : 'Updated successfully');
        onFinished();
      }}
      onError={e => displayError(`Something went wrong: ${e}`)}
      refetchQueries={() => ['GET_SLOT_PROTOS']}
    >
      {(updateOrCreate, { loading }) => {
        const disabledForm = fetchingInitialData || loading;
        return (
          <Formik
            enableReinitialize
            // validateOnBlur={false}
            // validateOnChange={false}
            initialValues={initialValues}
            onSubmit={values => {
              const slotProto = validateAndCreateMutationObj(values);

              if (!isNew) {
                slotProto.id = values.id;
              } else {
                slotProto.entityId = entityId;
              }

              const variables = {
                slotProto
              };

              if (!isNew) {
                const msg = (
                  <>
                    Updating schedule may cause{' '}
                    <strong>change or cancelation</strong> of future bookings
                    <br />
                    This process will trigger later tonight.
                    <br />
                    <strong>ARE YOU SURE WITH THESE CHANGES?</strong>
                  </>
                );
                showConfirmation('Confirm Update', msg, () =>
                  updateOrCreate({ variables })
                );
              } else {
                updateOrCreate({ variables });
              }
            }}
            validationSchema={validationSchema}
            render={({
              values,
              // errors,
              // status,
              // touched,
              // isSubmitting,
              // validateForm,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              ...props
            }) => {
              // console.log(`e ${JSON.stringify(errors, null, 2)}`);
              return (
                <Form disabled={disabledForm}>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <h5>Basics</h5>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <Col>
                      <label>Start Time&nbsp;</label>

                      <Field
                        type="text"
                        name="startTime"
                        component={TimeSelector}
                        caption="Start"
                        onBlur={setFieldTouched}
                        {...props}
                      />
                      <FieldErrorMessage name="startTime" />
                    </Col>
                    <Col>
                      <label>End Time&nbsp;</label>

                      <Field
                        type="text"
                        name="endTime"
                        component={TimeSelector}
                        caption="End"
                        onBlur={setFieldTouched}
                        {...props}
                      />
                      <FieldErrorMessage name="endTime" />
                    </Col>
                    <Col>
                      <label>Hospital&nbsp;</label>
                      <Field name="hospitalId" component={SelectHospital} />
                      <FieldErrorMessage name="hospitalId" />
                    </Col>
                    <Col>
                      <label>Expires On&nbsp;</label>

                      <Field
                        name="expiresOn"
                        component={DateSelectorFormik}
                        caption="Expires on"
                        onBlur={setFieldTouched}
                        showYearDropdown
                        dateFormat="D MMMM YYYY"
                        {...props}
                      />
                      <FieldErrorMessage name="expiresOn" />
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <h5>Days</h5>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <Col>
                      <Field
                        name="daysConf"
                        component={DaysSelector}
                        onBlur={setFieldTouched}
                      />
                      <FieldErrorMessage name="daysConf" />

                      {/* <Field name="days" component={DaysConfInput} /> */}
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <h5>Special Properties</h5>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <Col>
                      <Field
                        name="isReserved"
                        type="checkbox"
                        checked={values.isReserved}
                      />
                      &nbsp;Reserved&nbsp;
                    </Col>
                    <Col>
                      <Field
                        name="isWalkInEnabled"
                        type="checkbox"
                        checked={values.isWalkInEnabled}
                      />
                      &nbsp;Walk In&nbsp;
                    </Col>
                    <Col>
                      <Field
                        name="isTatkalEnabled"
                        type="checkbox"
                        checked={values.isTatkalEnabled}
                      />
                      &nbsp;Tatkal&nbsp;
                    </Col>
                    <Col>
                      <Field
                        name="isEnabled"
                        type="checkbox"
                        checked={values.isEnabled}
                      />
                      &nbsp;Enabled&nbsp;
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <h5>Availability</h5>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <Col>
                      <label>Tatkal Window (hrs)&nbsp;</label>
                      <Field
                        type="number"
                        name="tatkalWindow"
                        min={1}
                        max={720}
                        style={{ width: '100%' }}
                      />
                      <FieldErrorMessage name="tatkalWindow" />
                      &nbsp;
                    </Col>
                    <Col>
                      <label>Normal to Tatkal Ratio&nbsp;</label>
                      <Field
                        type="number"
                        name="regularToTatkalRatio"
                        min={1}
                        max={10}
                        style={{ width: '100%' }}
                      />
                      <FieldErrorMessage name="regularToTatkalRatio" />
                      &nbsp;
                    </Col>
                    <Col>
                      <label>Leverage&nbsp;</label>
                      <Field
                        type="number"
                        name="leverageRatio"
                        min={1}
                        max={5}
                        style={{ width: '100%' }}
                      />
                      <FieldErrorMessage name="leverageRatio" />
                      &nbsp;
                    </Col>
                    <Col>
                      <label>Leverage Tatkal/Walk-in&nbsp;</label>
                      <Field
                        type="number"
                        name="companionLeverageRatio"
                        min={1}
                        max={5}
                        style={{ width: '100%' }}
                      />
                      <FieldErrorMessage name="companionLeverageRatio" />
                      &nbsp;
                    </Col>
                    <Col>
                      <label>Farthest Slot (days)&nbsp;</label>
                      <Field
                        type="number"
                        name="maxBookAheadDays"
                        min={1}
                        max={365}
                        style={{ width: '100%' }}
                      />
                      <FieldErrorMessage name="maxBookAheadDays" />
                      &nbsp;
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: '0.2rem' }}>
                    <Col>
                      <h5>Procedures</h5>
                    </Col>
                  </Row>
                  <FieldErrorMessage name="procedures" />
                  <Row>
                    <Col>Name</Col>
                    <Col sm={1}>
                      Max{' '}
                      <HelpBubble
                        helpText="Maximum number of bookings for the procedure. Set 0 to disable limit."
                        helpId={'maxSlots'}
                        placement="top"
                      />
                    </Col>
                    <Col sm={1}>Duration</Col>
                    <Col>Procedure ID</Col>
                    <Col>Perform Procedure ID</Col>
                    <Col sm={1}>Delete</Col>
                  </Row>
                  <FieldArray
                    name="procedures"
                    render={arrayHelpers => {
                      // console.log(`values`, values);
                      return (
                        <Row style={{ paddingBottom: '0.2rem' }}>
                          <Col>
                            {values.procedures &&
                              values.procedures.map((proc, index) => {
                                return (
                                  <Row
                                    key={index}
                                    style={{ paddingBottom: '0.2rem' }}
                                  >
                                    <Col>
                                      <Field
                                        component={FormikProcedureSelector}
                                        name={`procedures.${index}.name`}
                                        style={{ width: '100%' }}
                                        excludeProcedures={
                                          values.procedures &&
                                          values.procedures.map(v => v.name)
                                        }
                                      />
                                      <FieldErrorMessage
                                        name={`procedures.${index}.name`}
                                      />
                                    </Col>
                                    <Col sm={1}>
                                      <Field
                                        type="number"
                                        name={`procedures.${index}.maxBookings`}
                                        min={0}
                                        max={999999}
                                        style={{ width: '100%' }}
                                      />
                                      <FieldErrorMessage
                                        name={`procedures.${index}.maxBookings`}
                                      />
                                    </Col>
                                    <Col sm={1}>
                                      <Field
                                        type="number"
                                        name={`procedures.${index}.slotDuration`}
                                        min={1}
                                        max={10}
                                        style={{ width: '100%' }}
                                      />
                                      <FieldErrorMessage
                                        name={`procedures.${index}.slotDuration`}
                                      />
                                    </Col>
                                    <Col>
                                      <Field
                                        type="text"
                                        name={`procedures.${index}.qwProcedureId`}
                                        style={{ width: '100%' }}
                                      />
                                      <FieldErrorMessage
                                        name={`procedures.${index}.qwProcedureId`}
                                      />
                                    </Col>
                                    <Col>
                                      <Field
                                        type="text"
                                        name={`procedures.${index}.qwPerformProcedureId`}
                                        style={{ width: '100%' }}
                                      />
                                      <FieldErrorMessage
                                        name={`procedures.${index}.qwPerformProcedureId`}
                                      />
                                    </Col>
                                    <Col sm={1}>
                                      <Button
                                        onClick={() => {
                                          const removedSlotDuration =
                                            values.procedures[index]
                                              .slotDuration;
                                          arrayHelpers.remove(index);
                                          // check if this is the last procedure with this size
                                          // and remove from slot pattern too!
                                          if (
                                            !values.procedures ||
                                            values.procedures.filter(
                                              ({ slotDuration }) =>
                                                slotDuration ===
                                                removedSlotDuration
                                            ).length === 1
                                          ) {
                                            const newSlotPattern = values.slotPattern.filter(
                                              sd => sd !== removedSlotDuration
                                            );

                                            setFieldValue(
                                              'slotPattern',
                                              newSlotPattern
                                            );
                                          }
                                        }}
                                        color="link"
                                      >
                                        <FaTrash />
                                      </Button>
                                    </Col>
                                  </Row>
                                );
                              })}

                            <Row>
                              <Col
                                style={{
                                  textAlign: 'right',
                                  marginTop: '0.2rem',
                                  marginBottom: '0.2rem'
                                }}
                              >
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      name: '',
                                      maxBookings: 0,
                                      slotDuration: 10
                                    })
                                  }
                                >
                                  Add Procedure
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field
                                  name="slotPattern"
                                  procedureList={values.procedures}
                                  component={SlotPattern}
                                />
                                <FieldErrorMessage name="slotPattern" />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    }}
                  />
                  <div className="modal-footer">
                    {disabledForm && <Loader size={5} inline />}
                    <span>Applicable&nbsp;from:</span>
                    <Field
                      name="applicableSince"
                      component={DateSelectorFormik}
                      caption="Applies From"
                      onBlur={setFieldTouched}
                      dateFormat="D MMMM YYYY"
                      selected={moment().add(1, 'day')}
                      {...props}
                      disabled
                    />
                    {/* set state, handleSubmit, unset, set slotProto */}
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      disabled={disabledForm}
                    >
                      {isNew ? 'CREATE' : 'UPDATE'}
                    </Button>{' '}
                    <Button onClick={onFinished} disabled={disabledForm}>
                      CANCEL
                    </Button>
                  </div>
                </Form>
              );
            }}
          />
        );
      }}
    </Mutation>
  );
};

TheForm.propTypes = {
  isNew: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  entityId: PropTypes.number,
  fetchingInitialData: PropTypes.bool,
  onFinished: PropTypes.func,
  name: PropTypes.string
};

TheForm.defaultProps = {
  isNew: false,
  fetchingInitialData: false,
  onFinished: () => {}
};
const SlotProtoEditForm = ({ slotPrototypeId, entityId, onFinished, name }) => {
  // console.log('slotPrototypeId', slotPrototypeId);
  return (
    <Container>
      {!slotPrototypeId ? (
        <TheForm
          initialValues={{
            startTime: undefined,
            endTime: undefined,
            hospitalId: undefined,
            daysConf: undefined,
            tatkalWindow: 24,
            regularToTatkalRatio: 2,
            leverageRatio: 1,
            maxBookAheadDays: 60,
            isEnabled: true,
            isReserved: false,
            isTatkalEnabled: false,
            isWalkInEnabled: true,
            procedures: [],
            slotPattern: [],
            expiresOn: moment().add(10, 'years'),
            applicableSince: moment().add(1, 'day'),
            companionLeverageRatio: 1
          }}
          name={name}
          entityId={entityId}
          isNew
          onFinished={onFinished}
        />
      ) : (
        <Query
          query={GET_SLOT_PROTOTYPE}
          variables={{ slotPrototypeId }}
          fetchPolicy="network-only"
          onError={e =>
            displayError(
              `Something went wrong while fetching the schedule: ${e}`
            )
          }
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loader />;
            }
            const initialValues = (data && data.slotPrototype) || {
              procedures: []
            };

            //clean up slot conf
            if (initialValues && initialValues.daysConf) {
              const daysConf = Object.entries(initialValues.daysConf).reduce(
                (cum, [day, weeks]) => {
                  if (weeks && Array.isArray(weeks) && weeks.length > 0) {
                    cum[day] = weeks;
                  }
                  return cum;
                },
                {}
              );
              initialValues.daysConf = daysConf;
              initialValues.expiresOn =
                initialValues.expiresOn &&
                moment(initialValues.expiresOn, 'YYYY-MM-DD');
              initialValues.applicableSince = moment().add(1, 'day');
            }

            return (
              <TheForm
                initialValues={initialValues}
                fetchingInitialData={loading}
                onFinished={onFinished}
                name={name}
              />
            );
          }}
        </Query>
      )}
    </Container>
  );
};

SlotProtoEditForm.propTypes = {
  slotPrototypeId: PropTypes.number,
  entityId: PropTypes.number,
  onFinished: PropTypes.func,
  name: PropTypes.string
};

export default SlotProtoEditForm;
