import React, { useState, useEffect } from 'react';
import { Mutation, Query } from 'react-apollo';
import { displaySuccess, displayError } from '../utilities';
import Loader from './Loader';
import { APPROVE_VACATION, GET_VACATIONS } from '../queries';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { getLoggedInUser } from '../permissions';
import ApproverNames from './ApproverNames';
const userHospitalIds =
  getLoggedInUser() && getLoggedInUser().hospitals
    ? getLoggedInUser().hospitals
    : [];

const Td = styled.td`
  vertical-align: middle !important;
`;

const RenderRow = ({
  name,
  time,
  reason,
  actionFunction,
  loading,
  approvalStatus,
  isAutoApproved,
  updated_at,
  hospitalId
}) => (
  <tr>
    <Td>{name}</Td>
    <Td>{moment(updated_at).format('DD MMM YYYY, hh:mm A')}</Td>
    <Td>{time}</Td>
    <Td>{reason}</Td>
    <Td>
      {isAutoApproved
        ? 'Auto Approved'
        : approvalStatus === 0
        ? 'Approval Pending on'
        : approvalStatus === 1
        ? 'Approved'
        : 'Declined'}
      {approvalStatus === 0 ? <ApproverNames value={hospitalId} /> : <></>}
    </Td>
    <Td>
      {approvalStatus === 0 && (
        <div style={{ display: 'flex' }}>
          <Button onClick={() => actionFunction(true)} color="link">
            APPROVE
          </Button>
          <Button onClick={() => actionFunction(false)} color="link">
            DENY
          </Button>
          <div style={{ width: 50 }}>{loading && <Loader size={5} />}</div>
        </div>
      )}
    </Td>
  </tr>
);

RenderRow.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  reason: PropTypes.string,
  actionBool: PropTypes.bool,
  actionFunction: PropTypes.func,
  loading: PropTypes.bool,
  index: PropTypes.number,
  approvalStatus: PropTypes.string,
  isAutoApproved: PropTypes.bool,
  updated_at: PropTypes.object,
  hospitalId: PropTypes.number
};

const RenderRowVacation = ({ doc, state, fromDate, toDate, getDocs }) => {
  const [entityId, setentityId] = useState();
  const [loading2, setloading2] = useState(false);
  const [vacationList, setvacationList] = useState([]);
  const [gVacation, setgVacation] = useState();

  let vacationFrom = fromDate && toDate ? moment(fromDate) : null;
  let vacationTo = fromDate && toDate ? moment(toDate) : null;
  useEffect(() => {
    let { entityId = null } = doc ? doc : {};
    setentityId(entityId);
  }, [doc]);
  useEffect(() => {
    if (getDocs && gVacation) {
      const list =
        gVacation && gVacation.length
          ? gVacation.map(item => item.entityId)
          : [];
      const validIds =
        getDocs && getDocs.length
          ? getDocs
              .filter(item => {
                if (list.includes(item.entityId)) {
                  return item.getHospitals.map(item =>
                    userHospitalIds.includes(item.id)
                  ).length
                    ? 1
                    : 0;
                } else {
                  return 0;
                }
              })
              .map(item => item.entityId)
          : [];
      const vList = gVacation.filter(item => validIds.includes(item.entityId));
      setvacationList(vList);
      setloading2(false);
    }
  }, [getDocs, gVacation, loading2]);
  let approvalStatus = 'ALL';
  switch (state) {
    case 'pending':
      approvalStatus = 'PENDING';
      break;
    case 'approved':
      approvalStatus = 'APPROVED';
      break;
    case 'denied':
      approvalStatus = 'REJECTED';
      break;
    default:
      approvalStatus = 'ALL';
      break;
  }

  return (
    <Query
      query={GET_VACATIONS}
      variables={{ entityId, approvalStatus, vacationFrom, vacationTo }}
      fetchPolicy="network-only"
      onCompleted={data => {
        const { getVacations } = data;
        setgVacation([]);
        setloading2(true);
        if (getVacations.length) {
          setgVacation(getVacations);
        } else {
          setgVacation([]);
        }
      }}
    >
      {({ loading, refetch }) => {
        return loading || loading2 ? (
          <tr>
            <td colSpan={6} style={{ textAlign: 'center' }}>
              <Loader />
            </td>
          </tr>
        ) : vacationList && vacationList.length ? (
          vacationList.map((item, index) => {
            const {
              reason,
              startTime,
              endTime,
              approvalStatus,
              id,
              entityName,
              isAutoApproved,
              updated_at,
              hospitalId
            } = item;
            const vTime = `${moment(startTime).format(
              'DD MMM YYYY, hh:mm A'
            )} - ${moment(endTime).format('DD MMM YYYY, hh:mm A')}`;
            return (
              <Mutation
                key={index}
                mutation={APPROVE_VACATION}
                refetchQueries={() => ['GET_VACATIONS']}
                onCompleted={data => {
                  const { vacationApprovals } = data;
                  if (vacationApprovals && vacationApprovals.approvalStatus) {
                    displaySuccess(
                      vacationApprovals.approvalStatus === 1
                        ? `Approved Successfully`
                        : vacationApprovals.approvalStatus === 2
                        ? `Denied Successfully`
                        : null
                    );
                  }
                }}
                onError={err => {
                  displayError(err);
                }}
              >
                {(approveFn, { loading }) => {
                  if (loading)
                    refetch({
                      entityId,
                      approvalStatus,
                      vacationFrom,
                      vacationTo
                    });
                  return (
                    <RenderRow
                      index={index}
                      name={entityName}
                      time={vTime}
                      actionBool={true}
                      reason={reason}
                      approvalStatus={approvalStatus}
                      isAutoApproved={isAutoApproved}
                      updated_at={updated_at}
                      hospitalId={hospitalId}
                      actionFunction={action =>
                        approveFn({
                          variables: {
                            vacationId: id,
                            approvalState: action ? 'APPROVED' : 'REJECTED'
                          }
                        })
                      }
                      loading={loading}
                    />
                  );
                }}
              </Mutation>
            );
          })
        ) : (
          <tr>
            <td colSpan={6} style={{ textAlign: 'center' }}>
              No Vacations Data Found
            </td>
          </tr>
        );
      }}
    </Query>
  );
};

RenderRowVacation.propTypes = {
  doc: PropTypes.object,
  state: PropTypes.string,
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
  getDocs: PropTypes.object
};

export default RenderRowVacation;
