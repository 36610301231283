import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_QUICKBOOKING_TIMING_DOCTORS } from '../queries';
import { Query } from 'react-apollo';
import Select from 'react-select';
import moment from 'moment';
import { logAmplitudeEvent } from '../utilities';

const DoctorSelector = props => {
  const {
    onChange,
    value,
    hospitalIds,
    departmentIds,
    cityId,
    placeholder,
    showAllDoctorOption,
    timings,
    dateSelected
  } = props;

  const eventProps = {
    departmentIds,
    hospitalIds,
    cityId,
    timings,
    dateSelected
  };
  logAmplitudeEvent('Quick_Book_Doctor_Select', eventProps, true);

  return (
    <Query
      query={SEARCH_QUICKBOOKING_TIMING_DOCTORS}
      variables={{
        searchInput: {
          departmentIds,
          hospitalIds,
          cityId,
          startDate: moment(
            dateSelected + timings,
            'DD/MM/YYYY HH:mm'
          ).toISOString(),
          endDate: moment(dateSelected + timings, 'DD/MM/YYYY HH:mm')
            .add('hour', '1')
            .toISOString(),
          sortBy: 'Rank'
        }
      }}
      fetchPolicy="network-only"
    >
      {({ loading, data: { getDoctorsQuickBook = [] } = {} }) => {
        let options = [];
        if (getDoctorsQuickBook && getDoctorsQuickBook.length) {
          options = getDoctorsQuickBook.map(d => {
            return { label: d.name, value: d };
          });
          if (showAllDoctorOption) {
            options = [{ label: 'All', value: 'All' }].concat(options);
          }
        }
        return (
          <Select
            // isMulti={isMulti}
            value={value}
            options={options}
            isLoading={loading}
            onChange={onChange}
            placeholder={placeholder}
          />
        );
      }}
    </Query>
  );
};

DoctorSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  hospitalIds: PropTypes.number,
  departmentIds: PropTypes.number,
  cityId: PropTypes.number,
  placeholder: PropTypes.string,
  showAllDoctorOption: PropTypes.bool,
  timings: PropTypes.string,
  dateSelected: PropTypes.string
};

DoctorSelector.defaultProps = {
  showAllDoctorOption: false
};

export default DoctorSelector;
