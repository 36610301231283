import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Loader from './Loader';

const UpdateInfoSection = ({
  user,
  loading,
  handleSubmit,
  handleSendForApproval,
  doctor,
  isLoading
}) => {
  if (doctor.profileStatus === 'pending' || user.role === 'marketing') {
    return null;
  }

  const renderBUHR = () => {
    if (user.role !== 'buhr') {
      return null;
    }
    return (
      <Button
        size="sm"
        color="dark"
        style={{ marginTop: '10px', marginBottom: '50px' }}
        onClick={() => handleSendForApproval(doctor)}
        disabled={isLoading}
      >
        Send for Approval
      </Button>
    );
  };

  const renderUpdateButton = () => {
    return (
      <Button
        size="sm"
        color="primary"
        style={{
          marginTop: '10px',
          marginBottom: '50px',
          marginRight: '20px'
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        Update Personal Info
      </Button>
    );
  };

  return (
    <div className="text-right">
      {loading && <Loader inline size={5} />}
      {renderUpdateButton()}
      {renderBUHR()}
    </div>
  );
};

UpdateInfoSection.propTypes = {
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSendForApproval: PropTypes.func.isRequired,
  doctor: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default UpdateInfoSection;
