import React from 'react';
// import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { UPLOAD_SLOTS_EXCEL } from '../queries/index';
import Loader from '../components/Loader';
import { Table } from 'reactstrap';

const BulkDoctorUpload = () => {
  return (
    <div>
      <h1>Upload doctor data</h1>
      <Mutation mutation={UPLOAD_SLOTS_EXCEL}>
        {(fileUpload, { loading, data }) => {
          const res =
            data && data.uploadSlotsExcel ? data.uploadSlotsExcel : [];
          return (
            <>
              <input
                type="file"
                onChange={event => {
                  const {
                    target: {
                      validity,
                      files: [file]
                    }
                  } = event;
                  // console.log(`fkkiles: ${JSON.stringify(file.name)}`);
                  validity.valid && fileUpload({ variables: { file } });
                }}
              />
              {loading && <Loader />}
              {res.map((r, i) => (
                <div key={i}>
                  <h3>Success: {r.success}</h3>
                  <h3>Failure: {r.failure}</h3>
                  <h3>Errors:</h3>
                  <Table>
                    <thead>
                      <tr>
                        <th>Doctor</th>
                        <th>Branch</th>
                        <th>Reason of failure</th>
                      </tr>
                    </thead>
                    <tbody>
                      {r.errors.map(e => (
                        <tr key={e.doctor.DoctorName}>
                          <td>{e.doctor.DoctorName}</td>
                          <td>{e.doctor.BranchName}</td>
                          <td>{e.message}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ))}
            </>
          );
        }}
      </Mutation>
    </div>
  );
};

export default BulkDoctorUpload;
