import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Query } from 'react-apollo';
import { LOOKUP } from '../queries';

const LookupSelect = ({ isMulti, lookup, value, onChange, ...props }) => {
  return (
    <Query query={LOOKUP} variables={{ type: lookup }}>
      {({ data: { lookups } = {}, loading }) => {
        let selected;
        if (value && !loading) {
          //Let's match case insensitive
          const valueLowerCase = isMulti
            ? value.map(d => `${d}`.toLowerCase())
            : `${value}`.toLowerCase();
          selected = isMulti
            ? lookups.filter(l =>
                valueLowerCase.includes(l.value.toLowerCase())
              )
            : lookups.find(l => l.value.toLowerCase() === valueLowerCase);
        }
        return (
          <Select
            options={lookups}
            isMulti={isMulti}
            value={selected}
            isLoading={loading}
            onChange={sel => {
              isMulti
                ? onChange(sel.map(v => v.value))
                : onChange(sel && sel.value);
            }}
            {...props}
            isClearable
          />
        );
      }}
    </Query>
  );
};

LookupSelect.propTypes = {
  isMulti: PropTypes.bool,
  lookup: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onChange: PropTypes.func
};

LookupSelect.defaultProps = {
  isMulti: true,
  value: [],
  onChange: () => {}
};

export default LookupSelect;
