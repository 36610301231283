import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import moment from 'moment';

const RangeContainer = styled.div`
  display: flex;
  & > * {
    margin-right: 1rem;
  }
  & > div {
    width: 10rem;
  }
`;

const ModifySlotTimeRange = ({
  startTime,
  endTime,
  setStartTime,
  setEndTime
}) => {
  // startTime = moment().isAfter(startTime) ? moment() : moment(startTime);
  startTime = moment(startTime);
  endTime = moment(endTime);
  let minEndTime = moment(startTime).add(15, 'minutes');
  if (startTime.isSameOrAfter(endTime)) {
    endTime = minEndTime;
  }
  if (minEndTime.isSameOrBefore(moment())) {
    minEndTime = moment().add(15, 'minutes');
  }

  let minStartTime = moment();
  if (
    moment(startTime)
      .startOf('day')
      .isAfter(moment().startOf('day'))
  ) {
    minStartTime = moment(startTime).startOf('day');
  }

  return (
    <RangeContainer>
      <div>
        <DatePicker
          selected={startTime}
          onChange={setStartTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="START"
          dateFormat="h:mm A"
          minTime={minStartTime}
          maxTime={moment(startTime)
            .hours(23)
            .minutes(30)}
        />
      </div>
      <span>&mdash;</span>
      <div>
        <DatePicker
          selected={endTime}
          onChange={setEndTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="END"
          dateFormat="h:mm A"
          minTime={minEndTime}
          maxTime={moment(endTime)
            .hours(23)
            .minutes(59)}
        />
      </div>
    </RangeContainer>
  );
};

ModifySlotTimeRange.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  setStartTime: PropTypes.func.isRequired,
  setEndTime: PropTypes.func.isRequired
};

export default ModifySlotTimeRange;
