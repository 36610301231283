import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SLOTS_QUERY } from '../queries';
import Veil from './Veil';
import SlotListRenderer from './SlotListRenderer';

export default class SlotList extends Component {
  render() {
    const {
      startDate,
      onSlotSelect,
      entityId,
      procedureName,
      hospitalId,
      onPrevClick,
      onNextClick
    } = this.props;
    if (!entityId) return <span>Please select a doctor</span>;
    if (!procedureName) return <span>Please select a procedure</span>;
    if (!startDate) return <span>Please select a start date</span>;

    let absoluteStartDate = moment(startDate);

    if (absoluteStartDate.isBefore(moment())) {
      absoluteStartDate = moment();
    }

    let isStartDateToday = false;
    const todayStart = moment().startOf('day');
    const fromDateStart = moment(absoluteStartDate).startOf('day');
    if (todayStart.isSame(fromDateStart)) {
      isStartDateToday = true;
    }

    return (
      <Query
        query={SLOTS_QUERY}
        variables={{
          entityId,
          absoluteStartDate,
          relativeStartDay: 0,
          numDays: 5,
          procedureName,
          hospitalId
        }}
        fetchPolicy="network-only"
      >
        {({ loading, data: { getEntitySlots } = {} }) => {
          return (
            <div>
              {loading && <Veil />}
              <SlotListRenderer
                isStartDateToday={isStartDateToday}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                onSlotSelect={onSlotSelect}
                slotList={getEntitySlots}
                isLoading={loading}
                entityId={entityId}
                hospitalId={hospitalId}
                procedureName={procedureName}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

SlotList.propTypes = {
  startDate: PropTypes.object,
  entityId: PropTypes.number,
  onSlotSelect: PropTypes.func,
  procedureName: PropTypes.string,
  hospitalId: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func
};
