import React, { useState } from 'react';
import ModifySlotAddDelay from './ModifySlotAddDelay';
import ModifySlotBlockSlots from './ModifySlotBlockSlots';
import ModifySlotChangeTiming from './ModifySlotChangeTiming';
import ModifySlotBlockTatkal from './ModifySlotBlockTatkal';
import ModifySlotShowSchedule from './ModifySlotShowSchedule';
import PropTypes from 'prop-types';
import moment, { Moment } from 'moment';

const actionOptions = [
  { label: 'Add Delay', value: 'delay' },
  { label: 'Block', value: 'block' },
  { label: 'Change Timing', value: 'editTime' },
  { label: 'Block Tatkals', value: 'blockTatkal' }
  // { label: 'Cherry Pick Slots', value: 'cherrypic' }
];

const ModifySlotContainer = ({ entityId }) => {
  const [date, setDate] = useState(moment());
  const [wizardState, setWizardState] = useState({
    screen: undefined,
    alteredSchedule: undefined
  });

  const onCancel = () => setWizardState({ ...wizardState, screen: undefined });

  const alteredSchedule = wizardState.alteredSchedule;
  switch (wizardState.screen) {
    case 'delay':
      return (
        <ModifySlotAddDelay
          alteredSchedule={alteredSchedule}
          onCancel={onCancel}
          date={date}
        />
      );
    case 'block':
      return (
        <ModifySlotBlockSlots
          alteredSchedule={alteredSchedule}
          onCancel={onCancel}
          date={date}
        />
      );
    case 'editTime':
      return (
        <ModifySlotChangeTiming
          alteredSchedule={alteredSchedule}
          onCancel={onCancel}
          date={date}
        />
      );
    case 'blockTatkal':
      return (
        <ModifySlotBlockTatkal
          alteredSchedule={alteredSchedule}
          onCancel={onCancel}
          date={date}
        />
      );
    default:
      return (
        <ModifySlotShowSchedule
          actionOpts={actionOptions}
          setWizardState={setWizardState}
          entityId={entityId}
          date={date}
          setDate={setDate}
        />
      );
  }
};

ModifySlotContainer.propTypes = {
  entityId: PropTypes.number.isRequired
};

export const toMoment = (HHmm: string, date: Moment) => {
  const dateYYYYMMDD = date.format('YYYYMMDD');
  return moment(`${dateYYYYMMDD}${HHmm}`, 'YYYYMMDDHHmm');
};

export default ModifySlotContainer;
