import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Select from 'react-select';
import { GET_PROCEDURES } from '../queries';
import { logAmplitudeEvent } from '../utilities';

const SelectProcedure = props => {
  // const [selectedProcedure, setProcedure] = useState(undefined);

  const { entityId, hospitalId, onProcedureSelect, selectedProcedure } = props;

  const eventProps = {
    entityId,
    hospitalId,
    selectedProcedure
  };
  return (
    <Query query={GET_PROCEDURES} variables={{ hospitalId, entityId }}>
      {({ loading: loading1, error, data: data1 }) => {
        let procopts = [];
        if (data1 && !loading1) {
          procopts = data1.getProcedures.map(p => ({
            label: p,
            value: p
          }));
        }

        const selectedOpt =
          procopts.find(({ value }) => value === selectedProcedure) || null;
        return (
          <>
            {error && <span className="error">{JSON.stringify(error)}</span>}
            <Select
              isLoading={loading1}
              placeholder={'Select a procedure'}
              value={selectedOpt}
              onChange={
                e => {
                  // setProcedure(e);
                  onProcedureSelect(e.value);
                  logAmplitudeEvent(
                    'Quick_Book_Procedure_Select',
                    eventProps,
                    true
                  );
                }
                // this.setState({
                //   selectedProcedure: e,
                //   displayState: STEPS.choose_slot
                // })
              }
              options={procopts}
            />
          </>
        );
      }}
    </Query>
  );
};

SelectProcedure.propTypes = {
  entityId: PropTypes.number.isRequired,
  hospitalId: PropTypes.number.isRequired,
  onProcedureSelect: PropTypes.func,
  selectedProcedure: PropTypes.string
};

export default SelectProcedure;
