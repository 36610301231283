import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlotProto from './SlotProto';
import { Query, Mutation } from 'react-apollo';
import {
  GET_SLOT_PROTOS,
  SLOT_PROTO_DELETION_AFFECTED_BOOKINGS,
  DELETE_SLOT_PROTO
} from '../queries';
import Loader from './Loader';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col
} from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { displayError, displaySuccess } from '../utilities';
import RenderFutureSlotprotoBookings from './RenderFutureSlotprotoBookings';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
    gridGap: '1rem'
  }
};

const SlotProtoContainer = props => {
  const [deletingSlotPrototypeId, setDeletingSlotPrototypeId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteBookings, setDeleteBookings] = useState(false);
  const { entityId, onEdit } = props;
  const hideModal = () => {
    setShowModal(false);
    setDeleteBookings(false);
  };
  const prepareModal = slotPrototypeId => {
    setShowModal(true);
    setDeletingSlotPrototypeId(slotPrototypeId);
  };
  return (
    <div>
      <Modal isOpen={showModal} toggle={hideModal} size="lg">
        <Mutation
          mutation={DELETE_SLOT_PROTO}
          onError={e => displayError(e)}
          onCompleted={() => {
            displaySuccess(`Schedule deleted`);
            hideModal();
          }}
          refetchQueries={() => ['GET_SLOT_PROTOS']}
        >
          {(deleteSlotProto, { loading }) => {
            return (
              <>
                <ModalHeader toggle={hideModal} style={{ color: 'red' }}>
                  <FaExclamationTriangle /> AFFECTED BOOKINGS
                </ModalHeader>
                <ModalBody>
                  <RenderFutureSlotprotoBookings
                    slotPrototypeId={deletingSlotPrototypeId}
                  />
                  {deleteBookings && (
                    <h5 style={{ color: 'red', display: 'inline' }}>
                      BOOKINGS ABOVE WILL BE CANCELLED SOON
                    </h5>
                  )}
                </ModalBody>
                <ModalFooter>
                  <div>
                    <Query
                      query={SLOT_PROTO_DELETION_AFFECTED_BOOKINGS}
                      variables={{ slotPrototypeId: deletingSlotPrototypeId }}
                    >
                      {({
                        loading: loadingChkbx,
                        error: errorChkbx,
                        data: { bookings } = {}
                      }) => {
                        if (loadingChkbx) return <Loader size={5} />;
                        if (errorChkbx)
                          return (
                            <span className="error">
                              Error fetching bookings
                            </span>
                          );

                        if (!bookings || bookings.length < 1) {
                          return (
                            <div className="text-right text-muted">
                              No affected bookings
                            </div>
                          );
                        }

                        return (
                          <Row>
                            <Col className="text-right">
                              <input
                                type="checkbox"
                                id="deleteBookings"
                                onChange={e =>
                                  setDeleteBookings(e.target.checked)
                                }
                              />{' '}
                              <label htmlFor="deleteBookings">
                                CANCEL {bookings.length} BOOKINGS
                              </label>
                            </Col>
                          </Row>
                        );
                      }}
                    </Query>
                    <Row>
                      <Col>
                        {loading && <Loader size={5} inline />}
                        <Button
                          color="primary"
                          onClick={() =>
                            deleteSlotProto({
                              variables: {
                                slotPrototypeId: deletingSlotPrototypeId,
                                cancelBookings: deleteBookings
                              }
                            })
                          }
                          disabled={loading}
                        >
                          DELETE SCHEDULE
                        </Button>{' '}
                        <Button
                          color="secondary"
                          onClick={hideModal}
                          disabled={loading}
                        >
                          CANCEL
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </ModalFooter>
              </>
            );
          }}
        </Mutation>
      </Modal>

      <Query query={GET_SLOT_PROTOS} variables={{ entityId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <span>Something went wrong! Error: ${error}</span>;
          return (
            data.getSlotPrototypes &&
            data.getSlotPrototypes.map(sp => {
              // console.log(`sp.. ${JSON.stringify(sp)}`);
              const { hospitalName, slotPrototypes } = sp;
              slotPrototypes.sort((s1, s2) => {
                const m1 = moment(s1.startTime, 'HHmm');
                const m2 = moment(s2.startTime, 'HHmm');
                return m1.diff(m2);
              });
              return (
                <div key={hospitalName} style={{ marginBottom: '1rem' }}>
                  <h3>{hospitalName}</h3>
                  <div style={styles.container}>
                    {slotPrototypes.map(s => (
                      <SlotProto
                        key={s.id}
                        slotPrototype={s}
                        procedures={s.procedures}
                        onEdit={onEdit}
                        onDelete={prepareModal}
                      />
                    ))}
                  </div>
                </div>
              );
            })
          );
        }}
      </Query>
    </div>
  );
};

SlotProtoContainer.propTypes = {
  entityId: PropTypes.number.isRequired,
  onEdit: PropTypes.func
};

export default SlotProtoContainer;
