import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const InOutBadge = ({ isInRoom, onLeave }) => {
  let inColor = isInRoom ? 'primary' : 'danger';
  let inText = isInRoom ? 'IN' : 'OUT';
  if (onLeave) {
    inColor = 'info';
    inText = 'ON LEAVE';
  }
  return (
    <h6 className="inline-force">
      <Badge color={inColor}>{inText}</Badge>
    </h6>
  );
};

InOutBadge.propTypes = {
  isInRoom: PropTypes.bool.isRequired,
  onLeave: PropTypes.bool
};

InOutBadge.defaultProps = {
  onLeave: false
};

export default InOutBadge;
