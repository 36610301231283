import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import { FaQuestionCircle } from 'react-icons/fa';
import TextArea from 'react-autosize-textarea';
import { Button, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import { REPORT_ERROR } from '../queries';
import { Mutation } from 'react-apollo';
import Veil from './Veil';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';

const why = {
  nr: 'New Registration'
};

const phoneRegExp = /^[6-9]\d{9}$/;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Name too small')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  mobile: Yup.string()
    .matches(phoneRegExp, 'Invalid mobile number')
    .required('Mobile number is required'),
  desc: Yup.string()
    .trim()
    .min(10, 'Please write a note more than 5 words')
    .required('A description is required')
});

const WhyRadio = ({
  field: { onChange, value, name, onBlur },
  why,
  ...props
}) => (
  <>
    <input
      type="radio"
      value={why}
      checked={value === why}
      id={`${why}-radio`}
      onChange={onChange}
      onBlur={onBlur}
      // onClick={() => form.setFieldValue(field.name, why)}
      {...props}
      name={name}
    />{' '}
    <label htmlFor={`${why}-radio`}>{why}</label>
  </>
);

WhyRadio.propTypes = {
  field: PropTypes.object,
  why: PropTypes.string.isRequired
};

const TextArea1 = ({ field, ...props }) => <TextArea {...field} {...props} />;
TextArea1.propTypes = {
  field: PropTypes.object
};

const renderForm = ({ values, handleSubmit }) => {
  return (
    <div
      style={{
        marginTop: '1rem',
        padding: '1rem',
        backgroundColor: '#f6f8fa'
      }}
    >
      <label>Name</label>
      <Field type="text" name="name" placeholder="Name" className="form-row" />
      <div>
        <ErrorMessage component="span" name="name" className="error" />
      </div>
      <label>E-Mail</label>
      <Field
        type="text"
        name="email"
        placeholder="Your CloudNine E-mail"
        className="form-row"
      />
      <div>
        <ErrorMessage component="span" name="email" className="error" />
      </div>
      <label>Mobile Number</label>
      <Field
        type="text"
        name="mobile"
        length={10}
        placeholder="Mobile Number"
        className="form-row"
      />
      <div>
        <ErrorMessage component="span" name="mobile" className="error" />
      </div>
      <label>
        Description <FaQuestionCircle id="help-desc" />
      </label>
      <UncontrolledTooltip placement="top" target="help-desc">
        Please let us know details. Let us know about your branch and
        designation. If it is about a new login, please tell us the purpose of
        it to decide a suitable permission for you. An administrator may contact
        you to know more.
      </UncontrolledTooltip>
      <Field
        component={TextArea1}
        name="desc"
        placeholder="Description (at least 5 words)"
        className="form-row"
        rows={3}
      />
      <div>
        <ErrorMessage component="span" name="desc" className="error" />
      </div>
      <label>Reason</label>
      <div>
        <Field name="why" component={WhyRadio} why={why.nr} />
      </div>
      <div className="text-right">
        <Button color="secondary" onClick={handleSubmit}>
          REQUEST NEW LOGIN
        </Button>
      </div>
    </div>
  );
};

renderForm.propTypes = {
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  field: PropTypes.object
};

const ForgotPasswordForm = () => {
  return (
    <Mutation
      mutation={REPORT_ERROR}
      onCompleted={() => {
        displaySuccess(
          'Request send successfully. Please give us 24 hours to contact you.',
          false
        );
      }}
      onError={() =>
        displayError(
          `Something went wrong, please report this to apptsupport@cloudninecare.com`
        )
      }
    >
      {(sendMessage, { loading }) => {
        return (
          <div>
            {loading && <Veil />}
            <Formik
              initialValues={{
                name: '',
                email: '',
                mobile: '',
                why: why.nr,
                desc: ''
              }}
              render={renderForm}
              validationSchema={validationSchema}
              onSubmit={({ name, email, mobile, why, desc }) => {
                const error = 'Request sent from login page';
                const message =
                  `----- ${why.toUpperCase()} -----\n` +
                  `NAME: ${name}\n` +
                  `CONTACT: ${email} (${mobile})\n` +
                  `WHY: ${why}\n` +
                  `DESCRIPTION: ${desc}\n` +
                  `--------------------------\n`;
                logAmplitudeEvent('Forgot_Password_Requested', {
                  name,
                  email,
                  mobile,
                  why,
                  desc
                });
                sendMessage({
                  variables: {
                    input: {
                      error,
                      message,
                      replyToEmail: email,
                      replyToPhone: mobile
                    }
                  }
                });
              }}
            />
          </div>
        );
      }}
    </Mutation>
  );
};

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;
