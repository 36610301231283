import React from 'react';
import PropTypes from 'prop-types';
import DP from 'react-datepicker';

const DateSelectorFormik = ({ form, field, onBlur, ...props }) => {
  return (
    <DP
      selected={field.value}
      onChange={v => form.setFieldValue(field.name, v)}
      onBlur={onBlur}
      {...props}
    />
  );
};

DateSelectorFormik.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  onBlur: PropTypes.func
};

export default DateSelectorFormik;
