import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { FaTimes } from 'react-icons/fa';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import DocFilterSelector, {
  DOC_FILTERS
} from '../components/DocFilterSelector';
import { getLoggedInUser } from '../permissions';
import { SEARCH_DOCTORS_EXTENDED } from '../queries';
import DocBadgeExtended from './DocBadgeExtended';
import Loader from './Loader';

const filterDocs = (docs, { sortBy, search }) => {
  if (docs && search && `${search}`.trim().length > 0) {
    docs = docs.filter(
      d => d.name.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
    );
  }

  if (!docs || docs.length < 2) {
    return docs;
  }
  const filter = sortBy;
  if (filter === DOC_FILTERS.a2z) {
    docs.sort((d1, d2) =>
      `${d1.name}`.toLowerCase().localeCompare(`${d2.name}`.toLowerCase())
    );
  } else if (filter === DOC_FILTERS.z2a) {
    docs.sort(
      (d1, d2) =>
        `${d1.name}`.toLowerCase().localeCompare(`${d2.name}`.toLowerCase()) *
        -1
    );
  } else if (filter === DOC_FILTERS.inFirst) {
    docs.sort((d1, d2) => {
      const in1 = d1.entity && d1.entity.isInRoom;
      const in2 = d2.entity && d2.entity.isInRoom;
      if (in1 !== in2) {
        return in1 === true ? -1 : 1;
      } else {
        return `${d1.name}`
          .toLowerCase()
          .localeCompare(`${d2.name}`.toLowerCase());
      }
    });
  } else if (filter === DOC_FILTERS.rank) {
    docs.sort((d1, d2) => {
      return d1.globalRank !== d2.globalRank
        ? d1.globalRank - d2.globalRank
        : `${d1.name}`.toLowerCase().localeCompare(`${d2.name}`.toLowerCase());
    });
  }

  return docs;
};

const DocListApptPage = ({
  searchInput,
  onStartBooking,
  listOfDoctors,
  onStartEmergencyBooking
}) => {
  const [localFilters, setLocalFilters] = useState({
    sortBy: DOC_FILTERS.rank
  });
  const setSorting = sortBy => setLocalFilters({ ...localFilters, sortBy });
  return (
    <Query
      query={SEARCH_DOCTORS_EXTENDED}
      variables={{ searchInput }}
      pollInterval={60000}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) return `Error!: ${error}`;
        const { docs } = data;
        const { role = null } = getLoggedInUser();
        const filteredDocs = filterDocs(docs, localFilters);
        listOfDoctors(filteredDocs);
        return (
          <>
            <div className="doc-list-header">
              <h5>Doctors {filteredDocs && filteredDocs.length}</h5>
              <DocFilterSelector onSelect={setSorting} />
            </div>
            <div>
              <InputGroup>
                <Input
                  placeholder="Search Doctor"
                  value={localFilters.search}
                  onChange={e =>
                    setLocalFilters({
                      ...localFilters,
                      search: e.target.value.trim()
                    })
                  }
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() =>
                      setLocalFilters({ ...localFilters, search: '' })
                    }
                  >
                    <FaTimes />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            {filteredDocs.map((doc, i) => (
              <DocBadgeExtended
                key={`d-${i}`}
                doc={doc}
                onBook={e => onStartBooking(e, doc.id, doc.entityId)}
                onEmergencyBook={e =>
                  onStartEmergencyBooking(e, doc.id, doc.entityId)
                }
                isCallcenter={role === 'customersupport'}
              />
            ))}
          </>
        );
      }}
    </Query>
  );
};

DocListApptPage.propTypes = {
  searchInput: PropTypes.object.isRequired,
  onStartBooking: PropTypes.func.isRequired,
  listOfDoctors: PropTypes.func.isRequired,
  onStartEmergencyBooking: PropTypes.func.isRequired
};

export default DocListApptPage;
