import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  FaEyeSlash,
  FaWalking,
  FaStopwatch,
  FaCrown,
  FaRupeeSign
} from 'react-icons/fa';
import { MdUndo } from 'react-icons/md';
import Loader from './Loader';
import { Mutation } from 'react-apollo';
import { displayError, logAmplitudeEvent } from '../utilities';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  UPDATE_BOOKING_STATE,
  UNDO_LAST_BOOKING_STATE,
  SEARCH_BOOKINGS_POLLING_INTERVAL
} from '../queries';
import { statusColors } from './patientBadge';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FaRegTimesCircle } from 'react-icons/fa';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';

export const apptStates = {
  CheckIn: { name: 'Check In', value: 'CheckedIn' },
  NoShow: { name: 'No Show', value: 'NoShow' },
  StartConsultation: { name: 'Start Consultation', value: 'StartConsultation' },
  EndConsultation: { name: 'End Consultation', value: 'EndConsultation' },
  CheckOut: { name: 'Check Out', value: 'CheckedOut' },
  CheckedOut: { name: 'Checked Out', value: 'NoAction' },
  Cancelled: { name: 'Cancelled', value: 'Cancelled' }
};

// function to show "Please update mpid to enable checkin / start consultation" toast message with timeout.
const errorMessage = message => {
  const messageClose = {};
  messageClose.autoClose = 3000;

  toast.error(
    <span style={{ color: 'white' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        <FaRegTimesCircle />
      </span>
      {message}
    </span>,
    messageClose
  );
};

const cancelConfirmationMessage = (name, reason) => (
  <>
    <span>
      Cancel <span style={{ color: 'blue', fontWeight: 'bold' }}>{name}</span>
      &#39;s appointment?
    </span>
    <br />
    <small>
      <strong>Reason:&nbsp;</strong>
      {reason}
    </small>
    <br />
    <strong>THIS ACTION IS IRREVERSIBLE</strong>
  </>
);
const reasons = [
  'Cancelled by Hospital',
  'Last minute emergency',
  'Need to reschedule',
  'Duplicate appointment booking',
  'Booked wrong procedure',
  'Booked for wrong person',
  'No longer required',
  'Booked by mistake',
  'Price too high',
  'My reason is not listed here'
];

const ActionButtonsPatientBadge = props => {
  const [showReasons, setShowReasons] = useState(false);
  const {
    booking: {
      id,
      mpid,
      appointmentState,
      created_at,
      checkinTS,
      noShowTS,
      startConsultationTS,
      endConsultationTS,
      checkoutTS,
      cancelledTS,
      userName,
      isWalkIn,
      isTatkal,
      isReserved
    },
    btnColor,
    isNoShowBtnEye,
    stopPolling,
    startPolling,
    TimeOfAppointment,
    PatientName
  } = props;

  const btnColor1 = btnColor || 'link';
  let actionButton = apptStates.CheckedOut;

  // used to enable / disable 'Check In' button based on whether mpid is present for a particular appointment.
  let isCheckInDisabled = false;

  // used to enable / disable 'Start Consultation' button based on whether mpid is present for a particular appointment.
  let isStartConsultationDisabled = false;

  //if Cancelled
  if (cancelledTS) {
    actionButton = apptStates.Cancelled;
    //if user hasn't checkedIn
  } else if (!checkinTS) {
    // checking if user’s mpid is present, if present then only show ‘Check In’ option.
    if (mpid) {
      isCheckInDisabled = false;
      actionButton = apptStates.CheckIn;
    } else {
      actionButton = '';
      isCheckInDisabled = true;
    }
    //customer has checkedIn but not ended consultation
  } else if (!!checkinTS && !startConsultationTS) {
    // checking if user’s mpid is present, if present then only show 'Start Consultation' option.
    if (mpid) {
      isStartConsultationDisabled = false;
      actionButton = apptStates.StartConsultation;
    } else {
      actionButton = '';
      isStartConsultationDisabled = true;
    }
    //consulation has started
  } else if (!!startConsultationTS && !endConsultationTS) {
    actionButton = apptStates.EndConsultation;
    //consultation ended
  } else if (!!endConsultationTS && !checkoutTS) {
    actionButton = apptStates.CheckOut;
  }

  //Can cancel if there is no activity
  const canCancel =
    !cancelledTS &&
    !noShowTS &&
    !checkinTS &&
    !startConsultationTS &&
    !endConsultationTS &&
    !checkoutTS;

  const getStatusTime = time => moment(time).format('DD-MMM hh:mm A');

  let badgeColor = 'light';
  let statusTime = getStatusTime(created_at);
  if (cancelledTS) {
    badgeColor = 'danger';
    statusTime = getStatusTime(cancelledTS);
  } else if (noShowTS) {
    badgeColor = 'warning';
    statusTime = getStatusTime(noShowTS);
  } else if (checkoutTS) {
    badgeColor = 'secondary';
    statusTime = getStatusTime(checkoutTS);
  } else if (startConsultationTS) {
    badgeColor = 'primary';
    statusTime = getStatusTime(startConsultationTS);
  } else if (checkinTS) {
    badgeColor = 'dark';
    statusTime = getStatusTime(checkinTS);
  }
  // can undo if:
  // Marked UNDO
  // OR [
  // 1. not-cancelled (because cancelation cannot be undone, slots opened, rebook)
  // AND
  // 2. at least in checked-in state (without check in there nothing to undo, you are at booked state)
  // ]
  const canUndo = !!noShowTS || (!cancelledTS && !!checkinTS);

  // const showCancel = canCancel && !noShowTS;
  // const showNoShow = canCancel && !cancelledTS;
  const isCheckedOut = !!checkoutTS;
  const canChangeState = !noShowTS && !cancelledTS;

  // TODO: why can't we just have ONE actionButtonParams? Think later.
  const noShowParams = {
    variables: { bookingId: id, state: apptStates.NoShow.value }
  };
  const cancelParams = {
    variables: { bookingId: id, state: apptStates.Cancelled.value }
  };

  const actionButtonParams = {
    variables: { bookingId: id, state: actionButton.value }
  };

  const undoParams = { variables: { bookingId: id } };

  const dxPrescriptionEnd = amsappointmentid => {
    const url = `${CX_USER_DB_BASE_URL}/dx2/prescription/end2`;

    const req_body = {
      amsappointmentid: amsappointmentid
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    fetch(url, fetchOpts)
      .then(response => response.json())
      .then(response => {
        if (response && response.status && response.status === 1) {
          logAmplitudeEvent(
            'DX_PRESCRIPTION_END_SUCCESS',
            {
              amsappointmentid: amsappointmentid
            },
            true
          );
        } else {
          logAmplitudeEvent(
            'DX_PRESCRIPTION_END_FAILED',
            {
              amsappointmentid: amsappointmentid,
              response: response
            },
            true
          );
        }
      })
      .catch(error => {
        logAmplitudeEvent(
          'DX_PRESCRIPTION_END_FAILED',
          {
            amsappointmentid: amsappointmentid,
            error: error
          },
          true
        );
      });
  };

  return (
    <Mutation
      mutation={UPDATE_BOOKING_STATE}
      refetchQueries={() => ['GET_BOOKINGS_STATUS']}
      onError={e => displayError(e)}
      onCompleted={data => {
        const {
          booking: {
            cancelationReason,
            objectName,
            procedureName,
            slotTime,
            userName,
            cancelledTS
          }
        } = data;
        if (cancelledTS) {
          const eventProps = {
            procedureName,
            PatientName: userName,
            TimeOfAppointment: moment(slotTime).format('hh:mm A'),
            DateOfBooking: moment(slotTime).format('DD-MM-YYYY'),
            ConsultantName: objectName,
            CancellationReason: cancelationReason
          };
          logAmplitudeEvent('Cancel_Appointment', eventProps, true);
        }
        if (data.booking.appointmentState === 'Checked Out') {
          dxPrescriptionEnd(id);
        }
      }}
    >
      {(updateState, { loading }) => {
        const getDropDown = (
          <DropdownMenu>
            <DropdownItem header>REASON?</DropdownItem>
            {reasons.map(reason => (
              <DropdownItem
                toggle={false}
                key={reason}
                onClick={() => {
                  showConfirmation(
                    'Cancel Booking',
                    cancelConfirmationMessage(userName, reason),
                    () =>
                      updateState({
                        variables: { ...cancelParams.variables, reason }
                      })
                  );
                  logAmplitudeEvent(
                    'Cancellation_Reason',
                    { reason: reason },
                    true
                  );
                }}
              >
                {reason}
              </DropdownItem>
            ))}
          </DropdownMenu>
        );

        let displayApptStateText = appointmentState;
        switch (appointmentState.toUpperCase()) {
          case 'BOOKED AND PAID':
            displayApptStateText = 'Booked';
            break;
          case 'START CONSULTATION':
            displayApptStateText = 'Consulting';
            break;
          default:
            displayApptStateText = appointmentState;
        }

        const apptStateText = (
          <span>
            {`${appointmentState}`.toUpperCase() === 'BOOKED AND PAID' && (
              <FaRupeeSign
                style={{ color: 'blue', marginRight: '0.2rem' }}
                title="Paid"
              />
            )}
            <span>
              {displayApptStateText} {` @${statusTime}`}
            </span>
          </span>
        );

        return (
          <Mutation
            mutation={UNDO_LAST_BOOKING_STATE}
            refetchQueries={() => ['GET_BOOKINGS_STATUS']}
            onError={e1 => displayError(e1)}
          >
            {(undoLastState, { loading: loadingUndo }) => (
              <React.Fragment>
                {loading || loadingUndo ? (
                  <Loader size={5} />
                ) : (
                  <span style={{ marginLeft: '0.2rem' }}>
                    <Badge
                      pill
                      color={badgeColor}
                      style={{
                        paddingBottom: '3px',
                        textTransform: 'uppercase',
                        width: '15rem'
                      }}
                    >
                      {apptStateText}
                    </Badge>
                  </span>
                )}
                {isWalkIn && (
                  <span
                    title="Walk In Booking"
                    alt="Walk In Booking"
                    style={{ color: `${statusColors[appointmentState]}` }}
                  >
                    <FaWalking />
                  </span>
                )}
                {isTatkal && (
                  <span
                    title="Tatkal Booking"
                    alt="Tatkal Booking"
                    style={{ color: `${statusColors[appointmentState]}` }}
                  >
                    <FaStopwatch />
                  </span>
                )}
                {isReserved && (
                  <span
                    title="Reserved Booking"
                    alt="Reserved Booking"
                    style={{ color: `${statusColors[appointmentState]}` }}
                  >
                    <FaCrown />
                  </span>
                )}
                <span>
                  <Button
                    size="sm"
                    className="patient-badge-action-button"
                    color={btnColor1}
                    title="Go back to last appointment stage"
                    onClick={() => {
                      undoLastState(undoParams);
                      logAmplitudeEvent('Undo_Icon_Pressed', {}, true);
                    }}
                    disabled={loading || !canUndo}
                  >
                    <MdUndo />
                  </Button>
                  <Button
                    size="sm"
                    className="patient-badge-action-button"
                    color={btnColor1}
                    title="Mark as No Show"
                    onClick={() => {
                      updateState(noShowParams);
                      logAmplitudeEvent(
                        'NoShow_Tab_Pressed',
                        { PatientName, TimeOfAppointment },
                        true
                      );
                    }}
                    disabled={loading || !canCancel}
                  >
                    {isNoShowBtnEye ? <FaEyeSlash /> : 'No Show'}
                  </Button>
                  <Dropdown
                    isOpen={showReasons}
                    toggle={() => {
                      if (!showReasons) {
                        //if we are about to show dropdown stop polling
                        // console.log('stopped polling');
                        stopPolling();
                      } else {
                        // console.log('started polling');
                        startPolling(SEARCH_BOOKINGS_POLLING_INTERVAL);
                      }
                      setShowReasons(!showReasons);
                    }}
                    style={{ display: 'inline' }}
                  >
                    <DropdownToggle
                      size="sm"
                      caret
                      color={btnColor1}
                      disabled={loading || !canCancel}
                      className="patient-badge-action-button"
                      onClick={() => stopPolling()}
                    >
                      Cancel
                    </DropdownToggle>
                    {getDropDown}
                  </Dropdown>
                  {/* <Button
                    size="sm"
                    className="patient-badge-action-button"
                    color={btnColor1}
                    onClick={() =>
                      showConfirmation(
                        'Cancel Booking',
                        cancelConfirmationMessage(userName),
                        () => updateState(cancelParams)
                      )
                    }
                    disabled={loading || !canCancel}
                  >
                    Cancel
                  </Button> */}
                  <Button
                    size="sm"
                    className="patient-badge-action-button"
                    color={btnColor1}
                    disabled={!canChangeState || isCheckedOut || loading}
                    onClick={() => {
                      updateState(actionButtonParams);
                      if (actionButton.name === 'Check In') {
                        logAmplitudeEvent(
                          'CheckedIn_Tab_Pressed',
                          { PatientName, TimeOfAppointment },
                          true
                        );
                      }
                      if (actionButton.name === 'Start Consultation') {
                        logAmplitudeEvent(
                          'StartConsultation_Tab_Pressed',
                          { PatientName, TimeOfAppointment },
                          true
                        );
                      }
                      if (
                        actionButton.name === 'Check Out' ||
                        actionButton.name === 'End Consultation'
                      ) {
                        dxPrescriptionEnd(id);
                        logAmplitudeEvent(
                          'CheckedOut_Tab_Pressed',
                          { PatientName, TimeOfAppointment },
                          true
                        );
                      }
                    }}
                  >
                    {actionButton.name}
                  </Button>

                  {/* Showing disabled check in button, on clicking it or on hover, "Please update mpid to enable checkin" toast message will trigger.  */}
                  {isCheckInDisabled ? (
                    <p
                      style={{
                        color: '#9da3a9',
                        fontSize: 13,
                        cursor: 'pointer',
                        display: 'inline'
                      }}
                      onMouseEnter={() => {
                        errorMessage('Please update mpid to enable checkin');
                      }}
                      onClick={() => {
                        errorMessage('Please update mpid to enable checkin');
                      }}
                    >
                      Check In
                    </p>
                  ) : null}

                  {/* Showing disabled start consultation button, on clicking it or on hover, "Please update mpid to enable start consultation" toast message will trigger.  */}
                  {isStartConsultationDisabled ? (
                    <p
                      style={{
                        color: '#9da3a9',
                        fontSize: 13,
                        cursor: 'pointer',
                        display: 'inline'
                      }}
                      onMouseEnter={() => {
                        errorMessage(
                          'Please update mpid to enable start consultation'
                        );
                      }}
                      onClick={() => {
                        errorMessage(
                          'Please update mpid to enable start consultation'
                        );
                      }}
                    >
                      Start Consultation
                    </p>
                  ) : null}
                </span>
              </React.Fragment>
            )}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

ActionButtonsPatientBadge.propTypes = {
  booking: PropTypes.object.isRequired,
  btnColor: PropTypes.string,
  isNoShowBtnEye: PropTypes.bool,
  stopPolling: PropTypes.func,
  startPolling: PropTypes.func,
  TimeOfAppointment: PropTypes.string,
  PatientName: PropTypes.string
};

ActionButtonsPatientBadge.defaultProps = {
  isNoShowBtnEye: true,
  stopPolling: () => {},
  startPolling: () => {},
  TimeOfAppointment: '',
  PatientName: ''
};

export default ActionButtonsPatientBadge;
