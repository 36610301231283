import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

export default function MpidRegistration(props) {
  const initialValues = {
    visitPurpose: '',
    first_name: '',
    date_of_birth: '',
    gender: '',
    mobile: '',
    email: '',
    addressStreet: '',
    addressCity: '',
    pinCode: '',
    forceCreate: false
  };

  const validationSchema = Yup.object().shape({
    visitPurpose: Yup.string(),
    first_name: Yup.string().required('Please enter the first name.'),
    date_of_birth: Yup.string().required('Please enter the date of birth'),
    gender: Yup.string().required('Please enter the gender'),
    mobile: Yup.string()
      .required('Please enter the mobile number')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid mobile number'),
    email: Yup.string()
      .required('Please enter the email address')
      .email('Invalid Email address'),
    addressStreet: Yup.string(),
    addressCity: Yup.string(),
    pinCode: Yup.string().matches(/^[1-9][0-9]{5}$/, 'Invalid pincode.'),
    forceCreate: Yup.boolean().required()
  });

  const onSubmit = (values, actions) => {
    logAmplitudeEvent(
      'Registration_Icon_Pressed',
      {
        mobile: values.mobile
      },
      true
    );

    const loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      registered_site_code: props.selectedHospitalDetails.siteCode,
      hospitalId: props.selectedHospitalDetails.id,
      first_name: values.first_name,
      date_of_birth: moment(values.date_of_birth).format('DD-MM-YYYY'),
      addressStreet: values.addressStreet,
      pinCode: values.pinCode ? values.pinCode.toString() : '',
      gender: values.gender,
      email: values.email,
      addressCity: values.addressCity,
      nationality_country: 'India',
      mobile: values.mobile.toString(),
      visitPurpose: values.visitPurpose,
      forceCreate: values.forceCreate,
      createdBy: loggedInUser && loggedInUser.email ? loggedInUser.email : '',
      source: 'AMS'
    };

    const url = `${CX_USER_DB_BASE_URL}/mpid/lt/register-patient`;
    axios
      .post(url, req_body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
        }
      })
      .then(resData => {
        actions.resetForm();
        logAmplitudeEvent(
          'Registration_Successful',
          {
            request: req_body
          },
          true
        );
        displaySuccess(resData.data.data.message);
        props.toggleListPage();
      })
      .catch(err => {
        console.log(err);
        actions.setSubmitting(false);
        logAmplitudeEvent(
          'Registration_Failed',
          {
            request: req_body
          },
          true
        );
        displayError(
          err.response.data
            ? err.response.data.error.message
            : 'Something went wrong!'
        );
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalHeader toggle={props.toggle}>Registration Details</ModalHeader>
      <ModalBody>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '8%',
                flexDirection: 'column'
              }}
            >
              <label>
                First Name:{' '}
                <span style={{ fontSize: '1em', color: 'red' }}>*</span>
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="text"
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                onBlur={formik.handleBlur}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <span style={{ color: 'red' }}>{formik.errors.first_name}</span>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                width: '5%',
                flexDirection: 'column'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                width: '10%',
                flexDirection: 'column'
              }}
            >
              <label>
                Email: <span style={{ fontSize: '1em', color: 'red' }}>*</span>
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <span style={{ color: 'red' }}>{formik.errors.email}</span>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '8%',
                flexDirection: 'column'
              }}
            >
              <label>Address:</label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="text"
                name="addressStreet"
                value={formik.values.addressStreet}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.addressStreet && formik.errors.addressStreet ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.addressStreet}
                </span>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                width: '5%',
                flexDirection: 'column'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                width: '10%',
                flexDirection: 'column'
              }}
            >
              <label>Pincode:</label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="number"
                name="pinCode"
                onChange={formik.handleChange}
                value={formik.values.pinCode}
                onBlur={formik.handleBlur}
              />
              {formik.touched.pinCode && formik.errors.pinCode ? (
                <span style={{ color: 'red' }}>{formik.errors.pinCode}</span>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '8%',
                flexDirection: 'column'
              }}
            >
              <label>City:</label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="text"
                name="addressCity"
                value={formik.values.addressCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.addressCity && formik.errors.addressCity ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.addressCity}
                </span>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                width: '5%',
                flexDirection: 'column'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                width: '11%',
                flexDirection: 'column'
              }}
            >
              <label>
                Mobile: <span style={{ fontSize: '1em', color: 'red' }}>*</span>
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <h6
                  style={{
                    marginRight: '4px',
                    marginBottom: '0px',
                    marginTop: '0px',
                    marginLeft: '0px'
                  }}
                >
                  +91{' '}
                </h6>
                <input
                  type="number"
                  maxLength="10"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile ? (
                <span style={{ color: 'red' }}>{formik.errors.mobile}</span>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '8%',
                flexDirection: 'column'
              }}
            >
              <label>
                Gender: <span style={{ fontSize: '1em', color: 'red' }}>*</span>
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <select
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  height: '35px'
                }}
              >
                <option value="">Select</option>
                <option gender="male" name="gender">
                  Male
                </option>
                <option gender="female" name="gender">
                  Female
                </option>
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <span style={{ color: 'red' }}>{formik.errors.gender}</span>
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                width: '5%',
                flexDirection: 'column'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                width: '10%',
                flexDirection: 'column'
              }}
            >
              <label>
                Date Of Birth:{' '}
                <span style={{ fontSize: '1em', color: 'red' }}>*</span>
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                width: '40%',
                flexDirection: 'column'
              }}
            >
              <input
                type="date"
                name="date_of_birth"
                max={moment().format('YYYY-MM-DD')}
                onChange={formik.handleChange}
                value={formik.values.date_of_birth}
                onBlur={formik.handleBlur}
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.date_of_birth}
                </span>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '14px',
              alignItems: 'center'
            }}
          >
            <label style={{ marginRight: '8px' }}>Purpose of Visit: </label>
            <div
              style={{
                display: 'flex',
                width: '37%',
                flexDirection: 'column'
              }}
            >
              <select
                value={formik.values.visitPurpose}
                name="visitPurpose"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  height: '35px'
                }}
              >
                <option value="">Select</option>
                <option service="Obstetrics" name="service">
                  PREGNANCY
                </option>
                <option service="Others" name="service">
                  WOMEN HEALTH
                </option>
                <option service="Fertility" name="service">
                  FERTILITY
                </option>
                <option service="Pediatrics" name="service">
                  PEDIATRICS
                </option>
                <option service="Obstetrics" name="service">
                  OBSTETRICS
                </option>
                <option service="Gynecology" name="service">
                  GYNECOLOGY
                </option>
                <option service="Cosmetology" name="service">
                  COSMETOLOGY
                </option>
                <option service="Obstetrics" name="service">
                  PREGNANCY SERVICES
                </option>
                <option service="Others" name="service">
                  OTHERS
                </option>
              </select>
              {formik.touched.visitPurpose && formik.errors.visitPurpose ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.visitPurpose}
                </span>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: 12
            }}
          >
            <input
              type="checkbox"
              id="forceCreate"
              name="forceCreate"
              checked={formik.values.forceCreate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              style={{ fontSize: '14px', margin: '0px', marginLeft: '6px' }}
              htmlFor="forceCreate"
            >
              <i>Force create</i>
            </label>
          </div>
          <div>
            {formik.touched.forceCreate && formik.errors.forceCreate ? (
              <span style={{ color: 'red' }}>{formik.errors.forceCreate}</span>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggleListPage}>
          Back
        </Button>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
        <Button color="primary" type="submit" disabled={formik.isSubmitting}>
          Register
        </Button>
      </ModalFooter>
    </form>
  );
}

MpidRegistration.propTypes = {
  selectedHospitalDetails: PropTypes.object,
  toggleListPage: PropTypes.func,
  toggle: PropTypes.func
};
