import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { SEND_BOOKING_PRESCRIPTION } from '../queries';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import { Button } from 'reactstrap';
import { FaReplyAll } from 'react-icons/fa';

const ResendPrescription = ({ bookingId }) => {
  return (
    <Mutation
      mutation={SEND_BOOKING_PRESCRIPTION}
      onCompleted={() => displaySuccess('Resent Prescription')}
      onError={e => displayError(e)}
      refetchQueries={() => ['BOOKING_MESSAGE_HISTORY']}
    >
      {sendReminder => (
        <Button
          color="link"
          title="Resend Prescription"
          size="sm"
          onClick={() => {
            sendReminder({ variables: { bookingId } });
            logAmplitudeEvent('Resend_Prescription', {}, true);
          }}
        >
          <FaReplyAll />
        </Button>
      )}
    </Mutation>
  );
};

ResendPrescription.propTypes = {
  bookingId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

ResendPrescription.defaultProps = {
  disabled: false
};

export default ResendPrescription;
