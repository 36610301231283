import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import SelectPaymentMode from './SelectPaymentMode';

const styles = {
  formLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

const PaymentForm = props => {
  const { paymentData, onChange, onSubmit } = props;
  const { cardNumber, transactionId, paymentMode } = paymentData || {};

  const shouldShowTransactionIdInput = payMode => {
    return payMode && payMode.toLowerCase().includes('cash') ? false : true;
  };

  const shouldShowCardNumberInput = payMode => {
    return payMode &&
      (payMode.toLowerCase().includes('cash') ||
        payMode.toLowerCase().includes('upi'))
      ? false
      : true;
  };

  return (
    <React.Fragment>
      <Row className="form-row">
        <Col xs={4} className="text-right" style={styles.formLabel}>
          Payment Mode<div style={{ color: 'red' }}>*</div>:
        </Col>
        <Col xs={8}>
          <SelectPaymentMode
            selectedPaymentMode={paymentMode}
            onPaymentModeSelect={pmode => onChange('paymentMode', pmode)}
          />
        </Col>
      </Row>
      {shouldShowTransactionIdInput(paymentMode) && (
        <Row className="form-row">
          <Col xs={4} className="text-right" style={styles.formLabel}>
            Transaction Id<div style={{ color: 'red' }}>*</div>:
          </Col>
          <Col xs={8}>
            <input
              type="number"
              onChange={e => onChange('transactionId', e.target.value)}
              defaultValue={transactionId}
            />
          </Col>
        </Row>
      )}
      {shouldShowCardNumberInput(paymentMode) && (
        <Row className="form-row">
          <Col xs={4} className="text-right" style={styles.formLabel}>
            Card Number (last 4 digits)<div style={{ color: 'red' }}>*</div>:
          </Col>
          <Col xs={8}>
            <input
              type="text"
              onChange={e => onChange('cardNumber', e.target.value)}
              defaultValue={cardNumber}
              maxLength={4}
            />
          </Col>
        </Row>
      )}
      <Row className="form-row">
        <Col className="text-right">
          <Button
            disabled={!paymentMode}
            color="primary"
            size="sm"
            type="submit"
            onClick={e => {
              e.preventDefault();
              onSubmit(paymentData);
            }}
          >
            Generate Invoice
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

PaymentForm.propTypes = {
  paymentData: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default PaymentForm;
