import React from 'react';
import PropTypes from 'prop-types';
import { FaWalking, FaStopwatch, FaCrown } from 'react-icons/fa';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import ReserveSlot from './reserveSlot';
import { UPDATE_RESERVE_SLOT } from '../queries';
import { showConfirmation } from '../ConfirmationDialogService';

export const WALKIN = 1;
export const TATKAL = 2;

const CompanionSlot = props => {
  const { id, companionType, isReserved, startTime } = props;
  let msg = "Are you sure you want to delete reservation at " + moment(startTime).format('h:mm A') + "?";
  const confirm = reservedSlot => {
    showConfirmation('Delete Reservation?', msg, () =>
      reservedSlot({ variables: { id: id, isReserved: false } })
    )
  };
  return (
    <Mutation
      mutation={UPDATE_RESERVE_SLOT}
      variables={{ id: id, isReserved: false }
      }
      refetchQueries={() => ['SLOTS_QUERY']}
      onError={e => {
        displayError(e)
      }}
      onCompleted={data => {
        displaySuccess('Reservation is deleted');
        let eventProps = { ...props, time: moment(startTime).format('h:mm A') };
        logAmplitudeEvent('Unreserve_Slot', eventProps, true);
      }}
    >
      {(reservedSlot, { loading, error, data }) => {
        switch (companionType) {
          case WALKIN:
            return isReserved ? (
              <span title="Reserved Walk in">
                <FaWalking onClick={() => confirm(reservedSlot)}
                  color="red" />
              </span>
            ) : (
              <>
                <span title="Walk in">
                  <FaWalking color="blue" />
                </span>
                <ReserveSlot {...props} />
              </>
            );
          case TATKAL:
            return isReserved ? (
              <span title="Reserved Tatkal">
                <FaStopwatch onClick={() => confirm(reservedSlot)}
                  color="red" />
              </span>
            ) : (
              <>
                <span title="Tatkal">
                  <FaStopwatch color="blue" />
                </span>
                <ReserveSlot {...props} />
              </>
            );
          default:
            return isReserved ? (
              <span title="Reserved">
                <FaCrown onClick={() => confirm(reservedSlot)}
                  color="red" />
              </span>
            ) : (
              <ReserveSlot {...props} />
            );
        }
      }}
    </Mutation>
  )
};

CompanionSlot.propTypes = {
  id: PropTypes.string,
  companionType: PropTypes.number.isRequired,
  isReserved: PropTypes.bool,
  startTime: PropTypes.instanceOf(Date),
  entityId: PropTypes.number,
  hospitalId: PropTypes.number,
  procedureName: PropTypes.string
};

export default CompanionSlot;