import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FaCog } from 'react-icons/fa';
import { Button, Col, Container, Row } from 'reactstrap';
import { DEFAULT_DOC_FEMALE, DEFAULT_DOC_MALE } from '../constants';
import DocSettings from './DocSettings';
import InOutBadge from './InOutBadge';
import WeeklySchedulePopover from './WeeklySchedulePopover';

const styles = {
  outer: {
    borderRadius: '4px',
    backgroundColor: '#f6f8fa',
    border: '1px solid #eaedef',
    padding: '10px',
    marginTop: '5px'
  },

  pic: {
    marginRight: '5px'
  }
};

export default class DocBadge extends Component {
  constructor(props) {
    super(props);
    //    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  renderProfileStatusTag() {
    const { profileStatus } = this.props.doc;
    const statusMap = {
      new: { className: 'doc-new-tag doc-new', text: 'NEW' },
      pending: { className: 'doc-new-tag doc-pending', text: 'PENDING' },
      rejected: { className: 'doc-new-tag doc-rejected', text: 'REJECTED' }
    };

    if (profileStatus in statusMap) {
      const { className, text } = statusMap[profileStatus];
      return (
        <div className={className}>
          <div className="badge-text">{text}</div>
        </div>
      );
    }

    return null;
  }

  toggle = e => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  onToggleSettings = e => {
    e.preventDefault();
    const { showDocSettings } = this.state;
    this.setState({ showDocSettings: !showDocSettings });
  };

  render() {
    const {
      doc,
      role,
      doc: { name, primarySpecialty, photos, entityId, isConfigured, entity },
      onBook,
      onClick
    } = this.props;
    const { showDocSettings } = this.state;
    const { isInRoom = false } = entity || {};
    let showRoom = true;
    if (!entity) {
      showRoom = false;
    }

    const dpURL = photos && photos.dp300 ? photos.dp300.url : undefined;

    let displayUrl = dpURL;
    if (!dpURL && doc.gender === 'Male') {
      displayUrl = DEFAULT_DOC_MALE;
    } else if (!dpURL) {
      displayUrl = DEFAULT_DOC_FEMALE;
    }

    // const { collapse } = this.state;
    const actionWhenLinkClicked = onClick ? onClick : onBook;
    return (
      <Container
        fluid
        style={styles.outer}
        className={classnames([
          { 'doc-unconfigured': !isConfigured },
          'doc-badge'
        ])}
      >
        <Row>
          <Col xs={3}>
            {role !== 'buhr' && this.renderProfileStatusTag()}
            <img
              style={styles.pic}
              src={displayUrl}
              alt={name}
              height={50}
              width={50}
            />
          </Col>
          <Col xs={8}>
            <span
              style={{ position: 'absolute', right: '-1.5rem', top: '-1rem' }}
            >
              <WeeklySchedulePopover entityId={entityId} entityName={name} />
              <Button size="sm" color="link" onClick={this.onToggleSettings}>
                <FaCog />
              </Button>
            </span>
            {showRoom && (
              <span
                style={{ position: 'absolute', right: '-1.5rem', bottom: 0 }}
              >
                <InOutBadge isInRoom={isInRoom} />
              </span>
            )}

            {showDocSettings ? (
              <DocSettings
                isOpen={showDocSettings}
                onToggle={this.onToggleSettings}
                entityId={entityId}
                doctorName={name}
              />
            ) : null}
            <div>
              <Button
                className="doc-badge-title"
                color="link"
                onClick={actionWhenLinkClicked}
              >
                <h6>{name}</h6>
              </Button>
              <br />
              <small>{primarySpecialty}</small>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

DocBadge.propTypes = {
  doc: PropTypes.object,
  role: PropTypes.string,
  onClick: PropTypes.func,
  onBook: PropTypes.func
};
