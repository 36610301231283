import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import AddOrEditCity from './AddOrEditCity';
import { Modal } from 'reactstrap';
import { useQuery } from 'react-apollo';
import Select from 'react-select';
import { GET_ALL_CITIES } from '../queries';

const CitiesTab = () => {
  const {
    data: { cities } = [],
    loading: citiesLoading
    // error: citiesError
  } = useQuery(GET_ALL_CITIES);

  const [selectedCity, setSelectedCity] = useState('');
  const [newCityModalOpen, setNewCityModalOpen] = useState(false);

  const handleCityChange = city => {
    setSelectedCity(city);
  };

  const toggleNewCityModalState = () => {
    setNewCityModalOpen(ps => !ps);
  };

  const getCitiesOpts = () => {
    return !isEmpty(cities)
      ? cities.map(city => ({ value: city.id, label: city.name, obj: city }))
      : [];
  };

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">Cities</h2>
        <button className="mb-0 btn btn-link" onClick={toggleNewCityModalState}>
          + New city
        </button>
      </div>
      <div
        className="mt-3"
        style={{
          maxWidth: '30rem'
        }}
      >
        <Select
          value={selectedCity}
          options={getCitiesOpts()}
          isLoading={citiesLoading}
          onChange={handleCityChange}
        />
      </div>
      {isEmpty(selectedCity) ? (
        <p className="mb-0">Please select a city to view / edit.</p>
      ) : (
        <div className="mt-5">
          <AddOrEditCity mode="edit" city={selectedCity} />
        </div>
      )}
      <Modal isOpen={newCityModalOpen} toggle={toggleNewCityModalState}>
        <div className="p-4">
          <AddOrEditCity mode="add" handleClose={toggleNewCityModalState} />
        </div>
      </Modal>
    </div>
  );
};

export default CitiesTab;
