import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Row, Col, Container } from 'reactstrap';
import { GiAmbulance } from 'react-icons/gi';
import SendError from './SendError';

class ErrorReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;

    if (error) {
      return (
        <Container>
          <Row>
            <Col style={{ padding: '1rem' }}>
              <Alert
                isOpen={true}
                toggle={() =>
                  this.setState({ error: undefined, errorInfo: undefined })
                }
                color="warning"
              >
                <h2>
                  <GiAmbulance />
                  {` Something went wrong. We are sorry.`}
                </h2>
                <p>
                  Please try{' '}
                  <Button size="sm" onClick={() => window.location.reload()}>
                    refreshing
                  </Button>
                  . It may fix the problem for now.
                </p>
                <p>
                  We keep releasing new features and bug fixes every other day.
                  Please provide us with the details such as what you were
                  trying to do when this happened, attach a link to the
                  screenshot and your contact info if you would like us to get
                  in touch for more clarification.
                </p>
                <div>
                  <SendError
                    errorMessage={error.message}
                    errorObject={errorInfo}
                  />
                </div>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

ErrorReport.propTypes = {
  error: PropTypes.string,
  errorInfo: PropTypes.object,
  onDismiss: PropTypes.func
};

export default ErrorReport;
