import React from 'react';
import { BeatLoader } from 'react-spinners';
import PropTypes from 'prop-types';

const Loader = ({ inline, ...props }) => (
  <div style={{ display: inline ? 'inline-block' : 'block' }}>
    <BeatLoader {...props} />
  </div>
);

Loader.propTypes = {
  inline: PropTypes.bool
};
export default Loader;
