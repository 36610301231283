import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useQuery } from 'react-apollo';
import { GET_SCAN_DATA } from '../queries';

const SelectSiteScan = ({ selectedscanName, onscanNameSelect, hosId }) => {
  const { loading, data: { getScanDetails = [] } = {} } = useQuery(
    GET_SCAN_DATA,
    {
      variables: { input: { siteCode: hosId } }
    }
  );

  let siteopts = getScanDetails.map(item => ({
    label: item.scanName,
    value: item
  }));
  const selectedOpt =
    siteopts.find(({ value }) => value === selectedscanName) || null;
  return (
    <Select
      isLoading={loading}
      loadingMessage={'fetching data'}
      placeholder={loading ? '' : 'Select Scan'}
      options={siteopts}
      value={selectedOpt}
      onChange={e => {
        onscanNameSelect(e.value);
      }}
    />
  );
};

SelectSiteScan.propTypes = {
  selectedscanName: PropTypes.string,
  onscanNameSelect: PropTypes.func,
  hosId: PropTypes.string
};

export default SelectSiteScan;
