import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  S2S_CX_SERVER_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import Loader from './Loader';
import PaymentForm from './PaymentForm';
import SearchPatientByMobile from './SearchPatientByMobile';
import SelectClinixProcedure from './SelectClinixProcedure';
import Veil from './Veil';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const BedAndPackageBookingModal = props => {
  const { isOpen, toggle, selectedHospitalDetails } = props;

  const [selectedBooking, updateSelectedBooking] = useState();
  const [totalAmount, updateTotalAmount] = useState();
  const [paymentData, updatePaymentData] = useState({});
  const [bookingList, updatebookingList] = useState();
  const [isPatientSelected, updateIsPatientSelected] = useState(false);
  const [userCompleteDetails, updateUserCompleteDetails] = useState();
  const [bookingCompleteDetails, updateBookingCompleteDetails] = useState();
  const [selectedBookingDetails, updateSelectedBookingDetails] = useState();
  const [bookingChargeloader, updateBookingChargeloader] = useState(false);
  const [bookingTypeSelected, updateBookingTypeSelected] = useState(false);
  const [selectedPatientAge, updateSelectedPatientAge] = useState('');
  const [selectedPatientGender, updateSelectedPatientGender] = useState('');
  const [searchType, updatesearchType] = useState(0);
  const [shouldShowCreateInvoiceLoader, showCreateInvoiceLoader] = useState(
    false
  );
  const [invoiceGenerationSuccess, updateInvoiceGenerationSuccess] = useState(
    false
  );
  const [pdfLink, updatePdfLink] = useState(null);

  useEffect(() => {
    updateSelectedBooking();
    updateTotalAmount();
    updatePaymentData({});
    updatebookingList();
    updateIsPatientSelected(false);
    updateUserCompleteDetails();
    updateBookingCompleteDetails();
    updateSelectedBookingDetails();
    updateBookingChargeloader(false);
    updateBookingTypeSelected(false);
    updateSelectedPatientAge('');
    updateSelectedPatientGender('');
    showCreateInvoiceLoader(false);
    updateInvoiceGenerationSuccess(false);
    updatePdfLink(null);
    updatesearchType(0);
  }, [isOpen, toggle]);

  // function to fetch bed booking / package booking charges configured in zoho books based on hospitalid and charge item type.
  const fetchCharges = cf_chargeitemtype => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/get_charges`;

    const req_body = {
      cf_hospitalid: selectedHospitalDetails.id.toString(),
      cf_chargeitemtype: cf_chargeitemtype,
      siteCode: selectedHospitalDetails.siteCode
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getChargesZohoBooks = async cf_chargeitemtype => {
    try {
      updateBookingTypeSelected(false);
      updateBookingChargeloader(true);
      const response = await fetchCharges(cf_chargeitemtype);
      if (response && response.status && response.status === 1) {
        updateBookingCompleteDetails(response.data);
        let bookingList = response.data.map(p => p.item_name);
        updatebookingList(bookingList);
        let amplitudeEventName;
        if (searchType === 1) {
          amplitudeEventName = 'bed_booking_charge_fetched_successfully';
        }
        if (searchType === 2) {
          amplitudeEventName = 'package_booking_charge_fetched_successfully';
        }
        logAmplitudeEvent(
          amplitudeEventName,
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: cf_chargeitemtype
          },
          true
        );
        updateBookingChargeloader(false);
        updateBookingTypeSelected(true);
      } else {
        let amplitudeEventName;
        if (searchType === 1) {
          amplitudeEventName = 'bed_booking_charge_fetching_failed';
        }
        if (searchType === 2) {
          amplitudeEventName = 'package_booking_charge_fetching_failed';
        }
        logAmplitudeEvent(
          amplitudeEventName,
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: cf_chargeitemtype
          },
          true
        );
        updateBookingChargeloader(false);
        updateBookingTypeSelected(true);
      }
    } catch (error) {
      let amplitudeEventName;
      if (searchType === 1) {
        amplitudeEventName = 'bed_booking_charge_fetching_failed';
      }
      if (searchType === 2) {
        amplitudeEventName = 'package_booking_charge_fetching_failed';
      }
      logAmplitudeEvent(
        amplitudeEventName,
        {
          hospitalId: selectedHospitalDetails.id,
          chargeType: cf_chargeitemtype
        },
        true
      );
      updateBookingChargeloader(false);
      updateBookingTypeSelected(true);
    }
  };

  // function to create customer’s bed booking and package booking payment invoice based on get_charges API response and other customer payment details.
  const createInvoiceData = (siteCode, lineItemsList) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      mpid: userCompleteDetails.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      gst_no: selectedHospitalDetails.gstNo,
      first_name: userCompleteDetails.userName,
      email: userCompleteDetails.userEmail,
      mobile: userCompleteDetails.userMobile,
      product_type: selectedBookingDetails.product_type,
      sitecode: siteCode,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_address: selectedHospitalDetails.address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      line_items: lineItemsList,
      procedure: searchType === 1 ? 'Bedbooking' : 'Package',
      logo_url: selectedHospitalDetails.logoUrl,
      notes: {
        patient_age: selectedPatientAge,
        patient_gender: selectedPatientGender,
        selected_clinic_address: selectedHospitalDetails.address
      }
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createPaymentInvoice = async () => {
    showCreateInvoiceLoader(true);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    const siteCode = selectedHospitalDetails.siteCode;

    let lineItemsList = [
      {
        item_id: selectedBookingDetails.item_id,
        header_name: `${selectedBookingDetails.item_name} invoice`,
        is_taxable: false,
        tax_exemption_id: selectedBookingDetails.tax_exemption_id,
        name: selectedBookingDetails.name,
        description: selectedBookingDetails.description,
        hsn_or_sac: selectedBookingDetails.hsn_or_sac,
        product_type: selectedBookingDetails.product_type,
        rate: selectedBookingDetails.rate,
        item_total: selectedBookingDetails.rate
      }
    ];

    try {
      const response = await createInvoiceData(siteCode, lineItemsList);
      if (response && response.status && response.status === 1) {
        showCreateInvoiceLoader(false);
        updateInvoiceGenerationSuccess(true);

        let amplitudeEventName;
        if (searchType === 1) {
          displaySuccess('Bed booking invoice generated successfully');
          amplitudeEventName = 'bed_booking_invoice_generated_successfully';
        }
        if (searchType === 2) {
          displaySuccess('Package booking invoice generated successfully');
          amplitudeEventName = 'package_booking_invoice_generated_successfully';
        }
        updatePdfLink(response.data.s3pdflink);

        logAmplitudeEvent(
          amplitudeEventName,
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: selectedBookingDetails.product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: searchType === 1 ? 'Bedbooking' : 'Package',
            s3pdflink: response.s3pdflink
          },
          true
        );
      } else {
        let amplitudeEventName;
        let emsg;
        if (searchType === 1) {
          amplitudeEventName = 'bed_booking_invoice_generation_failed';
          emsg = `Bed booking invoice generation failed`;
        }
        if (searchType === 2) {
          amplitudeEventName = 'package_booking_invoice_generation_failed';
          emsg = `Package booking invoice generation failed`;
        }
        logAmplitudeEvent(
          amplitudeEventName,
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: selectedBookingDetails.product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: searchType === 1 ? 'Bedbooking' : 'Package',
            response: response
          },
          true
        );
        showCreateInvoiceLoader(false);
        throw new Error(emsg);
      }
    } catch (error) {
      let amplitudeEventName;
      if (searchType === 1) {
        amplitudeEventName = 'bed_booking_invoice_generation_failed';
      }
      if (searchType === 2) {
        amplitudeEventName = 'package_booking_invoice_generation_failed';
      }
      logAmplitudeEvent(
        amplitudeEventName,
        {
          mpid: userCompleteDetails.mpid,
          salesperson_name: loggedInUser.name,
          reference_number: paymentData.transactionId
            ? paymentData.transactionId
            : 'N/A',
          txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
          payment_mode: paymentData.paymentMode,
          gst_no: selectedHospitalDetails.gstNo,
          first_name: userCompleteDetails.userName,
          email: userCompleteDetails.userEmail,
          mobile: userCompleteDetails.userMobile,
          product_type: selectedBookingDetails.product_type,
          sitecode: siteCode,
          hospital_id: selectedHospitalDetails.id.toString(),
          hospital_name: selectedHospitalDetails.name,
          hospital_address: selectedHospitalDetails.address,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          line_items: lineItemsList,
          procedure: searchType === 1 ? 'Bedbooking' : 'Package',
          error: error
        },
        true
      );
      showCreateInvoiceLoader(false);
      displayError(error);
    }
  };

  const handleUserData = selectedPatient => {
    if (!selectedPatient) {
      updateIsPatientSelected(false);
      return;
    } else {
      const userData = {
        mpid: selectedPatient.mpid,
        userName: selectedPatient.first_name,
        userCountry: selectedPatient.nationality_country,
        userMobile: selectedPatient.mobile,
        userEmail: selectedPatient.email
      };
      updateUserCompleteDetails(userData);
      let patientAge = calculateAge(selectedPatient.date_of_birth);
      updateSelectedPatientAge(patientAge);
      updateSelectedPatientGender(selectedPatient.gender);
      updateIsPatientSelected(true);
    }
  };

  // function to calculate patient age in years and months based on his date of birth.
  const calculateAge = dateString => {
    let today = new Date();
    let DOB = new Date(dateString);
    let totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    let years = today.getFullYear() - DOB.getFullYear();

    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    let months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months === 0) months = 11;
    } else {
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    let age = years + ' years ' + months + ' months ';
    return age;
  };

  const handleMobileNoChange = () => updateIsPatientSelected(true);

  const handleOnClose = () => {
    updateSelectedBooking();
    updateTotalAmount();
    updatePaymentData({});
    updatebookingList();
    updateIsPatientSelected(false);
    updateUserCompleteDetails();
    updateBookingCompleteDetails();
    updateSelectedBookingDetails();
    updateBookingChargeloader(false);
    updateBookingTypeSelected(false);
    showCreateInvoiceLoader(false);
    updateInvoiceGenerationSuccess(false);
    updatePdfLink(null);
    updatesearchType(0);
  };

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };

  const createInvoice = async () => {
    createPaymentInvoice();
  };

  const viewInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Bed And Package Booking</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Row style={{ marginTop: '1rem' }}>
                <Col sm={6}>
                  <Col sm={6} style={{ marginTop: -10, marginBottom: 10 }}>
                    <Row>
                      <TextCenter>
                        <input
                          type="radio"
                          id="sbd"
                          onClick={() => {
                            updateTotalAmount();
                            updatesearchType(1);
                            getChargesZohoBooks('Bedbooking');
                          }}
                          checked={searchType === 1}
                        />{' '}
                        <label style={{ marginTop: 5 }} htmlFor="sbd">
                          Bed Booking
                        </label>
                      </TextCenter>
                    </Row>
                    <Row>
                      <TextCenter>
                        <input
                          type="radio"
                          id="sbh"
                          onClick={() => {
                            updateTotalAmount();
                            updatesearchType(2);
                            getChargesZohoBooks('Package');
                          }}
                          checked={searchType === 2}
                        />{' '}
                        <label style={{ marginTop: 5 }} htmlFor="sbh">
                          Package Booking
                        </label>
                      </TextCenter>
                    </Row>
                  </Col>
                  {bookingChargeloader ? (
                    <Loader />
                  ) : bookingTypeSelected && searchType !== 0 && isOpen ? (
                    <SelectClinixProcedure
                      procedureList={bookingList}
                      procedureType={
                        searchType === 1 ? 'Bedbooking' : 'Package'
                      }
                      enteredSearchText={SearchedText => SearchedText}
                      selectedProcedure={selectedBooking}
                      onProcedureSelect={selectedBooking => {
                        updateSelectedBooking(selectedBooking);
                        const selectedOpt = bookingCompleteDetails.find(
                          data => data.item_name === selectedBooking
                        );
                        if (selectedOpt && selectedOpt.rate) {
                          updateTotalAmount(selectedOpt.rate);
                          updateSelectedBookingDetails(selectedOpt);
                        } else {
                          updateTotalAmount();
                          updateSelectedBookingDetails();
                        }
                      }}
                    />
                  ) : null}
                </Col>
                <Col sm={6}>
                  {selectedBooking && totalAmount ? (
                    <h6 style={{ fontWeight: 'bold', marginTop: 75 }}>
                      Amount: &#x20B9; {totalAmount}
                    </h6>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Patient Details'}</CardHeader>
                <Collapse
                  isOpen={
                    totalAmount && parseFloat(totalAmount) >= 0 ? true : false
                  }
                >
                  <CardBody>
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Search by mobile<div style={{ color: 'red' }}>*</div>:
                      </Col>
                      <Col xs={8}>
                        <SearchPatientByMobile
                          onChange={handleUserData}
                          onMobileNoChange={handleMobileNoChange}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    isPatientSelected &&
                    userCompleteDetails &&
                    totalAmount &&
                    parseFloat(totalAmount) >= 0
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreateInvoiceLoader ||
                        invoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreateInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          if (
                            (!paymentData.transactionId ||
                              paymentData.transactionId.length < 5) &&
                            paymentData.paymentMode !== 'Cash'
                          ) {
                            displayError(
                              'Please provide a valid transaction id, minimum 5 digits !!!'
                            );
                            return;
                          } else {
                            showConfirmation(
                              `Create ${
                                searchType === 1 ? 'Bed Booking' : 'Package'
                              } Invoice Confirmation`,
                              `Are you sure you want to create ${
                                searchType === 1 ? 'bed booking' : 'package'
                              }  invoice?`,
                              () => createInvoice()
                            );
                          }
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewInvoice}>
            View {searchType === 1 ? 'Bed Booking' : 'Package'} Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

BedAndPackageBookingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedHospitalDetails: PropTypes.object
};

export default BedAndPackageBookingModal;
