import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Collapse
} from 'reactstrap';
import moment from 'moment';
import EmergencyBookApptForm from './EmergencyBookApptForm';
import SelectProcedure from './SelectProcedure';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { DEFAULT_DOC_MALE, DEFAULT_DOC_FEMALE } from '../constants';
import Loader from './Loader';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const DoctorSpecialization = styled.span`
  color: '#808080',
  textTransform: 'capitalize'
`;

const DOC_QUERY = gql`
  query doctor($id: String) {
    doctor: getDoctor(id: $id) {
      name
      qualification
      photos {
        dp300 {
          url
        }
      }
      gender
      hospitals: getHospitals {
        id
        name
      }
    }
  }
`;

const EmergencyBookingModal = props => {
  const {
    isOpen,
    toggle,
    doctorId,
    entityId,
    hospitalId,
    hospitalName
  } = props;
  const [selectedProcedure, updateSelectedProcedure] = useState();
  const handleOnClose = () => {
    updateSelectedProcedure();
  };

  if (!doctorId) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Emergency Appointment Booking</ModalHeader>
      <ModalBody>
        <Container>
          <Query query={DOC_QUERY} variables={{ id: doctorId }}>
            {({ loading, data: { doctor } = { doctor: {} } }) => {
              if (loading) {
                return <Loader loading={true} />;
              }

              const {
                // slotTypes,
                photos,
                name,
                qualification,
                gender
              } = doctor;

              const dpURL =
                photos && photos.dp300 ? photos.dp300.url : undefined;
              let displayUrl = dpURL;

              if (!dpURL && gender === 'Male') {
                displayUrl = DEFAULT_DOC_MALE;
              } else if (!dpURL) {
                displayUrl = DEFAULT_DOC_FEMALE;
              }

              return (
                <Row>
                  <Col sm={2}>
                    <img src={displayUrl} alt={name} height={100} width={100} />
                  </Col>
                  <Col sm={10}>
                    <Row>
                      <Col>
                        <h4 style={{ display: 'inline' }}>{name}</h4>{' '}
                        <DoctorSpecialization>
                          {qualification}
                        </DoctorSpecialization>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6>{hospitalName}</h6>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem' }}>
                      <Col sm={6}>
                        <h6>{moment().format('DD MMM YYYY')}</h6>
                      </Col>
                      <Col sm={6}>
                        <SelectProcedure
                          hospitalId={hospitalId}
                          entityId={entityId}
                          selectedProcedure={selectedProcedure}
                          onProcedureSelect={selectedProcedure => {
                            updateSelectedProcedure(selectedProcedure);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }}
          </Query>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Patient Details'}</CardHeader>
                <Collapse isOpen={selectedProcedure ? true : false}>
                  <CardBody>
                    <EmergencyBookApptForm
                      procedureName={selectedProcedure}
                      hospitalId={hospitalId}
                      entityId={entityId}
                    />
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EmergencyBookingModal.propTypes = {
  doctorId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  entityId: PropTypes.number,
  hospitalId: PropTypes.number,
  hospitalName: PropTypes.string
};

export default EmergencyBookingModal;
