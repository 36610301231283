import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import axios from 'axios';
import { displayError } from '../utilities';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';

const CustomerRegister = props => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [customerRegister, setCustomerRegister] = useState({
    loading: false,
    response: {}
  });

  const handleChange = event => {
    if (event.target.value) {
      const regex = /^[0-9]*$/;
      if (regex.test(event.target.value) && event.target.value.length <= 10) {
        setMobileNumber(event.target.value);
      }
    } else {
      setMobileNumber('');
    }
  };

  const handleSubmit = () => {
    if (!mobileNumber || mobileNumber.length !== 10) {
      displayError('Please provide 10 digit mobile.');
      return;
    }
    setCustomerRegister(ps => ({ loading: true, response: ps.response }));
    axios
      .get(`${CX_USER_DB_BASE_URL}/mpid/v2/${mobileNumber}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
        }
      })
      .then(res => {
        setCustomerRegister({ loading: false, response: res.data });
      })
      .catch(err => {
        setCustomerRegister(ps => ({ loading: false, response: ps.response }));
        console.error(err.response.data);
        displayError(
          err.response.data
            ? err.response.data.error.message
            : 'Something went wrong!'
        );
      });
  };

  const getMainContent = () => {
    if (isEmpty(customerRegister.response)) {
      return null;
    } else if (isEmpty(customerRegister.response.data)) {
      return (
        <div
          style={{ maxWidth: '400px' }}
          className="d-flex flex-column align-items-start mt-4"
        >
          <p>Sorry, this phone number is not registered with Cloudnine</p>
          <p>
            Please check with the customer if he/she has any other number
            registered with Cloudnine. If not, register the customer using the
            button below.
          </p>
          <Button onClick={props.toggleListPage}>Register here</Button>
        </div>
      );
    } else {
      return (
        <>
          <div className="mt-4">
            <h4 className="mb-3">Customer details</h4>
            <Table bordered responsive striped>
              <thead>
                <tr>
                  <th>Mobile number</th>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Date of birth</th>
                  <th>MPID</th>
                </tr>
              </thead>
              <tbody>
                {customerRegister.response.data.map(item => (
                  <tr key={item.mpid}>
                    <td>{item.mobile}</td>
                    <td>{`${item.first_name} ${item.last_name}`.trim()}</td>
                    <td>{item.email}</td>
                    <td>
                      {moment(item.date_of_birth, 'YYYY-MM-DD').format(
                        'DD-MM-YYYY'
                      )}
                    </td>
                    <td>{item.mpid}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex mt-3 justify-content-start align-items-start">
            <p className="mr-5" style={{ maxWidth: '500px' }}>
              {`If you want to register a new customer with this number or
              register a customer with another number, click "Register Here"
              button`}
            </p>
            <Button onClick={props.toggleListPage}>Register Here</Button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <ModalHeader toggle={props.toggle}>Customer Register</ModalHeader>
      <ModalBody>
        <div style={{ minHeight: '300px' }}>
          <div className="d-flex" style={{ gap: '10px', maxWidth: '500px' }}>
            <input
              type="text"
              className="flex-fill"
              name="search"
              placeholder="Please provide the mobile number"
              value={mobileNumber}
              onChange={handleChange}
            />
            <Button
              disabled={customerRegister.loading}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
          {getMainContent()}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

CustomerRegister.propTypes = {
  toggle: PropTypes.func,
  toggleListPage: PropTypes.func
};

export default CustomerRegister;
