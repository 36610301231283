import axios from 'axios';
import { Formik } from 'formik';
import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane
} from 'reactstrap';
import * as Yup from 'yup';
import Loader from '../components/Loader';
import {
  CX_USER_DB_BASE_URL,
  UPDATE_CUSTOMER_LAMBDA_URL,
  S2S_C9USERMORE_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

const TABS = {
  CUSTOMER_DETAILS: 1
};

function updatePatientsDetailsLT(payload) {
  return axios.post(UPDATE_CUSTOMER_LAMBDA_URL, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

function syncUserDetails(mobile) {
  return axios.post(
    `${CX_USER_DB_BASE_URL}/mpid/lt/sync-patients`,
    { mobile },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    }
  );
}

// eslint-disable-next-line react/prop-types
const ErrorMessage = ({ message }) => {
  return <div style={{ color: 'red' }}>{message}</div>;
};

const CustomerDetailsModal = ({ isOpen, toggle, userMPID }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.CUSTOMER_DETAILS);
  const [loaderIcon, setLoaderIcon] = useState(true);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [detailsLoaderIconVisible, setDetailsLoaderIconVisible] = useState(
    false
  );

  useEffect(() => {
    getCustomerDetails(userMPID);
    setSelectedTab(TABS.CUSTOMER_DETAILS);
    setLoaderIcon(true);
    setCustomerDetails([]);
    setDetailsLoaderIconVisible(false);
    if (isOpen) {
      logAmplitudeEvent(
        'Customer_Details_Icon_Pressed',
        {
          userMPID: userMPID,
          selectedTab: 'PATIENT DETAILS'
        },
        true
      );
    }
    // eslint-disable-next-line
  }, [isOpen, toggle, userMPID]);

  // function to fetch customer's personal details.
  const fetchCustomerDetails = userMPID => {
    const url = `${CX_USER_DB_BASE_URL}/mpid/v2/${userMPID}`;

    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and store customer's personal details in state to show it to the users.
  const getCustomerDetails = async userMPID => {
    setLoaderIcon(true);
    try {
      const response = await fetchCustomerDetails(userMPID);
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data
      ) {
        setCustomerDetails(response.data[0]);
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to update customer's information.
  const updatePatientDetails = values => {
    setDetailsLoaderIconVisible(true);
    const req_body = {
      body: {
        mpi: userMPID,
        registeredSiteCode: values.registeredCenter,
        gender: values.gender,
        dateOfBirth: values.dateOfBirth,
        firstName: values.name,
        emergencyContactName: values.emergencyContactName || ' ',
        emergencyContactMobile: values.emergencyContactMobile || ' ',
        email: values.email
      }
    };

    updatePatientsDetailsLT(req_body)
      .then(response => {
        if (
          response.data &&
          response.data.head &&
          response.data.head.StatusValue &&
          response.data.head.StatusValue === 200
        ) {
          setDetailsLoaderIconVisible(false);
          displaySuccess('Details updated successfully');
          logAmplitudeEvent(
            'Patient_Details_Updated_Successfully',
            {
              selectedTab: 'PATIENT DETAILS',
              userMPID: userMPID,
              userName: values.name.trim(),
              dateOfBirth: values.dateOfBirth,
              gender: values.gender,
              emergencyContactName: values.emergencyContactName,
              emergencyContactMobile: values.emergencyContactMobile,
              email: values.email
            },
            true
          );
          return syncUserDetails(customerDetails.mobile);
        } else {
          setDetailsLoaderIconVisible(false);
          displayError('updation failed, try again !!!');
          logAmplitudeEvent(
            'Patient_Details_Updation_Failed',
            {
              selectedTab: 'PATIENT DETAILS',
              userMPID: userMPID,
              userName: values.name.trim(),
              dateOfBirth: values.dateOfBirth,
              gender: values.gender,
              emergencyContactName: values.emergencyContactName,
              emergencyContactMobile: values.emergencyContactMobile,
              email: values.email,
              response: response.data
            },
            true
          );
        }
      })
      .catch(error => {
        setDetailsLoaderIconVisible(false);
        displayError('Details updation failed, try again !!!');
        logAmplitudeEvent(
          'Details_Updation_Failed',
          {
            selectedTab: 'PATIENT DETAILS',
            userMPID: userMPID,
            userName: values.name.trim(),
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            emergencyContactName: values.emergencyContactName,
            emergencyContactMobile: values.emergencyContactMobile,
            email: values.email,
            error: error
          },
          true
        );
      });
  };

  const patientDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name must be minimum 3 characters !!!')
      .required('Required'),
    dateOfBirth: Yup.date().required('Required'),
    mpid: Yup.string().required('Required'),
    registeredCenter: Yup.string().required('Required'),
    mobile: Yup.string().required('Required'),
    emergencyContactName: Yup.string().min(
      3,
      'Name must be minimum 3 characters !!!'
    ),
    gender: Yup.string()
      .min(4, 'Gender must be minimum 4 characters')
      .required('Enter gender'),
    emergencyContactMobile: Yup.string()
      .matches(/^[0-9]*$/, 'Please enter valid number!!!')
      .min(10, 'Please enter a valid 10 digit number !!!')
      .max(10, 'Please enter a valid 10 digit number !!!'),
    email: Yup.string()
      .email('Invalid email')
      .required('Enter email')
  });

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Patient&#39;s Personal Details</ModalHeader>
      <ModalBody>
        <TabContent activeTab={selectedTab}>
          <TabPane tabId={TABS.CUSTOMER_DETAILS}>
            {loaderIcon ? (
              <Loader />
            ) : selectedTab === TABS.CUSTOMER_DETAILS &&
              customerDetails !== [] ? (
              <Formik
                initialValues={{
                  name: customerDetails.first_name,
                  dateOfBirth: customerDetails.date_of_birth,
                  mpid: customerDetails.MPI,
                  registeredCenter: customerDetails.registered_site_code,
                  mobile: customerDetails.mobile,
                  emergencyContactName: (
                    customerDetails.emergency_contact_name || ''
                  ).trim(),
                  gender: customerDetails.gender,
                  emergencyContactMobile: (
                    customerDetails.emergency_contact_mobile || ''
                  ).trim(),
                  email: customerDetails.email
                }}
                validationSchema={patientDetailsSchema}
                onSubmit={updatePatientDetails}
              >
                {({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  touched
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '10%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Name:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="text"
                            onChange={handleChange('name')}
                            onBlur={handleBlur('name')}
                            value={values.name != null ? values.name : ''}
                          />
                          {errors.name && touched.name ? (
                            <ErrorMessage message={errors.name} />
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '3%',
                            flexDirection: 'column',
                            marginLeft: '10px'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '8%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '19%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Date Of Birth:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '26%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="date"
                            max={new Date().toISOString().split('T')[0]}
                            onChange={handleChange('dateOfBirth')}
                            value={values.dateOfBirth}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '2%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '11%',
                            flexDirection: 'column',
                            textAlign: 'right'
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '8%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>MPID:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input type="text" value={values.mpid} disabled />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '5%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '26%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Registered Center:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="text"
                            value={values.registeredCenter}
                            disabled
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '2%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '11%',
                            flexDirection: 'column',
                            textAlign: 'right'
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '8%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Mobile:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input type="text" value={values.mobile} disabled />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '5%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '26%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Emergency Contact Name:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="text"
                            onChange={handleChange('emergencyContactName')}
                            onBlur={handleBlur('emergencyContactName')}
                            value={values.emergencyContactName}
                          />
                          {errors.emergencyContactName &&
                          touched.emergencyContactName ? (
                            <ErrorMessage
                              message={errors.emergencyContactName}
                            />
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '2%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '11%',
                            flexDirection: 'column',
                            textAlign: 'right'
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 12
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '8%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Gender:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="text"
                            onChange={handleChange('gender')}
                            onBlur={handleBlur('gender')}
                            value={values.gender}
                          />
                          {errors.gender && touched.gender ? (
                            <ErrorMessage message={errors.gender} />
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '5%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '26%',
                            flexDirection: 'column'
                          }}
                        >
                          <label>Emergency Contact Number:</label>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '24%',
                            flexDirection: 'column'
                          }}
                        >
                          <input
                            type="text"
                            onChange={handleChange('emergencyContactMobile')}
                            onBlur={handleBlur('emergencyContactMobile')}
                            value={
                              values.emergencyContactMobile != null
                                ? values.emergencyContactMobile
                                : ''
                            }
                          />
                          {errors.emergencyContactMobile &&
                          touched.emergencyContactMobile ? (
                            <ErrorMessage
                              message={errors.emergencyContactMobile}
                            />
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            width: '2%',
                            flexDirection: 'column'
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            width: '11%',
                            flexDirection: 'column',
                            textAlign: 'right'
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'left',
                          alignItems: 'center',
                          marginTop: 13
                        }}
                      >
                        <label
                          style={{
                            marginBottom: -3,
                            paddingBottom: '2%',
                            width: '50%'
                          }}
                        >
                          Email:&#160;
                          <input
                            type="text"
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            value={values.email != null ? values.email : ''}
                            style={{ width: '80%' }}
                          />
                          {errors.email && touched.email ? (
                            <ErrorMessage message={errors.email} />
                          ) : null}
                        </label>
                        {detailsLoaderIconVisible ? (
                          <div
                            style={{
                              display: 'inline',
                              marginTop: -11,
                              width: '16.2%',
                              paddingLeft: '6%'
                            }}
                          >
                            <Loader inline size={5} />
                          </div>
                        ) : (
                          <Button
                            color="primary"
                            style={{ marginTop: -11, marginLeft: 20 }}
                            type="submit"
                          >
                            UPDATE DETAILS
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            ) : null}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

CustomerDetailsModal.propTypes = {
  userMPID: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default CustomerDetailsModal;
