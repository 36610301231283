import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getFirstNameUpperCase } from '../utilities';
import { DEFAULT_DOC_FEMALE, DEFAULT_DOC_MALE } from '../constants';
import RoomStatus from './RoomStatus';
import { Button, Collapse } from 'reactstrap';
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaRegCalendarAlt
} from 'react-icons/fa';
import DoctorProfile from './DoctorProfile';
import WeeklySchedulePopover from './WeeklySchedulePopover';
import EditDoctorProfile from './EditDoctorProfile';
import DocSettings from './DocSettings';

const DocBanner = ({ doctor }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showManageSchedule, setShowManageSchedule] = useState(false);
  const toggleProfile = () => setShowProfile(!showProfile);
  const toggleManageSchedule = () => setShowManageSchedule(!showManageSchedule);

  const {
    name,
    photos,
    gender,
    primarySpecialty,
    secondarySpecialty,
    entityId,
    entity,
    id
  } = doctor || {};
  const firstName = getFirstNameUpperCase(name);
  let img = DEFAULT_DOC_FEMALE;
  if (photos && photos.dp300) {
    img = photos.dp300.url;
  } else if (gender === 'Male') {
    img = DEFAULT_DOC_MALE;
  }

  let roomNo = undefined;
  let isInRoom = false;
  if (entity) {
    roomNo = entity.roomNo;
    isInRoom = entity.isInRoom;
  }

  return (
    <div className="doc-ban-container">
      <div className="doc-ban-head">
        <div>
          <img src={img} alt={name} />
        </div>
        <div>
          <h3>{firstName}</h3>
        </div>
        <div>
          {primarySpecialty}
          {secondarySpecialty ? `, ${secondarySpecialty}` : ''}
        </div>
        <div className="doc-ban-head-container">
          <RoomStatus
            entityId={entityId}
            isInRoom={isInRoom}
            roomNo={roomNo}
            doctorName={name}
          />
        </div>
        <div className="doc-ban-head-container">
          <Button
            color="link"
            onClick={toggleManageSchedule}
            style={{ paddingLeft: 0 }}
          >
            <FaRegCalendarAlt /> Manage Schedule &amp; Vacation
          </Button>
          <DocSettings
            isOpen={showManageSchedule}
            onToggle={toggleManageSchedule}
            entityId={entityId}
            doctorName={name}
          />
        </div>
        <div className="doc-ban-head-container flex-space-btw">
          <Button
            color="link"
            onClick={toggleProfile}
            style={{ padding: 0, margin: 0 }}
          >
            {showProfile ? (
              <span>
                Hide Profile <FaAngleDoubleUp />
              </span>
            ) : (
              <span>
                Show Profile <FaAngleDoubleDown />
              </span>
            )}
          </Button>
          <EditDoctorProfile doctorId={id} />
          <WeeklySchedulePopover
            buttonText="Weekly Schedule"
            entityId={entityId}
            entityName={name}
          />
        </div>
        <Collapse isOpen={showProfile} className="doc-ban-head-container">
          <DoctorProfile doctor={doctor} />
        </Collapse>
      </div>
    </div>
  );
};

DocBanner.propTypes = {
  doctor: PropTypes.object.isRequired
};

export default DocBanner;
