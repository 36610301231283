import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const FieldErrorMessage = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {msg =>
        typeof msg === 'string' ? <span className="error">{msg}</span> : null
      }
    </ErrorMessage>
  );
};

FieldErrorMessage.propTypes = {
  name: PropTypes.string.isRequired
};

export default FieldErrorMessage;
