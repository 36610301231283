import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import ReportBugModal from './ReportBugModal';
const FoOhFoBanner = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Jumbotron style={{ margin: '1rem' }}>
            <h1 className="display-3">Hi, Voyager of Unknown Lands!</h1>
            <p className="lead">
              We wish we could have a page to serve you for the URL you have
              entered. Unfortunately, we are limited by the resources and lack
              of creativity.
            </p>
            <hr className="my-2" />
            <p>
              This page does not exist. If you were looking for something you
              cannot find. Hit us up!
            </p>
            <p className="lead">
              <a href="/" className="btn btn-primary">
                Go Back to Home
              </a>{' '}
              <ReportBugModal buttonText="Contact Support" />
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default FoOhFoBanner;
