import ConfirmationDialog from './ConfirmationDialog';

let confirmationDialogInstance: ConfirmationDialog;

export const registerConfirmationDialog = instance => {
  confirmationDialogInstance = instance;
};

export const showConfirmation = (
  title,
  jsxMessage,
  okCallback,
  cancelCallback = () => {}
) => {
  confirmationDialogInstance.dialogObj = {
    title,
    message: jsxMessage,
    okCallback,
    cancelCallback
  };
};
