import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { FaTrash } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';
import { DELETE_SCAN_PROCEDURE_MAPPING } from '../queries';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

const getMessage = scanName => (
  <>
    <span>
      Permanently delete the scan of{' '}
      <strong style={{ color: 'red' }}>{scanName}</strong>
      ?<br />
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteScanMapping = ({ scanName }) => {
  const confirm = deleteScanMapping => {
    showConfirmation('Delete Scan Mapping?', getMessage(scanName), () =>
      deleteScanMapping({ variables: { scanName } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_SCAN_PROCEDURE_MAPPING}
      onCompleted={() => {
        displaySuccess(`${scanName} is deleted`);
        logAmplitudeEvent(
          'Scan_procedure_mapping_deleted',
          { deletedScanName: `${scanName}` },
          true
        );
      }}
      onError={e => displayError(e)}
      refetchQueries={() => ['GET_SCAN_PROCEDURE_MAPPING']}
    >
      {(deleteScanMapping, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteScanMapping)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteScanMapping.propTypes = {
  scanName: PropTypes.string
};

export default DeleteScanMapping;
