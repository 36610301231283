import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';
import { getFullName } from '../utilities';
export default class PatientSearchByPhone extends Component {
  state = {};
  fetchPat = async mobileRaw => {
    const mobile = `${mobileRaw}`.trim().slice(0, 10);
    if (mobile.length < 10) return;
    const res = await fetch(`${CX_USER_DB_BASE_URL}/mpid/v2/${mobile}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    }).then(r => r.json());

    const list = res.data
      .filter(r => !r.isDeleted)
      .map(r => ({
        label: getFullName(r.first_name, r.last_name) + ' - ' + r.mpid,
        value: r
      }));
    list.push({
      label: 'Create New Patient',
      value: { isNew: true, mobile }
    });
    return list;
  };

  handleInputChange = (inputRaw, { action }) => {
    // console.log('slot inp ' + input + ', act ' + JSON.stringify(action));
    const input = `${inputRaw}`.trim().replace(/\D/, '');
    if (input && input.length > 10) {
      this.setState({ value: input.slice(0, 10) });
    } else {
      this.setState({ value: input });
    }
    // TODO: figure out why it does not render input if next line
    // placed at the top of the function
    if (action !== 'input-change') return;
    this.props.onInputChange(input);
  };

  render() {
    const { onChange } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        inputValue={this.state.value}
        loadOptions={mobile => this.fetchPat(mobile)}
        onChange={opt => onChange(opt.value)}
        onInputChange={this.handleInputChange}
        maxMenuHeight={200}
      />
    );
  }
}

PatientSearchByPhone.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  hospitalId: PropTypes.number
};
