import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { UPLOAD_LOGO } from '../queries';
import { Mutation } from 'react-apollo';
import Loader from './Loader';
import { displayError, logAmplitudeEvent } from '../utilities';

const styles = {
  loader: {
    position: 'absolute',
    left: '20%',
    top: '50%'
  }
};

const UploadLogo = props => {
  let { url, hospitalId, onlogoUpload } = props;
  const ref = useRef();
  let type = 'png';
  if (url && url.charAt(0) === '/') {
    type = 'jpg';
  }
  let displayUrl = 'data:image/' + type + ';base64,' + url;
  let flag = false;
  return (
    <Mutation
      mutation={UPLOAD_LOGO}
      onCompleted={data => {
        logAmplitudeEvent(
          'Hospital_logo_updated',
          { hospitalId: hospitalId },
          true
        );
        setTimeout(() => {
          onlogoUpload(data['uploadLogo']);
          ref.current.value = '';
        }, 1500);
      }}
      onError={e => displayError(e)}
      refetchQueries={() => ['GET_ALL_HOSPITALS_FULL']}
      awaitRefetchQueries
    >
      {(uploadLogo, { loading, data }) => {
        if (loading) {
          flag = true;
        } else if (flag === 1) {
          const uploadedStr = data['uploadLogo'] || {};
          if (uploadedStr && uploadedStr.charAt(0) === '/') {
            type = 'jpg';
          }
          displayUrl = 'data:image/' + type + ';base64,' + uploadedStr;
          flag = false;
        }
        return (
          <div style={{ position: 'relative' }}>
            <label htmlFor="image">
              <img src={displayUrl} height={150} width={150} alt="Logo" />
              <input
                type="file"
                id="image"
                accept="image/png, image/jpg, image/jpeg"
                onChange={({
                  target: {
                    files: [imageFile]
                  }
                }) => {
                  if (imageFile.size <= 200000) {
                    uploadLogo({ variables: { imageFile, hospitalId } });
                  } else displayError('Image size cannot be more than 200KB');
                }}
                ref={ref}
              />
            </label>
            {loading && (
              <div style={styles.loader}>
                <Loader size={10} />
              </div>
            )}
          </div>
        );
      }}
    </Mutation>
  );
};

UploadLogo.propTypes = {
  url: PropTypes.string.isRequired,
  hospitalId: PropTypes.number.isRequired,
  onlogoUpload: PropTypes.func
};

export default UploadLogo;
