import React from 'react';
import PropTypes from 'prop-types';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { UncontrolledTooltip } from 'reactstrap';

const HelpBubble = ({ helpText, placement, helpId }) => {
  return (
    <span>
      <FaRegQuestionCircle id={helpId} />
      <UncontrolledTooltip placement={placement} target={helpId}>
        {helpText}
      </UncontrolledTooltip>
    </span>
  );
};

HelpBubble.propTypes = {
  helpText: PropTypes.string.isRequired,
  helpId: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ])
};

HelpBubble.defaultProps = {
  placement: 'auto'
};

export default HelpBubble;
