import { S2S_USER_SERVICES_API_KEY } from '../constants';

export const graphqlApiRequest = async (url, query, variables, token) => {
  const req_body = {
    query,
    variables
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: token ? `Bearer ${token}` : '',
      'x-api-key': S2S_USER_SERVICES_API_KEY || ''
    },
    body: JSON.stringify(req_body)
  };

  try {
    const response = await fetch(url, fetchOpts);
    return await response.json();
  } catch (error) {
    if (error.message === 'Failed to fetch') {
      throw new Error(
        'Internet connection is not stable, Please check and try in sometimes again.'
      );
    } else {
      throw new Error(`An error occurred. Please retry again ${error}`);
    }
  }
};
