import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Query } from 'react-apollo';
import Loader from './Loader';
import { GET_BOOKINGS_AFFECTED_BY_VACATION } from '../queries';
import moment from 'moment';

const BookingsAffectedByVacation = props => {
  const { vacationId } = props;
  return (
    <div>
      <span>
        <strong>Affected Bookings</strong>
      </span>
      <Table size="sm" striped={false}>
        <tbody>
          <Query
            query={GET_BOOKINGS_AFFECTED_BY_VACATION}
            variables={{
              vacationId
            }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <tr>
                    <td>
                      <Loader size={5} />
                    </td>
                  </tr>
                );
              }
              if (error) {
                return (
                  <tr>
                    <td>ERROR: {JSON.stringify(error)}</td>
                  </tr>
                );
              }
              // console.log('data', data);
              const { affectedBookings } = data;
              if (!affectedBookings || affectedBookings.length < 1) {
                return (
                  <tr>
                    <td>No bookings affected</td>
                  </tr>
                );
              } else {
                return affectedBookings.map(b => (
                  <tr key={b.id}>
                    <td>{b.userName}</td>
                    <td>
                      at {moment(b.slotTime).format('ddd, DD MMM YYYY hh:mm a')}
                    </td>
                    <td>{b.userMobile}</td>
                  </tr>
                ));
              }
            }}
          </Query>
        </tbody>
      </Table>
    </div>
  );
};

BookingsAffectedByVacation.propTypes = {
  vacationId: PropTypes.number.isRequired
};

export default BookingsAffectedByVacation;
