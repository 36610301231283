import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FaWalking, FaStopwatch, FaCrown } from 'react-icons/fa';
import moment from 'moment';
import omit from 'lodash/omit';
import classnames from 'classnames';

const timeFmt = 'HHmm';
const timeDisplayFmt = 'h:mm A';
const dayMapping = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday'
};
const weekMapping = ['First', 'Second', 'Third', 'Fourth', 'Fifth'];

const getDaysConfStr = daysConfFromGql => {
  // gql adds this extra __typename :(
  const daysConf = omit(daysConfFromGql, '__typename');
  return Object.entries(daysConf)
    .filter(([_, weeks]) => weeks && weeks.length > 0)
    .map(([day, weeks]) => {
      if ([1, 2, 3, 4, 5].every(w => weeks.includes(w))) {
        return dayMapping[day];
      }
      const humanizedWeeks = weeks.map(w => weekMapping[w - 1]);
      const last = humanizedWeeks.pop();
      return `${humanizedWeeks.join(', ')} and ${last} ${dayMapping[day]}`;
    })
    .join(', ');
};

const SlotProto = props => {
  const {
    slotPrototype: {
      id,
      startTime,
      endTime,
      daysConf,
      isReserved,
      isWalkInEnabled,
      isTatkalEnabled,
      // isEnabled,
      // entityId,
      tatkalWindow,
      regularToTatkalRatio,
      // slotDuration,
      maxBookAheadDays,
      leverageRatio,
      expiresOn,
      shouldRecreateSlots
    },
    procedures,
    onEdit,
    onDelete
  } = props;

  const st = moment(startTime, timeFmt, true).format(timeDisplayFmt);
  const et = moment(endTime, timeFmt, true).format(timeDisplayFmt);
  const daysStr = getDaysConfStr(daysConf);
  const proceduresStr =
    procedures &&
    procedures.map(p => `${p.name} (${p.slotDuration})`).join(', ');
  let expiresOnStr = 'Never';
  if (expiresOn) {
    const m = moment(expiresOn, 'YYYY-MM-DD');
    const duration = moment.duration(m.diff(moment(), 'days'), 'days');
    expiresOnStr = `${m.format('D MMM YYYY')} (${duration.humanize()})`;
  }
  return (
    <Card
      style={{ minHeight: '10rem', maxWidth: '25rem' }}
      className={classnames({ 'edited-slotproto': shouldRecreateSlots })}
    >
      <CardBody>
        <CardTitle>
          {st}&nbsp;&mdash;&nbsp;{et}
        </CardTitle>
        <CardSubtitle>{daysStr}</CardSubtitle>
        <hr />
        <div>
          <label>DURATION:&nbsp;</label>
          <span>
            {procedures.map(p => p.slotDuration).join(', ')}&nbsp;minutes
          </span>
        </div>
        <div>
          <label>TATKAL WINDOW:&nbsp;</label>
          <span>{tatkalWindow}&nbsp;hrs</span>
        </div>
        <div>
          <label>TATKAL RATIO:&nbsp;</label>
          <span>One tatkal every {regularToTatkalRatio}&nbsp;slots</span>
        </div>
        <div>
          <label>FARTHEST BOOKING:&nbsp;</label>
          <span>{maxBookAheadDays}&nbsp;days</span>
        </div>
        <div>
          <label>LEVERAGE:&nbsp;</label>
          <span>{leverageRatio}X</span>
        </div>
        <div>
          <label>PROCEDURES:&nbsp;</label>
          <span>{proceduresStr}</span>
        </div>
        <div>
          <label>EXPIRY:&nbsp;</label>
          <span>{expiresOnStr}</span>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <span
            style={{ fontSize: '2rem', marginRight: '2rem' }}
            title={isWalkInEnabled ? 'Walk In Enable' : 'Walk In Disabled'}
          >
            <FaWalking color={isWalkInEnabled ? 'blue' : 'grey'} />
          </span>
          <span
            style={{ fontSize: '2rem', marginRight: '2rem' }}
            title={isTatkalEnabled ? 'Tatkal Enable' : 'Tatkal Disabled'}
          >
            <FaStopwatch color={isTatkalEnabled ? 'blue' : 'grey'} />
          </span>
          <span
            style={{ fontSize: '2rem', marginRight: '2rem' }}
            title={isReserved ? 'Reseved Slots' : 'Unreserved Slots'}
          >
            <FaCrown color={isReserved ? 'blue' : 'grey'} />
          </span>
        </div>
        <div style={{ marginBottom: '0.2rem' }}>
          <Button color="link" onClick={() => onDelete(id)}>
            Delete
          </Button>{' '}
          <Button color="primary" onClick={() => onEdit(id)}>
            Edit
          </Button>
        </div>
        {shouldRecreateSlots && (
          <div className="error">SCHEDULED FOR RECREATION</div>
        )}
      </CardBody>
    </Card>
  );
};

SlotProto.propTypes = {
  slotPrototype: PropTypes.object.isRequired,
  procedures: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

SlotProto.defaultProps = {
  onEdit: () => {},
  onDelete: () => {}
};

export default SlotProto;
