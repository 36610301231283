import { Field, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import AdminUserApiKeySecretBlock from '../components/AdminUserApiKeySecretBlock';
import {
  CREATE_ADMIN_USER,
  GET_ALL_HOSPITALS,
  UPDATE_ADMIN_USER
} from '../queries';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import SelectRoles from './SelectRoles';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const phoneRegExp = new RegExp('^[0-9]{10}$');

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name should be at least three characters long')
    .required('Please enter the name'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter the email'),
  role: Yup.string().required('Please select the role'),
  mobile: Yup.string()
    .nullable()
    .matches(phoneRegExp, 'Phone number is not valid')
    .optional()
});

const fireAmplitudeEvent = (id, key, oldValue, newValue) => {
  logAmplitudeEvent(
    `Users_${key}_updated`,
    { userId: id, oldValue, newValue },
    true
  );
};

const sendAmplitudeEvent = (id, origValue, newValue) => {
  const keys = Object.keys(newValue);
  for (const key of keys) {
    const newAttr = newValue[key];
    const origAttr = origValue[key];
    if (Array.isArray(newAttr)) {
      let newAttrstr = newAttr.sort().toString();
      let origAtrstr = origAttr.sort().toString();
      newAttrstr.localeCompare(origAtrstr) !== 0 &&
        fireAmplitudeEvent(id, key, origAttr, newAttr);
    } else if (typeof newAttr === 'string') {
      //If two strings are not equal then trigger event
      newAttr.localeCompare(origAttr) !== 0 &&
        fireAmplitudeEvent(id, key, origAttr, newAttr);
    } else if (newAttr !== origAttr) {
      fireAmplitudeEvent(id, key, origAttr, newAttr);
    }
  }
};

const SelectHospitalsField = ({ field, form }) => {
  return (
    <Query query={GET_ALL_HOSPITALS} variables={{ showAll: true }}>
      {({ loading, error, data }) => {
        if (error) return <span>Error: cannot load hospitals.</span>;
        let options = [];
        if (data && data.hospitals) {
          options = data.hospitals.map(({ name, id }) => ({
            label: name,
            value: id
          }));
        }
        const dopts = field.value
          ? field.value.map(v => options.find(h => h.value === v))
          : [];
        // console.log('h', options, field.value, dopts);

        // note: select has a key which generally is not required, the problem was that
        // defaultValue loads dynamically, during the first render there is no data so
        // it loads empty input box, when data comes back it does not update, adding a
        // key that changes when data arrives force reloads the component
        return (
          <Select
            key={dopts}
            isMulti
            placeholder="All Hospitals"
            name={field.name}
            options={options}
            onChange={selectedOpts =>
              form.setFieldValue(field.name, selectedOpts.map(o => o.value))
            }
            defaultValue={dopts}
            isLoading={loading}
          />
        );
      }}
    </Query>
  );
};

SelectHospitalsField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

const defaultInitValue = {
  name: '',
  role: '',
  email: '',
  mobile: '',
  hospitals: [],
  hasApiAccess: false,
  canApproveVacations: false,
  canApproveProtocols: false,
  canApproveDoctors: false,
  isConcierge: false
};

const AdminUserModal = props => {
  const { adminUser, isOpen, hideModal, onSave } = props;
  let adminUserEdit = isEmpty(adminUser) ? { ...defaultInitValue } : adminUser;
  let { id } = adminUserEdit;
  // create a copy
  const origValue = { ...adminUserEdit };
  // console.log('adminUserEdit', adminUserEdit);
  return (
    <Mutation
      mutation={CREATE_ADMIN_USER}
      onCompleted={createData => {
        // if (data && data.adminSignUp && data.adminSignUp.id) {
        //   id = data.adminSignUp.id;
        //   adminUserEdit = data.adminSignUp;
        // }
        onSave(createData);
        displaySuccess('New user created');
      }}
      refetchQueries={() => ['SEARCH_ADMIN_USERS']}
    >
      {(createUser, { loading, error }) => {
        // if (data && data.adminSignUp && data.adminSignUp.id) {
        //   id = data.adminSignUp.id;
        //   adminUserEdit = data.adminSignUp;
        // }
        return (
          <Mutation
            mutation={UPDATE_ADMIN_USER}
            onCompleted={updateData => {
              onSave(updateData);
              displaySuccess('User updated');
              logAmplitudeEvent(
                'User_updated',
                updateData['updateAdminUser'],
                true
              );
            }}
          >
            {(updateUser, { loading: updateLoading, error: updateError }) => {
              const errObj =
                error ||
                (updateError &&
                  error.graphQLErrors &&
                  error.graphQLErrors.length > 0);
              const displayErr = errObj ? error.graphQLErrors[0].message : null;
              const displayLoading = loading || updateLoading;
              return (
                <Modal isOpen={isOpen} toggle={hideModal}>
                  <ModalHeader toggle={hideModal}>
                    {id ? 'Edit Admin User' : 'Create New Admin User'}
                  </ModalHeader>
                  <ModalBody>
                    {/* <pre>{JSON.stringify(adminUser, null, 2)}</pre> */}
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={adminUserEdit}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                          if (!id) {
                            // console.log('creating');
                            if (!values.hospitals) {
                              values.hospitals = [];
                            }
                            if (values.isConcierge && !values.mobile) {
                              return displayError(
                                'Mobile is required to make concierge'
                              );
                            }
                            if (
                              values.role !== 'gre' ||
                              values.hospitals.length !== 1
                            ) {
                              values.isConcierge = false;
                            }
                            createUser({ variables: { input: values } });
                          } else {
                            if (values.isConcierge && !values.mobile) {
                              return displayError(
                                'Mobile is required to make concierge'
                              );
                            }
                            if (
                              values.role !== 'gre' ||
                              values.hospitals.length !== 1
                            ) {
                              values.isConcierge = false;
                            }
                            // console.log('updating');
                            sendAmplitudeEvent(id, origValue, values);
                            const input = { id, ...values };
                            delete input.apiSecret;
                            delete input.__typename;
                            updateUser({
                              variables: { input }
                            });
                          }
                        }}
                      >
                        {formik => (
                          <div>
                            {error && <div className="error">{displayErr}</div>}
                            {/* {data && <pre>{JSON.stringify(data, null, 2)}</pre>} */}
                            <div style={styles.formContainer}>
                              <div>
                                Name
                                <span style={{ fontSize: '1em', color: 'red' }}>
                                  *
                                </span>
                              </div>
                              <div>
                                <Field name="name" style={{ width: '100%' }} />
                                {formik.touched.name && formik.errors.name && (
                                  <div className="error">
                                    {formik.errors.name}
                                  </div>
                                )}
                              </div>
                              <div>
                                Email
                                <span style={{ fontSize: '1em', color: 'red' }}>
                                  *
                                </span>
                              </div>
                              <div>
                                <Field name="email" style={{ width: '100%' }} />
                                {formik.touched.email &&
                                  formik.errors.email && (
                                    <div className="error">
                                      {formik.errors.email}
                                    </div>
                                  )}
                              </div>
                              <div>
                                Role
                                <span style={{ fontSize: '1em', color: 'red' }}>
                                  *
                                </span>
                              </div>
                              <div>
                                <Field
                                  name="role"
                                  render={({ form, field }) => {
                                    return (
                                      <SelectRoles
                                        selectedRoles={field.value}
                                        onChange={v =>
                                          form.setFieldValue(field.name, v)
                                        }
                                      />
                                    );
                                  }}
                                />
                                {formik.touched.role && formik.errors.role && (
                                  <div className="error">
                                    {formik.errors.role}
                                  </div>
                                )}
                              </div>
                              <div>Hospitals</div>
                              <div>
                                <Field
                                  component={SelectHospitalsField}
                                  name="hospitals"
                                />
                              </div>
                              <div>
                                Mobile
                                {formik.values.isConcierge && (
                                  <span
                                    style={{ fontSize: '1em', color: 'red' }}
                                  >
                                    *
                                  </span>
                                )}
                              </div>
                              <div>
                                <Field
                                  name="mobile"
                                  style={{ width: '100%' }}
                                />
                                {formik.touched.mobile &&
                                  formik.errors.mobile && (
                                    <div className="error">
                                      {formik.errors.mobile}
                                    </div>
                                  )}
                              </div>
                              <div>API Access</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="hasApiAccess"
                                  checked={formik.values.hasApiAccess}
                                />
                              </div>
                              <div>Approving Authority</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="canApproveVacations"
                                  checked={formik.values.canApproveVacations}
                                />
                              </div>
                              <div>Can Approve Protocols</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="canApproveProtocols"
                                  checked={formik.values.canApproveProtocols}
                                />
                              </div>
                              <div>Can Approve Doctors</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="canApproveDoctors"
                                  checked={formik.values.canApproveDoctors}
                                />
                              </div>
                              {formik.values.role === 'gre' &&
                                formik.values.hospitals.length === 1 && (
                                  <>
                                    <div>Is Concierge</div>
                                    <div>
                                      <Field
                                        type="checkbox"
                                        name="isConcierge"
                                        checked={formik.values.isConcierge}
                                      />
                                    </div>
                                  </>
                                )}
                              {formik.values.hasApiAccess && (
                                <div style={styles.spans2}>
                                  <AdminUserApiKeySecretBlock
                                    adminUser={adminUserEdit}
                                  />
                                </div>
                              )}
                              <div style={styles.buttonRow}>
                                <Button
                                  onClick={formik.submitForm}
                                  size="sm"
                                  color="primary"
                                  disabled={displayLoading}
                                >
                                  {id ? 'Update' : 'Create'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </ModalBody>
                </Modal>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

AdminUserModal.propTypes = {
  adminUser: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default AdminUserModal;
