import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Query, useQuery } from 'react-apollo';
import { FaCog, FaEdit } from 'react-icons/fa';
import {
  Button,
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledPopover
} from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import { OrderIcon, ShowCheckBox } from '../components/Common';
import EditMultipleLabsModal from '../components/EditMultipleLabsModal';
import LabModal from '../components/LabModal';
import Loader from '../components/Loader';
import SelectSiteCode from '../components/SelectSiteCode';
import SyncWithLT from '../components/SyncWithLT';
import { GET_LAB_DATA, GET_LOGGED_IN_USER } from '../queries';
import { handleExportCSV, sortTable } from '../utilities/labs';
import DeleteLab from './DeleteLab';
import DeleteMultipleLabs from './DeleteMultipleLabs';

const styles = {
  page: {
    margin: '1rem'
  },
  toolbar: {
    marginBottom: '1rem'
  }
};

const Labs = () => {
  const [currentEditLab, setLab] = useState();
  const [searchCriteria, setSearchCriteria] = useState({});
  const [sorting, setSorting] = useState({ column: 'labName', order: 'asc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteCompleted, setDeleteCompleted] = useState(false);
  const {
    data: { me }
  } = useQuery(GET_LOGGED_IN_USER);
  const isCustomerSupport = me.role === 'customersupport';

  const hideModal = () => setLab(undefined);
  const editLab = lab => {
    setLab(lab);
  };

  useEffect(() => {
    if (deleteCompleted) {
      setSelectedRows([]);
      setDeleteCompleted(false);
    }
  }, [deleteCompleted]);

  const isSelected = rowData => {
    return selectedRows.some(row => row.itemId === rowData.itemId);
  };

  const toggleRowSelection = rowData => {
    if (isSelected(rowData)) {
      setSelectedRows(
        selectedRows.filter(row => row.itemId !== rowData.itemId)
      );
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const hideBulkEdit = () => {
    setSelectedRows([]);
  };
  const bulkEditHandler = selectedRows => {
    setSelectedRows([...selectedRows]);
  };

  const handleSorting = column => {
    return () => {
      setSorting(prev => ({
        ...prev,
        column,
        order: prev.order === 'asc' ? 'desc' : 'asc'
      }));
    };
  };

  return (
    <AuthRequired pageName="labs">
      <div style={styles.page}>
        <Row>
          <Col sm={3}>
            <h1>Labs</h1>
          </Col>
          {!isCustomerSupport && (
            <Col className="text-right">
              <Button color="link" id="labsSettings">
                <FaCog />
              </Button>
              <UncontrolledPopover
                trigger="legacy"
                placement="bottom"
                target="labsSettings"
              >
                <PopoverHeader>Labs Settings</PopoverHeader>
                <PopoverBody>
                  <SyncWithLT syncType="lab" />
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
          )}
        </Row>
        <div style={styles.toolbar}>
          {!isCustomerSupport && (
            <Button color="primary" size="sm" onClick={() => editLab({})}>
              Add New
            </Button>
          )}
          <Formik
            initialValues={searchCriteria}
            onSubmit={values => {
              const { labName, siteCode } = values;
              const searchC = {};
              if (labName && labName.trim().length > 3) {
                searchC.labName = labName;
              }
              if (siteCode && siteCode.length > 0) {
                searchC.siteCode = siteCode;
              }

              const { orderBy, isAsc } = searchCriteria;
              const s = { orderBy, isAsc, ...searchC };
              setSearchCriteria(s);
            }}
            render={() => (
              <Form>
                <div className="labScanHeader">
                  <label>Name</label>
                  <label>Site Code</label>
                  <label>&nbsp;</label>
                  <Field name="labName" />
                  <Field
                    name="siteCode"
                    render={({ field, form }) => (
                      <SelectSiteCode
                        selectedSiteCode={field.value}
                        onSiteCodeSelect={v =>
                          form.setFieldValue(field.name, v)
                        }
                      />
                    )}
                  />
                  <Button color="primary" type="submit">
                    Search
                  </Button>
                </div>
              </Form>
            )}
          />
        </div>
        {!Object.keys(searchCriteria).length ? (
          <h3>Please select Hospital</h3>
        ) : (
          <Query query={GET_LAB_DATA} variables={{ input: searchCriteria }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error) return <div>Error: {error}</div>;
              if (!data || !data.getLabDetails) {
                return <div>No lab data found</div>;
              }
              const finalData = data.getLabDetails;
              if (finalData.length === 0) {
                return <h3>No lab data Available</h3>;
              }
              return (
                <>
                  <Button
                    style={{
                      float: 'right',
                      color: 'black',
                      backgroundColor: 'khaki',
                      fontWeight: 'bold',
                      marginRight: '2rem',
                      marginBottom: '1rem'
                    }}
                    size="sm"
                    onClick={() => handleExportCSV(finalData)}
                  >
                    Export as CSV
                  </Button>
                  {selectedRows.length > 0 && (
                    <div>
                      {selectedRows.length === 1
                        ? '1 entry selected - '
                        : `${selectedRows.length} entries selected - `}
                      <Button
                        color="link"
                        onClick={() => {
                          bulkEditHandler(selectedRows);
                          setEditModalOpen(true);
                        }}
                      >
                        <FaEdit />
                      </Button>{' '}
                      <DeleteMultipleLabs
                        itemIds={selectedRows.map(row => row.itemId)}
                        onDeleteCompleted={() => {
                          setDeleteCompleted(true);
                        }}
                      />
                    </div>
                  )}
                  <EditMultipleLabsModal
                    isOpen={editModalOpen}
                    hideModal={() => {
                      setEditModalOpen(false);
                    }}
                    labsDetails={selectedRows}
                    onSave={() => {
                      setEditModalOpen(false);
                      hideBulkEdit();
                    }}
                  />
                  <div>
                    <Table size="sm" striped responsive>
                      <thead>
                        <tr>
                          {!isCustomerSupport && (
                            <th>
                              <input
                                type="checkbox"
                                onChange={() => {
                                  if (
                                    selectedRows.length === finalData.length
                                  ) {
                                    setSelectedRows([]);
                                  } else {
                                    setSelectedRows([...finalData]);
                                  }
                                }}
                                checked={
                                  selectedRows.length === finalData.length
                                }
                              />
                            </th>
                          )}
                          <th onClick={handleSorting('labName')}>
                            Name{' '}
                            <span style={{ color: 'grey' }}>
                              <OrderIcon {...sorting} sortCol="labName" />
                            </span>
                          </th>
                          <th onClick={handleSorting('enable')}>
                            Enabled
                            <span style={{ color: 'grey' }}>
                              <OrderIcon {...sorting} sortCol="enable" />
                            </span>
                          </th>
                          <th onClick={handleSorting('rate')}>
                            Amount{' '}
                            <span style={{ color: 'grey' }}>
                              <OrderIcon {...sorting} sortCol="rate" />
                            </span>
                          </th>
                          <th onClick={handleSorting('homeCollection')}>
                            Home Collection
                            <span style={{ color: 'grey' }}>
                              <OrderIcon
                                {...sorting}
                                sortCol="homeCollection"
                              />
                            </span>
                          </th>
                          <th onClick={handleSorting('siteCode')}>
                            SiteCode{' '}
                            <span style={{ color: 'grey' }}>
                              <OrderIcon {...sorting} sortCol="siteCode" />
                            </span>
                          </th>
                          {!isCustomerSupport && <th md={2}>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {finalData ? (
                          sortTable(sorting, finalData).map(data => {
                            return (
                              <tr key={data.itemId}>
                                {!isCustomerSupport && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={() => toggleRowSelection(data)}
                                      checked={isSelected(data)}
                                    />
                                  </td>
                                )}
                                <td>{data.labName}</td>
                                <td>
                                  <ShowCheckBox enable={data.enable} />
                                </td>
                                <td>{data.rate}</td>
                                <td>
                                  <ShowCheckBox enable={data.homeCollection} />
                                </td>
                                <td>{data.siteCode}</td>
                                {!isCustomerSupport && (
                                  <td>
                                    <Button
                                      color="link"
                                      onClick={() => editLab(data)}
                                    >
                                      <FaEdit />
                                    </Button>
                                    <DeleteLab
                                      itemId={parseInt(data.itemId)}
                                      name={data.labName}
                                      siteCode={data.siteCode}
                                    />
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <h3>No Lab Data Available</h3>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              );
            }}
          </Query>
        )}
        <LabModal
          isOpen={!!currentEditLab}
          hideModal={hideModal}
          labDetails={currentEditLab}
          onSave={hideModal}
        />
      </div>
    </AuthRequired>
  );
};
export default Labs;
