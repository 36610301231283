import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useQuery } from 'react-apollo';
import { GET_ALL_HOSPITALS } from '../queries';
import { FaInfoCircle } from 'react-icons/fa';

//code changes from constant sitecode to get hospitals on 30 Aug 2021

const SelectSiteCode = ({ selectedSiteCode, onSiteCodeSelect }) => {
  const { loading, data: { hospitals = [] } = {} } = useQuery(
    GET_ALL_HOSPITALS,
    {
      variables: { showAll: true }
    }
  );
  const [siteCodes, setSiteCodes] = useState([]);
  const [matchingHospitals, setMatchingHospitals] = useState([]);

  useEffect(() => {
    const siteCodeSet = new Set();
    hospitals.forEach(h => {
      siteCodeSet.add(h.siteCode);
    });
    setSiteCodes(Array.from(siteCodeSet));
  }, [hospitals]);

  useEffect(() => {
    updateHospitalList(selectedSiteCode);
    // eslint-disable-next-line
  }, [selectedSiteCode]);

  function updateHospitalList(siteCodeVal) {
    const matchingHospitals = hospitals.filter(h => h.siteCode === siteCodeVal);
    setMatchingHospitals(matchingHospitals);
  }

  let siteopts = siteCodes.map(siteCode => ({
    label: siteCode,
    value: siteCode
  }));

  const selectedOpt =
    siteopts.find(({ value }) => value === selectedSiteCode) || null;

  return (
    <div className="siteCodeWrapper">
      <div className="siteCodeDiv" style={{ zIndex: 10 }}>
        <Select
          isLoading={loading}
          loadingMessage={'fetching data'}
          placeholder={loading ? '' : 'Select Site Code'}
          options={siteopts}
          value={selectedOpt}
          onChange={e => {
            onSiteCodeSelect(e.value);
            updateHospitalList(e.value);
          }}
        />
      </div>
      <div className="hospNameDiv">
        <span className="hospName">
          {' '}
          <FaInfoCircle size={28} />
          <span className="hospName-tooltip">
            {matchingHospitals.map(h => (
              <div key={h.id} className="matchingHospital">
                {h.name}
              </div>
            ))}
          </span>
        </span>
      </div>
    </div>
  );
};

SelectSiteCode.propTypes = {
  selectedSiteCode: PropTypes.string,
  onSiteCodeSelect: PropTypes.func
};

export default SelectSiteCode;
