import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { RESEND_MESSAGE } from '../queries';
import { Button } from 'reactstrap';
import Loader from './Loader';

const ResendMessage = ({ id }) => {
  return (
    <Mutation
      mutation={RESEND_MESSAGE}
      refetchQueries={() => ['BOOKING_MESSAGE_HISTORY']}
      variables={{ messageId: id }}
    >
      {(resend, { loading }) => {
        if (loading) {
          return <Loader size={5} inline />;
        }
        return (
          <Button
            color="link"
            onClick={() => resend()}
            style={{ paddingLeft: 0 }}
          >
            RESEND
          </Button>
        );
      }}
    </Mutation>
  );
};

ResendMessage.propTypes = {
  id: PropTypes.number.isRequired
};

export default ResendMessage;
