import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GiArmBandage } from 'react-icons/gi';
import SendError from './SendError';
import PropTypes from 'prop-types';
import { logAmplitudeEvent } from '../utilities';

const ReportBugModal = ({ buttonText }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        color="link"
        onClick={() => {
          setShowModal(true);
          logAmplitudeEvent('Report_Bug_Pressed', {}, true);
        }}
      >
        {buttonText}
      </Button>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)} size="lg">
        <ModalHeader toggle={() => setShowModal(false)}>
          <GiArmBandage />
          {' Help us improve!'}
        </ModalHeader>
        <ModalBody>
          <h5>We listen to you</h5>
          <p>
            We appreciate your feedback. It helps us to keep the priorities
            straight. Please provide as much detail as you can. And, to follow
            up, it would be great if you enter your contact details.
            <br />
            <br />
            Thanks!
          </p>
          <SendError
            errorMessage="Manual Reporting via Report Bug Modal"
            errorObject={{}}
            onSent={() => setShowModal(false)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

ReportBugModal.propTypes = {
  buttonText: PropTypes.string
};

ReportBugModal.defaultProps = {
  buttonText: 'Report Bug'
};

export default ReportBugModal;
