import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { DELETE_LAB_DATA } from '../queries';
import { showConfirmation } from '../ConfirmationDialogService';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';

const getMessage = name => (
  <>
    <span>
      Permanently delete the lab of{' '}
      <strong style={{ color: 'red' }}>{name}</strong>
      ?<br />
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteLab = ({ itemId, name }) => {
  const confirm = deleteLab => {
    showConfirmation('Delete Lab?', getMessage(name), () =>
      deleteLab({ variables: { itemId } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_LAB_DATA}
      onCompleted={() => {
        displaySuccess(`${name} is deleted`);
        logAmplitudeEvent(
          'Lab_deleted',
          { deletedLabName: `${name}`, deletedLabId: `${itemId}` },
          true
        );
      }}
      onError={e => displayError(e)}
      refetchQueries={() => ['GET_LAB_DATA']}
    >
      {(deleteLab, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteLab)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteLab.propTypes = {
  itemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

export default DeleteLab;
