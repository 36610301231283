import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Col, Row } from 'reactstrap';
import * as yup from 'yup';
import { displayError, getFullName, logAmplitudeEvent } from '../utilities';
import ApptBookingLongForm from './ApptBookingLongForm';
import PatientSearchByPhone from './PatientSearchByPhone';
// import { toast } from 'react-toastify';
import { showConfirmation } from '../ConfirmationDialogService';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';
import { CREATE_BOOKING } from '../queries';
import BookingInfo from './BookingInfo';
import Veil from './Veil';

const styles = {
  docName: {
    fontWeight: 'bold'
  },
  docSpl: {
    color: '#808080',
    textTransform: 'capitalize'
  },
  stDate: {
    fontWeight: 'bold'
  },
  topMargin: {
    marginTop: 10
  },
  slotHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    backgroundColor: '#b3b3b3',
    border: '1px solid #fff'
  },
  dayContainer: {
    border: '1px solid #c7c7c7',
    textAlign: 'center'
  },
  slotContainer: {
    borderTop: '1px solid #c7c7c7'
  },
  formLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

//o = data coming from userDB, map it to booking object
const getMappedJSON = o => ({
  mpid: o.mpid,
  userName: getFullName(o.first_name, o.last_name),
  userCountry: o.nationality_country,
  userMobile: o.mobile,
  userEmail: o.email
});

const fetchCustomerDetails = userMPID => {
  const url = `${CX_USER_DB_BASE_URL}/mpid/v2/${userMPID}`;
  const fetchOpts = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
    }
  };
  return fetch(url, fetchOpts).then(response => response.json());
};

const schema = yup.object().shape({
  mpid: yup.string().nullable(),
  slotId: yup.string().required(),
  procedureName: yup.string('Procedure name is not provided').required(),
  userName: yup
    .string('Patient name should be alphabetical')
    .required('Patient name is required.'),
  userMobile: yup.string().required('Mobile number is required'),
  userCountry: yup.string().required('Select a country.'),
  userEmail: yup
    .string()
    .nullable()
    .email('Email is not well formatted'),
  guardianName: yup.string('Guardian name should be alphabetical'),
  guardianRel: yup.string('Please select relationship with guardian'),
  userGender: yup.string('Gender is required'),
  userDoB: yup.date('Please specify date of birth.')
});

export default class BookApptForm extends Component {
  state = {
    booking: {
      mpid: null,
      slotId: null,
      isPaid: false,
      userName: null,
      userMobile: null,
      userCountry: null,
      userEmail: null
    }
  };

  // showError = message => toast.error(`👎 ${message}`);
  submitBooking = async createBooking => {
    const { booking } = this.state;
    const { selectedSlot, procedureName } = this.props;
    booking.slotId = selectedSlot ? selectedSlot.id : null;
    booking.procedureName = procedureName;
    booking.userCountry = booking.userCountry || 'India';

    try {
      const myErrors = [];
      const formdata = await schema.validate(booking);

      // TODO: re-enable them later, Saurabh does not want these fields as mandatory
      /*
      if (!booking.mpid) {
        if (
          !booking.userGender ||
          (booking.userGender !== 'Male' && booking.userGender !== 'Female')
        ) {
          myErrors.push('Gender is required');
        }

        if (!booking.userDoB) {
          myErrors.push('Date of birth is required');
        }

        if (booking.userDoB) {
          formdata.userDoB = booking.userDoB.format('YYYY-MM-DD');
        }

        if (moment().diff(booking.userDoB, 'years') < 18) {
          if (!booking.guardianName) {
            myErrors.push('Guardian name is required for minors');
          }
          if (!booking.guardianRel) {
            myErrors.push('Guardian relationship is required for minors');
          }
        }
      }
      */
      if (booking.procedureName === 'Video Consultation') {
        throw new Error('Video consultation can not be booked through AMS.');
      }

      if (booking.mpid) {
        const customerDetails = await fetchCustomerDetails(booking.mpid);
        if (
          customerDetails &&
          customerDetails.status &&
          customerDetails.status !== 1
        ) {
          throw new Error('Fetching patient details failed');
        }
        if (customerDetails.data[0].isDeleted === true) {
          throw new Error(
            'Patient was deleted and can not book an appointment'
          );
        }
      }

      if (myErrors.length > 0) {
        const e = new Error();
        e.errors = myErrors;
        throw e;
      }

      // clean data
      if (booking.userDoB) {
        formdata.userDoB = booking.userDoB.format('YYYY-MM-DD');
      }

      await createBooking({
        variables: { createBooking: formdata },
        refetchQueries: [
          'SEARCH_BOOKINGS',
          'SLOTS_QUERY',
          'GET_BOOKINGS_STATUS'
        ]
      });
    } catch (e) {
      displayError(e);
      // console.error('Error: ' + e.message + '\n' + JSON.stringify(e));
      // this.showError(e.errors.join(', '));
      // displayError(e.errors.join(', '));
    }
  };

  handleUserData = selectedPatient => {
    //The next line is when user presses backspace on
    //empty input, this makes react-select to trigger onChange
    //as you are deleting empty string
    if (!selectedPatient) {
      this.setState({ isPatientSelected: false });
      return;
    }

    const { isNew, mobile, mpid } = selectedPatient;

    //clean up booking when user is reselected
    //to avoid data seeping into from prev user
    const booking = {};

    if (isNew) {
      const updatedBooking = { userMobile: mobile };
      this.setState({
        isNewPatient: true,
        isPatientSelected: true,
        booking: updatedBooking,
        hasMPI: !!mpid
      });
    } else {
      const userData = getMappedJSON(selectedPatient);
      const updatedBooking = Object.assign({}, booking, userData);
      this.setState({
        isNewPatient: false,
        isPatientSelected: true,
        booking: updatedBooking,
        hasMPI: !!mpid
      });
    }
  };

  updateBooking = (key, value) => {
    // console.log('key: ' + key + ', v: ' + value);
    const { booking } = this.state;
    booking[key] = value;
    this.setState({ booking });
  };

  //If the user is typing let's assume no user is selected
  handlePhoneUpdate = () => this.setState({ isPatientSelected: false });

  render() {
    const { selectedSlot, mode, hospitalId } = this.props;
    const slotTime = selectedSlot
      ? moment(selectedSlot.startTime).format('ddd, DD MMM hh:mm a')
      : '';
    // console.log('slot sel', selectedSlot);
    const { isPatientSelected, booking, isNewPatient, hasMPI } = this.state;

    return (
      <Mutation
        mutation={CREATE_BOOKING}
        refetchQueries={() => ['GET_BOOKINGS_STATUS']}
        awaitRefetchQueries
        onCompleted={data => {
          const {
            createBooking: {
              objectName = '',
              procedureName = '',
              slotTime = '',
              userName = ''
            } = {}
          } = data;
          const eventProps = {
            procedureName,
            PatientName: userName,
            TimeOfAppointment: moment(slotTime).format('hh:mm A'),
            DateOfBooking: moment(slotTime).format('DD-MM-YYYY'),
            ConsultantName: objectName
          };
          if (mode === 'quickbook')
            logAmplitudeEvent(
              'Book_Appointment_Created',
              Object.assign(eventProps, { bookingMode: 'quickbook' }),
              true
            );
          else logAmplitudeEvent('Book_Appointment_Created', eventProps, true);
        }}
      >
        {(createBooking, { data, loading }) => {
          const { createBooking: bookingRes } = data || {};
          // console.log('data got', data);
          // if(error) returndisplayError(JSON.stringify(error));
          return !data ? (
            <fieldset disabled={loading}>
              <Row className="form-row">
                {loading && <Veil />}
                <Col xs={4} className="text-right" style={styles.formLabel}>
                  Appointment Time<div style={{ color: 'red' }}>*</div>:
                </Col>
                <Col xs={8}>
                  <input readOnly value={slotTime} />
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={4} className="text-right" style={styles.formLabel}>
                  Search or Add by Mobile<div style={{ color: 'red' }}>*</div>:
                </Col>
                <Col xs={8}>
                  <PatientSearchByPhone
                    hospitalId={hospitalId}
                    onChange={this.handleUserData}
                    onInputChange={this.handlePhoneUpdate}
                  />
                </Col>
              </Row>
              {isPatientSelected && (
                <ApptBookingLongForm
                  isNewPatient={isNewPatient}
                  hasMPI={hasMPI}
                  bookingData={booking}
                  onChange={this.updateBooking}
                  onSubmit={() => {
                    showConfirmation(
                      'Booking Confirmation',
                      'Are you sure you want to book this appointment?',
                      () => this.submitBooking(createBooking)
                    );
                  }}
                />
              )}
            </fieldset>
          ) : (
            <BookingInfo bookingId={bookingRes.id} />
          );
        }}
      </Mutation>
    );
  }
}

BookApptForm.propTypes = {
  selectedSlot: PropTypes.object,
  procedureName: PropTypes.string,
  mode: PropTypes.string,
  hospitalId: PropTypes.number
};
