const convertToCSV = data => {
  const csvRows = [];
  const headers = [
    'Scan Name',
    'Item Id',
    'SiteCode',
    'About Info',
    'AMS Procedure',
    'Amount',
    'Waiting Time',
    'Duration of Scan',
    'Trimester Data',
    'Week Data',
    'Email Template',
    'SMS Template',
    'Enable',
    'Favourite Scan'
  ];
  csvRows.push(headers.join(','));
  for (const item of data) {
    const values = [
      `"${(item.scanName || '').replace(/"/g, '""')}"`,
      item.itemId,
      item.siteCode,
      `"${(item.aboutInfo || '').replace(/"/g, '""')}"`,
      `"${(item.LtProcedure || '').replace(/"/g, '""')}"`,
      item.rate,
      item.waitingTime,
      `"${(item.durationofScan || '').replace(/"/g, '""')}"`,
      `"${(item.trimisterData || '').replace(/"/g, '""')}"`,
      `"${(item.weekData || '').replace(/"/g, '""')}"`,
      `"${(item.emailTemplate || '').replace(/"/g, '""')}"`,
      `"${(item.smsTemplate || '').replace(/"/g, '""')}"`,
      item.enable ? 'true' : 'false',
      item.favouriteScan ? 'true' : 'false'
    ];
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

export const handleExportCSV = data => {
  if (data && data.length > 0) {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'scan_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};

const customSort = (a, b, order) => {
  const parseDuration = str => {
    if (str) {
      const matches = str.match(/\d+/g);
      if (matches && matches.length === 2) {
        const [min, max] = matches.map(Number);
        return (min + max) / 2;
      }
    }
    return order === 'asc' ? Infinity : -Infinity; // Place null values at end
  };
  const aValue = parseDuration(a.durationofScan);
  const bValue = parseDuration(b.durationofScan);
  if (order === 'asc') {
    return aValue - bValue;
  } else {
    return bValue - aValue;
  }
};

const weekDataSort = (a, b, order) => {
  const parseOrdinal = str => {
    // Extract the numeric part and convert it to a number
    const numericA = parseInt(str.match(/\d+/), 10) || 0;
    return order === 'asc' ? numericA : -numericA;
  };

  const aValue = a.weekData ? parseOrdinal(a.weekData) : Infinity;
  const bValue = b.weekData ? parseOrdinal(b.weekData) : Infinity;

  return aValue - bValue;
};

const removeSpecialCharacters = str => {
  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

export const sortTable = (sorting, dataToSort) => {
  const sortedData = [...dataToSort].sort((a, b) => {
    if (sorting.column === 'weekData') {
      return weekDataSort(a, b, sorting.order);
    }
    if (sorting.column === 'durationofScan') {
      return customSort(a, b, sorting.order);
    }
    if (sorting.column === 'rate' || sorting.column === 'itemId') {
      const valueA = a[sorting.column];
      const valueB = b[sorting.column];

      // Handling null values
      if (valueA === null && valueB !== null) {
        return 1;
      }
      if (valueA !== null && valueB === null) {
        return -1;
      }
      if (valueA === null && valueB === null) {
        return 0;
      }
      if (sorting.order === 'asc') {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    } else {
      const valueA = a[sorting.column];
      const valueB = b[sorting.column];

      // Handling null values
      if (valueA === '' && valueB !== '') {
        return 1;
      }
      if (valueA !== '' && valueB === '') {
        return -1;
      }
      if (valueA === '' && valueB === '') {
        return 0;
      }
      if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        return sorting.order === 'asc'
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      }

      const stringValueA =
        typeof valueA === 'string'
          ? removeSpecialCharacters(valueA.toLowerCase())
          : '';
      const stringValueB =
        typeof valueB === 'string'
          ? removeSpecialCharacters(valueB.toLowerCase())
          : '';

      return sorting.order === 'asc'
        ? stringValueA.localeCompare(stringValueB)
        : stringValueB.localeCompare(stringValueA);
    }
  });
  return sortedData;
};
