import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import Loader from './Loader';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { DEFAULT_DOC_MALE, DEFAULT_DOC_FEMALE } from '../constants';
import UploadDP from './UploadDP';
import omit from 'lodash/omit';
import { betterParseInt, displayError } from '../utilities';
import {
  ServicesLookupInput,
  TagsLookupInput,
  SplLookupInput,
  LangLookupInput,
  // LangInput,
  // DateField,
  TxtArea,
  SelectInput,
  // Checkbox,
  TxtInput,
  FixedText,
  validateDoc
} from './DocFormFields';

const DoctorFormModal = props => {
  // const { doctor, departments, updateDoc, showModal, toggle, loading } = props;
  const { doctor, updateDoc, showModal, toggle, loading } = props;
  const initValue = omit(doctor, [
    '__typename',
    'entityId',
    'department',
    'photos',
    'cloudinaryId',
    'ltFirstSyncData',
    'ltLastSyncData',
    'ltCreatedAt',
    'ltUpdatedAt',
    'created_at',
    'updated_at',
    'entity',
    'siteCode'
  ]);
  // remove the redundant one
  return (
    <Formik
      // key={`doc-${doctor.id}`}
      initialValues={initValue}
      validationSchema={validateDoc}
      onSubmit={values => {
        const { dateOfJoining, dateSincePracticing } = values;
        if (!dateOfJoining || dateOfJoining.trim().length < 1) {
          delete values.dateOfJoining;
        }
        if (!dateSincePracticing || dateSincePracticing.trim().length < 1) {
          delete values.dateSincePracticing;
        }

        delete values.isConfigured;

        if (typeof values.hasOp !== 'boolean') {
          delete values.hasOp;
        }
        if (typeof values.hasOt !== 'boolean') {
          delete values.hasOt;
        }
        if (typeof values.hasIp !== 'boolean') {
          delete values.hasIp;
        }
        if (typeof values.isEnabled !== 'boolean') {
          delete values.isEnabled;
        }
        if (typeof values.isVisible !== 'boolean') {
          delete values.isVisible;
        }

        const gr = betterParseInt(values.globalRank);
        if (isNaN(gr)) {
          displayError(`Global rank must be a number`);
          return;
        } else {
          values.globalRank = gr;
        }
        // console.log('values', values);

        updateDoc({ variables: { docInput: values } });
      }}
      onReset={initValue}
    >
      {({ handleSubmit, resetForm }) => {
        //const deptOpts = departments.map(d => ({
        //  label: d.name,
        //  value: d.id
        //}));
        // const selectedOpt = deptOpts.find(o => o.value === doctor.departmentId);
        const { photos } = doctor;

        let dpURL = photos && photos.dp300 && photos.dp300.url;

        if (!dpURL && doctor.gender === 'Male') {
          dpURL = DEFAULT_DOC_MALE;
        } else if (!dpURL) {
          dpURL = DEFAULT_DOC_FEMALE;
        }

        // console.error('Errors', errors);
        return (
          <Modal isOpen={showModal} toggle={toggle} size="xl">
            <ModalHeader
              toggle={() => {
                resetForm();
                toggle();
              }}
            >
              Edit Profile
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <Row style={{ justifyContent: 'center' }}>
                  <Loader inline />
                </Row>
              ) : (
                <Row>
                  <Col>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                        backgroundColor: '#564088',
                        padding: '1rem',
                        borderRadius: '1rem'
                      }}
                    >
                      <UploadDP url={dpURL} doctorId={doctor.id} />
                    </div>
                    <FixedText label="Site Code" value={doctor.siteCode} />
                    {/* <Field name="name" label="Name" component={TxtInput} /> */}
                    <Field
                      name="phoneNumber"
                      label="Contact Numbers"
                      component={TxtInput}
                    />
                    {/* <Field name="email" label="Email" component={TxtInput} /> */}
                    <Field
                      name="gender"
                      label="Gender"
                      options={[
                        { label: 'Male', value: 'Male' },
                        { label: 'Female', value: 'Female' }
                      ]}
                      component={SelectInput}
                    />
                    <Field
                      name="languages"
                      label="Languages"
                      component={LangLookupInput}
                    />
                    {/* <Field
                    name="qualification"
                    label="Qualification"
                    component={TxtInput}
                  /> */}

                    <Field
                      name="primarySpecialty"
                      label="Primary Speciality"
                      component={SplLookupInput}
                    />
                    <Field
                      name="secondarySpecialty"
                      label="Secondary Speciality"
                      component={SplLookupInput}
                    />
                    {/* <Field
                      component={SelectInput}
                      name="departmentId"
                      options={deptOpts}
                      value={selectedOpt}
                      label="Department"
                    />
                    <Field
                    name="medicalCouncilRegNo"
                    label="Registration Number"
                    component={TxtInput}
                  />
                  <Field
                    name="dateSincePracticing"
                    label="Practicing Since"
                    component={DateField}
                  />
                  <Field
                    name="dateOfJoining"
                    label="Joining Date"
                    component={DateField}
                  /> */}
                  </Col>
                  <Col>
                    <Field
                      name="services"
                      label="Services"
                      component={ServicesLookupInput}
                    />
                    <Field
                      name="tags"
                      label="Tags"
                      component={TagsLookupInput}
                    />
                    {/* <Field name="awards" label="Awards" component={TxtInput} />
                  <Field
                    name="membership"
                    label="Membership"
                    component={TxtInput}
                  /> */}
                    <Field
                      name="aboutMe"
                      label="About Me"
                      component={TxtArea}
                    />
                    {/* <Field
                    name="globalRank"
                    label="Global Rank"
                    component={TxtInput}
                  />
                  <Field
                    name="altDepartment"
                    label="Alternate Department"
                    component={TxtInput}
                  />

                  <Field
                    name="altDesignationType"
                    label="Alternate Designation"
                    component={TxtInput}
                  />
                  <Field
                    name="altDesignation"
                    label="Alternate Designation"
                    component={TxtInput}
                  />
                  <Field
                    name="lifetrenzId"
                    label="Lifetrenz Id"
                    component={TxtInput}
                  />
                  <Field
                    name="seoDescription"
                    label="Description for SEO"
                    component={TxtArea}
                  />
                  <Field
                    name="seoTitle"
                    label="Title for SEO"
                    component={TxtArea}
                  />
                  <Field
                    name="hasOp"
                    label="Has OP Access?"
                    component={Checkbox}
                  />
                  <Field name="seoSlug" label="SEO Slug" component={TxtInput} />
                  <Field
                    name="hasOt"
                    label="Has OT Access?"
                    component={Checkbox}
                  />
                  <Field
                    name="hasIp"
                    label="Has IP Access?"
                    component={Checkbox}
                  />
                  <Field name="isVisible" label="Visible?" component={Checkbox} />
                  <Field name="isEnabled" label="Enabled?" component={Checkbox} />
                   */}
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  resetForm();
                  toggle();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={loading}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

DoctorFormModal.propTypes = {
  doctor: PropTypes.object.isRequired,
  departments: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateDoc: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  toggle: PropTypes.func,
  loading: PropTypes.bool
};

export default DoctorFormModal;
