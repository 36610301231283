import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Mutation } from 'react-apollo';
import Loader from './Loader';
import { RESET_API_SECRET } from '../queries';

const AdminUserApiKeySecretBlock = props => {
  const { adminUser } = props;
  // console.log('>> ', adminUser);
  const { id, apiSecret } = adminUser || {};
  return (
    <div>
      <div>
        <strong>API Key:&nbsp;</strong>ck.{id}
      </div>
      <Mutation mutation={RESET_API_SECRET}>
        {(resetSecret, { loading, error, data }) => {
          let secretDisplay = apiSecret || 'Generate a new secret.';
          if (data && data.newApiSecret) {
            secretDisplay = data.newApiSecret;
          }
          return (
            <div>
              <strong>API secret:&nbsp;</strong>
              {secretDisplay}
              {loading ? (
                <Loader size={5} />
              ) : (
                <Button
                  size="sm"
                  color="link"
                  onClick={() => resetSecret({ variables: { userId: id } })}
                >
                  Regenerate
                </Button>
              )}
              {error && <span>Error: {error}</span>}
            </div>
          );
        }}
      </Mutation>

      <div>
        <small>
          NOTE: Regenerate will generate new secret causing old secret to no
          longer work.
        </small>
      </div>
    </div>
  );
};

AdminUserApiKeySecretBlock.propTypes = {
  adminUser: PropTypes.object
};

export default AdminUserApiKeySecretBlock;
