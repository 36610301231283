import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { SLOT_PROTO_DELETION_AFFECTED_BOOKINGS } from '../queries';
import Loader from './Loader';
import { FaThumbsUp } from 'react-icons/fa';
import moment from 'moment';
import { Table } from 'reactstrap';

const renderBookings = ({ loading, bookings, error }) => {
  if (loading) return <Loader />;
  if (error) {
    return <div className="error">{JSON.stringify(error)}</div>;
  }

  if (!bookings || bookings.length < 1) {
    return (
      <h4>
        <FaThumbsUp /> No apparent future bookings. We are good to go!
      </h4>
    );
  }

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Appointment At ↓</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map(b => (
          <tr key={b.id}>
            <td>{b.userName}</td>
            <td>{b.userMobile}</td>
            <td>{b.userEmail}</td>
            <td>{moment(b.slotTime).format('DD MMM YYYY hh:mm A')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

renderBookings.propTypes = {
  bookings: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool
};

const RenderFutureSlotprotoBookings = ({ slotPrototypeId }) => {
  return (
    <Query
      query={SLOT_PROTO_DELETION_AFFECTED_BOOKINGS}
      variables={{ slotPrototypeId }}
    >
      {({ loading, error, data: { bookings } = {} }) =>
        renderBookings({ loading, bookings, error })
      }
    </Query>
  );
};

RenderFutureSlotprotoBookings.propTypes = {
  slotPrototypeId: PropTypes.number.isRequired
};

export default RenderFutureSlotprotoBookings;
