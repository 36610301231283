import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { FaParachuteBox, FaTimes } from 'react-icons/fa';
import groupBy from 'lodash/groupBy';

const COLOR_MAP = [
  'bg-success text-white',
  'bg-warning text-dark',
  'bg-info text-white',
  'bg-dark text-white',
  'bg-primary text-white',
  'bg-danger text-white',
  'bg-secondary text-white'
];

export const getColorForSlotDuration = slotDuration =>
  COLOR_MAP[slotDuration % COLOR_MAP.length];

const SlotPatternGen = styled.div`
  padding: 1rem 0.2rem;
  border: solid 1px #ddd;
`;

const Item = styled.div`
  border-radius: 2px;
  border: 1px ${props => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
  margin: 0 8px 0 0;
  position: relative;
  width: ${props => (props.isShort ? '4rem' : '8rem')};
  overflow-x: hidden;
  white-space: nowrap;
  height: 100%;
`;

const ItemHeader = styled.div`
  padding: 0.1rem;
  background-color: #ddd;
  text-transform: uppercase;
  font-weight: bold;
`;

const ItemBody = styled.div`
  padding: 0.1rem;
  background-color: #fff;
`;

const Procedure = styled.div`
  font-size: 0.8rem;
`;

const DeleteSlot = styled.button`
  border: none;
  padding: 0.1rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ddd;
`;

const SlotBucket = ({ item, isDragging, removable, onRemove, ...props }) => (
  <Item isDragging={isDragging} {...props} isShort={removable}>
    <ItemHeader className={getColorForSlotDuration(item.slotDuration)}>
      {`${item.slotDuration}${removable ? 'm' : ' minutes'}`}
    </ItemHeader>

    {removable && (
      <DeleteSlot
        onClick={onRemove}
        className={getColorForSlotDuration(item.slotDuration)}
      >
        <FaTimes />
      </DeleteSlot>
    )}

    <ItemBody>
      {item.procedures.map(name => (
        <Procedure title={name} key={name}>
          {name}
        </Procedure>
      ))}
    </ItemBody>
  </Item>
);

SlotBucket.propTypes = {
  item: PropTypes.object.isRequired,
  isDragging: PropTypes.bool,
  removable: PropTypes.bool,
  onRemove: PropTypes.func
};

SlotBucket.defaultProps = {
  items: [],
  isDragging: false,
  removable: false,
  onRemove: () => {}
};

const SlotBucketClone = styled(SlotBucket)`
  ~ div {
    transform: none !important;
  }
`;

const Repository = styled.div`
  border: ${props => (props.isDraggingOver ? '1px dashed #ddd' : 'none')};
  padding: 0.1rem;
  display: flex;
  padding-bottom: 1rem;
`;

const PatternHolder = styled(Repository)`
  border: ${props =>
    props.isDraggingOver ? '1px dashed #000' : '1px solid #ddd'};
  border-radius: 4px;
  padding: 0.2rem;
  overflow: auto;
`;

const EmptyPatternHolder = styled.h3`
  color: #ddd;
  text-align: center;
  padding: 0.2rem;
`;

const Title4 = styled.h5`
  color: #999;
  padding: 0;
  text-transform: uppercase;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SlotPattern = ({ procedureList, field: { value, name }, form }) => {
  // const [items, setItems1] = useState(value);

  const itemz = !procedureList
    ? []
    : Object.values(
        groupBy(procedureList.filter(i => !!i.slotDuration), 'slotDuration')
      ).map(arr => ({
        slotDuration: arr[0].slotDuration,
        procedures: arr.map(({ name }) => name)
      }));

  const setItems = itmz => {
    // setItems1(itmz);
    const p = itmz.map(s => s.slotDuration);
    // console.log(p);
    // onChange(p);
    form.setFieldValue(name, p);
  };

  const items = value
    .map(v => itemz.find(({ slotDuration }) => slotDuration === v))
    .filter(v => !!v)
    .map(i => ({ ...i, id: `k-${Math.random()}` }));
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const destId = result.destination.droppableId;
    const destIdx = result.destination.index;
    const srcId = result.source.droppableId;
    const srcIdx = result.source.index;

    // Do not reorder or n the choice listt
    if (srcId === 'availableSlotProtos' && destId === 'availableSlotProtos') {
      return;
    }

    // 0. if cooming frrom availableslotprotoos
    if (srcId === 'availableSlotProtos') {
      const newArr = Array.from(items);
      newArr.splice(destIdx, 0, { ...itemz[srcIdx], id: Math.random() });
      setItems(newArr);
      return;
    }

    // 1. if just reoorderring
    if (srcId === destId && srcId === 'slotPattern') {
      const itemzz = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      setItems(itemzz);
      return;
    }

    //2. remove from patten if moved from pattern box to choice list
    if (srcId === 'slotPattern' && destId === 'availableSlotProtos') {
      const newArr = Array.from(items);
      newArr.splice(srcIdx, 1);
      setItems(newArr);
      return;
    }
  };

  const removeSlot = idx => {
    const newItms = Array.from(items);
    newItms.splice(idx, 1);
    setItems(newItms);
  };

  return (
    <SlotPatternGen>
      <DragDropContext onDragEnd={onDragEnd}>
        <Title4>create slot pattern</Title4>
        <Droppable
          droppableId="availableSlotProtos"
          direction="horizontal"
          isDropDisabled={true}
        >
          {(provided, snapshot) => {
            return (
              <Repository
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {itemz.map((itm, idx) => {
                  return (
                    <Draggable
                      key={itm.slotDuration}
                      draggableId={`${itm.slotDuration}`}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SlotBucket
                              item={itm}
                              isDragging={snapshot.isDragging}
                            />
                          </div>
                          {snapshot.isDragging && (
                            <SlotBucketClone item={itm} />
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Repository>
            );
          }}
        </Droppable>

        <Droppable droppableId="slotPattern" direction="horizontal">
          {(provided, snapshot) => {
            return (
              <PatternHolder
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {items && items.length > 0 ? (
                  items.map((itm, idx) => {
                    // console.log('itm', idx, itm);
                    return (
                      <Draggable
                        key={itm.id}
                        draggableId={`${itm.id}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SlotBucket
                              item={itm}
                              isDragging={snapshot.isDragging}
                              removable={true}
                              onRemove={() => removeSlot(idx)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <EmptyPatternHolder>
                    <FaParachuteBox /> DROP SLOTS HERE!
                  </EmptyPatternHolder>
                )}
                {provided.placeholder}
              </PatternHolder>
            );
          }}
        </Droppable>
      </DragDropContext>
    </SlotPatternGen>
  );
};

SlotPattern.propTypes = {
  procedureList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        slotDuration: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  ),
  field: PropTypes.object, //formik specific
  form: PropTypes.object //formik specific
  // value: PropTypes.arrayOf(PropTypes.number),
  // onChange: PropTypes.func
};

SlotPattern.defaultProps = {
  value: [],
  onChange: () => {}
};

export default SlotPattern;
