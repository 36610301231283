import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
export default class DatePicker1 extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDate: this.props.selectedDate };
  }
  handleChange = selectedDate => {
    this.setState({ selectedDate });
    const { name } = this.props;
    // console.log(`${name} => ${selectedDate.toString()}`);
    this.props.onChange(name, selectedDate);
  };
  render() {
    const { selectedDate } = this.state;
    return (
      <DatePicker
        selected={selectedDate}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        dateFormat="ddd, DD MMM YYYY"
      />
    );
  }
}
DatePicker1.propTypes = {
  selectedDate: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string
};
