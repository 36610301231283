import fetch from 'isomorphic-fetch';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  S2S_CX_SERVER_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import DiscountInput, { discountTypes } from './DiscountInput';
import PaymentForm from './PaymentForm';
import SearchPatientByMobile from './SearchPatientByMobile';
import SelectClinixProcedure from './SelectClinixProcedure';
import Veil from './Veil';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const OtherServicesBookingModal = props => {
  const { isOpen, toggle, selectedHospitalDetails, allDoctorDetails } = props;

  const [selectedOtherServices, updateSelectedOtherServices] = useState();
  const [totalAmount, updateTotalAmount] = useState();
  const [paymentData, updatePaymentData] = useState({});
  const [otherServicesList, updateOtherServicesList] = useState();
  const [isPatientSelected, updateIsPatientSelected] = useState(false);
  const [userCompleteDetails, updateUserCompleteDetails] = useState();
  const [
    otherServicesCompleteDetails,
    updateOtherServicesCompleteDetails
  ] = useState([]);
  const [lineItemsList, updateLineItemsList] = useState([]);
  const [selectedPatientAge, updateSelectedPatientAge] = useState('');
  const [selectedPatientGender, updateSelectedPatientGender] = useState('');
  const [selectedConsultingDoctor, updateSelectedConsultingDoctor] = useState(
    ''
  );
  const [
    shouldShowCreateOtherServicesInvoiceLoader,
    showCreateOtherServicesInvoiceLoader
  ] = useState(false);

  //New State for discount
  const [discount, setDiscount] = useState('0');
  const [discountType, setDiscountType] = useState(
    discountTypes.find(item => item.value === 'percentage')
  );
  const [discountNotes, setDiscountNotes] = useState('');

  const [
    otherServicesInvoiceGenerationSuccess,
    updateOtherServicesInvoiceGenerationSuccess
  ] = useState(false);

  const [pdfLink, updatePdfLink] = useState(null);

  useEffect(() => {
    updateSelectedOtherServices();
    updateTotalAmount();
    updatePaymentData({});
    updateOtherServicesList();
    updateIsPatientSelected(false);
    updateSelectedPatientAge('');
    updateSelectedPatientGender('');
    updateUserCompleteDetails();
    updateOtherServicesCompleteDetails([]);
    updateLineItemsList([]);
    showCreateOtherServicesInvoiceLoader(false);
    updateOtherServicesInvoiceGenerationSuccess(false);
    updatePdfLink(null);

    if (isOpen) {
      getOtherServiceChargesFromZohoBooks();
    }
    // eslint-disable-next-line
  }, [isOpen, toggle]);

  let docListArr = null;
  if (isOpen) {
    docListArr = allDoctorDetails.map(doc => ({
      label: doc.name,
      value: doc.id
    }));
  }

  useEffect(() => {
    if (!isEmpty(docListArr) && isEmpty(selectedConsultingDoctor))
      updateSelectedConsultingDoctor(docListArr[0]);
    // eslint-disable-next-line
  }, [docListArr]);

  let searchedTextValue = null;

  // function to fetch lab charges configured in zoho books based on hospitalid and charge item type.
  const fetchOtherServiceCharges = () => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/get_charges`;
    let req_body = {};

    if (searchedTextValue && searchedTextValue.length === 1) {
      req_body = {
        cf_hospitalid: selectedHospitalDetails.id.toString(),
        cf_chargeitemtype: 'Other services',
        name_startswith: searchedTextValue,
        siteCode: selectedHospitalDetails.siteCode
      };
    } else {
      req_body = {
        cf_hospitalid: selectedHospitalDetails.id.toString(),
        cf_chargeitemtype: 'Other services',
        siteCode: selectedHospitalDetails.siteCode
      };
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getOtherServiceChargesFromZohoBooks = async () => {
    try {
      const response = await fetchOtherServiceCharges();
      if (response && response.status && response.status === 1) {
        let completeDetails = otherServicesCompleteDetails.concat(
          response.data
        );
        completeDetails = completeDetails.filter(
          (arr, index, self) =>
            index === self.findIndex(t => t.item_id === arr.item_id)
        );
        updateOtherServicesCompleteDetails(completeDetails);
        let list = response.data.map(p => p.item_name);
        updateOtherServicesList(list);
        logAmplitudeEvent(
          'lab_charge_fetched_successfully',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Other services'
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'lab_charge_fetching_failed',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Other services'
          },
          true
        );
      }
    } catch (error) {
      logAmplitudeEvent(
        'lab_charge_fetching_failed',
        {
          hospitalId: selectedHospitalDetails.id,
          chargeType: 'Other services'
        },
        true
      );
    }
  };

  // function to create customer’s lab payment invoice based on get_charges API response and other customer payment details.
  const createOtherServicesInvoiceData = (siteCode, referringDoctor) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      mpid: userCompleteDetails.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      gst_no: selectedHospitalDetails.gstNo,
      first_name: userCompleteDetails.userName,
      email: userCompleteDetails.userEmail,
      mobile: userCompleteDetails.userMobile,
      product_type: lineItemsList[0].product_type,
      sitecode: siteCode,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_address: selectedHospitalDetails.address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      line_items: lineItemsList,
      procedure: 'Other services',
      logo_url: selectedHospitalDetails.logoUrl,
      notes: {
        patient_age: selectedPatientAge,
        ...(!isEmpty(referringDoctor) && !isEmpty(referringDoctor.label)
          ? { referring_doc_name: referringDoctor.label }
          : {}),
        patient_gender: selectedPatientGender,
        selected_hospital_name: selectedHospitalDetails.name,
        selected_clinic_address: selectedHospitalDetails.address
      },
      discount: parseInt(discount),
      discountType: discountType.value,
      ...(!isEmpty(referringDoctor) && !isEmpty(referringDoctor.value)
        ? { ams_doctor_id: referringDoctor.value }
        : {})
    };

    if (req_body.discount && discountNotes) {
      req_body.discountNotes = discountNotes;
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createOtherServicesPaymentInvoice = async () => {
    showCreateOtherServicesInvoiceLoader(true);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    const siteCode = selectedHospitalDetails.siteCode;

    try {
      const response = await createOtherServicesInvoiceData(
        siteCode,
        selectedConsultingDoctor
      );
      if (response && response.status && response.status === 1) {
        showCreateOtherServicesInvoiceLoader(false);
        updateOtherServicesInvoiceGenerationSuccess(true);
        displaySuccess('Other services invoice generated successfully');
        updatePdfLink(response.data.s3pdflink);

        logAmplitudeEvent(
          'other_services_invoice_generated_successfully',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: lineItemsList[0].product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: 'Other services',
            s3pdflink: response.s3pdflink
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'other_services_invoice_generation_failed',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: lineItemsList[0].product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: 'Other services',
            response: response
          },
          true
        );
        showCreateOtherServicesInvoiceLoader(false);
        const emsg = `Other services invoice generation failed`;
        throw new Error(emsg);
      }
    } catch (error) {
      logAmplitudeEvent(
        'other_services_invoice_generation_failed',
        {
          mpid: userCompleteDetails.mpid,
          salesperson_name: loggedInUser.name,
          reference_number: paymentData.transactionId
            ? paymentData.transactionId
            : 'N/A',
          txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
          payment_mode: paymentData.paymentMode,
          gst_no: selectedHospitalDetails.gstNo,
          first_name: userCompleteDetails.userName,
          email: userCompleteDetails.userEmail,
          mobile: userCompleteDetails.userMobile,
          product_type: lineItemsList[0].product_type,
          sitecode: siteCode,
          hospital_id: selectedHospitalDetails.id.toString(),
          hospital_name: selectedHospitalDetails.name,
          hospital_address: selectedHospitalDetails.address,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          line_items: lineItemsList,
          procedure: 'Other services',
          error: error
        },
        true
      );
      showCreateOtherServicesInvoiceLoader(false);
      displayError(error);
    }
  };

  const handleUserData = selectedPatient => {
    if (!selectedPatient) {
      updateIsPatientSelected(false);
      return;
    } else {
      const userData = {
        mpid: selectedPatient.mpid,
        userName: selectedPatient.first_name,
        userCountry: selectedPatient.nationality_country,
        userMobile: selectedPatient.mobile,
        userEmail: selectedPatient.email
      };
      updateUserCompleteDetails(userData);
      let patientAge = calculateAge(selectedPatient.date_of_birth);
      updateSelectedPatientAge(patientAge);
      updateSelectedPatientGender(selectedPatient.gender);
      updateIsPatientSelected(true);
    }
  };

  // function to calculate patient age in years and months based on his date of birth.
  const calculateAge = dateString => {
    let today = new Date();
    let DOB = new Date(dateString);
    let totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    let years = today.getFullYear() - DOB.getFullYear();

    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    let months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months === 0) months = 11;
    } else {
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    let age = years + ' years ' + months + ' months ';
    return age;
  };

  const handleMobileNoChange = () => updateIsPatientSelected(true);

  const handleOnClose = () => {
    updateSelectedOtherServices();
    updateTotalAmount();
    updatePaymentData({});
    showCreateOtherServicesInvoiceLoader(false);
    updateOtherServicesInvoiceGenerationSuccess(false);
    updatePdfLink(null);
  };

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };

  const createOtherServicesInvoice = () => {
    createOtherServicesPaymentInvoice();
  };

  const viewOtherServicesInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Other Services Booking</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Row style={{ marginTop: '1rem' }}>
                <Col xs={10}>
                  <SelectClinixProcedure
                    procedureList={otherServicesList}
                    procedureType={'Other services'}
                    selectedProcedure={selectedOtherServices}
                    enteredSearchText={SearchedText => {
                      if (SearchedText && SearchedText.length === 1) {
                        searchedTextValue = SearchedText;
                        getOtherServiceChargesFromZohoBooks();
                      } else {
                        searchedTextValue = null;
                      }
                    }}
                    onProcedureSelect={selectedLab => {
                      let selectedProcedureArr = [];
                      let selectedProcedureCompleteArr = [];

                      otherServicesCompleteDetails.forEach(labDetails => {
                        selectedLab.forEach(lab => {
                          if (labDetails.item_name === lab.value) {
                            selectedProcedureCompleteArr.push(labDetails);
                            selectedProcedureArr.push({
                              item_id: labDetails.item_id,
                              header_name: `${labDetails.item_name} invoice`,
                              is_taxable: false,
                              tax_exemption_id: labDetails.tax_exemption_id,
                              name: labDetails.name,
                              description: labDetails.description,
                              hsn_or_sac: labDetails.hsn_or_sac,
                              product_type: labDetails.product_type,
                              rate: labDetails.rate,
                              item_total: labDetails.item_total
                            });
                          }
                        });
                      });

                      let totalLabAmount = 0;

                      selectedProcedureArr = selectedProcedureArr.filter(
                        (arr, index, self) =>
                          index ===
                          self.findIndex(t => t.item_id === arr.item_id)
                      );

                      selectedProcedureCompleteArr = selectedProcedureCompleteArr.filter(
                        (arr, index, self) =>
                          index ===
                          self.findIndex(t => t.item_id === arr.item_id)
                      );

                      selectedProcedureCompleteArr.forEach(procedure => {
                        totalLabAmount = totalLabAmount + procedure.rate;
                      });

                      if (totalLabAmount > 0) {
                        updateTotalAmount(totalLabAmount);
                        updateLineItemsList(selectedProcedureArr);
                      } else {
                        updateTotalAmount();
                        updateLineItemsList();
                      }
                    }}
                  />
                </Col>
                <Col sm={2}>
                  {totalAmount > 0 ? (
                    <h6 style={{ fontWeight: 'bold', marginTop: 7 }}>
                      Amount: &#x20B9; {totalAmount}
                    </h6>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <DiscountInput
                discount={discount}
                setDiscount={setDiscount}
                discountType={discountType}
                setDiscountType={setDiscountType}
                discountNotes={discountNotes}
                setDiscountNotes={setDiscountNotes}
                totalAmount={totalAmount}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={5}
              lg={2}
              style={{
                display: 'flex',
                marginTop: 6
              }}
              className="mt-3"
            >
              <h6>
                Consulting doctor<span style={{ color: 'red' }}>*</span>:
              </h6>
            </Col>
            <Col xs={12} md={7} lg={10} className="mt-3">
              <Select
                placeholder="Select consulting doctor"
                value={
                  docListArr && docListArr.length === 1
                    ? docListArr[0]
                    : selectedConsultingDoctor
                }
                onChange={doc => {
                  updateSelectedConsultingDoctor(doc);
                }}
                options={docListArr || []}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Patient Details'}</CardHeader>
                <Collapse
                  isOpen={
                    totalAmount && parseFloat(totalAmount) > 0 ? true : false
                  }
                >
                  <CardBody>
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Search by mobile<div style={{ color: 'red' }}>*</div>:
                      </Col>
                      <Col xs={8}>
                        <SearchPatientByMobile
                          onChange={handleUserData}
                          onMobileNoChange={handleMobileNoChange}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    isPatientSelected &&
                    userCompleteDetails &&
                    totalAmount &&
                    parseFloat(totalAmount) > 0
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreateOtherServicesInvoiceLoader ||
                        otherServicesInvoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreateOtherServicesInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          if (
                            (!paymentData.transactionId ||
                              paymentData.transactionId.length < 5) &&
                            paymentData.paymentMode !== 'Cash'
                          ) {
                            displayError(
                              'Please provide a valid transaction id, minimum 5 digits !!!'
                            );
                            return;
                          } else {
                            showConfirmation(
                              'Create Other Services Invoice Confirmation',
                              'Are you sure you want to create other services invoice?',
                              () => createOtherServicesInvoice()
                            );
                          }
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewOtherServicesInvoice}>
            View Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OtherServicesBookingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedHospitalDetails: PropTypes.object,
  allDoctorDetails: PropTypes.array
};

export default OtherServicesBookingModal;
