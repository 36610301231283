import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { TV_DISPLAY_URL } from '../constants';

const DoctorSelectBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f8f8f8;
`;

const GeneratedLinkBox = styled.div`
  display: inline-block;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 5px 10px;
  word-wrap: break-word;
  max-width: 1000px;
`;

const DoctorName = styled.div`
  flex-grow: 1;
`;

const Heading = styled.div`
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const TVDisplayModal = props => {
  const { isOpen, toggle, allDoctorDetails, selectedHospitalDetails } = props;
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [generatedLink, setGeneratedLink] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTimer, setErrorTimer] = useState(null);

  const handleDoctorSelect = selectedDoctor => {
    if (selectedDoctors.some(doctor => doctor.id === selectedDoctor.id)) {
      return;
    } else {
      setSelectedDoctors([...selectedDoctors, selectedDoctor]);
    }
  };

  const handleDoctorDeSelect = selectedDoctor => {
    if (selectedDoctors.some(doctor => doctor.id === selectedDoctor.id)) {
      setSelectedDoctors(
        selectedDoctors.filter(doctor => doctor.id !== selectedDoctor.id)
      );
    }
  };

  const generateLink = () => {
    if (selectedDoctors.length === 0) {
      setErrorMessage('Select doctors to generate link');
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
      const timerId = setTimeout(() => {
        setErrorMessage('');
      }, 3000);

      setErrorTimer(timerId);
      return;
    }

    const hospitalId = selectedHospitalDetails.id;
    const entityIds = selectedDoctors.map(doctor => doctor.entityId).join(',');
    const link = `${TV_DISPLAY_URL}/hospId/${hospitalId}/entityId/${entityIds}`;
    setGeneratedLink(link);
    setErrorMessage('');
  };

  const selectAll = () => {
    if (selectedDoctors.length < allDoctorDetails.length) {
      setSelectedDoctors([...allDoctorDetails]);
    }
  };

  const deSelectAll = () => {
    if (selectedDoctors.length > 0) {
      setSelectedDoctors([]);
    }
  };

  const resetState = () => {
    setSelectedDoctors([]);
    setGeneratedLink('');
    setIsCopied(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" onClosed={resetState}>
      <ModalHeader toggle={toggle}>
        {selectedHospitalDetails && selectedHospitalDetails.name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className="card">
              <CardHeader onClick={selectAll}>
                <HeaderContainer>
                  <Heading>Select Doctors</Heading>
                  {selectedDoctors &&
                  allDoctorDetails &&
                  selectedDoctors.length === allDoctorDetails.length ? (
                    <span
                      role="img"
                      aria-label="selected"
                      style={{ color: 'green' }}
                    >
                      <FaCheck />
                    </span>
                  ) : null}
                </HeaderContainer>
              </CardHeader>
              <Collapse isOpen={true}>
                <CardBody>
                  {allDoctorDetails &&
                    allDoctorDetails.map(doctor => (
                      <DoctorSelectBox
                        key={doctor.id}
                        onClick={() => handleDoctorSelect(doctor)}
                      >
                        <DoctorName>
                          {doctor.name}
                          {' - '}
                          {doctor.primarySpecialty}
                        </DoctorName>
                        {selectedDoctors.some(
                          selectedDoctor => selectedDoctor.id === doctor.id
                        ) ? (
                          <span
                            role="img"
                            aria-label="selected"
                            style={{ color: 'green' }}
                          >
                            <FaCheck />
                          </span>
                        ) : null}
                      </DoctorSelectBox>
                    ))}
                </CardBody>
              </Collapse>
            </div>
          </Col>
          <Col>
            <div className="card">
              <CardHeader onClick={deSelectAll}>
                <HeaderContainer>
                  <Heading>Selected Doctors</Heading>
                  {selectedDoctors.length > 0 ? (
                    <span
                      role="img"
                      aria-label="deselect"
                      style={{ color: 'red' }}
                    >
                      <FaTimes />
                    </span>
                  ) : null}
                </HeaderContainer>
              </CardHeader>
              <Collapse isOpen={true}>
                <CardBody>
                  {selectedDoctors.map(doctor => (
                    <DoctorSelectBox
                      key={doctor.id}
                      onClick={() => handleDoctorDeSelect(doctor)}
                    >
                      <DoctorName>
                        {doctor.name} - {doctor.primarySpecialty}
                      </DoctorName>
                      <span
                        role="img"
                        aria-label="deselect"
                        style={{ color: 'red' }}
                      >
                        <FaTimes />
                      </span>
                    </DoctorSelectBox>
                  ))}
                </CardBody>
              </Collapse>
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <div className="mt-3 mb-2 d-flex justify-content-end ">
            <Button color="primary" onClick={generateLink}>
              Generate Link
            </Button>
          </div>
          {errorMessage && (
            <div className="text-danger mb-2 text-right">{errorMessage}</div>
          )}
          {generatedLink && (
            <div className="d-flex justify-content-end mt-2">
              <div className="d-flex align-items-center mr-3">
                <strong>Generated Link:</strong>
                <GeneratedLinkBox>{generatedLink}</GeneratedLinkBox>
              </div>
              <div className="mt-2">
                <Button
                  color="info"
                  className="ml-2"
                  onClick={() => {
                    navigator.clipboard.writeText(generatedLink);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 2000);
                  }}
                >
                  Copy
                </Button>
                {isCopied && (
                  <div className="text-success mt-2">Link copied!</div>
                )}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            toggle();
            resetState();
          }}
        >
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

TVDisplayModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  allDoctorDetails: PropTypes.array,
  selectedHospitalDetails: PropTypes.object
};

export default TVDisplayModal;
