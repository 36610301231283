import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Select, { components } from 'react-select';
import { DEFAULT_DOC_FEMALE, DEFAULT_DOC_MALE } from '../constants';
import { SEARCH_DOCTORS_DD } from '../queries';

const Option = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        <img
          alt={data.label}
          src={data.avatar}
          style={{ height: '30px', marginRight: '5px' }}
        />
        {data.label}
      </div>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object.isRequired
};

const SelectDoctors = ({
  isMulti,
  field,
  form,
  hospitalIds,
  departmentIds,
  languages,
  procedures,
  ...props
}) => {
  const value = field.value || [];
  return (
    <Query
      query={SEARCH_DOCTORS_DD}
      variables={{
        searchInput: { hospitalIds, departmentIds, languages, procedures }
      }}
      fetchPolicy="network-only"
    >
      {({ loading, data: { getDoctors } = {} }) => {
        let options = [];
        let selectedOpts = [];
        if (getDoctors) {
          options = getDoctors
            .filter(d => d.profileStatus === 'approved')
            .map(d => {
              let avatar = d.photos && d.photos.dp300 && d.photos.dp300.url;
              if (!avatar && d.gender) {
                if (d.gender === 'Male') {
                  avatar = DEFAULT_DOC_MALE;
                }
              }
              avatar = avatar || DEFAULT_DOC_FEMALE;
              return { label: d.name, value: d.id, avatar };
            });
          selectedOpts = Array.isArray(value)
            ? value.map(v => options.find(o => o.value === v))
            : options.find(o => o.value === value);
        }
        return (
          <Select
            {...props}
            isMulti={isMulti}
            value={selectedOpts}
            options={options}
            isLoading={loading}
            onChange={v =>
              form.setFieldValue(
                field.name,
                isMulti ? v.map(vv => vv.value) : v.value
              )
            }
            components={{ Option }}
          />
        );
      }}
    </Query>
  );
};

SelectDoctors.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number),
  field: PropTypes.object,
  form: PropTypes.object,
  hospitalIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  departmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string).isRequired,
  procedures: PropTypes.arrayOf(PropTypes.string).isRequired
};

SelectDoctors.defaultProps = {
  isMulti: false,
  value: []
};

export default SelectDoctors;
