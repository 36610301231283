import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-datepicker';
import moment from 'moment';
const TimeSelector = ({
  form,
  field,
  timeInterval,
  caption,
  onBlur,
  ...props
}) => {
  const selected = field.value ? moment(field.value, 'HHmm') : undefined;
  return (
    <TimePicker
      {...props}
      selected={selected}
      onChange={v => form.setFieldValue(field.name, v)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeInterval}
      dateFormat="h:mm a"
      timeCaption={caption}
      onBlur={() => {
        onBlur(field.name, true);
      }}
    />
  );
};

TimeSelector.propTypes = {
  timeInterval: PropTypes.number,
  caption: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  onBlur: PropTypes.func
};

TimeSelector.defaultProps = {
  timeInterval: 5,
  value: moment(),
  caption: 'Time',
  onBlur: () => {}
};

export default TimeSelector;
