import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const Heading = styled.div`
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ShowCreatedCouponModal = props => {
  const { createdCoupons, showModal, toggle } = props;

  const handleCopy = couponcode => {
    navigator.clipboard.writeText(couponcode);
    toast.info('Coupon Copied!', { autoClose: 2000 });
  };

  return (
    <Modal isOpen={showModal} toggle={toggle} size="l">
      <ModalHeader toggle={toggle}>
        Successfully generated coupon code
      </ModalHeader>
      <ModalBody>
        {createdCoupons &&
          createdCoupons.map(coupon => {
            return (
              <Row key={coupon}>
                <Col>
                  <CardHeader>
                    <HeaderContainer>
                      <Heading>
                        Coupon code:{' '}
                        {coupon && coupon.couponcode ? coupon.couponcode : 'NA'}
                      </Heading>
                    </HeaderContainer>
                  </CardHeader>
                </Col>
                <Col>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleCopy(coupon.couponcode);
                    }}
                    style={{ marginTop: '5px' }}
                  >
                    Copy Coupon
                  </Button>
                </Col>
              </Row>
            );
          })}
        <br />
        <div>
          {createdCoupons &&
            createdCoupons.length === 1 &&
            'NOTE: This coupon code can be used only once for the mpid'}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            toggle();
          }}
        >
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShowCreatedCouponModal.propTypes = {
  createdCoupons: PropTypes.arrayOf(PropTypes.object), //can contain mulitple based on MPID coupon
  showModal: PropTypes.bool,
  toggle: PropTypes.func
};

export default ShowCreatedCouponModal;
