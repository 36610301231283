import React, { useState, useEffect } from 'react';
import { JsonToTable } from 'react-json-to-table';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import {
  CX_SERVER_BASE_URL,
  CLOUD_FUNCTIONS_BASE_URL,
  CX_USER_DB_BASE_URL,
  S2S_CX_SERVER_API_KEY,
  S2S_C9USERMORE_API_KEY
} from '../constants';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { FaRegTimesCircle } from 'react-icons/fa';
import { logAmplitudeEvent } from '../utilities';

const TABS = {
  PRESCRIPTIONS: 1,
  LAB_REPORTS: 2,
  SCAN_REPORTS: 3,
  UPLOADED_FILES: 4,
  MEDICAL_HISTORY: 5
};

const CustomerRxModal = ({ userMobile, userMPID, isOpen, toggle }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.PRESCRIPTIONS);
  const [pastPrescriptionHistory, setPastPrescriptionHistory] = useState([]);
  const [labReportHistory, setLabReportHistory] = useState([]);
  const [scanReportHistory, setScanReportHistory] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loaderIcon, setLoaderIcon] = useState(true);
  const [buttonLoaderIcon, setButtonLoaderIcon] = useState(false);
  const [indexValue, setIndexValue] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medicalHisNoData, setMedicalHisNoData] = useState(false);
  const [medicalHisError, setMedicalHisError] = useState(false);

  useEffect(() => {
    getLTAppointmentHistory(userMPID);
    setSelectedTab(TABS.PRESCRIPTIONS);
    setPastPrescriptionHistory([]);
    setLabReportHistory([]);
    setScanReportHistory([]);
    setUploadedFiles([]);
    setLoaderIcon(true);
    setButtonLoaderIcon(false);
    setIndexValue(null);
    setMedicalHistory([]);
    setMedicalHisNoData(false);
    setMedicalHisError(false);
    if (isOpen) {
      logAmplitudeEvent(
        'Customer_Rx_Icon_Pressed',
        {
          userMobile: userMobile,
          userMPID: userMPID,
          selectedTab: 'PRESCRIPTIONS'
        },
        true
      );
    }
    // eslint-disable-next-line
  }, [userMobile, userMPID]);

  // function to fetch customer's prescription details.
  const fetchPrescription = (
    mpid,
    ltappointmentid,
    sitecode,
    docLTId,
    appointment_date
  ) => {
    const url = `${CX_SERVER_BASE_URL}/soap/pdf/get`;

    const req_body = {
      patientMpi: mpid,
      appointmentId: ltappointmentid,
      siteCode: sitecode,
      docLTId: docLTId,
      appointment_date: appointment_date
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to handle view prescription button press, on trigger, button loader icon is shown until fetchPrescription response is received.
  const handleOnPressViewPrescription = async (appointment, index) => {
    const prescriptionTab = window.open('', '_blank');
    prescriptionTab.document.write('Loading...');
    setIndexValue(index);
    setButtonLoaderIcon(true);
    const appointment_date = moment(
      appointment.appointment_date,
      'DD-MM-YYYY'
    ).format('YYYY-MM-DD');
    const appointment_date_UTC = `${appointment_date}T00:00:00Z`;
    const { mpi, appointment_id, site_code, consultant_id } = appointment;

    try {
      const response = await fetchPrescription(
        mpi,
        appointment_id,
        site_code,
        consultant_id,
        appointment_date_UTC
      );

      // if prescription is available, it will open in a new tab of browser.
      if (response && response.status && response.msg) {
        // const prescriptionUrl = response.msg;
        prescriptionTab.location.href = response.msg;
        // window.open(prescriptionUrl, '_blank');
        setButtonLoaderIcon(false);
      } else {
        if (
          response &&
          response.status === 1 &&
          response.data &&
          response.data.ltReceiptId
        ) {
          // const prescriptionUrl = response.data.ltReceiptId;
          // window.open(prescriptionUrl, '_blank');
          prescriptionTab.location.href = response.data.ltReceiptId;
          setButtonLoaderIcon(false);
        }

        // if prescription is not available, 'No Prescription Available' toast message will trigger.
        else {
          errorMessage('No Prescription Available');
          setButtonLoaderIcon(false);
        }
      }
    } catch (error) {
      setButtonLoaderIcon(false);
    }
  };

  // function to fetch customer's appointment details.
  const fetchLTAppointmentHistory = userMPID => {
    const url = `${CLOUD_FUNCTIONS_BASE_URL}/LTHistoryDetails`;

    const req_body = {
      body: {
        mpi: userMPID,
        siteCode: 'C9-OAR'
      }
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and sort all appointments based on appointment date and store this information in state to show it to the users.
  const getLTAppointmentHistory = async userMPID => {
    setLoaderIcon(true);
    try {
      const response = await fetchLTAppointmentHistory(userMPID);
      if (response && response.head && response.head.StatusValue === 200) {
        const allPastAppointments = response.body.Data[0].appointmentId
          .filter(
            appointment =>
              appointment.status.toLowerCase() === 'arrived' ||
              appointment.status.toLowerCase() === 'seen' ||
              appointment.status.toLowerCase() === 'consulting' ||
              appointment.status.toLowerCase() === 'nursing'
          )
          .map(appointment => ({ ...appointment, mpi: userMPID }));
        allPastAppointments.sort(
          (itema, itemb) =>
            moment(
              itemb.appointment_date + ' ' + itemb.appointment_time,
              'DD-MM-YYYY HH:mm'
            ) -
            moment(
              itema.appointment_date + ' ' + itema.appointment_time,
              'DD-MM-YYYY HH:mm'
            )
        );
        setPastPrescriptionHistory(allPastAppointments);
        setLoaderIcon(false);
      } else if (response) {
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view lab report button press.
  const handleOnPressLabReport = async (report, index) => {
    setIndexValue(index);
    setButtonLoaderIcon(true);
    const { REPORT_URL } = report;

    // if lab report is available, it will open in a new tab of browser.
    if (REPORT_URL) {
      window.open(REPORT_URL, '_blank');
      setButtonLoaderIcon(false);
    }

    // if lab report is not available, 'No Lab Report Available' toast message will trigger.
    else {
      setButtonLoaderIcon(false);
      errorMessage('No Lab Report Available');
    }
  };

  // function to fetch customer's lab report details.
  const fetchLabHistory = (mpid, mobile) => {
    const url = `${CX_SERVER_BASE_URL}/qkams/get/lab/report?phone=${mobile}&mpid=${mpid}`;

    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': 'e564ae0fe9834ae8176d1c844e12da83',
        'Access-Token': 'MWZNY9ZPMLCSOLD6UKTH',
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      }
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and sort all lab reports based on sample collected on date and store this information in state to show it to the users.
  const getLabReportHistory = async (mpid, mobile) => {
    setLoaderIcon(true);
    try {
      const response = await fetchLabHistory(mpid, mobile);
      if (
        response &&
        response.head &&
        response.head.StatusValue === 200 &&
        response.body &&
        response.body.Data &&
        response.body.Data[0].SAMPLE_COLLECTED_ON
      ) {
        const allLabReports = response.body.Data;
        allLabReports.sort(
          (itema, itemb) =>
            moment(itemb['SAMPLE_COLLECTED_ON'], 'DD-MM-YYYY HH:mm') -
            moment(itema['SAMPLE_COLLECTED_ON'], 'DD-MM-YYYY HH:mm')
        );
        setLabReportHistory(allLabReports);
        setLoaderIcon(false);
      } else if (response) {
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view scan report button press.
  const handleOnPressScanReport = async (report, index) => {
    setIndexValue(index);
    setButtonLoaderIcon(true);
    const { updatedPdflink } = report;

    // if scan report is available, it will open in a new tab of browser.
    if (updatedPdflink) {
      window.open(updatedPdflink, '_blank');
      setButtonLoaderIcon(false);
    }

    // if scan report is not available, 'No Scan Report Available' toast message will trigger.
    else {
      setButtonLoaderIcon(false);
      errorMessage('No Scan Report Available');
    }
  };

  // function to fetch customer's scan report details.
  const fetchScanHistory = mpid => {
    const url = `${CX_SERVER_BASE_URL}/qkams/get/scan/reports?mpid=${mpid}`;

    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': '56489a9bfdf45j495o95n4503jn',
        'Access-Token': 'MGDGGDFREFCVHFDGHHF9',
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      }
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and sort all scan reports based on visit date and store this information in state to show it to the users.
  const getScanReportHistory = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await fetchScanHistory(mpid);

      if (response && response.status) {
        const allScanReports = response.data;
        allScanReports.sort(
          (itema, itemb) =>
            moment(itemb['VisitDate'], 'DD/MM/YYYY hh:mm:ss A') -
            moment(itema['VisitDate'], 'DD/MM/YYYY hh:mm:ss A')
        );
        setScanReportHistory(allScanReports);
        setLoaderIcon(false);
      } else if (response) {
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to handle view uploaded file button press.
  const handleOnPressUploadedFile = async (file, index) => {
    setIndexValue(index);
    setButtonLoaderIcon(true);
    const { s3filelink } = file;

    // if uploaded file is available, it will open in a new tab of browser.
    if (s3filelink) {
      window.open(s3filelink, '_blank');
      setButtonLoaderIcon(false);
    }

    // if uploaded file is not available, 'File Not Available' toast message will trigger.
    else {
      setButtonLoaderIcon(false);
      errorMessage('File Not Available');
    }
  };

  // function to fetch customer's uploaded file details.
  const fetchUploadedFiles = mpid => {
    const url = `${CX_USER_DB_BASE_URL}/videoconsultation/getfile/${mpid}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    }).then(response => response.json());
  };

  // function to fetch and sort all uploaded files based on updated at date and store this information in state to show it to the users.
  const getUploadedFilesDetails = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await fetchUploadedFiles(mpid);
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data &&
        response.data.length
      ) {
        const allUploadedFiles = response.data;
        allUploadedFiles.sort(
          (itema, itemb) =>
            moment(itemb['updated_at'], 'DD/MM/YYYY hh:mm:ss A') -
            moment(itema['updated_at'], 'DD/MM/YYYY hh:mm:ss A')
        );
        let uploadedFilesArray = [];

        // reformatting the sorted data
        for (let i = allUploadedFiles.length - 1; i > -1; i--) {
          uploadedFilesArray.push({
            filename: allUploadedFiles[i].filename,
            updated_at: moment(allUploadedFiles[i].updated_at).format(
              'DD/MM/YYYY HH:mm'
            ),
            s3filelink: allUploadedFiles[i].s3filelink
          });
        }
        setUploadedFiles(uploadedFilesArray);
        setLoaderIcon(false);
      } else if (response) {
        setLoaderIcon(false);
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  // function to show custom toast message with timeout.
  const errorMessage = message => {
    const messageClose = {};
    messageClose.autoClose = 3000;

    toast.error(
      <span style={{ color: 'white' }}>
        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          <FaRegTimesCircle />
        </span>
        {message}
      </span>,
      messageClose
    );
  };

  // function to fetch Medical History from UserDB
  const fetchMedicalHistory = mpid => {
    const url = `${CX_USER_DB_BASE_URL}/mpid/v2/${mpid}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    }).then(response => response.json());
  };

  // function to fetch the medical history
  const getMedicalHistory = async mpid => {
    setLoaderIcon(true);
    setMedicalHisNoData(false);
    setMedicalHisError(false);
    try {
      const response = await fetchMedicalHistory(mpid);
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data &&
        response.data.length
      ) {
        let data = response.data[0];
        let maternityRecord = data['maternityrecord'] || {};
        if (maternityRecord) {
          //If Received the response without the required fields
          setMedicalHisNoData(true);
          if (maternityRecord.maternity) {
            if (maternityRecord.maternity.vaccination) {
              let vacObj = maternityRecord.maternity.vaccination;
              delete maternityRecord.maternity['vaccination'];
              //To sort the vaccination
              maternityRecord.maternity['vaccination'] = Object.fromEntries(
                Object.entries(vacObj).sort(([, a], [, b]) =>
                  a.localeCompare(b)
                )
              );
              setMedicalHisNoData(false);
            }
            if (
              maternityRecord.maternity.investigations &&
              maternityRecord.maternity.investigations.length
            ) {
              let invObj = [];
              maternityRecord.maternity.investigations.forEach(iObj => {
                //To add measuring units to some fields
                //PlateletCount - To get comma separated value
                if (Object.entries(iObj).length) {
                  //To handle [{}] this case
                  let tempObj = {
                    Date: iObj.Date || ' ',
                    'Hb (g/dl)': iObj.Hb || ' ',
                    'TSH (mIU/L)': iObj.TSH || ' ',
                    'OGCT (mg/dl)': iObj.OGCT || ' ',
                    'PlateletCount (k/uL)': iObj.PlateletCount
                      ? parseInt(iObj.PlateletCount).toLocaleString('en-IN')
                      : ' ',
                    'HIV I & II': iObj['HIV I & II'] || ' '
                  };
                  delete iObj.Date;
                  delete iObj.Hb;
                  delete iObj.TSH;
                  delete iObj.OGCT;
                  delete iObj.PlateletCount;
                  delete iObj['HIV I & II'];
                  invObj.push(Object.assign(tempObj, iObj));
                }
              });
              delete maternityRecord.maternity.investigations;
              maternityRecord.maternity.investigations = invObj;
              setMedicalHisNoData(false);
            }
            if (maternityRecord.maternity.pastMedicalHistory) {
              if (maternityRecord.maternity.pastMedicalHistory.length) {
                //To get Comma separated value
                let temp = maternityRecord.maternity.pastMedicalHistory.join(
                  ', '
                );
                delete maternityRecord.maternity.pastMedicalHistory;
                maternityRecord.maternity.pastMedicalHistory = temp;
                setMedicalHisNoData(false);
              } else {
                //To handle []
                delete maternityRecord.maternity.pastMedicalHistory;
              }
            }
            if (maternityRecord.maternity.familyHistory) {
              //To get Comma separated value
              for (const [fKey, fValue] of Object.entries(
                maternityRecord.maternity.familyHistory
              )) {
                delete maternityRecord.maternity.familyHistory[fKey];
                if (typeof fValue === 'string') {
                  maternityRecord.maternity.familyHistory[fKey] = fValue;
                } else {
                  maternityRecord.maternity.familyHistory[fKey] = fValue
                    ? fValue.join(', ')
                    : ' ';
                }
              }
            }
            if (maternityRecord.maternity.allergies) {
              //To get Comma separated value
              for (const [aKey, aValue] of Object.entries(
                maternityRecord.maternity.allergies
              )) {
                delete maternityRecord.maternity.allergies[aKey];
                if (typeof aValue === 'string') {
                  maternityRecord.maternity.allergies[aKey] = aValue;
                } else {
                  maternityRecord.maternity.allergies[aKey] = aValue
                    ? aValue.join(', ')
                    : ' ';
                }
              }
            }
            if (maternityRecord.maternity.obsHistory) {
              if (maternityRecord.maternity.obsHistory.length) {
                if (
                  Object.keys(maternityRecord.maternity.obsHistory[0])
                    .length === 0
                ) {
                  delete maternityRecord.maternity.obsHistory; //To handle [{}]
                }
              }
            }
          }
          if (maternityRecord.parity) {
            const isNoData = Object.values(maternityRecord.parity).every(
              value => value === null || `${value}`.trim() === ''
            );
            if (isNoData) {
              //For ex - parity:{a:"",e:""....}
              // render no data available
              setMedicalHisNoData(true);
              delete maternityRecord.parity;
            } else {
              setMedicalHisNoData(false);
            }
          }
        } else {
          //Received the response with no 'maternityrecord' key
          setMedicalHisNoData(true);
        }
        setMedicalHistory(maternityRecord);
        setLoaderIcon(false);
        //Received the response with no error
        setMedicalHisError(false);
      } else if (response && response.status === 0) {
        setLoaderIcon(false);
        //Upon receiving the response with no data
        setMedicalHisNoData(true);
        setMedicalHisError(false);
      } else {
        setLoaderIcon(false);
        setMedicalHisNoData(false);
        setMedicalHisError(true);
      }
    } catch (error) {
      setLoaderIcon(false);
      setMedicalHisNoData(false);
      setMedicalHisError(true);
    }
  };
  return (
    <div>
      <Modal scrollable={true} size="lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Patient&#39;s Prescription Details
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.PRESCRIPTIONS
                })}
                onClick={() => {
                  setSelectedTab(TABS.PRESCRIPTIONS);
                  getLTAppointmentHistory(userMPID);
                  logAmplitudeEvent(
                    'Customer_Rx_Icon_Pressed',
                    {
                      userMobile: userMobile,
                      userMPID: userMPID,
                      selectedTab: 'PRESCRIPTIONS'
                    },
                    true
                  );
                }}
              >
                PRESCRIPTIONS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.LAB_REPORTS
                })}
                onClick={() => {
                  setSelectedTab(TABS.LAB_REPORTS);
                  getLabReportHistory(userMPID, userMobile);
                  logAmplitudeEvent(
                    'Customer_Rx_Icon_Pressed',
                    {
                      userMobile: userMobile,
                      userMPID: userMPID,
                      selectedTab: 'LAB REPORTS'
                    },
                    true
                  );
                }}
              >
                LAB REPORTS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.SCAN_REPORTS
                })}
                onClick={() => {
                  setSelectedTab(TABS.SCAN_REPORTS);
                  getScanReportHistory(userMPID);
                  logAmplitudeEvent(
                    'Customer_Rx_Icon_Pressed',
                    {
                      userMobile: userMobile,
                      userMPID: userMPID,
                      selectedTab: 'SCAN REPORTS'
                    },
                    true
                  );
                }}
              >
                SCAN REPORTS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.UPLOADED_FILES
                })}
                onClick={() => {
                  setSelectedTab(TABS.UPLOADED_FILES);
                  getUploadedFilesDetails(userMPID);
                  logAmplitudeEvent(
                    'Customer_Rx_Icon_Pressed',
                    {
                      userMobile: userMobile,
                      userMPID: userMPID,
                      selectedTab: 'UPLOADED FILES'
                    },
                    true
                  );
                }}
              >
                UPLOADED FILES
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.MEDICAL_HISTORY
                })}
                onClick={() => {
                  setSelectedTab(TABS.MEDICAL_HISTORY);
                  getMedicalHistory(userMPID);
                  logAmplitudeEvent(
                    'Customer_Rx_Icon_Pressed',
                    {
                      userMobile: userMobile,
                      userMPID: userMPID,
                      selectedTab: 'MEDICAL HISTORY'
                    },
                    true
                  );
                }}
              >
                MEDICAL HISTORY
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={selectedTab}>
            <TabPane tabId={TABS.PRESCRIPTIONS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.PRESCRIPTIONS &&
                (pastPrescriptionHistory.length ? (
                  pastPrescriptionHistory.map((appointment, index) => {
                    const { consultant_name, appointment_date } = appointment;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {consultant_name ? (
                              <div>{consultant_name}</div>
                            ) : null}
                            {appointment_date ? (
                              <div>{appointment_date}</div>
                            ) : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW PRESCRIPTION"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressViewPrescription(
                                    appointment,
                                    index
                                  );
                                }}
                              >
                                VIEW PRESCRIPTION
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Prescription Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.LAB_REPORTS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.LAB_REPORTS &&
                (labReportHistory.length ? (
                  labReportHistory.map((report, index) => {
                    const { SAMPLE_COLLECTED_ON, ITEM_NAME } = report;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {ITEM_NAME ? <div>{ITEM_NAME}</div> : null}
                            {SAMPLE_COLLECTED_ON ? (
                              <div>
                                Sample Collected on - {SAMPLE_COLLECTED_ON}
                              </div>
                            ) : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW REPORT"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressLabReport(report);
                                }}
                              >
                                VIEW REPORT
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Lab Report Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.SCAN_REPORTS}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.SCAN_REPORTS &&
                (scanReportHistory.length ? (
                  scanReportHistory.map((report, index) => {
                    const { VisitDate, ScanDoneBy } = report;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {VisitDate ? (
                              <div>Done on - {VisitDate}</div>
                            ) : null}
                            {ScanDoneBy ? <div>by {ScanDoneBy}</div> : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW REPORT"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressScanReport(report);
                                }}
                              >
                                VIEW REPORT
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Scan Report Available</p>
                  </div>
                ))
              )}
            </TabPane>

            <TabPane tabId={TABS.UPLOADED_FILES}>
              {loaderIcon ? (
                <Loader />
              ) : (
                selectedTab === TABS.UPLOADED_FILES &&
                (uploadedFiles.length ? (
                  uploadedFiles.map((file, index) => {
                    const { updated_at, filename } = file;
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 13
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              flexDirection: 'column'
                            }}
                          >
                            {filename ? <div>{filename}</div> : null}
                            {updated_at ? <div>{updated_at}</div> : null}
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            {buttonLoaderIcon && index === indexValue ? (
                              <div style={{ marginRight: 60 }}>
                                <Loader size={5} />
                              </div>
                            ) : (
                              <Button
                                size="sm"
                                className="patient-badge-action-button"
                                color="primary"
                                title="VIEW FILE"
                                style={{ textAlign: 'right' }}
                                onClick={() => {
                                  handleOnPressUploadedFile(file);
                                }}
                              >
                                VIEW FILE
                              </Button>
                            )}
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: 13
                    }}
                  >
                    <p>No Uploaded File Available</p>
                  </div>
                ))
              )}
            </TabPane>
            <TabPane tabId={TABS.MEDICAL_HISTORY}>
              {loaderIcon ? (
                <Loader />
              ) : (
                <div style={{ paddingTop: '1vw' }}>
                  {medicalHistory.parity ? (
                    <div>
                      {medicalHistory.parity.a ||
                      medicalHistory.parity.e ||
                      medicalHistory.parity.g ||
                      medicalHistory.parity.l ||
                      medicalHistory.parity.p ||
                      medicalHistory.parity.ml ||
                      medicalHistory.parity.mlm ? (
                        <div>
                          <div>
                            <strong>Parity</strong>
                          </div>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {medicalHistory.parity.a ? (
                              <div style={{ width: '10%' }}>
                                A: {medicalHistory.parity.a}
                              </div>
                            ) : null}
                            {medicalHistory.parity.e ? (
                              <div style={{ width: '10%' }}>
                                E: {medicalHistory.parity.e}
                              </div>
                            ) : null}
                            {medicalHistory.parity.g ? (
                              <div style={{ width: '10%' }}>
                                G: {medicalHistory.parity.g}
                              </div>
                            ) : null}
                            {medicalHistory.parity.l ? (
                              <div style={{ width: '10%' }}>
                                L: {medicalHistory.parity.l}
                              </div>
                            ) : null}
                            {medicalHistory.parity.p ? (
                              <div style={{ width: '10%' }}>
                                P: {medicalHistory.parity.p}
                              </div>
                            ) : null}
                            {medicalHistory.parity.ml ? (
                              <div style={{ width: '15%' }}>
                                ML(y): {medicalHistory.parity.ml}
                              </div>
                            ) : null}
                            {medicalHistory.parity.mlm ? (
                              <div style={{ width: '10%' }}>
                                ML(m): {medicalHistory.parity.mlm}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      <hr />
                    </div>
                  ) : null}
                  {medicalHistory.maternity ? (
                    <div>
                      {medicalHistory.maternity.obsHistory ? (
                        <div>
                          {medicalHistory.maternity.obsHistory.length ? (
                            <div>
                              <strong>OBS History:</strong>
                              {medicalHistory.maternity.obsHistory.map(
                                (obsHistObj, obsKey) => {
                                  const {
                                    year,
                                    modeOfDelivery,
                                    birthWeight,
                                    gender,
                                    comments,
                                    deliveryOutcome,
                                    outcome,
                                    age
                                  } = obsHistObj;
                                  return (
                                    <div
                                      key={obsKey}
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                      }}
                                    >
                                      {year ? (
                                        <div style={{ width: '50%' }}>
                                          Pregnancy Year: {year}
                                        </div>
                                      ) : null}
                                      {modeOfDelivery ? (
                                        <div style={{ width: '50%' }}>
                                          Mode of Delivery: {modeOfDelivery}
                                        </div>
                                      ) : null}
                                      {birthWeight ? (
                                        <div style={{ width: '50%' }}>
                                          Baby Birth Weight(kgs): {birthWeight}
                                        </div>
                                      ) : null}
                                      {gender ? (
                                        <div style={{ width: '50%' }}>
                                          Baby Gender: {gender}
                                        </div>
                                      ) : null}
                                      {deliveryOutcome ? (
                                        <div style={{ width: '50%' }}>
                                          Delivery Outcome: {deliveryOutcome}
                                        </div>
                                      ) : null}
                                      {outcome ? (
                                        <div style={{ width: '50%' }}>
                                          Outcome: {outcome}
                                        </div>
                                      ) : null}
                                      {age ? (
                                        <div style={{ width: '50%' }}>
                                          Baby Age(years): {age}
                                        </div>
                                      ) : null}
                                      {comments ? (
                                        <div style={{ width: '50%' }}>
                                          Comments: {comments}
                                        </div>
                                      ) : null}
                                      <hr />
                                    </div>
                                  );
                                }
                              )}
                              <hr />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {medicalHistory.maternity.familyHistory ? (
                        <div>
                          {Object.entries(
                            medicalHistory.maternity.familyHistory
                          ).length ? (
                            <div>
                              <strong>Family History:</strong>
                              {Object.entries(
                                medicalHistory.maternity.familyHistory
                              ).map(([key, val], index) => {
                                return (
                                  <div key={index}>
                                    {key} - {val}
                                  </div>
                                );
                              })}
                              <hr />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {medicalHistory.maternity.pastMedicalHistory ? (
                        <div>
                          <strong>Past Medical History:</strong>
                          <span>
                            {' '}
                            {medicalHistory.maternity.pastMedicalHistory}
                          </span>
                          <hr />
                        </div>
                      ) : null}
                      {medicalHistory.maternity.cycleDuration ||
                      medicalHistory.maternity.durationFlow ? (
                        <div>
                          <div>
                            <strong>Menstrual History:</strong>
                          </div>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {medicalHistory.maternity.cycleDuration ? (
                              <div style={{ width: '50%' }}>
                                Cycle Duration(Days):{' '}
                                {medicalHistory.maternity.cycleDuration}
                              </div>
                            ) : null}
                            {medicalHistory.maternity.durationFlow ? (
                              <div style={{ width: '50%' }}>
                                Duration of Flow(Days):{' '}
                                {medicalHistory.maternity.durationFlow}
                              </div>
                            ) : null}
                          </div>
                          <hr />
                        </div>
                      ) : null}
                      {medicalHistory.maternity.allergies ? (
                        <div>
                          {Object.entries(medicalHistory.maternity.allergies)
                            .length ? (
                            <div>
                              <strong>Allergies:</strong>
                              {Object.entries(
                                medicalHistory.maternity.allergies
                              ).map(([allergyKey, allergyVal], index) => {
                                return (
                                  <div key={index}>
                                    {allergyKey} - {allergyVal}
                                  </div>
                                );
                              })}
                              <hr />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {medicalHistory.maternity.pallor ||
                      medicalHistory.maternity.breasts ||
                      medicalHistory.maternity.thyroid ||
                      medicalHistory.maternity.rs ||
                      medicalHistory.maternity.cvs ? (
                        <div>
                          <div>
                            <strong>General Examination:</strong>
                          </div>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {medicalHistory.maternity.pallor ? (
                              <div style={{ width: '50%' }}>
                                Pallor: {medicalHistory.maternity.pallor}
                              </div>
                            ) : null}
                            {medicalHistory.maternity.breasts ? (
                              <div style={{ width: '50%' }}>
                                Breasts: {medicalHistory.maternity.breasts}
                              </div>
                            ) : null}
                            {medicalHistory.maternity.thyroid ? (
                              <div style={{ width: '50%' }}>
                                Thyroid: {medicalHistory.maternity.thyroid}
                              </div>
                            ) : null}
                            {medicalHistory.maternity.rs ? (
                              <div style={{ width: '50%' }}>
                                RS: {medicalHistory.maternity.rs}
                              </div>
                            ) : null}
                            {medicalHistory.maternity.cvs ? (
                              <div style={{ width: '50%' }}>
                                CVS: {medicalHistory.maternity.cvs}
                              </div>
                            ) : null}
                          </div>
                          <hr />
                        </div>
                      ) : null}
                      {medicalHistory.maternity.investigations ? (
                        <div>
                          {medicalHistory.maternity.investigations.length ? (
                            <div>
                              <strong>Investigation Findings:</strong>
                              <JsonToTable
                                json={medicalHistory.maternity.investigations}
                              />
                              <hr />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {medicalHistory.maternity.vaccination ? (
                        <div>
                          {Object.entries(medicalHistory.maternity.vaccination)
                            .length ? (
                            <div>
                              <strong>Vaccination:</strong>
                              {Object.entries(
                                medicalHistory.maternity.vaccination
                              ).map(
                                ([vaccinationKey, vaccinationVal], index) => {
                                  return (
                                    <div key={index}>
                                      {vaccinationKey} - {vaccinationVal}
                                    </div>
                                  );
                                }
                              )}
                              <hr />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
              {medicalHisNoData ? (
                <div className="customerRxModalNotfn">
                  <p>No Data Available</p>
                </div>
              ) : null}
              {medicalHisError ? (
                <div className="customerRxModalNotfn">
                  <p>
                    Sorry something went wrong, please contact support admin
                  </p>
                </div>
              ) : null}
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  );
};

CustomerRxModal.propTypes = {
  userMobile: PropTypes.string.isRequired,
  userMPID: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default CustomerRxModal;
