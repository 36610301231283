import React, { useState } from 'react';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';

import classnames from 'classnames';
import BulkDoctorUpload from '../components/BulkDoctorUpload';
import UploadDoctorMetadata from '../components/UploadDoctorMetadata';
import UploadQWBookings from '../components/UploadQWBookings';
import UploadLtSyncData from '../components/UploadLtSyncData';
import UploadAdminUsers from '../components/UploadAdminUsers';
import AuthRequired from '../components/AuthRequired';

const DataLoad = () => {
  const [activeTab, setActiveTab] = useState('1');
  return (
    <AuthRequired pageName="loader">
      <div style={{ margin: '1rem' }}>
        <h1>Temporary Bulk Dataloader</h1>
        <Nav tabs style={{ marginBottom: '1rem' }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                setActiveTab('1');
              }}
            >
              Create Update Doctor
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                setActiveTab('2');
              }}
            >
              Doctor Metadata
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                setActiveTab('3');
              }}
            >
              QW Bookings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                setActiveTab('4');
              }}
            >
              Load LT Data
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                setActiveTab('5');
              }}
            >
              CREATE USERS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                setActiveTab('6');
              }}
            >
              QW DATA DUMP
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <BulkDoctorUpload />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <UploadDoctorMetadata />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <UploadQWBookings />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <UploadLtSyncData />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <UploadAdminUsers />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="6">
            <Row>
              <Col sm="12">
                <UploadQwDataDump />
              </Col>
            </Row>
          </TabPane> */}
        </TabContent>
      </div>
    </AuthRequired>
  );
};

DataLoad.propTypes = {};

export default DataLoad;
