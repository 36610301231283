import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import AddOrEditSiteCode from './AddOrEditSiteCode';
import { Modal } from 'reactstrap';
import { useQuery } from 'react-apollo';
import Select from 'react-select';
import { GET_ALL_SITECODES } from '../queries';

const SiteCodeTab = () => {
  const {
    data: { siteCodes } = [],
    loading: citiesLoading
    // error: citiesError
  } = useQuery(GET_ALL_SITECODES);

  const [selectedSiteCode, setSelectedSiteCode] = useState('');
  const [newSiteCodeModalOpen, setNewSiteCodeModalOpen] = useState(false);

  const handleSiteCodeChange = siteCode => {
    setSelectedSiteCode(siteCode);
  };

  const toggleNewSiteCodeModalState = () => {
    setNewSiteCodeModalOpen(ps => !ps);
  };

  const getSiteCodeOpts = () => {
    return !isEmpty(siteCodes)
      ? siteCodes.map(sitecode => ({
          value: sitecode.id,
          label: sitecode.sitecode
        }))
      : [];
  };

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0">SiteCode</h2>
        <button
          className="mb-0 btn btn-link"
          onClick={toggleNewSiteCodeModalState}
        >
          + New SiteCode
        </button>
      </div>
      <div
        className="mt-3"
        style={{
          maxWidth: '30rem'
        }}
      >
        <Select
          value={selectedSiteCode}
          options={getSiteCodeOpts()}
          isLoading={citiesLoading}
          onChange={handleSiteCodeChange}
        />
      </div>
      {isEmpty(selectedSiteCode) ? (
        <p className="mb-0">Please select a siteCode to view / edit.</p>
      ) : (
        <div className="mt-5">
          <AddOrEditSiteCode mode="edit" sitecode={selectedSiteCode} />
        </div>
      )}
      <Modal isOpen={newSiteCodeModalOpen} toggle={toggleNewSiteCodeModalState}>
        <div className="p-4">
          <AddOrEditSiteCode
            mode="add"
            handleClose={toggleNewSiteCodeModalState}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SiteCodeTab;
