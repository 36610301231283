import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Query, useQuery } from 'react-apollo';
import { FaUserMd } from 'react-icons/fa';
import { FixedSizeList as List } from 'react-window';
import { GET_LOGGED_IN_USER } from '../queries';
import { displayError } from '../utilities';
import DocBadge from './DocBadge';
import Loader from './Loader';

const RenderDoctorList = props => {
  const { query, variables, filterFunction, onDoctorClick } = props;
  const {
    data: { me }
  } = useQuery(GET_LOGGED_IN_USER);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Query
      query={query}
      variables={variables}
      fetchPolicy="network-only"
      pollInterval={isVisible ? 30000 : 0}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) {
          displayError(error);
          return (
            <div className="muted-text doc-not-found">
              <h4>
                <FaUserMd /> No doctor found
              </h4>
            </div>
          );
        }

        const { getDoctors: docs } = data;
        if (!docs || docs.length < 1) {
          return (
            <div className="muted-text doc-not-found">
              <h4>
                <FaUserMd /> No doctor found
              </h4>
            </div>
          );
        }

        const renderDocs = filterFunction ? docs.filter(filterFunction) : docs;

        return (
          <List
            itemData={renderDocs}
            height={800}
            itemSize={80}
            itemCount={renderDocs.length}
          >
            {({ index, data, style }) => (
              <div style={{ ...style, paddingRight: '5px' }}>
                <DocBadge
                  doc={data[index]}
                  role={me.role}
                  onClick={e => {
                    e.preventDefault();
                    onDoctorClick(data[index].id);
                  }}
                />
              </div>
            )}
          </List>
        );
      }}
    </Query>
  );
};

RenderDoctorList.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  filterFunction: PropTypes.func,
  onDoctorClick: PropTypes.func.isRequired
};

export default RenderDoctorList;
