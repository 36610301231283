import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { DELETE_SCAN_DATA } from '../queries';
import { showConfirmation } from '../ConfirmationDialogService';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';

const getMessage = name => (
  <>
    <span>
      Permanently delete the scan of{' '}
      <strong style={{ color: 'red' }}>{name}</strong>
      ?<br />
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteScan = ({ itemId, name }) => {
  const confirm = deleteScan => {
    showConfirmation('Delete Scan?', getMessage(name), () =>
      deleteScan({ variables: { itemId } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_SCAN_DATA}
      onCompleted={() => {
        displaySuccess(`${name} is deleted`);
        logAmplitudeEvent(
          'Scan_deleted',
          { deletedScanName: `${name}`, deletedScanId: `${itemId}` },
          true
        );
      }}
      onError={e => displayError(e)}
      refetchQueries={() => ['GET_SCAN_DATA']}
    >
      {(deleteScan, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteScan)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteScan.propTypes = {
  itemId: PropTypes.number,
  name: PropTypes.string
};

export default DeleteScan;
