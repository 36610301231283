import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { Mutation } from 'react-apollo';
import { UPDATE_BOOKING } from '../queries';
import Loader from './Loader';
import { displayError } from '../utilities';

const EditableBookingField = ({
  booking,
  field,
  placeholder,
  clickToEditText
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const toggle = () => setIsEditing(!isEditing);
  const callMutation = updateField => {
    const v = `${inputRef.current.value}`.trim();
    if (v.length < 1) {
      return;
    }
    updateField({ variables: { input: { [field]: v, id: booking.id } } });
  };
  return (
    <Mutation
      mutation={UPDATE_BOOKING}
      onCompleted={() => setIsEditing(false)}
      onError={e => displayError(e)}
      refetchQueries={() => ['SEARCH_BOOKINGS']}
    >
      {(updateField, { loading, data: { booking: updatedBooking } = {} }) => {
        if (loading) return <Loader inline size={5} />;

        const latestBooking = updatedBooking || booking;
        if (!isEditing) {
          const hasValidText =
            latestBooking[field] && `${latestBooking[field]}`.trim().length > 0;
          return (
            <span
              className={classnames({
                'editable-booking-field': true,
                'editable-booking-field-empty': !hasValidText
              })}
              onClick={toggle}
            >
              {hasValidText ? latestBooking[field] : clickToEditText}
            </span>
          );
        } else {
          return (
            <>
              <input
                placeholder={placeholder}
                defaultValue={latestBooking[field]}
                onClick={toggle}
                // onBlur={toggle}
                ref={inputRef}
              />
              <Button color="link" onClick={() => callMutation(updateField)}>
                <FaCheck />
              </Button>
              <Button color="link" onClick={toggle}>
                <FaTimes />
              </Button>
            </>
          );
        }
      }}
    </Mutation>
  );
};

EditableBookingField.propTypes = {
  booking: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  clickToEditText: PropTypes.string
};

EditableBookingField.defaultProps = {
  placeholder: '',
  clickToEditText: 'Click to edit'
};

export default EditableBookingField;
