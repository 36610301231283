const convertToCSV = data => {
  const csvRows = [];
  const headers = ['Scan Name', 'AMS Procedure'];
  csvRows.push(headers.join(','));
  for (const item of data) {
    const values = [
      `"${(item.scanName || '').replace(/"/g, '""')}"`,
      `"${(item.procedureName || '').replace(/"/g, '""')}"`
    ];
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

export const handleExportCSV = data => {
  if (data && data.length > 0) {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'AMS_procedure_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};

const removeSpecialCharacters = str => {
  return str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

export const sortTable = (sorting, dataToSort) => {
  const sortedData = [...dataToSort].sort((a, b) => {
    const valueA = a[sorting.column]
      ? removeSpecialCharacters(a[sorting.column].toLowerCase())
      : '';
    const valueB = b[sorting.column]
      ? removeSpecialCharacters(b[sorting.column].toLowerCase())
      : '';

    // Handling null values
    if (valueA === '' && valueB !== '') {
      return 1;
    }
    if (valueA !== '' && valueB === '') {
      return -1;
    }
    if (valueA === '' && valueB === '') {
      return 0;
    }
    if (sorting.order === 'asc') {
      return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
    } else {
      return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
    }
  });
  return sortedData;
};
