import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Query } from 'react-apollo';
import { WEEKLY_SCHEDULE } from '../queries';
import Loader from './Loader';
import moment from 'moment';
import classnames from 'classnames';

// const fmt = HHmm => moment(HHmm, 'HHmm').format('hh:mm A');
const DAY_ORDER = { sun: 1, mon: 2, tue: 3, wed: 4, thu: 5, fri: 6, sat: 7 };
const availabilityRenderer = (timing, todayLowerCase) =>
  timing
    .sort(
      (o1, o2) =>
        DAY_ORDER[o1.day.toLowerCase()] - DAY_ORDER[o2.day.toLowerCase()]
    )
    .map(({ day, hours }, index) => (
      <tr
        key={index}
        className={classnames({
          'bg-info text-white': todayLowerCase === `${day}`.trim().toLowerCase()
        })}
      >
        <td>{day.toUpperCase()}</td>
        <td>
          {hours.map(timeRange => (
            <div key={`${timeRange.startTime}-${day}`}>
              {`${timeRange.startTime} - ${timeRange.endTime}`}
            </div>
          ))}
        </td>
      </tr>
    ));

const WeeklySchedule = ({ entityId }) => {
  const todayLowerCase = moment()
    .format('ddd')
    .toLowerCase();
  return (
    <Query query={WEEKLY_SCHEDULE} variables={{ entityId }}>
      {({ loading, error, data: { weeklySchedule } = {} }) => {
        if (loading) return <Loader size={5} />;
        if (error) return <div>{error}</div>;
        if (!weeklySchedule) return <span>No available schedule</span>;
        // console.log(weeklySchedule);
        return (
          <div>
            {weeklySchedule.map(st => (
              <div key={st.hospital}>
                <h6>{st.hospital}</h6>
                <Table>
                  <tbody>
                    {availabilityRenderer(st.timing, todayLowerCase)}
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
        );
      }}
    </Query>
  );
};

WeeklySchedule.propTypes = {
  entityId: PropTypes.number.isRequired
};

export default WeeklySchedule;
