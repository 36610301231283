import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { CX_USER_DB_BASE_URL, S2S_C9USERMORE_API_KEY } from '../constants';

export default class SearchPatientByMobile extends Component {
  state = {};

  fetchPatientList = async mobileno => {
    const mobile = `${mobileno}`.trim().slice(0, 10);

    if (mobile.length < 10) return;

    const response = await fetch(`${CX_USER_DB_BASE_URL}/mpid/v2/${mobile}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    }).then(resp => resp.json());

    const patientList = response.data
      .filter(res => !res.isDeleted)
      .map(res => ({
        label: `${res.first_name} - ${res.MPI}`,
        value: res
      }));
    return patientList;
  };

  handleMobileNoChange = (inputData, { action }) => {
    const input = `${inputData}`.trim().replace(/\D/, '');

    if (input && input.length && input.length > 10) {
      this.setState({ value: input.slice(0, 10) });
    } else {
      this.setState({ value: input });
    }

    if (action !== 'input-change') return;
    this.props.onMobileNoChange(input);
  };

  render() {
    const { onChange } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        inputValue={this.state.value}
        loadOptions={this.fetchPatientList}
        onChange={option => onChange(option.value)}
        onMobileNoChange={this.handleMobileNoChange}
        maxMenuHeight={200}
      />
    );
  }
}

SearchPatientByMobile.propTypes = {
  onChange: PropTypes.func,
  onMobileNoChange: PropTypes.func
};
