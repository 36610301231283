import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col
} from 'reactstrap';
import { gql } from 'apollo-boost';
import { Mutation } from 'react-apollo';
import Loader from './Loader';
import { UPDATE_BOOKING } from '../queries';
import { displaySuccess, displayError } from '../utilities';

export const UPDATE_MPID = gql`
  mutation UPDATE_MPID($input: BookingUpdateInput) {
    booking: updateBooking(input: $input) {
      id
      mpid
    }
  }
`;

const UpdateMpidModal = props => {
  const { isOpen, toggle, bookingId } = props;
  const [mpIdData, updatempIdData] = useState('');

  useEffect(() => {
    updatempIdData('');
  }, [isOpen, toggle, bookingId]);

  const submitMpi = async updateBooking => {
    try {
      if (mpIdData.length === 16 && /^\d+$/.test(mpIdData)) {
        await updateBooking({
          variables: {
            input: {
              id: bookingId,
              mpid: mpIdData
            }
          }
        });
        displaySuccess('Mpid Updated Successfully');
      } else {
        const emsg = `Provide Valid Mpid`;
        throw new Error(emsg);
      }
    } catch (e) {
      //   console.error('Error: ' + e.message + '\n' + JSON.stringify(e));
      displayError(e.message);
    }
  };

  const handleOnClose = () => {};

  if (!bookingId) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="l"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>UPDATE MPID</ModalHeader>
      <ModalBody>
        <Mutation mutation={UPDATE_BOOKING}>
          {(updateBooking, { loading }) => {
            if (loading) return <Loader />;

            return (
              <Row>
                <Col sm={3}>MPID:</Col>
                <Col sm={6}>
                  <input
                    type="text"
                    onChange={e => updatempIdData(e.target.value)}
                    defaultValue={mpIdData}
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    color="primary"
                    onClick={() => submitMpi(updateBooking)}
                  >
                    UPDATE
                  </Button>
                </Col>
              </Row>
            );
          }}
        </Mutation>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

UpdateMpidModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  bookingId: PropTypes.string
};

export default UpdateMpidModal;
