import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import { showConfirmation } from '../ConfirmationDialogService';
import { useMutation } from 'react-apollo';
import SlotCancelationReasons from './SlotCancelationReasons';
import { BLOCK_SLOTS } from '../queries';
import { displaySuccess, displayError } from '../utilities';
import { toMoment } from './ModifySlotContainer';
import ModifySlotAffectedBookingsToolBar from './ModifySlotAffectedBookingsToolBar';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 1rem;
  }
`;

const OkCancel = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    margin-left: 1rem;
  }
`;

const Highlight = styled.span`
  color: red;
`;

const Blue = styled.span`
  color: blue;
`;

const CancelationReason = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
`;

const ModifySlotBlockTatkal = ({ onCancel, date, alteredSchedule }) => {
  const {
    start,
    end,
    slotPrototypeId,
    // isAltered,
    alteredStart,
    alteredEnd,
    // isAdhoc,
    // hospitalName,
    isTatkalEnabled
    // isBlockAll,
    // userName,
    // updatedAt
  } = alteredSchedule;
  const [isCancelBookings, setIsCancelBookings] = useState(false);
  const [reason, setReason] = useState();

  const [blockSlots, { loading }] = useMutation(BLOCK_SLOTS, {
    variables: {
      input: {
        slotPrototypeId,
        date,
        reason,
        cancelBookings: isCancelBookings,
        action: isTatkalEnabled ? 'block' : 'unblock',
        onlyTatkal: true
      }
    },
    onCompleted: () => {
      displaySuccess(
        `Tatkal slots ${isTatkalEnabled ? 'blocked' : 'unblocked'} successfully`
      );
      onCancel();
    },
    onError: e => displayError(e),
    refetchQueries: () => ['SEARCH_DOCTORS_EXTENDED']
  });
  const dateM = moment(date);
  const actualStartTime = alteredStart
    ? toMoment(alteredStart, dateM)
    : toMoment(start, dateM);
  const actualEndTime = alteredEnd
    ? toMoment(alteredEnd, dateM)
    : toMoment(end, dateM);

  const origStartStr = actualStartTime.format('h:mm A');
  const origEndStr = actualEndTime.format('h:mm A');

  let okButtonText = isCancelBookings
    ? 'Block Tatkal Slots and Cancel Bookings'
    : 'Block Tatkal Slots, do NOT Cancel Bookings';
  const okButtonColor = isCancelBookings ? 'danger' : 'primary';

  if (!isTatkalEnabled) {
    okButtonText = 'Unblock Tatkal Slots';
  }

  const onOk = () => {
    return showConfirmation(
      'Check Configuration',
      <ul style={{ textAlign: 'left' }}>
        <li>
          <strong>{isTatkalEnabled ? 'Block all' : 'Unblock all'}</strong>{' '}
          Tatkal slots between{' '}
          <strong>
            {origStartStr} - {origEndStr}
          </strong>{' '}
          on {dateM.format('ddd, DD MMM YYYY')}
        </li>
        <li>
          {isCancelBookings
            ? 'Cancel all the Tatkal bookings in this duration'
            : 'Leave the bookings untouched'}
        </li>
      </ul>,
      () => blockSlots()
    );
  };

  const isReasonRequired = isTatkalEnabled;
  const isEnabledOkButton = loading || (!reason && isReasonRequired);
  return (
    <MainContainer>
      <h4>
        {isTatkalEnabled ? 'Block' : 'Unblock'} tatkal slots between{' '}
        <Highlight>
          {origStartStr} - {origEndStr} on{' '}
          <Blue>{dateM.format('ddd, DD MMM YYYY')}</Blue>
        </Highlight>
      </h4>

      {isReasonRequired && (
        <CancelationReason>
          <div>Reason</div>
          <div>
            <SlotCancelationReasons onChange={r => setReason(r)} />
          </div>
          {!reason && <span className="error">Required</span>}
        </CancelationReason>
      )}
      {isTatkalEnabled && (
        <>
          <div>
            <input
              disabled={!isTatkalEnabled}
              type="checkbox"
              checked={isCancelBookings}
              onChange={e => setIsCancelBookings(e.target.checked)}
              id="cancelBookings"
            />{' '}
            <label htmlFor="cancelBookings">Cancel all Tatkal booking</label>
          </div>

          <ModifySlotAffectedBookingsToolBar
            modification="blockTatkal"
            slotPrototypeId={slotPrototypeId}
            date={date}
            leaveOrphans={!isCancelBookings}
            enabled={!isEnabledOkButton}
          />
        </>
      )}

      <OkCancel>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={isEnabledOkButton}
          onClick={onOk}
          color={okButtonColor}
        >
          {okButtonText}
        </Button>
      </OkCancel>
    </MainContainer>
  );
};

ModifySlotBlockTatkal.propTypes = {
  alteredSchedule: PropTypes.objectOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      slotPrototypeId: PropTypes.number.isRequired,
      isAltered: PropTypes.bool.isRequired,
      alteredStart: PropTypes.instanceOf(Date),
      alteredEnd: PropTypes.instanceOf(Date),
      isAdhoc: PropTypes.bool.isRequired,
      hospitalName: PropTypes.string,
      isTatkalEnabled: PropTypes.bool.isRequired,
      isBlockAll: PropTypes.bool
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default ModifySlotBlockTatkal;
