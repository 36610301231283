import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import BookingInfo from './BookingInfo';
import BookingEvents from './BookingEvents';
import { CX_SERVER_BASE_URL, S2S_CX_SERVER_API_KEY } from '../constants';
import Loader from '../components/Loader';
import fetch from 'isomorphic-fetch';
import moment from 'moment';

import classnames from 'classnames';
import BookingMessages from './BookingMessages';
import { logAmplitudeEvent } from '../utilities';

const TABS = {
  INFO: 1,
  HISTORY: 2,
  MESSAGES: 3,
  BED_BOOKING: 4
};
const BookingInfoModal = ({ bookingId, isOpen, toggle, mpid }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.INFO);
  const [loaderIcon, setLoaderIcon] = useState(true);
  const [bedBookingDetails, setBedBookingDetails] = useState([]);

  useEffect(() => {
    if (isOpen) {
      logAmplitudeEvent(
        'Info_Icon_Pressed',
        { selectedTab: 'INFO', mpid: mpid },
        true
      );
    }
    setSelectedTab(TABS.INFO);
    setBedBookingDetails([]);
    setLoaderIcon(true);
    // eslint-disable-next-line
  }, [bookingId, mpid]);

  // function to fetch customer's bed booking details.
  const fetchBedBookingDetails = mpid => {
    const url = `${CX_SERVER_BASE_URL}/qkams/get/LT/sales/invoice`;

    const req_body = {
      mpi: mpid,
      department: 'OP'
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  // function to fetch and sort all bed bookings based on billed on date and store this information in state to show it to the users.
  const getBedBookingDetails = async mpid => {
    setLoaderIcon(true);
    try {
      const response = await fetchBedBookingDetails(mpid);
      if (
        response &&
        response.head &&
        response.head.StatusValue === 200 &&
        response.body &&
        response.body.Data &&
        response.body.Data.length >= 1
      ) {
        const allBedBookings = response.body.Data.filter(
          item => item.billType === 'IP Booking'
        );

        if (allBedBookings.length >= 1) {
          allBedBookings.sort(
            (itema, itemb) =>
              moment(itemb['billedOn'], 'DD-MM-YYYY HH:mm') -
              moment(itema['billedOn'], 'DD-MM-YYYY HH:mm')
          );
          setBedBookingDetails(allBedBookings);
          setLoaderIcon(false);
        } else {
          setLoaderIcon(false);
        }
      } else {
        setLoaderIcon(false);
      }
    } catch (error) {
      setLoaderIcon(false);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Booking Details</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: selectedTab === TABS.INFO })}
              onClick={() => {
                setSelectedTab(TABS.INFO);
                logAmplitudeEvent(
                  'Info_Icon_Pressed',
                  { selectedTab: 'INFO', mpid: mpid },
                  true
                );
              }}
            >
              INFO
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: selectedTab === TABS.HISTORY })}
              onClick={() => {
                setSelectedTab(TABS.HISTORY);
                logAmplitudeEvent(
                  'Info_Icon_Pressed',
                  { selectedTab: 'HISTORY', mpid: mpid },
                  true
                );
              }}
            >
              HISTORY
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: selectedTab === TABS.MESSAGES })}
              onClick={() => {
                setSelectedTab(TABS.MESSAGES);
                logAmplitudeEvent(
                  'Info_Icon_Pressed',
                  { selectedTab: 'MESSAGES', mpid: mpid },
                  true
                );
              }}
            >
              MESSAGES
            </NavLink>
          </NavItem>
          {mpid ? (
            <NavItem>
              <NavLink
                className={classnames({
                  active: selectedTab === TABS.BED_BOOKING
                })}
                onClick={() => {
                  setSelectedTab(TABS.BED_BOOKING);
                  getBedBookingDetails(mpid);
                  logAmplitudeEvent(
                    'Info_Icon_Pressed',
                    { selectedTab: 'BED BOOKING', mpid: mpid },
                    true
                  );
                }}
              >
                BED BOOKING
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent activeTab={selectedTab}>
          <TabPane tabId={TABS.INFO}>
            <BookingInfo bookingId={bookingId} />
          </TabPane>
          <TabPane tabId={TABS.HISTORY}>
            <div style={{ marginTop: '0.5rem' }}>
              {selectedTab === TABS.HISTORY && (
                <BookingEvents bookingId={bookingId} />
              )}
            </div>
          </TabPane>
          <TabPane tabId={TABS.MESSAGES}>
            <div style={{ marginTop: '0.5rem' }}>
              {selectedTab === TABS.MESSAGES && (
                <BookingMessages bookingId={bookingId} />
              )}
            </div>
          </TabPane>
          <TabPane tabId={TABS.BED_BOOKING}>
            {loaderIcon ? (
              <Loader />
            ) : (
              selectedTab === TABS.BED_BOOKING &&
              (bedBookingDetails.length ? (
                bedBookingDetails.map((bedbookings, index) => {
                  const {
                    department,
                    billNo,
                    billedOn,
                    gross,
                    tax,
                    discount,
                    net
                  } = bedbookings;
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 13
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {department ? (
                            <div>Department: {department}</div>
                          ) : null}
                          {billNo ? <div>Bill No: {billNo}</div> : null}
                          {billedOn ? <div>Billed On: {billedOn}</div> : null}
                        </div>
                        <div style={{ textAlign: 'left' }}>
                          {gross ? <div>Gross: Rs.{gross}</div> : null}
                          {tax ? <div>Tax: Rs.{tax}</div> : null}
                          {discount ? <div>Discount: Rs.{discount}</div> : null}
                          {net ? <div>Net: {net}</div> : null}
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 300,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 13
                  }}
                >
                  <p>No Bed Booking Details Available</p>
                </div>
              ))
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

BookingInfoModal.propTypes = {
  bookingId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  mpid: PropTypes.func.isRequired
};

export default BookingInfoModal;
