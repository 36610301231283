import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'react-dropdown-tree-select/dist/styles.css';

import React, { Component } from 'react';
import TopNav from './components/TopNav';
import 'moment-timezone';
import moment from 'moment';
import { ApolloProvider } from 'react-apollo';
import { GQL_ENDPOINT } from './constants';
import { ToastContainer } from 'react-toastify';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import ConfirmationDialog from './ConfirmationDialog';
import { resolvers, typeDefs } from './resolvers';
import { amplitudeInit } from './utilities';

moment.tz.setDefault('Asia/Kolkata');

const httpLink = createUploadLink({
  uri: GQL_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  resolvers,
  typeDefs
});

cache.writeData({
  data: {
    userHospitals: [],
    selectedHospitals: []
  }
});

class App extends Component {
  componentDidMount = () => {
    amplitudeInit();
  };
  render() {
    return (
      <ApolloProvider client={client}>
        <ToastContainer
          position="top-center"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
        />
        <ConfirmationDialog />
        <TopNav />
      </ApolloProvider>
    );
  }
}

export default App;
