import { Field, Formik } from 'formik';
import Select from 'react-select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CREATE_SCAN_PROCEDURE_MAPPING } from '../queries';
import {
  displaySuccess,
  logAmplitudeEvent,
  pregnancyWeekOptions,
  recommendationTypeOptions,
  scanTypeOptions,
  trimisterOptions
} from '../utilities';
import SelectProcedureV3 from './SelectProcedureV3';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const SelectInput = ({ field, form, options }) => {
  const favorableValue = options.find(o => o.value === field.value);
  return (
    <div>
      <Select
        value={favorableValue}
        onChange={option => form.setFieldValue(field.name, option.value)}
        options={options}
      />
    </div>
  );
};

SelectInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })
  ).isRequired
};

const ScanProcedureMappingModal = props => {
  // eslint-disable-next-line react/prop-types
  const { AMSProcedureDetails, isOpen, hideModal, onSave, action } = props;

  const [serverErr, setServerErr] = useState('');
  const _hideModal = () => {
    setServerErr('');
    hideModal();
  };
  const initialValues = {
    scanName:
      AMSProcedureDetails && AMSProcedureDetails.scanName
        ? AMSProcedureDetails.scanName
        : '',
    procedureId:
      AMSProcedureDetails && AMSProcedureDetails.procedureId
        ? parseInt(AMSProcedureDetails.procedureId)
        : '',
    aboutInfo:
      AMSProcedureDetails && AMSProcedureDetails.aboutInfo
        ? AMSProcedureDetails.aboutInfo
        : '',
    weekData:
      AMSProcedureDetails && AMSProcedureDetails.weekData
        ? AMSProcedureDetails.weekData
        : '',
    trimisterData:
      AMSProcedureDetails && AMSProcedureDetails.trimisterData
        ? AMSProcedureDetails.trimisterData
        : '',
    descriptionShort:
      AMSProcedureDetails && AMSProcedureDetails.descriptionShort
        ? AMSProcedureDetails.descriptionShort
        : '',
    scanType:
      AMSProcedureDetails && AMSProcedureDetails.scanType
        ? AMSProcedureDetails.scanType
        : '',
    recommendationType:
      AMSProcedureDetails && AMSProcedureDetails.recommendationType
        ? AMSProcedureDetails.recommendationType
        : '',
    durationofScan:
      AMSProcedureDetails && AMSProcedureDetails.durationofScan
        ? AMSProcedureDetails.durationofScan
        : ''
  };

  return (
    <Mutation
      mutation={CREATE_SCAN_PROCEDURE_MAPPING}
      onCompleted={data => {
        onSave(data);
        displaySuccess(
          action === 'edit'
            ? 'Scan updated successfully.'
            : 'New scan created successfully.'
        );
        logAmplitudeEvent(
          action === 'edit' ? 'scan_updated' : 'scan_created',
          data.createScanProcedureMapping,
          true
        );
      }}
      refetchQueries={() => ['GET_SCAN_PROCEDURE_MAPPING', 'GET_SCAN_DATA']}
      onError={error => {
        setServerErr(error && error.message);
      }}
    >
      {(updateScan, { loading: updateLoading }) => {
        const displayLoading = updateLoading;
        return (
          <Modal isOpen={isOpen} toggle={_hideModal}>
            <ModalHeader toggle={_hideModal}>
              {action === 'edit'
                ? 'Edit Scan Procedure Mapping'
                : 'Add Scan Procedure Mapping'}
            </ModalHeader>
            <ModalBody>
              <div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={(values, { setFieldError }) => {
                    const payload = values;
                    if (action === 'edit') {
                      payload.id =
                        typeof AMSProcedureDetails.id == 'number'
                          ? AMSProcedureDetails.id
                          : parseInt(AMSProcedureDetails.id);
                    }
                    if (
                      (action === 'add' || action === 'edit') &&
                      (!payload.scanName ||
                        !payload.procedureId ||
                        !payload.scanType ||
                        !payload.recommendationType)
                    ) {
                      if (!payload.scanName) {
                        setFieldError(
                          'scanName',
                          'Please enter a valid Scan Name'
                        );
                      }
                      if (!payload.procedureId) {
                        setFieldError(
                          'procedureId',
                          'Please select a valid AMS Procedure'
                        );
                      }
                      if (!payload.scanType) {
                        setFieldError(
                          'scanType',
                          'Please select a valid AMS Scan Type'
                        );
                      }
                      if (!payload.recommendationType) {
                        setFieldError(
                          'recommendationType',
                          'Please select a valid AMS Recommendation Type'
                        );
                      }

                      return;
                    }
                    updateScan({
                      variables: { input: payload }
                    });
                  }}
                >
                  {formik => (
                    <div>
                      {serverErr && (
                        <div className="error">
                          Error updating AMS Procedure. {serverErr}
                        </div>
                      )}
                      {formik.errors && (
                        <div className="error">
                          {formik.errors.scanName && (
                            <div>{formik.errors.scanName}</div>
                          )}
                          {formik.errors.procedureId && (
                            <div>{formik.errors.procedureId}</div>
                          )}
                          {formik.errors.scanType && (
                            <div>{formik.errors.scanType}</div>
                          )}
                          {formik.errors.recommendationType && (
                            <div>{formik.errors.recommendationType}</div>
                          )}
                        </div>
                      )}
                      <div style={styles.formContainer}>
                        <div>Scan Name</div>
                        <div>
                          <Field name="scanName" style={{ width: '100%' }} />
                        </div>
                        <div>AMS Procedure</div>
                        <Field
                          name={`procedureId`}
                          render={({ form, field }) => {
                            return (
                              <SelectProcedureV3
                                {...props}
                                value={field.value}
                                onChange={v =>
                                  form.setFieldValue(field.name, v)
                                }
                              />
                            );
                          }}
                        />
                        <div>About Info</div>
                        <div>
                          <Field name="aboutInfo" style={{ width: '100%' }} />
                        </div>
                        <div>Short Description</div>
                        <div>
                          <Field
                            name="descriptionShort"
                            style={{ width: '100%' }}
                          />
                        </div>
                        <div>Scan Week</div>
                        <div>
                          <Field
                            name="weekData"
                            style={{ width: '100%' }}
                            options={pregnancyWeekOptions}
                            component={SelectInput}
                          />
                        </div>
                        <div>Trimester</div>
                        <div>
                          <Field
                            name="trimisterData"
                            options={trimisterOptions}
                            component={SelectInput}
                          />
                        </div>
                        <div>Scan Type</div>
                        <div>
                          <Field
                            name="scanType"
                            style={{ width: '100%' }}
                            options={scanTypeOptions}
                            component={SelectInput}
                          />
                        </div>
                        <div>Recommendation Type</div>
                        <div>
                          <Field
                            name="recommendationType"
                            style={{ width: '100%' }}
                            options={recommendationTypeOptions}
                            component={SelectInput}
                          />
                        </div>
                        <div>Scan Duration</div>
                        <div>
                          <Field
                            name="durationofScan"
                            style={{ width: '100%' }}
                          />
                        </div>
                        <div style={styles.buttonRow}>
                          <Button
                            onClick={formik.submitForm}
                            size="sm"
                            color="primary"
                            disabled={displayLoading}
                          >
                            {action === 'edit' ? 'Update' : 'Create'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </ModalBody>
          </Modal>
        );
      }}
    </Mutation>
  );
};

ScanProcedureMappingModal.propTypes = {
  AMSProcedureDetails: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  action: PropTypes.string
};

export default ScanProcedureMappingModal;
