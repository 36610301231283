import React, { useEffect, useState } from 'react';
import { Row, Label, Button, Input, Form, FormGroup, Col } from 'reactstrap';
import {
  GET_ALL_DEPARTMENTS,
  GET_LOGGED_IN_USER_DETAILS,
  createMpidCoupon,
  deleteCoupon,
  fetchCouponDetails,
  fetchPatientDetails
} from '../queries';
import CouponDetail from './CouponDetail';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ShowCreatedCouponModal from './ShowCreatedCouponModal';
import { logAmplitudeEvent, commonCouponFormData } from '../utilities';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { getLoggedInUser } from '../permissions';
import { useQuery } from 'react-apollo';

const initialFormData = {
  enteredCouponCount: 0,
  ...commonCouponFormData,
  departments: []
};

const MpidCouponForm = ({ coupons, resetFlag }) => {
  const [mpid, setMpid] = useState('');
  const [user, setUser] = useState(null);
  const [allCouponsDetails, setAllCouponsDetails] = useState(null);
  const [toggleCouponDetail, setToggleCouponDetails] = useState(false);
  const [toggleCreateCoupon, setToggleCreateCoupon] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [createdCoupons, setCreatedCoupons] = useState([]);
  const [ltOptions, setLtOptions] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (resetFlag) {
      resetFormData();
    }
  }, [resetFlag]);

  const resetFormData = function() {
    setFormData(initialFormData);
    setToggleCouponDetails(false);
    setToggleCreateCoupon(false);
  };

  const { data: { departments = [] } = {} } = useQuery(GET_ALL_DEPARTMENTS, {
    onError: error => {
      toast.error(`Failed to fetch departments: ${error.message}`, {
        autoClose: 3000
      });
    }
  });

  const { data: { hospitals = [] } = {} } = useQuery(
    GET_LOGGED_IN_USER_DETAILS,
    {
      onError: error => {
        toast.error(`Reload to fetch hospitals, ${error.message || error}`, {
          autoClose: 3000
        });
      },
      onCompleted: data => {
        const { hospitals, me } = data;
        if (!me || !hospitals) {
          toast.error('Failed to fetch hospitals', { autoClose: 3000 });
          return;
        }
      }
    }
  );

  const handleDepartmentChange = e => {
    const { name, checked } = e.target;
    const department = departments.find(dep => dep.name === name);
    let updatedSelectedDepartments = [...formData.departments];
    if (checked) {
      updatedSelectedDepartments.push(department);
    } else {
      updatedSelectedDepartments = updatedSelectedDepartments.filter(
        dep => dep.id !== department.id
      );
    }

    setFormData({
      ...formData,
      departments: updatedSelectedDepartments
    });
  };

  const handleGetCoupons = async () => {
    const pattern = /^[0-9\b]+$/;
    setUser(null);
    setAllCouponsDetails(null);

    if (mpid && pattern.test(mpid) && mpid.length === 16) {
      try {
        toast.info('Fetching MPID Details', { autoClose: 1000 });
        const userResponse = await fetchPatientDetails(mpid);
        const couponResponse = await fetchCouponDetails(mpid);
        if (
          userResponse &&
          userResponse.data &&
          userResponse.data.getPatientDetails
        ) {
          if (userResponse.data.getPatientDetails[0])
            setUser(userResponse.data.getPatientDetails[0]);
          else toast.error(`MPID details not found`, { autoClose: 3000 });
        }
        if (couponResponse && couponResponse.getCouponDetails) {
          setAllCouponsDetails(couponResponse.getCouponDetails);
        }
      } catch (error) {
        toast.error(`Failed to fetch, ${error}`, { autoClose: 3000 });
      }
    } else {
      toast.error(`Please enter a valid 16 digit mpid!`, { autoClose: 3000 });
    }
  };

  const toggle = () => {
    setCreatedCoupons(null);
    setshowModal(!showModal);
    setFormData(initialFormData);
    handleGetCoupons();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    handleGetCoupons();
  };

  const handleCreateCoupon = () => {
    if (toggleCreateCoupon) {
      setFormData(initialFormData);
    }
    setToggleCreateCoupon(!toggleCreateCoupon);
  };

  const handleDeleteCoupon = async cpn => {
    const loggedUser = getLoggedInUser();

    let message = '';
    let isError = false;

    try {
      const response = await deleteCoupon(cpn.id, loggedUser.name);

      const deleteCouponMessage =
        response &&
        response.data &&
        response.data.deleteCoupon &&
        response.data.deleteCoupon.message;
      const errorMessage =
        response &&
        response.errors &&
        response.errors[0] &&
        response.errors[0].message;

      message =
        deleteCouponMessage ||
        errorMessage ||
        'Failed to delete, Something went wrong';
      isError = !deleteCouponMessage;

      if (deleteCouponMessage) {
        handleGetCoupons();
      }
    } catch (err) {
      message = `Failed to delete, ${err}`;
      isError = true;
    } finally {
      const toastFn = isError ? toast.error : toast.success;
      toastFn(message, { autoClose: 3000 });
    }
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'enteredCouponCount' ? parseInt(value || 0) : value
    });
  };

  const handleChangeSelect = (name, e) => {
    if (name === 'selectedLtItemId') {
      const enteredComment = coupons.newCouponsMap[e.ltitemid] || '';
      setFormData({
        ...formData,
        selectedLtItemId: e.ltitemid,
        enteredComment: enteredComment
      });
    } else if (name === 'selectedSiteCode') {
      setFormData({
        ...formData,
        selectedSiteCode: e.siteCode,
        enteredComment: '',
        selectedLtItemId: ''
      });

      if (coupons && coupons.couponArr) {
        let ltOptions = coupons.couponArr.filter(
          cpn => cpn.sitecode === e.siteCode
        );
        setLtOptions(ltOptions);
      }
    }
  };

  const handleDateChange = (name, selectedDate) => {
    setFormData({
      ...formData,
      [name]: selectedDate.format('YYYY-MM-DD')
    });
  };

  const validationSchema = Yup.object({
    enteredCouponCount: Yup.number()
      .required('Please enter coupon count.')
      .min(1, 'Coupon count must be at least 1.')
      .max(10, 'Coupon count must be at most 10.'),
    selectedLtItemId: Yup.string().required('Please select an offer.'),
    selectedSiteCode: Yup.string().required('Please select a site code'),
    departments: Yup.array()
      .min(1, 'Please select at least one department')
      .required('Please select at least one department')
  });

  const handleLogEvent = message => {
    logAmplitudeEvent(
      message,
      {
        formData,
        mpid,
        user
      },
      true
    );
  };

  const handleSubmitToCreate = async () => {
    setIsButtonDisabled(true);
    try {
      const response = await createMpidCoupon(formData, mpid, user);
      if (response && response.data && response.data.createCoupon) {
        toast.success('Mpid Coupon created successfully', { autoClose: 3000 });
        setCreatedCoupons(response.data.createCoupon);
        setshowModal(true);
        handleLogEvent('Mpid Coupon created successfully');
      } else if (response && response.errors[0]) {
        handleLogEvent(
          `Failed to create Mpid coupon,${response.errors[0].message}`
        );
        toast.error(
          `Failed to create Mpid coupon,${response.errors[0].message}`,
          { autoClose: 3000 }
        );
      } else {
        handleLogEvent('Failed to create Mpid coupon');
        toast.error('Failed to create Mpid coupon', { autoClose: 3000 });
      }
    } catch (err) {
      handleLogEvent('Failed to create Mpid coupon');
      toast.error(`Failed to create Mpid coupon, ${err}`, { autoClose: 3000 });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    setErrors({});
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      handleSubmitToCreate();
    } catch (error) {
      const newErrors = {};
      error.inner.forEach(err => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    }
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: '40px',
      minHeight: '40px'
    })
  };

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>Mpid Coupons </h1>
        </Col>
        <Col className="text-right">
          {mpid && user && (
            <Button onClick={handleCreateCoupon} color="primary">
              {toggleCreateCoupon
                ? 'Cancel Coupon Creation / Discard'
                : 'Create New Coupon'}
            </Button>
          )}
        </Col>
      </Row>
      <br />
      <>
        <Row>
          <Col className="max-w-400 mr-4">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                className="d-flex align-items-center justify-content-flex-start"
                style={{ gap: '20px' }}
              >
                <Label for="mpid">MPID</Label>
                <Input
                  id="mpid"
                  style={{ width: '20%', height: '40px' }}
                  name="mpid"
                  placeholder="Please Enter Mpid"
                  value={mpid}
                  onChange={e => {
                    setUser(null);
                    let inputVal = e.target.value;
                    inputVal = inputVal.replace(/\D/g, '');
                    inputVal = inputVal.slice(0, 16);
                    setMpid(inputVal);
                  }}
                />
                <Button disabled={!mpid} color="primary" onClick={handleSubmit}>
                  Search
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </>
      {toggleCreateCoupon && coupons && coupons.couponArr ? (
        <div>
          <br />
          <h4>Enter details of coupon codes to be generated</h4>
          <br />
          <Form onSubmit={handleSubmitForm}>
            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="enteredCouponCount" style={{ width: '20%' }}>
                    Coupon Count
                  </label>
                  <Input
                    type="number"
                    style={{ width: '80%', height: '40px' }}
                    id="enteredCouponCount"
                    name="enteredCouponCount"
                    placeholder="Enter Coupon Count"
                    value={
                      formData.enteredCouponCount === 0
                        ? ''
                        : formData.enteredCouponCount
                    }
                    onChange={e => handleFormChange(e)}
                  />
                </div>
                <div>
                  {errors.enteredCouponCount && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.enteredCouponCount}
                    </div>
                  )}
                </div>
              </div>
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedSiteCode" style={{ width: '20%' }}>
                    Hospitals
                  </label>
                  <div style={{ width: '80%' }}>
                    <Select
                      id="selectedSiteCode"
                      styles={customStyles}
                      options={hospitals || []}
                      getOptionValue={({ siteCode }) => siteCode}
                      getOptionLabel={({ name }) => name}
                      onChange={e => handleChangeSelect('selectedSiteCode', e)}
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedSiteCode && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedSiteCode}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedStartDate" style={{ width: '20%' }}>
                    Start Date
                  </label>
                  <div style={{ width: '80%' }}>
                    <DatePicker
                      className="date-field"
                      selected={moment(formData.selectedStartDate)}
                      onChange={date =>
                        handleDateChange('selectedStartDate', date)
                      }
                      dateFormat="ddd, DD MMM YYYY"
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedStartDate && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedStartDate}
                    </div>
                  )}
                </div>
              </div>

              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedLtItemId" style={{ width: '20%' }}>
                    Offer
                  </label>
                  <div style={{ width: '80%' }}>
                    <Select
                      id="selectedLtItemId"
                      styles={customStyles}
                      value={
                        formData.selectedLtItemId
                          ? ltOptions.find(
                              option =>
                                option.value === formData.selectedLtItemId
                            )
                          : ''
                      }
                      options={ltOptions || []}
                      getOptionValue={({ ltitemid }) => ltitemid}
                      getOptionLabel={({ ltdiscountname }) => ltdiscountname}
                      onChange={e => handleChangeSelect('selectedLtItemId', e)}
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedLtItemId && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedLtItemId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedExpiryDate" style={{ width: '20%' }}>
                    Expiry Date
                  </label>
                  <div style={{ width: '80%' }}>
                    <DatePicker
                      className="date-field"
                      selected={moment(formData.selectedExpiryDate)}
                      onChange={date =>
                        handleDateChange('selectedExpiryDate', date)
                      }
                      dateFormat="ddd, DD MMM YYYY"
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedExpiryDate && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedExpiryDate}
                    </div>
                  )}
                </div>
              </div>
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="enteredComment" style={{ width: '20%' }}>
                    Comment
                  </label>
                  <Input
                    type="text"
                    id="enteredComment"
                    style={{ width: '80%', height: '40px' }}
                    name="enteredComment"
                    placeholder="Enter Comment"
                    value={formData.enteredComment}
                    onChange={e => handleFormChange(e)}
                  />
                </div>
                <div>
                  <div>
                    {errors.enteredComment && (
                      <div className="error" style={{ marginLeft: '20%' }}>
                        {errors.enteredComment}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center mt-4">
                <div
                  style={{
                    width: '9%',
                    marginRight: '1%'
                  }}
                >
                  Select Departments
                </div>
                <div
                  className="d-flex flex-wrap"
                  style={{
                    gap: '20px',
                    alignItems: 'center'
                  }}
                >
                  {departments &&
                    departments.map(department => (
                      <label
                        key={department.id}
                        style={{
                          marginTop: '10px'
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ marginRight: '5px' }}
                          name={department.name}
                          checked={
                            formData &&
                            formData.departments &&
                            formData.departments.some(
                              dep => dep.id === department.id
                            )
                          }
                          onChange={handleDepartmentChange}
                        />
                        {department.name}
                      </label>
                    ))}
                </div>
              </div>
              <div>
                {errors.departments && (
                  <div className="error" style={{ marginLeft: '10%' }}>
                    {errors.departments}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-3 text-right">
              <Button color="primary" type="submit" disabled={isButtonDisabled}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        user &&
        mpid.length === 16 && (
          <CouponDetail
            user={user}
            allCouponsDetails={allCouponsDetails}
            mpid={mpid}
            toggleCouponDetail={toggleCouponDetail}
            setToggleCouponDetails={setToggleCouponDetails}
            handleDeleteCoupon={handleDeleteCoupon}
          />
        )
      )}
      <ShowCreatedCouponModal
        createdCoupons={createdCoupons}
        showModal={showModal}
        toggle={toggle}
      />
    </div>
  );
};

MpidCouponForm.propTypes = {
  coupons: PropTypes.shape({
    couponArr: PropTypes.array,
    newCouponsMap: PropTypes.object
  }).isRequired,
  resetFlag: PropTypes.bool
};

export default MpidCouponForm;
