import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import ReportBugModal from './ReportBugModal';
const UnauthorizedBanner = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Jumbotron style={{ margin: '1rem' }}>
            <h1 className="display-3">Hello, Curious You!</h1>
            <p className="lead">
              We love to see that your curiousity brought you here. But
              seriously, you are not supposed to see this page. It is possible
              that the role you are assigned does not have enough privilege.
            </p>
            <hr className="my-2" />
            <p>
              If you think this was a mistake. Raise your voice, make some
              noise.
            </p>
            <p className="lead">
              <a href="/" className="btn btn-primary">
                Go Back to Home
              </a>{' '}
              <ReportBugModal buttonText="It was a mistake, I want to talk to your manager!" />
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default UnauthorizedBanner;
