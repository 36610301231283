import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import {
  FaRegQuestionCircle,
  FaArrowRight,
  FaArrowLeft,
  FaClipboardList
} from 'react-icons/fa';
import CompanionSlot from './CompanionSlot';
import moment from 'moment';
import classnames from 'classnames';
import NoSlotReason from './NoSlotReason';

const styles = {
  slotContainer: {
    borderTop: '1px solid #c7c7c7'
  },
  slotWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};

const RenderSlotsColumn = ({
  slots,
  onSlotSelect,
  entityId,
  hospitalId,
  date,
  procedureName
}) => {
  if (!slots || slots.length < 1) {
    return (
      <Row style={styles.slotContainer}>
        <Col>
          <NoSlotReason
            entityId={entityId}
            hospitalId={hospitalId}
            startDate={moment(date, 'ddd, MMM DD').format('YYYY-MM-DD')}
            endDate={moment(date, 'ddd, MMM DD').format('YYYY-MM-DD')}
          />
        </Col>
      </Row>
    );
  }

  return slots.map(slot => (
    <Row style={styles.slotContainer} key={`${slot.id}`}>
      <Col style={styles.slotWrapper}>
        {slot.leverageRatio > 0 && slot.isAvailable ? (
          <>
            {/* eslint-disable-next-line */}
            <a
              style={slot.isReserved ? { color: 'gray' } : {}}
              href=""
              onClick={e => {
                e.preventDefault();
                onSlotSelect(slot);
              }}
            >
              {moment(slot.startTime).format('h:mm A')}
            </a>
            <CompanionSlot
              id={slot.id}
              isReserved={slot.isReserved}
              companionType={slot.companionSlotType}
              startTime={slot.startTime}
              entityId={entityId}
              hospitalId={hospitalId}
              procedureName={procedureName}
            />
          </>
        ) : (
          <span className="text-muted">
            {moment(slot.startTime).format('hh:mm A')}
          </span>
        )}
        {!slot.isAvailable && slot.unavailabilityReason ? (
          <span className="text-muted">
            <small className="text-muted">
              {' '}
              <FaRegQuestionCircle id={`info-${slot.id}`} />
            </small>
            <UncontrolledTooltip placement="right" target={`info-${slot.id}`}>
              {slot.unavailabilityReason}
            </UncontrolledTooltip>
          </span>
        ) : null}
      </Col>
    </Row>
  ));
};

const SlotListRenderer = ({
  slotList,
  onPrevClick,
  onNextClick,
  isStartDateToday,
  onSlotSelect,
  entityId,
  hospitalId,
  procedureName
}) => {
  if (!slotList) {
    //not using isLoading because I want to keep old slots in the background
    return (
      <h5>
        <FaClipboardList /> Loooking for empty slots...
      </h5>
    );
  }
  if (slotList.length < 1) {
    return <h5>No Slots to Render. Contact Support.</h5>;
  }
  return (
    <Row>
      <Col sm={1}>
        <Button onClick={onPrevClick} disabled={isStartDateToday}>
          <FaArrowLeft />
        </Button>
      </Col>
      <Col sm={10}>
        <Row className="slot-col-header">
          {slotList.map(({ date }) => (
            <Col key={date}>
              <span>{date}</span>
            </Col>
          ))}
        </Row>
        <Row className="slot-time-container overflow-auto slot-col">
          {slotList.map(({ slots, date }, i) => {
            // const filteredSlots = slots.filter(s => s.isAvailable);
            return (
              <Col
                key={`scol-${i}`}
                className={classnames({
                  'no-slot': !slots || slots.length < 1
                })}
              >
                <RenderSlotsColumn
                  slots={slots}
                  onSlotSelect={onSlotSelect}
                  hospitalId={hospitalId}
                  entityId={entityId}
                  date={date}
                  procedureName={procedureName}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col sm={1}>
        <Button onClick={onNextClick}>
          <FaArrowRight />
        </Button>
      </Col>
    </Row>
  );
};

SlotListRenderer.propTypes = {
  slotList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  isStartDateToday: PropTypes.bool,
  onSlotSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  entityId: PropTypes.number,
  hospitalId: PropTypes.number,
  procedureName: PropTypes.string
};

export default SlotListRenderer;
