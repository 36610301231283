import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { FaBars, FaSearch, FaThLarge } from 'react-icons/fa';
import { FiMinus, FiPlus } from 'react-icons/fi';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from 'reactstrap';
import { GET_ALL_HOSPITALS, GET_PROTOCOLS } from '../queries';

const ViewProtocols = ({ editingHandler, role }) => {
  const [query, setQuery] = useState('');
  const [hospital, setHospital] = useState();
  const [toggleId, setToggleId] = useState();
  const [displayStyle, setDisplayStyle] = useState('grid');

  const [
    getAllProtocols,
    { data: getProtocols, loading, called }
  ] = useLazyQuery(GET_PROTOCOLS, {
    variables: {
      hospitalId: hospital && hospital.id
    }
  });

  const { data: { hospitals } = {}, loading: isLoading } = useQuery(
    GET_ALL_HOSPITALS
  );

  function getOptionLabel({ name }) {
    return name;
  }

  function getOptionValue({ id }) {
    return id;
  }

  function toggle(id) {
    return () => {
      setToggleId(toggleId === id ? null : id);
    };
  }

  function queryHandler(event) {
    setQuery(event.target.value);
  }

  function _getAllProtocols(hospitalId = '', question = '') {
    getAllProtocols({
      variables: {
        hospitalId: hospitalId || hospital.id,
        question: question || query,
        approved: true
      }
    });
  }

  function searchHandler() {
    if (!hospital) {
      toast.info('Please select hospital first', { autoClose: 3000 });
      return;
    }
    if (!query) {
      toast.info('Please type to search', { autoClose: 3000 });
      return;
    }

    _getAllProtocols();
  }

  function handleListAll() {
    if (!hospital) {
      toast.info('Please select hospital first', { autoClose: 3000 });
      return;
    }
    getAllProtocols({
      variables: {
        hospitalId: hospital.id,
        approved: true
      }
    });
    if (query) {
      setQuery('');
    }
  }

  function onHospitalChange(value) {
    setHospital(value);
    if (called) {
      _getAllProtocols(value.id);
    }
  }

  const _protocols = (getProtocols && getProtocols.getProtocols) || [];

  function toggleDisplayStyle(style) {
    setDisplayStyle(style);
  }

  const isGrid = displayStyle === 'grid';

  return (
    <>
      <h1 style={{ display: 'flex', alignItems: 'center' }}>
        Centre Protocols
        <span style={{ marginLeft: 'auto' }}>
          <div
            style={{
              display: 'flex',
              borderRadius: '15px',
              overflow: 'hidden',
              border: '1px solid #007BFF',
              width: '70px',
              height: '30px'
            }}
          >
            <div
              style={{
                flex: '1',
                backgroundColor: isGrid ? '#007BFF' : 'white',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={() => toggleDisplayStyle('grid')}
            >
              <FaThLarge
                size={15}
                style={{
                  cursor: 'pointer',
                  color: isGrid ? 'white' : '#007BFF'
                }}
              />
            </div>
            <div
              style={{
                flex: '1',
                backgroundColor: isGrid ? 'white' : '#007BFF',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={() => toggleDisplayStyle('list')}
            >
              <FaBars
                size={15}
                style={{
                  cursor: 'pointer',
                  color: isGrid ? '#007BFF' : 'white'
                }}
              />
            </div>
          </div>
        </span>
      </h1>
      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <div
              style={{
                minWidth: '30rem'
              }}
            >
              <Select
                value={hospital}
                options={hospitals}
                isLoading={isLoading || loading}
                onChange={onHospitalChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />
            </div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <Button onClick={handleListAll} color="primary" disabled={loading}>
              List All
            </Button>
          </div>
        </Col>
      </Row>
      <div>
        <div>
          <h3 className="mt-3 mb-3 d-flex justify-content-center">
            What do you need help with?
          </h3>
          <InputGroup disabled={loading}>
            <Input
              placeholder="Search the protocols"
              value={query}
              onChange={queryHandler}
              style={{
                height: 40
              }}
            />
            <InputGroupAddon addonType="append">
              <Button
                onClick={searchHandler}
                color={loading ? 'secondary' : 'primary'}
              >
                <FaSearch />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div
          style={{
            marginTop: 40,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          {_protocols.length > 0 ? (
            _protocols.map(protocol => (
              <div
                key={protocol.id}
                style={{
                  backgroundColor: '#dee2e6',
                  padding: 15,
                  borderRadius: 5,
                  marginBottom: 20,
                  width: displayStyle === 'grid' ? '30%' : '100%',
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: displayStyle === 'grid' ? '30px' : '0'
                }}
                id={`toggler${protocol.id}`}
                onClick={toggle(protocol.id)}
              >
                <div className="d-flex justify-content-between">
                  <div style={{ flex: 1 }}>
                    <h4
                      style={
                        displayStyle === 'grid' ? { fontSize: '18px' } : {}
                      }
                    >
                      {protocol.title}
                    </h4>
                  </div>
                  {(role === 'admin' || role === 'gre') && (
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Button
                        className="btn-sm"
                        color="primary"
                        onClick={() => {
                          editingHandler(protocol, hospital);
                        }}
                      >
                        Edit
                      </Button>
                      <div style={{ marginLeft: '8px' }}>
                        {toggleId === protocol.id ? (
                          <FiMinus size={20} />
                        ) : (
                          <FiPlus size={20} />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <Collapse isOpen={toggleId === protocol.id}>
                  <Card
                    style={{
                      backgroundColor: '#dee2e6',
                      border: 'none'
                    }}
                  >
                    <CardBody>
                      <h5
                        className="display-linebreak"
                        style={
                          displayStyle === 'grid' ? { fontSize: '16px' } : {}
                        }
                      >
                        {protocol.answer}
                      </h5>
                      <div className="d-flex justify-content-between">
                        <div>Updated By: {protocol.updatedBy} </div>
                        <div>
                          Updated at:{' '}
                          {new Date(protocol.updated_at).toDateString()}
                        </div>
                        <div>Approved By: {protocol.approvedBy} </div>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ))
          ) : (
            <h3 className="d-flex justify-content-center">
              No Protocol found for the selected hospital
            </h3>
          )}
        </div>
      </div>
    </>
  );
};

ViewProtocols.propTypes = {
  editingHandler: PropTypes.func,
  role: PropTypes.string
};

export default ViewProtocols;
