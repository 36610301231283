import React from 'react';
import { Mutation } from 'react-apollo';
import { BULK_UPLOAD_LT_PROFILE } from '../queries/index';
import Loader from '../components/Loader';

const UploadLtSyncData = () => {
  return (
    <div>
      <h1>Upload bookings json data</h1>
      <Mutation mutation={BULK_UPLOAD_LT_PROFILE}>
        {(fileUpload, { loading, data, error }) => {
          // const res = 'no resp';
          return (
            <>
              <input
                type="file"
                onChange={event => {
                  const {
                    target: {
                      validity,
                      files: [file]
                    }
                  } = event;
                  // console.log(`fkkiles: ${JSON.stringify(file.name)}`);
                  validity.valid && fileUpload({ variables: { file } });
                }}
              />
              {loading && <Loader />}

              {data ? <pre>{JSON.stringify(data)}</pre> : 'no data'}
              <h3>Errors:</h3>

              <div>
                <pre>{error && <pre>{JSON.stringify(error)}</pre>}</pre>
              </div>
            </>
          );
        }}
      </Mutation>
    </div>
  );
};

export default UploadLtSyncData;
