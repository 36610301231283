import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { GET_ALL_CITIES } from '../queries';
import Select from 'react-select';
import { logAmplitudeEvent } from '../utilities';

const CitySelector = ({ onChange, value, ...props }) => {
  logAmplitudeEvent('Quick_Book_City_Select', {}, true);

  return (
    <Query query={GET_ALL_CITIES}>
      {({ data: { cities } = [] }, loading) => {
        const opts =
          cities &&
          cities.length &&
          cities.map(({ id, name }) => ({ value: id, label: name }));
        return (
          <Select
            value={value}
            options={opts}
            isLoading={loading}
            onChange={onChange}
            {...props}
          />
        );
      }}
    </Query>
  );
};

CitySelector.propTypes = {
  onChange: PropTypes.object,
  value: PropTypes.object
};

CitySelector.defaultProps = {
  onChange: () => {}
};

export default CitySelector;
