import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { MODIFY_SLOT_AFFECTED_BOOKINGS } from '../queries';
import Loader from './Loader';
import { displayError } from '../utilities';
import { FaPrint, FaRupeeSign } from 'react-icons/fa';
import { Button, Table } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

const ModifySlotEffect = styled.div`
  margin: 0.5rem;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Info = styled.div`
  font-weight: bold;
`;
const Print = styled.div`
  color: blue;
`;
const AffectedBookings = styled.div`
  margin: 1rem 0;
`;

const BookingRow = styled.tr`
  background-color: ${props => (props.ispaid ? 'yellow' : '')};
`;

const BookingsTable = ({ affectedBookings }) => (
  <Table striped size="small">
    <thead>
      <tr>
        <th>Time</th>
        <th>Name</th>
        <th>Mobile</th>
        <th>Procedure</th>
      </tr>
    </thead>
    <tbody>
      {affectedBookings.map((b, index) => (
        <BookingRow isPaid={b.isPaid} key={`affectedBooking-${index}`}>
          <td>{moment(b.slotTime).format('h:mm A')}</td>
          <td>
            {b.userName} {b.isPaid && <FaRupeeSign />}
          </td>
          <td>{b.userMobile}</td>
          <td>{b.procedureName}</td>
        </BookingRow>
      ))}
    </tbody>
  </Table>
);

BookingsTable.propTypes = {
  affectedBookings: PropTypes.object.isRequired
};

const ModifySlotAffectedBookings = ({
  modification,
  slotPrototypeId,
  date,
  leaveOrphans,
  delayFrom,
  delayInMinutes,
  newStartTime,
  newEndTime
}) => {
  const printAreaRef = useRef();
  const { data, loading, error } = useQuery(MODIFY_SLOT_AFFECTED_BOOKINGS, {
    variables: {
      input: {
        modification,
        slotPrototypeId,
        date,
        leaveOrphans,
        delayFrom,
        delayInMinutes,
        newStartTime,
        newEndTime
      }
    },
    fetchPolicy: 'network-only',
    onError: e => displayError(e)
  });
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div className="error">Something went wrong!</div>;
  }

  if (!data) {
    return <div>No data!</div>;
  }

  const {
    affectedBookings: { affectedBookings, info }
  } = data;

  const noAffectedBooking = affectedBookings.length < 1;
  return (
    <ModifySlotEffect>
      <InfoContainer>
        <Info>{info}</Info>
        <Print>
          <ReactToPrint
            trigger={() => (
              <Button color="link" disabled={noAffectedBooking}>
                <FaPrint />
              </Button>
            )}
            content={() => printAreaRef.current}
          />
        </Print>
      </InfoContainer>
      <AffectedBookings ref={printAreaRef}>
        {noAffectedBooking ? (
          <div className="text-muted">No affected booking!</div>
        ) : (
          <BookingsTable affectedBookings={affectedBookings} />
        )}
      </AffectedBookings>
    </ModifySlotEffect>
  );
};

ModifySlotAffectedBookings.propTypes = {
  modification: PropTypes.oneOf(
    'blockAll',
    'blockTatkal',
    'delay',
    'changeTiming'
  ).isRequired,
  slotPrototypeId: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  leaveOrphans: PropTypes.bool.isRequired,
  delayFrom: PropTypes.instanceOf(Date),
  delayInMinutes: PropTypes.number,
  newStartTime: PropTypes.instanceOf(Date),
  newEndTime: PropTypes.instanceOf(Date)
};

export default ModifySlotAffectedBookings;
