import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConfirmationDialog = ({
  show,
  onConfirm,
  onCancel,
  title,
  message,
  buttonText
}) => (
  <Modal isOpen={show} toggle={onCancel}>
    <ModalHeader toggle={onCancel} className="text-center">
      {title}
    </ModalHeader>
    <ModalBody
      className="text-center"
      style={{ fontSize: '18px', marginBottom: '0' }}
    >
      {message}
    </ModalBody>
    <ModalFooter
      className="text-center"
      style={{ justifyContent: 'center', borderTop: '0' }}
    >
      <span className="cancel-text" onClick={onCancel}>
        Cancel
      </span>
      <Button
        color={
          buttonText === 'Reject' || buttonText === 'Delete'
            ? 'danger'
            : 'primary'
        }
        onClick={onConfirm}
        style={{ minWidth: '60px', marginLeft: '15px' }}
      >
        {buttonText}
      </Button>
    </ModalFooter>
  </Modal>
);

ConfirmationDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default ConfirmationDialog;
