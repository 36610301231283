import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
const isCloudnine = [
  { key: 'Belongs to Cloudnine Hospital', value: true },
  { key: 'Does not Belong to Cloudnine Hospital', value: false }
];

const SelectCloudnineHosp = props => {
  const { selectedCloudnine, onCloudnineSelect } = props;
  let cloudnineopts = isCloudnine.map(p => ({
    label: p.key,
    value: p.value
  }));

  const selectedOpt =
    cloudnineopts.find(({ value }) => value === selectedCloudnine) || null;
  return (
    <>
      <Select
        placeholder={'Select hospital belongs to Cloudnine'}
        value={selectedOpt}
        onChange={e => {
          onCloudnineSelect(e.value);
        }}
        options={cloudnineopts}
      />
    </>
  );
};

SelectCloudnineHosp.propTypes = {
  selectedCloudnine: PropTypes.bool,
  onCloudnineSelect: PropTypes.func
};

export default SelectCloudnineHosp;
