import classnames from 'classnames';
import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import AuditLogsTab from '../components/AuditLogsTab';
import AuthRequired from '../components/AuthRequired';
import SystemLogsTab from '../components/SystemLogsTab';
import { getLoggedInUser } from '../permissions';

const styles = {
  page: {
    margin: '1rem'
  },
  toolbar: {
    marginBottom: '1rem',
    marginTop: '1rem'
  }
};

const user = getLoggedInUser();
const userRole = user && user.role;

const Logs = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <AuthRequired pageName="logs">
      <div style={styles.page}>
        <h1>Logs</h1>
        {userRole === 'admin' && (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === '1'
                  })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  System Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={currentActiveTab === '2' ? 'active' : ''}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Audit Logs
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <SystemLogsTab />
              </TabPane>
              <TabPane tabId="2">
                <AuditLogsTab />
              </TabPane>
            </TabContent>
          </>
        )}
        {userRole !== 'admin' && <SystemLogsTab />}
      </div>
    </AuthRequired>
  );
};

export default Logs;
