import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import Select from 'react-select';
import { displayError } from '../utilities';
import { GET_ROLES } from '../queries';

const SelectRoles = ({ isMulti, onChange, selectedRoles, ...props }) => {
  return (
    <Query
      query={GET_ROLES}
      onError={() =>
        displayError('Something went wrong while fetching the roles')
      }
    >
      {({ loading, data }) => {
        let opts = [];
        if (data && data.roles) {
          const disableSomeRoles = data.roles.map(d =>
            d.value === 'doctor' ? { ...d, isDisabled: true } : d
          );
          opts = disableSomeRoles;
        }

        let defaultValue;
        if (!isMulti) {
          defaultValue = opts.find(o => selectedRoles === o.value);
        } else {
          defaultValue = opts.filter(o => selectedRoles.includes(o.value));
        }
        return (
          <Select
            options={opts}
            isLoading={loading}
            isMulti={isMulti}
            onChange={values =>
              !isMulti
                ? onChange(values.value)
                : onChange(values.map(v => v.value))
            }
            defaultValue={defaultValue}
            {...props}
          />
        );
      }}
    </Query>
  );
};

SelectRoles.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  selectedRoles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ])
};

SelectRoles.defaultProps = {
  isMulti: false,
  onChange: () => {},
  selectedRoles: []
};

export default SelectRoles;
