import React from 'react';
import { Query } from 'react-apollo';
import { LOCAL_USER_HOSPITALS } from '../queries';
import PropTypes from 'prop-types';

export const withUserData = WrappedComponent => {
  const EnhancedComponent = props => (
    <Query query={LOCAL_USER_HOSPITALS}>
      {({ data: { hospitals, allHospitals, selectedHospitals } = {} }) => {
        return (
          <WrappedComponent
            hospitals={hospitals}
            allHospitals={allHospitals}
            selectedHospitals={selectedHospitals}
            {...props}
          />
        );
      }}
    </Query>
  );

  EnhancedComponent.displayName = 'EnhancedWithUserData';
  WrappedComponent.propTypes = {
    selectedHospitals: PropTypes.array,
    hospitals: PropTypes.array,
    allHospitals: PropTypes.array
  };

  return EnhancedComponent;
};
