import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Collapse
} from 'reactstrap';
import moment from 'moment';
import { FaMobileAlt } from 'react-icons/fa';
import SelectSiteScan from './SelectSiteScan';
import PaymentForm from './PaymentForm';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import Loader from './Loader';
import { showConfirmation } from '../ConfirmationDialogService';
import Veil from './Veil';
import fetch from 'isomorphic-fetch';
import * as yup from 'yup';
import {
  displayError,
  displaySuccess,
  logAmplitudeEvent,
  getHospitalSiteCode
} from '../utilities';
import { CX_SERVER_BASE_URL, S2S_CX_SERVER_API_KEY } from '../constants';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const DoctorSpecialization = styled.span`
  color: '#808080',
  textTransform: 'capitalize'
`;

const DOC_QUERY = gql`
  query doctor($id: String) {
    doctor: getDoctor(id: $id) {
      name
      qualification
      lifetrenzId
      hospitals: getHospitals {
        id
        name
      }
    }
  }
`;

const DISPLAY_BOOKING_QUERY = gql`
  query DISPLAY_BOOKING_QUERY($bookingId: String) {
    booking: getBooking(id: $bookingId) {
      id
      mpid
      prospectId
      hospitalId
      userName
      userMobile
      userEmail
      slotTime
      departmentId
    }
  }
`;

const PModeSchema = yup.object().shape({
  paymentMode: yup.string().required('Payment mode is required')
});

const CardSchema = yup.object().shape({
  paymentId: yup.number().required('Transaction ID is required'),
  paymentMode: yup.string().required('Payment mode is required'),
  cardNumber: yup
    .number('Card Number should be numeric')
    .required('Card Number is required')
    .test(
      'len',
      'Card Number must be exactly 4 digit',
      val => val && val.toString().length === 4
    )
});

const UPISchema = yup.object().shape({
  paymentId: yup.number().required('Transaction ID is required'),
  paymentMode: yup.string().required('Payment mode is required')
});

const generateLTInvoice = (
  paymentId,
  productinfo,
  amount,
  firstname,
  email,
  phone,
  mpid,
  sitecode,
  paymentsource,
  appointmentId
) => {
  const txnid = phone + '.' + Date.now();

  const url = `${CX_SERVER_BASE_URL}/book/ams/LaborRad`;
  const req_body = {
    mihpayid: paymentId,
    status: 'success',
    txnid: txnid,
    amount: amount,
    addedon: moment().format('YYYY-MM-DD HH:mm:ss'),
    productinfo: productinfo,
    firstname: firstname,
    email: email,
    phone: phone,
    udf1: `ams~${mpid}~${sitecode}~${productinfo}~Radiology`,
    payment_source: paymentsource,
    appointmentId
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

const CreateScanInvoiceModal = props => {
  const { isOpen, toggle, doctorId, bookingId } = props;
  const [selectedscanName, updateSelectedscanName] = useState();
  const [shouldShowChargesLoader, showChargesLoader] = useState(false);
  const [bookingData, updateBookingData] = useState(undefined);
  const [doctorData, updateDoctorData] = useState(undefined);
  const [totalAmount, updateTotalAmount] = useState(undefined);
  const [chargesErrorMsg, updateChargesErrorMsg] = useState('');
  const [paymentData, updatePaymentData] = useState({});
  const [shouldShowCreateInvoiceLoader, showCreateInvoiceLoader] = useState(
    false
  );
  const [invoiceGenerationSuccess, updateInvoiceGenerationSuccess] = useState(
    false
  );
  const [pdfLink, updatePdfLink] = useState(null);

  const handleOnClose = () => {
    updateSelectedscanName();
    updateBookingData(undefined);
    updateDoctorData(undefined);
    showChargesLoader(false);
    updateTotalAmount(undefined);
    updateChargesErrorMsg('');
    updatePaymentData({});
    showCreateInvoiceLoader(false);
    updateInvoiceGenerationSuccess(false);
    updatePdfLink(null);
  };

  const getSiteCodeofId = async id => {
    const getData = await getHospitalSiteCode();
    const hospData = getData.data.getHospitals;

    const dataSite = hospData.filter(opt => opt.id === id);
    return dataSite[0].siteCode;
  };

  const initiateGetCharges = async (selectedscanName, mpi) => {
    const eventProps = {
      userName: bookingData.userName,
      userEmail: bookingData.userEmail,
      userMobile: bookingData.userMobile,
      Mpi: mpi,
      SelectedScan: selectedscanName
    };
    logAmplitudeEvent(
      'Create_Scan_Invoice_Initiated',
      Object.assign(eventProps, { status: 'success' }),
      true
    );

    console.log(selectedscanName);
    showChargesLoader(true);
    updateChargesErrorMsg('');
    updateTotalAmount(undefined);
    try {
      //show charges
      const totalAmount = selectedscanName.rate;
      showChargesLoader(false);
      updateTotalAmount(totalAmount);
      updateChargesErrorMsg('');
    } catch (error) {
      showChargesLoader(false);
      updateChargesErrorMsg('Some error occured. Try again later');
      updateTotalAmount(undefined);
    }
  };

  if (!doctorId || !bookingId) {
    return null;
  }

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };
  const insertInformationUserdb = async (
    mpid,
    sitecode,
    amount,
    firstname,
    email,
    phone,
    productinfo,
    doctorname,
    ltdoctorid,
    scanId,
    scanName,
    scanCharge,
    productid
  ) => {
    const url = `${CX_SERVER_BASE_URL}/booking/V2/post`;
    const req_body = {
      productid: productid,
      mpid: mpid,
      sitecode: sitecode,
      amount: amount,
      billedon: moment().format('YYYY-MM-DD HH:mm:ss'),
      patientname: firstname,
      patientemail: email,
      patientmobile: phone,
      servicetype: 'Radiology',
      dateofappt: moment().format('YYYY-MM-DD'),
      productinfo: productinfo,
      source: 'AMS',
      status: 'pending',
      consultant: doctorname,
      labDetails: [
        {
          id: scanId,
          serviceName: scanName,
          charge: scanCharge
        }
      ],
      charges: [scanCharge],
      labId: [scanId],
      labnames: [scanName],
      consultantId: ltdoctorid,
      isSamplePickupRequested: false,
      collectionAddress: '',
      timeappt: null
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts)
      .then(r => r.json())
      .then(respData => console.log(req_body, respData));
  };

  const createInvoice = async () => {
    showCreateInvoiceLoader(true);
    updateInvoiceGenerationSuccess(false);
    try {
      const siteCode = await getSiteCodeofId(bookingData.hospitalId);
      const productid = `B${Math.floor(100000 + Math.random() * 900000)}`;

      let data = {
        paymentId: paymentData.transactionId,
        bookingId,
        totalAmount,
        cardNumber: paymentData.cardNumber,
        userName: bookingData.userName,
        userEmail: bookingData.userEmail,
        userMobile: bookingData.userMobile,
        mpid: bookingData.mpid,
        lifetrenzId: doctorData.lifetrenzId,
        name: doctorData.name,
        siteCode,
        selectedscanName,
        slotTime: bookingData.slotTime,
        paymentMode: paymentData.paymentMode
      };

      await PModeSchema.validate(data);
      if (data.paymentMode.toLowerCase().includes('card'))
        await CardSchema.validate(data);
      if (data.paymentMode.toLowerCase() === 'upi') {
        data.cardNumber = null;
        await UPISchema.validate(data);
      }
      if (data.paymentMode.toLowerCase() === 'cash') {
        data.paymentId = null;
        data.cardNumber = null;
      }

      const eventProps = {
        PaymentMode: data.paymentMode,
        userName: data.userName,
        userEmail: data.userEmail,
        userMobile: data.userMobile,
        Mpi: data.mpid,
        SelectedScan: data.selectedscanName
      };

      logAmplitudeEvent(
        'Create_Scan_Invoice',
        Object.assign(eventProps, { status: 'initiated' }),
        true
      );
      await insertInformationUserdb(
        data.mpid,
        data.siteCode,
        data.totalAmount,
        data.userName,
        data.userEmail,
        data.userMobile,
        data.bookingId,
        data.name,
        data.lifetrenzId,
        selectedscanName.itemId,
        selectedscanName.scanName,
        selectedscanName.rate,
        productid
      );
      const res = await generateLTInvoice(
        data.paymentId,
        productid,
        data.totalAmount,
        data.userName,
        data.userEmail,
        data.userMobile,
        data.mpid,
        data.siteCode,
        data.paymentMode,
        data.bookingId
      );
      if (res && res.statusValue) {
        showCreateInvoiceLoader(false);
        updateInvoiceGenerationSuccess(true);
        displaySuccess('Invoice generated successfully');
        updatePdfLink(res.s3pdflink);

        const eventProps = {
          PatientName: data.userName,
          PaymentMode: data.paymentMode,
          SelectedScan: data.selectedscanName
        };
        logAmplitudeEvent(
          'Create_Scan_Invoice_Success',
          Object.assign(eventProps, { status: 'success' }),
          true
        );
      } else {
        const emsg = `Invoice generation failed: ${res.msg}`;
        throw new Error(emsg);
      }
    } catch (error) {
      showCreateInvoiceLoader(false);
      displayError(error);
    }
  };

  const viewInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Create Invoice</ModalHeader>
      <ModalBody>
        <Container>
          <Query
            query={DISPLAY_BOOKING_QUERY}
            variables={{ bookingId }}
            onCompleted={data => updateBookingData(data.booking)}
          >
            {({ loading, data }) => {
              if (loading) return <Loader />;

              const {
                booking: { userName, userMobile, mpid, slotTime, prospectId }
              } = data;

              return (
                <Row>
                  <Col sm={9}>
                    <h2>{userName}</h2>
                    <div>
                      <span className="text-muted">
                        MPI: {mpid ? mpid : 'Not Available'}{' '}
                      </span>
                    </div>
                    <div>
                      <span className="text-muted">
                        Prospect ID: {prospectId}
                      </span>
                    </div>
                    <div>
                      <span
                        className="appt-time"
                        style={{ fontWeight: 'bold' }}
                      >
                        Appointment Time:{' '}
                        {`${moment(slotTime).format(
                          'ddd DD MMM YYYY hh:mm A'
                        )}`}
                      </span>
                    </div>
                    <hr />
                  </Col>
                  <Col sm={3} className="text-right">
                    <FaMobileAlt />
                    <span>{` ${userMobile}`}</span>
                  </Col>
                </Row>
              );
            }}
          </Query>
          <Query
            query={DOC_QUERY}
            variables={{ id: doctorId }}
            onCompleted={data => updateDoctorData(data.doctor)}
          >
            {({ loading, data: { doctor } = { doctor: {} } }) => {
              if (loading) {
                return <Loader loading={true} />;
              }

              const { name, qualification } = doctor;

              const siteCode = localStorage.getItem('SELECTED_SITECODE');

              return (
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h4 style={{ display: 'inline' }}>{name}</h4>{' '}
                        <DoctorSpecialization>
                          {qualification}
                        </DoctorSpecialization>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem' }}>
                      <Col sm={6}>
                        {bookingData ? (
                          <SelectSiteScan
                            selectedscanName={selectedscanName}
                            onscanNameSelect={selectedscanName => {
                              updateSelectedscanName(selectedscanName);
                              initiateGetCharges(
                                selectedscanName,
                                bookingData.mpid
                              );
                            }}
                            hosId={siteCode}
                          />
                        ) : null}
                      </Col>
                      <Col sm={6}>
                        {shouldShowChargesLoader ? (
                          <Loader loading={true} />
                        ) : null}
                        {chargesErrorMsg ? (
                          <span className="error">{chargesErrorMsg}</span>
                        ) : null}
                        {totalAmount ? (
                          <h6 style={{ fontWeight: 'bold' }}>
                            Amount: {totalAmount}
                          </h6>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }}
          </Query>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    totalAmount && parseFloat(totalAmount) >= 0 ? true : false
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreateInvoiceLoader ||
                        invoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreateInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          showConfirmation(
                            'Create Invoice Confirmation',
                            'Are you sure you want to create invoice for this appointment?',
                            () => createInvoice()
                          );
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewInvoice}>
            View Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreateScanInvoiceModal.propTypes = {
  doctorId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  bookingId: PropTypes.string
};

export default CreateScanInvoiceModal;
