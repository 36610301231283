import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  ButtonGroup,
  Row,
  Col
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import BookingsPrintAreaGrouped from './BookingPrintAreaGrouped';
import { FaColumns, FaTasks, FaThList, FaCog, FaClock } from 'react-icons/fa';
import Select from 'react-select';
import DateTimePicker from 'react-datepicker';
import moment from 'moment';

const attributes = [
  { label: 'Phone', value: 'userMobile' },
  { label: 'Appointment Time', value: 'slotTime', isDate: true },
  { label: 'Procedure', value: 'procedureName' },
  { label: 'Booking Time', value: 'created_at', isDate: true },
  { label: 'Check-in Time', value: 'checkinTS', isDate: true },
  { label: 'Booking Status', value: 'appointmentState' },
  { label: 'Wait Time', value: 'Wait Time' },
  { label: 'MPI', value: 'mpid' },
  { label: 'Notes', value: 'userQuestions' }
];

const PrintBookingsModal = props => {
  const printAreaRef = useRef();
  const [extraCols, setExtraCols] = useState([]);
  const [moreAttributes, setMoreAttributes] = useState([]);
  const [extraRowsCount, setExtraRowsCount] = useState(0);
  const [showCanceled, setShowCanceled] = useState(false);
  const [fontSize, setFontSize] = useState('1rem');
  const [fromTime, setFromTime] = useState(moment('8:00 am', 'hh:mm a'));
  const [toTime, setToTime] = useState(moment('10:00 pm', 'hh:mm a'));

  const { isOpen, toggle, searchCriteria, selectedHospitals } = props;
  const setColNames = e => {
    const v = e.target.value;
    if (!!v && v.trim().length > 0) {
      setExtraCols(
        v
          .trim()
          .replace(/,\s*$/, '')
          .split(/\s*,\s*/)
      );
    } else {
      setExtraCols([]);
    }
  };
  const handleFromTime = time => {
    if (moment(toTime).diff(time) <= 0) {
      setFromTime(moment(time));
      setToTime(moment(time).add(30, 'minute'));
    } else {
      setFromTime(moment(time));
    }
  };
  const handleToTime = time => {
    if (moment(fromTime).diff(time) >= 0) {
      setFromTime(moment(time));
      setToTime(moment(time).add(30, 'minute'));
    } else {
      setToTime(moment(time));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        Print Bookings
        <br />
        <small>Prints appointments you see on the screen</small>
      </ModalHeader>
      <ModalBody
        style={{ overflow: 'auto', minheight: '30rem', maxHeight: '40rem' }}
        className="print-modal"
      >
        <Row>
          <Col>
            <label>
              <FaTasks /> SELECT MORE ATTRIBUTES
            </label>
            <Select
              isMulti
              options={attributes}
              onChange={selected => setMoreAttributes(selected)}
            />
          </Col>
          <Col>
            <label>
              <FaColumns /> ADD EXTRA COLUMNS
            </label>
            <input
              onChange={setColNames}
              type="text"
              placeholder="type comma separate names"
            />
          </Col>
          <Col>
            <Col>
              <label>
                <FaThList /> EMPTY ROWS COUNT (MAX 10)
              </label>
              <input
                type="number"
                onChange={e =>
                  parseInt(e.target.value) >= 10
                    ? setExtraRowsCount(10)
                    : setExtraRowsCount(parseInt(e.target.value))
                }
                value={extraRowsCount ? extraRowsCount : ''}
                min={0}
                max={20}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div>
              <FaClock /> SELECT TIME RANGE
            </div>
            <Row>
              <Col xs={6}>
                <label>From</label>
                <DateTimePicker
                  placeholderText="From"
                  selected={fromTime}
                  onChange={handleFromTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={120}
                  dateFormat="h:mm a"
                  maxTime={moment('10:00 pm', 'hh:mm a')}
                  minTime={moment('8:00 am', 'hh:mm a')}
                  popperClassName="default-style-timepicker"
                />
              </Col>
              <Col xs={6}>
                <label>To</label>
                <DateTimePicker
                  placeholderText="To"
                  selected={toTime}
                  onChange={handleToTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={120}
                  dateFormat="h:mm a"
                  maxTime={moment('10:00 pm', 'hh:mm a')}
                  minTime={moment('8:00 am', 'hh:mm a')}
                  popperClassName="default-style-timepicker"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <label>
              <FaCog /> OTHER SETTINGS
            </label>
            <div>
              <input
                type="checkbox"
                onChange={e => setShowCanceled(e.target.checked)}
                id="setShowCancelledCheckbox"
                style={{ display: 'inline', width: '2rem' }}
              />
              <label
                htmlFor="setShowCancelledCheckbox"
                style={{ fontWeight: 'normal' }}
              >
                Include cancelled bookings
              </label>

              <span>{` | Print Font Size `}</span>
              <ButtonGroup>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setFontSize('0.5rem')}
                  outline={fontSize !== '0.5rem'}
                >
                  <small>A</small>
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setFontSize('1rem')}
                  outline={fontSize !== '1rem'}
                >
                  A
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setFontSize('1.5rem')}
                  style={{ fontSize: '1.2rem' }}
                  outline={fontSize !== '1.5rem'}
                >
                  A
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <BookingsPrintAreaGrouped
          searchCriteria={{ ...searchCriteria, hospitals: selectedHospitals }}
          fields={extraCols}
          extraAttrs={moreAttributes}
          ref={printAreaRef}
          showCanceled={showCanceled}
          emptyRowsCount={extraRowsCount}
          fromTime={moment(fromTime).format('hh:mm a')}
          toTime={moment(toTime).format('hh:mm a')}
        />
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          pageStyle={`
          @media print {
            table {
                font-size: ${fontSize};
            }
          }`}
          trigger={() => (
            <Button size="sm" color="primary">
              PRINT
            </Button>
          )}
          content={() => printAreaRef.current}
        />
        <Button color="secondary" size="sm" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PrintBookingsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired,
  selectedHospitals: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default PrintBookingsModal;
