import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SlotProtoCreateEditWizard from './SlotProtoCreateEditWizard';

const SlotPrototypeFormModal = ({
  entityId,
  slotPrototypeId,
  isOpen,
  toggle,
  name
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        {slotPrototypeId ? 'Edit Slot Prototype' : 'New Slot Prototype'}
      </ModalHeader>
      <ModalBody>
        <SlotProtoCreateEditWizard
          entityId={entityId}
          slotPrototypeId={slotPrototypeId}
          onFinished={() => toggle()}
          name={name}
        />
      </ModalBody>
    </Modal>
  );
};

SlotPrototypeFormModal.propTypes = {
  slotPrototypeId: PropTypes.number,
  entityId: PropTypes.number,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  name: PropTypes.string
};

SlotPrototypeFormModal.defaultProps = {
  isOpen: false,
  toggle: () => {}
};

export default SlotPrototypeFormModal;
