import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import DepartmentsSelect from '../components/DepartmentsSelect';
import LookupSelect from '../components/LookupSelect';

const DoctorSearchBar = ({ onSubmit }) => {
  return (
    <Row>
      <Col>
        <Formik
          initialValues={{ globalSearch: false }}
          onSubmit={values => {
            // if (values.hasOp && !values.hasOps) {
            //   delete values.hasOp;
            // }
            onSubmit(values);
          }}
          render={({ values }) => (
            <Form>
              <Row>
                <Col sm={2} className="form-row">
                  <Field type="text" name="name" placeholder="Name" />
                </Col>
                <Col sm={2} className="form-row">
                  <Field type="text" name="siteCode" placeholder="Site Code" />
                </Col>
                <Col sm={4}>
                  <Field
                    name="specialities"
                    component={({ form, field }) => (
                      <LookupSelect
                        lookup="spl"
                        placeholder="Specializations"
                        onChange={v => form.setFieldValue('specialities', v)}
                        value={field.value || []}
                        isMulti
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name="departmentIds"
                    component={({ form, field }) => (
                      <DepartmentsSelect
                        placeholder="Departments"
                        onChange={v => form.setFieldValue('departmentIds', v)}
                        value={field.value || []}
                        isMulti
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Field
                    name="globalSearch"
                    type="checkbox"
                    checked={values.showOnlyConfigured}
                  />
                  {` Global Search | `}
                  <Field name="hasOp" type="checkbox" checked={values.hasOp} />
                  {` Has OP `}
                  <Button
                    color="primary"
                    type="submit"
                    size="sm"
                    style={{ marginRight: '20px' }}
                  >
                    SEARCH
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Col>
    </Row>
  );
};

DoctorSearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default DoctorSearchBar;
