import { gql } from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { FaMobileAlt } from 'react-icons/fa';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import * as yup from 'yup';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  CX_USER_DB_BASE_URL,
  S2S_CX_SERVER_API_KEY,
  S2S_C9USERMORE_API_KEY
} from '../constants';
import {
  displayError,
  displaySuccess,
  getHospitalSiteCode,
  logAmplitudeEvent
} from '../utilities';
import DiscountInput, { discountTypes } from './DiscountInput';
import Loader from './Loader';
import PaymentForm from './PaymentForm';
import SelectProcedureV2 from './SelectProcedureV2';
import Veil from './Veil';
import SelectClinixProcedure from './SelectClinixProcedure';

const styles = {
  formLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 22
  },
  colLabel: {
    marginTop: 18
  }
};

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const DoctorSpecialization = styled.span`
  color: '#808080',
  textTransform: 'capitalize'
`;
let removeCouponButton = true;

const DOC_QUERY = gql`
  query doctor($id: String) {
    doctor: getDoctor(id: $id) {
      id
      name
      qualification
      lifetrenzId
      hospitals: getHospitals {
        id
        name
      }
    }
  }
`;

const DISPLAY_BOOKING_QUERY = gql`
  query DISPLAY_BOOKING_QUERY($bookingId: String) {
    booking: getBooking(id: $bookingId) {
      id
      mpid
      prospectId
      hospitalId
      userName
      userMobile
      userEmail
      slotTime
      departmentId
    }
  }
`;

const PModeSchema = yup.object().shape({
  paymentMode: yup.string().required('Payment mode is required')
});

const CardSchema = yup.object().shape({
  paymentId: yup.number().required('Transaction ID is required'),
  paymentMode: yup.string().required('Payment mode is required'),
  cardNumber: yup
    .number('Card Number should be numeric')
    .required('Card Number is required')
    .test(
      'len',
      'Card Number must be exactly 4 digit',
      val => val && val.toString().length === 4
    )
});

const UPISchema = yup.object().shape({
  paymentId: yup.number().required('Transaction ID is required'),
  paymentMode: yup.string().required('Payment mode is required')
});

const getChargesFromLT = (
  mpid,
  ltSiteCode,
  slotTime,
  ltDoctorId,
  ltProcedureId
) => {
  const url = 'https://rus0ja0gxh.execute-api.us-east-1.amazonaws.com/Prod';
  const req_body = {
    body: {
      service_list: [
        {
          site_code: ltSiteCode,
          service_type: ltProcedureId,
          patient_mpi: mpid,
          consultant_id: ltDoctorId,
          date_of_appointment: moment(slotTime).format('YYYY-MM-DD'),
          time_of_appointment: null, // time of appointment is not required to get charges
          quantity: '1'
        }
      ]
    }
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

const generateLTInvoice = (
  paymentId,
  productinfo,
  amount,
  cardnumber,
  firstname,
  email,
  phone,
  mpid,
  ltdoctorid,
  doctorname,
  sitecode,
  ltprocedureid,
  slotTime,
  paymentsource,
  couponcode,
  userEmail
) => {
  const url = `${CX_SERVER_BASE_URL}/LT/book/success`;
  const req_body = {
    mihpayid: paymentId,
    amount: amount,
    addedon: moment().format('YYYY-MM-DD HH:mm:ss'),
    productinfo: productinfo,
    firstname: firstname,
    email: email,
    phone: phone,
    mpid: mpid,
    ltdoctorid: ltdoctorid,
    doctorname: doctorname,
    ltprocedureid: ltprocedureid,
    sitecode: sitecode,
    appointmentts: slotTime,
    source: 'ams',
    udf1: mpid,
    udf2: `ams~${ltdoctorid}~${doctorname}~${ltprocedureid}~${couponcode}`,
    udf3: sitecode,
    udf4: moment(slotTime).format('YYYY-MM-DD'),
    udf5: moment(slotTime).format('HH:mm'),
    card_no: cardnumber,
    payment_source: paymentsource,
    userEmail: userEmail
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

const applyCoupon = (
  couponcode,
  mobile,
  mpid,
  registeredSiteCode,
  departmentid
) => {
  const url = `${CX_SERVER_BASE_URL}/coupon/apply/code`;
  const req_body = {
    couponcode: couponcode,
    mpid: mpid,
    registeredSiteCode: registeredSiteCode,
    mobile: mobile,
    departmentid: departmentid
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

const removeCoupon = (
  couponcode,
  mobile,
  mpid,
  registeredSiteCode,
  emailId,
  dateTime
) => {
  const url = `${CX_SERVER_BASE_URL}/coupon/remove/code`;
  const req_body = {
    couponcode: couponcode,
    mpid: mpid,
    emailId: emailId,
    registeredSiteCode: registeredSiteCode,
    mobile: mobile,
    dateTime: dateTime
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
    },
    body: JSON.stringify(req_body)
  };

  return fetch(url, fetchOpts).then(r => r.json());
};

const CreateInvoiceModal = props => {
  const {
    isOpen,
    toggle,
    doctorId,
    bookingId,
    selectedHospitalDetails
  } = props;
  const [selectedProcedure, updateSelectedProcedure] = useState();
  const [shouldShowChargesLoader, showChargesLoader] = useState(false);
  const [bookingData, updateBookingData] = useState(undefined);
  const [doctorData, updateDoctorData] = useState(undefined);
  const [totalAmount, updateTotalAmount] = useState(undefined);
  const [chargesErrorMsg, updateChargesErrorMsg] = useState('');
  const [paymentData, updatePaymentData] = useState({});
  const [couponCode, updateCouponCode] = useState('');
  const [procedureList, updateProcedureList] = useState('');
  const [customerDetails, setCustomerDetails] = useState({});
  const [consultChargeloader, updateConsultChargeloader] = useState(false);
  const [procedureCompleteDetails, updateProcedureCompleteDetails] = useState(
    ''
  );
  const [selectedProcedureDetails, updateSelectedProcedureDetails] = useState();
  const [shouldShowCreateInvoiceLoader, showCreateInvoiceLoader] = useState(
    false
  );
  const [invoiceGenerationSuccess, updateInvoiceGenerationSuccess] = useState(
    false
  );
  const [shouldApplyCouponLoader, showApplyCouponLoader] = useState(false);
  const [pdfLink, updatePdfLink] = useState(null);
  const [shouldShowTextBox, showTextBox] = useState(false);

  const [discount, updateDiscount] = useState(undefined);
  const [discountType, setDiscountType] = useState(
    discountTypes.find(item => item.value === 'percentage')
  );
  const [discountNotes, setDiscountNotes] = useState('');

  useEffect(() => {
    updateSelectedProcedure();
    showChargesLoader(false);
    updateBookingData(undefined);
    updateDoctorData(undefined);
    updateTotalAmount(undefined);
    updateDiscount(undefined);
    updateChargesErrorMsg('');
    updatePaymentData({});
    updateCouponCode('');
    updateProcedureList('');
    setCustomerDetails({});
    updateConsultChargeloader(false);
    updateProcedureCompleteDetails('');
    updateSelectedProcedureDetails();
    showCreateInvoiceLoader(false);
    updateInvoiceGenerationSuccess(false);
    showApplyCouponLoader(false);
    updatePdfLink(null);
    showTextBox(false);
    if (isOpen && selectedHospitalDetails.chargeMaster === 'zoho_books') {
      getDocChargesZohoBooks(doctorId, 'Consult');
    }
    // eslint-disable-next-line
  }, [isOpen]);

  // function to fetch customer's date of birth and gender details.
  const fetchCustomerDetails = userMPID => {
    const url = `${CX_USER_DB_BASE_URL}/mpid/v2/${userMPID}`;
    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2S_C9USERMORE_API_KEY}`
      }
    };
    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getCustomerDetails = async userMPID => {
    try {
      const response = await fetchCustomerDetails(userMPID);
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data
      ) {
        setCustomerDetails(response.data[0]);
      } else {
        setCustomerDetails({});
      }
    } catch (error) {
      setCustomerDetails({});
    }
  };

  // function to fetch doctor charges configured in zoho books based on amsdoctorid and charge item type.
  const fetchDocCharges = (doctorId, chargeType) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/get_charges`;

    const req_body = {
      amsdoctorid: doctorId,
      cf_chargeitemtype: chargeType,
      cf_hospitalid: selectedHospitalDetails.id,
      siteCode: selectedHospitalDetails.siteCode
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getDocChargesZohoBooks = async (doctorId, chargeType) => {
    try {
      updateConsultChargeloader(true);
      const response = await fetchDocCharges(doctorId, chargeType);
      if (response && response.status && response.status === 1) {
        updateProcedureCompleteDetails(response.data);
        let procedureList = response.data.map(p => p.item_name);
        updateProcedureList(procedureList);
        logAmplitudeEvent(
          'doc_charge_fetched_successfully',
          {
            doctorId: doctorId,
            chargeType: chargeType
          },
          true
        );
        updateConsultChargeloader(false);
      } else {
        logAmplitudeEvent(
          'doc_charge_fetching_failed',
          {
            doctorId: doctorId,
            chargeType: chargeType,
            response: response
          },
          true
        );
        updateConsultChargeloader(false);
      }
    } catch (error) {
      logAmplitudeEvent(
        'doc_charge_fetching_failed',
        {
          doctorId: doctorId,
          chargeType: chargeType,
          error: error
        },
        true
      );
      updateConsultChargeloader(false);
    }
  };

  // function to calculate patient age in years and months based on his date of birth.
  const calculateAge = dateString => {
    let today = new Date();
    let DOB = new Date(dateString);
    let totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    let years = today.getFullYear() - DOB.getFullYear();

    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    let months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months === 0) months = 11;
    } else {
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    let age = years + ' years ' + months + ' months ';
    return age;
  };

  // function to create customer’s payment invoice based on get_charges API response and other customer payment details like ("payment_mode").
  const createInvoiceInUsersServiceAndZohoBooks = (
    siteCode,
    patientAge,
    patientGender
  ) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      appointmentId: bookingData.id,
      mpid: bookingData.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      gst_no: selectedHospitalDetails.gstNo,
      first_name: bookingData.userName,
      email: bookingData.userEmail,
      mobile: bookingData.userMobile,
      product_type: selectedProcedureDetails[0].product_type,
      sitecode: siteCode,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_address: selectedHospitalDetails.address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      line_items: selectedProcedureDetails,
      procedure: 'Consultation',
      logo_url: selectedHospitalDetails.logoUrl,
      ams_doctor_id: doctorData.id,
      doctor_name: doctorData.name,
      notes: {
        patient_age: patientAge,
        patient_gender: patientGender,
        selected_hospital_name: selectedHospitalDetails.name,
        ...(!selectedHospitalDetails.isCloudnineHosp
          ? { selected_clinic_address: selectedHospitalDetails.address }
          : { selected_hospital_address: selectedHospitalDetails.address })
      },
      discount: discount ? parseInt(discount) : 0,
      discountType: discountType.value
    };

    if (req_body.discount && discountNotes) {
      req_body.discountNotes = discountNotes;
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createInvoiceInUsersService = (siteCode, patientAge, patientGender) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_consult_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      mpid: bookingData.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      procedure: 'Consultation',
      first_name: bookingData.userName,
      email: bookingData.userEmail,
      mobile: bookingData.userMobile,
      appointmentId: bookingData.id,
      gst_no: selectedHospitalDetails.gstNo,
      sitecode: siteCode,
      doctor_id: doctorData.id,
      doctor_name: doctorData.name,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      logo_url: selectedHospitalDetails.logoUrl,
      notes: {
        patient_age: patientAge,
        patient_gender: patientGender,
        ...(selectedHospitalDetails.isCloudnineHosp
          ? { selected_hospital_address: selectedHospitalDetails.address }
          : { selected_clinic_address: selectedHospitalDetails.address })
      },
      discount: discount ? parseInt(discount) : 0,
      discountType: discountType.value,
      line_items: selectedProcedureDetails
    };

    if (req_body.discount && discountNotes) {
      req_body.discountNotes = discountNotes;
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    setDiscountNotes('');
    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createPaymentInvoice = async () => {
    let patientAge = calculateAge(customerDetails.date_of_birth);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    const sitecodeResp = await getSiteCodeofId(bookingData.hospitalId);
    const siteCode = sitecodeResp[0].siteCode;
    try {
      let response = {};
      if (
        selectedHospitalDetails.settings &&
        selectedHospitalDetails.settings.chargeMaster &&
        selectedHospitalDetails.settings.chargeMaster
          .invoiceGeneratorForConsultation === 'users_service_and_zoho_books'
      ) {
        response = await createInvoiceInUsersServiceAndZohoBooks(
          siteCode,
          patientAge,
          customerDetails.gender
        );
      } else {
        response = await createInvoiceInUsersService(
          siteCode,
          patientAge,
          customerDetails.gender
        );
      }

      if (response && response.status && response.status === 1) {
        showCreateInvoiceLoader(false);
        updateInvoiceGenerationSuccess(true);
        displaySuccess('Invoice generated successfully');
        updatePdfLink(response.data.s3pdflink);

        logAmplitudeEvent(
          'Invoice_created_successfully',
          {
            mpid: bookingData.mpid,
            salespersonName: loggedInUser.name,
            referenceNumber: paymentData.transactionId,
            txnId: paymentData.transactionId,
            paymentMode: paymentData.paymentMode,
            itemId: selectedProcedureDetails.item_id,
            procedure: selectedProcedureDetails.cf_procedure,
            taxExemptionId: selectedProcedureDetails.tax_exemption_id,
            description: selectedProcedureDetails.description,
            gstNo: selectedHospitalDetails.gstNo,
            userName: bookingData.userName,
            email: bookingData.userEmail,
            mobile: bookingData.userMobile,
            appointmentId: bookingData.id,
            product_type: selectedProcedureDetails.product_type,
            hsn_or_sac: selectedProcedureDetails.hsn_or_sac,
            rate: selectedProcedureDetails.rate,
            sitecode: siteCode,
            doctor_id: doctorData.id,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            doctor_name: doctorData.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            s3pdflink: response.s3pdflink
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'Invoice_creation_failed',
          {
            mpid: bookingData.mpid,
            salespersonName: loggedInUser.name,
            referenceNumber: paymentData.transactionId,
            txnId: paymentData.transactionId,
            paymentMode: paymentData.paymentMode,
            itemId: selectedProcedureDetails.item_id,
            procedure: selectedProcedureDetails.cf_procedure,
            taxExemptionId: selectedProcedureDetails.tax_exemption_id,
            description: selectedProcedureDetails.description,
            gstNo: selectedHospitalDetails.gstNo,
            userName: bookingData.userName,
            email: bookingData.userEmail,
            mobile: bookingData.userMobile,
            appointmentId: bookingData.id,
            product_type: selectedProcedureDetails.product_type,
            hsn_or_sac: selectedProcedureDetails.hsn_or_sac,
            rate: selectedProcedureDetails.rate,
            sitecode: siteCode,
            doctor_id: doctorData.id,
            doctor_name: doctorData.name,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            response: response
          },
          true
        );
        showCreateInvoiceLoader(false);
        const emsg = `Invoice generation failed: ${response}`;
        throw new Error(emsg);
      }
    } catch (error) {
      logAmplitudeEvent(
        'Invoice_creation_failed',
        {
          mpid: bookingData.mpid,
          salespersonName: loggedInUser.name,
          referenceNumber: paymentData.transactionId,
          txnId: paymentData.transactionId,
          paymentMode: paymentData.paymentMode,
          itemId: selectedProcedureDetails.item_id,
          procedure: selectedProcedureDetails.cf_procedure,
          taxExemptionId: selectedProcedureDetails.tax_exemption_id,
          description: selectedProcedureDetails.description,
          gstNo: selectedHospitalDetails.gstNo,
          userName: bookingData.userName,
          email: bookingData.userEmail,
          mobile: bookingData.userMobile,
          appointmentId: bookingData.id,
          product_type: selectedProcedureDetails.product_type,
          hsn_or_sac: selectedProcedureDetails.hsn_or_sac,
          rate: selectedProcedureDetails.rate,
          sitecode: siteCode,
          doctor_id: doctorData.id,
          doctor_name: doctorData.name,
          hospital_id: selectedHospitalDetails.id.toString(),
          hospital_name: selectedHospitalDetails.name,
          hospital_address: selectedHospitalDetails.address,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          error: error
        },
        true
      );
      showCreateInvoiceLoader(false);
      displayError(error);
    }
  };

  const handleOnClose = () => {
    updateSelectedProcedure();
    updateBookingData(undefined);
    updateDoctorData(undefined);
    showChargesLoader(false);
    updateTotalAmount(undefined);
    updateDiscount(undefined);
    updateChargesErrorMsg('');
    updatePaymentData({});
    updateCouponCode('');
    showCreateInvoiceLoader(false);
    updateInvoiceGenerationSuccess(false);
    showApplyCouponLoader(false);
    updatePdfLink(null);
    showTextBox(false);
    if (removeCouponButton === true) {
      removeCouponCode();
      // showConfirmation(
      //   'Remove Coupon Confirmation',
      //   'Are you sure you want to remove the coupon?',
      //   () => removeCouponCode()
      // );
    }
  };

  const getSiteCodeofId = async id => {
    const getData = await getHospitalSiteCode();
    const hospData = getData.data.getHospitals;

    return hospData.filter(opt => opt.id === id);
  };
  const initiateGetCharges = async (
    mpid,
    hospitalId,
    slotTime,
    lifetrenzId,
    selectedProcedure
  ) => {
    showChargesLoader(true);
    updateChargesErrorMsg('');
    updateTotalAmount(undefined);
    updateDiscount(undefined);
    try {
      const datass = await getSiteCodeofId(hospitalId);
      const siteCode = datass[0].siteCode;

      if (!siteCode) throw new Error('Invalid site code');
      const res = await getChargesFromLT(
        mpid,
        siteCode,
        slotTime,
        lifetrenzId,
        selectedProcedure
      );
      if (res && res.head && res.head.StatusValue === 200) {
        //show charges
        const totalAmount = res.body.Data[0].total_payable;
        const discount =
          res.body.Data[0].item_list[0].discounts_and_packages[0].amount;
        showChargesLoader(false);
        updateTotalAmount(totalAmount);
        updateDiscount(discount);
        updateChargesErrorMsg('');
      } else throw new Error('Charge not available');
    } catch (error) {
      showChargesLoader(false);
      updateChargesErrorMsg('Some error occured. Try again later');
      updateTotalAmount(undefined);
      updateDiscount(undefined);
    }
  };

  if (!doctorId || !bookingId) {
    return null;
  }

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };

  const createInvoice = async () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    showCreateInvoiceLoader(true);
    updateInvoiceGenerationSuccess(false);
    if (selectedHospitalDetails.chargeMaster === 'zoho_books') {
      createPaymentInvoice();
    } else {
      try {
        const datass = await getSiteCodeofId(bookingData.hospitalId);
        const siteCode = datass[0].siteCode;

        let data = {
          paymentId: paymentData.transactionId,
          bookingId,
          totalAmount,
          cardNumber: paymentData.cardNumber,
          userName: bookingData.userName,
          userEmail: bookingData.userEmail,
          userMobile: bookingData.userMobile,
          mpid: bookingData.mpid,
          lifetrenzId: doctorData.lifetrenzId,
          name: doctorData.name,
          siteCode,
          selectedProcedure,
          slotTime: bookingData.slotTime,
          paymentMode: paymentData.paymentMode,
          couponCode: couponCode
        };

        await PModeSchema.validate(data);
        if (data.paymentMode.toLowerCase().includes('card'))
          await CardSchema.validate(data);
        if (data.paymentMode.toLowerCase() === 'upi') {
          data.cardNumber = null;
          await UPISchema.validate(data);
        }
        if (data.paymentMode.toLowerCase() === 'cash') {
          data.paymentId = null;
          data.cardNumber = null;
        }

        const eventProps = {
          PatientName: data.userName,
          PaymentMode: data.paymentMode,
          SelectedProcedure: data.selectedProcedure
        };

        logAmplitudeEvent(
          'Create_Invoice',
          Object.assign(eventProps, { status: 'initiated' }),
          true
        );

        const res = await generateLTInvoice(
          data.paymentId,
          data.bookingId,
          data.totalAmount,
          data.cardNumber,
          data.userName,
          data.userEmail,
          data.userMobile,
          data.mpid,
          data.lifetrenzId,
          data.name,
          data.siteCode,
          data.selectedProcedure,
          data.slotTime,
          data.paymentMode,
          data.couponCode,
          loggedInUser['email']
        );
        if (res && res.status) {
          removeCouponButton = false;
          showCreateInvoiceLoader(false);
          updateInvoiceGenerationSuccess(true);
          displaySuccess('Invoice generated successfully');
          updatePdfLink(res.s3pdflink);
          logAmplitudeEvent(
            'Create_Invoice',
            Object.assign(eventProps, { status: 'success' }),
            true
          );
        } else {
          removeCouponButton = true;
          const emsg = `Invoice generation failed: ${res.msg}`;
          throw new Error(emsg);
        }
      } catch (error) {
        showCreateInvoiceLoader(false);
        displayError(error);
      }
    }
  };

  const updateCouponCodeDetails = value => {
    updateCouponCode(value);
  };

  const applyCouponCode = async () => {
    showApplyCouponLoader(true);
    try {
      // const siteCode = getSiteCode(bookingData.hospitalId);
      const datass = await getSiteCodeofId(bookingData.hospitalId);
      const siteCode = datass[0].siteCode;

      let Coupondata = {
        couponcode: couponCode,
        mobile: bookingData.userMobile,
        mpid: bookingData.mpid,
        registeredSiteCode: siteCode,
        departmentid: bookingData.departmentId
      };
      const eventProps = Coupondata;

      logAmplitudeEvent(
        'Apply_Coupon',
        Object.assign(eventProps, { status: 'applied' }),
        true
      );

      if (Coupondata.couponcode) {
        const res = await applyCoupon(
          Coupondata.couponcode,
          Coupondata.mobile,
          Coupondata.mpid,
          Coupondata.registeredSiteCode,
          Coupondata.departmentid
        );
        if (res && res.status) {
          showApplyCouponLoader(false);
          showTextBox(true);

          removeCouponButton = true;
          displaySuccess('Coupon Applied successfully');
          logAmplitudeEvent(
            'Apply_Coupon',
            Object.assign(eventProps, { status: 'success' }),
            true
          );
          initiateGetCharges(
            bookingData.mpid,
            bookingData.hospitalId,
            bookingData.slotTime,
            doctorData.lifetrenzId,
            selectedProcedure
          );
        } else {
          showTextBox(false);
          showApplyCouponLoader(false);
          removeCouponButton = true;
          const emsg = `Coupon Application failed: ${res.msg}`;
          throw new Error(emsg);
        }
      }
    } catch (error) {
      displayError(error);
    }
  };

  const removeCouponCode = async () => {
    showTextBox(false);
    updateCouponCodeDetails('');

    try {
      // const siteCode = getSiteCode(bookingData.hospitalId);
      const datass = await getSiteCodeofId(bookingData.hospitalId);
      const siteCode = datass[0].siteCode;

      let Coupondata = {
        couponcode: couponCode,
        mobile: bookingData.userMobile,
        mpid: bookingData.mpid,
        registeredSiteCode: siteCode,
        emailId: bookingData.userEmail,
        dateTime: moment().format('DD-MM-YYYY hh:mm')
      };
      const eventProps = Coupondata;

      logAmplitudeEvent(
        'Remove_Coupon',
        Object.assign(eventProps, { status: 'removed' }),
        true
      );

      const res = await removeCoupon(
        Coupondata.couponcode,
        Coupondata.mobile,
        Coupondata.mpid,
        Coupondata.registeredSiteCode,
        Coupondata.emailId,
        Coupondata.dateTime
      );
      if (res && res.status) {
        removeCouponButton = false;
        displaySuccess('Coupon Removed successfully');
        logAmplitudeEvent(
          'Remove_Coupon',
          Object.assign(eventProps, { status: 'success' }),
          true
        );
        initiateGetCharges(
          bookingData.mpid,
          bookingData.hospitalId,
          bookingData.slotTime,
          doctorData.lifetrenzId,
          selectedProcedure
        );
      } else {
        const emsg = `Coupon Remove failed: ${res.msg}`;
        throw new Error(emsg);
      }
    } catch (error) {
      // displayError(error);
    }
  };

  const viewInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  const calcFinalAmount = () => {
    if (selectedHospitalDetails.chargeMaster === 'lt') {
      return totalAmount;
    }

    if (!discount || discount === '0') {
      return totalAmount;
    }

    if (discountType.value === 'percentage') {
      return (totalAmount - totalAmount * (parseInt(discount) / 100)).toFixed(
        2
      );
    } else {
      return totalAmount - parseInt(discount);
    }
  };

  const calcDiscountedAmount = () => {
    if (selectedHospitalDetails.chargeMaster === 'lt') {
      return discount;
    }

    if (discountType.value === 'percentage') {
      return (totalAmount * (parseInt(discount) / 100)).toFixed(2);
    } else {
      return discount;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Create Invoice</ModalHeader>
      <ModalBody>
        <Container>
          <Query
            query={DISPLAY_BOOKING_QUERY}
            variables={{ bookingId }}
            onCompleted={data => {
              getCustomerDetails(data.booking.mpid);
              updateBookingData(data.booking);
            }}
          >
            {({ loading, data }) => {
              if (loading) return <Loader />;

              const {
                booking: { userName, userMobile, mpid, slotTime, prospectId }
              } = data;

              return (
                <Row>
                  <Col sm={9}>
                    <h2>{userName}</h2>
                    <div>
                      <span className="text-muted">
                        MPI: {mpid ? mpid : 'Not Available'}{' '}
                      </span>
                    </div>
                    <div>
                      <span className="text-muted">
                        Prospect ID: {prospectId}
                      </span>
                    </div>
                    <div>
                      <span
                        className="appt-time"
                        style={{ fontWeight: 'bold' }}
                      >
                        Appointment Time:{' '}
                        {`${moment(slotTime).format(
                          'ddd DD MMM YYYY hh:mm A'
                        )}`}
                      </span>
                    </div>
                    <hr />
                  </Col>
                  <Col sm={3} className="text-right">
                    <FaMobileAlt />
                    <span>{` ${userMobile}`}</span>
                  </Col>
                </Row>
              );
            }}
          </Query>
          <Query
            query={DOC_QUERY}
            variables={{ id: doctorId }}
            onCompleted={data => updateDoctorData(data.doctor)}
          >
            {({ loading, data: { doctor } = { doctor: {} } }) => {
              if (loading) {
                return <Loader loading={true} />;
              }

              const { name, qualification, lifetrenzId } = doctor;

              return (
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h4 style={{ display: 'inline' }}>{name}</h4>{' '}
                        <DoctorSpecialization>
                          {qualification}
                        </DoctorSpecialization>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1rem' }}>
                      <Col sm={6}>
                        {bookingData ? (
                          selectedHospitalDetails.chargeMaster === 'lt' ? (
                            <SelectProcedureV2
                              procedureList={procedureList}
                              selectedHospitalDetails={selectedHospitalDetails}
                              selectedProcedure={selectedProcedure}
                              onProcedureSelect={selectedProcedure => {
                                updateSelectedProcedure(selectedProcedure);
                                initiateGetCharges(
                                  bookingData.mpid,
                                  bookingData.hospitalId,
                                  bookingData.slotTime,
                                  lifetrenzId,
                                  selectedProcedure
                                );
                              }}
                            />
                          ) : consultChargeloader ? (
                            <Loader />
                          ) : (
                            <SelectClinixProcedure
                              procedureList={procedureList}
                              procedureType={'Consultation'}
                              selectedProcedure={selectedProcedure}
                              enteredSearchText={() => {}}
                              onProcedureSelect={selectedProcedure => {
                                let selectedProcedureArr = [];
                                let selectedProcedureCompleteArr = [];

                                procedureCompleteDetails.forEach(
                                  procedureDetails => {
                                    selectedProcedure.forEach(procedure => {
                                      if (
                                        procedureDetails.item_name ===
                                        procedure.value
                                      ) {
                                        selectedProcedureCompleteArr.push(
                                          procedureDetails
                                        );
                                        selectedProcedureArr.push({
                                          item_id: procedureDetails.item_id,
                                          header_name: `${procedureDetails.item_name} invoice`,
                                          is_taxable: false,
                                          tax_exemption_id:
                                            procedureDetails.tax_exemption_id,
                                          name: procedureDetails.name,
                                          description:
                                            procedureDetails.description,
                                          hsn_or_sac:
                                            procedureDetails.hsn_or_sac,
                                          product_type:
                                            procedureDetails.product_type,
                                          rate: procedureDetails.rate,
                                          item_total: procedureDetails.rate
                                        });
                                      }
                                    });
                                  }
                                );

                                let totalProcedureAmount = 0;

                                selectedProcedureArr = selectedProcedureArr.filter(
                                  (arr, index, self) =>
                                    index ===
                                    self.findIndex(
                                      t => t.item_id === arr.item_id
                                    )
                                );

                                selectedProcedureCompleteArr = selectedProcedureCompleteArr.filter(
                                  (arr, index, self) =>
                                    index ===
                                    self.findIndex(
                                      t => t.item_id === arr.item_id
                                    )
                                );

                                selectedProcedureCompleteArr.forEach(
                                  procedure => {
                                    totalProcedureAmount =
                                      totalProcedureAmount + procedure.rate;
                                  }
                                );

                                if (totalProcedureAmount > 0) {
                                  showChargesLoader(false);
                                  updateChargesErrorMsg('');
                                  updateTotalAmount(totalProcedureAmount);
                                  updateSelectedProcedureDetails(
                                    selectedProcedureArr
                                  );
                                } else {
                                  showChargesLoader(false);
                                  updateChargesErrorMsg(
                                    'Some error occured. Try again later'
                                  );
                                  updateSelectedProcedureDetails();
                                  updateTotalAmount();
                                }
                              }}
                            />
                          )
                        ) : null}
                      </Col>
                      <Col sm={6}>
                        {shouldShowChargesLoader ? (
                          <Loader loading={true} />
                        ) : null}
                        {chargesErrorMsg ? (
                          <span className="error">{chargesErrorMsg}</span>
                        ) : null}
                        {discount && discount !== '0' ? (
                          <h6 style={{ fontWeight: 'bold' }}>
                            Discount Applied: &#x20B9; {calcDiscountedAmount()}
                          </h6>
                        ) : null}
                        {totalAmount ? (
                          <h6 style={{ fontWeight: 'bold' }}>
                            Total Amount: &#x20B9; {calcFinalAmount()}
                          </h6>
                        ) : null}
                      </Col>
                    </Row>
                    {selectedProcedure &&
                    selectedHospitalDetails.chargeMaster === 'lt' ? (
                      <Row className="form-row">
                        <Col
                          xs={1.5}
                          className="text-left"
                          style={styles.formLabel}
                        >
                          Coupon Code:
                        </Col>
                        <Col xs={5} style={styles.colLabel}>
                          <input
                            type="text"
                            onChange={e =>
                              updateCouponCodeDetails(e.target.value)
                            }
                            value={couponCode}
                            disabled={shouldShowTextBox}
                          />
                        </Col>
                        <Col xs={2.5} style={styles.colLabel}>
                          <fieldset disabled={shouldApplyCouponLoader}>
                            {shouldApplyCouponLoader && <Veil />}
                            <Button
                              color="primary"
                              disabled={!couponCode}
                              onClick={() => applyCouponCode()}
                            >
                              Apply Coupon
                            </Button>
                          </fieldset>
                        </Col>
                        {!pdfLink ? (
                          <Col xs={2.5} style={styles.colLabel}>
                            <Button
                              color="danger"
                              disabled={!couponCode}
                              onClick={() => {
                                showConfirmation(
                                  'Remove Coupon Confirmation',
                                  'Are you sure you want to remove the coupon?',
                                  () => removeCouponCode()
                                );
                              }}
                            >
                              Remove Coupon
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                    ) : selectedProcedureDetails ? (
                      <Row className="form-row mt-2">
                        <Col>
                          <DiscountInput
                            discount={discount || ''}
                            setDiscount={updateDiscount}
                            discountType={discountType}
                            setDiscountType={setDiscountType}
                            discountNotes={discountNotes}
                            setDiscountNotes={setDiscountNotes}
                            totalAmount={totalAmount}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              );
            }}
          </Query>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    totalAmount && parseFloat(totalAmount) >= 0 ? true : false
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreateInvoiceLoader ||
                        invoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreateInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          if (
                            (!paymentData.transactionId ||
                              paymentData.transactionId.length < 5) &&
                            paymentData.paymentMode !== 'Cash'
                          ) {
                            displayError(
                              'Please provide a valid transaction id, minimum 5 digits !!!'
                            );
                            return;
                          } else {
                            showConfirmation(
                              'Create Invoice Confirmation',
                              'Are you sure you want to create invoice for this appointment?',
                              () => createInvoice()
                            );
                          }
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewInvoice}>
            View Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CreateInvoiceModal.propTypes = {
  doctorId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  bookingId: PropTypes.string,
  couponData: PropTypes.object,
  selectedHospitalDetails: PropTypes.object
};

export default CreateInvoiceModal;
