import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { useMutation } from 'react-apollo';
import { UPDATE_DOCTOR_MUTATION } from '../queries';
import * as Yup from 'yup';
import omit from 'lodash/omit';
import { displayError, displaySuccess } from '../utilities';
import { FaTrash, FaPlus } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import LookupSelect from './LookupSelect';
import ConfirmationDialog from './ConfirmationDialog';
import TextArea from 'react-autosize-textarea';
import { getLoggedInUser } from '../permissions';

const TrashButtonStyled = styled(Button)`
  color: red;
  background: none;
  border: none;
  outline: none;
`;

const TrashButton = ({ index, action, toDelete }) => {
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(prev => !prev);
  };

  const handleConfirm = () => {
    action(index);
    toggle();
  };

  return (
    <>
      <div className="exp-input d-flex">
        <TrashButtonStyled onClick={toggle}>
          <FaTrash />
        </TrashButtonStyled>
      </div>
      <ConfirmationDialog
        show={show}
        onConfirm={handleConfirm}
        onCancel={toggle}
        title={`Delete Doctor's '${toDelete}'`}
        message={`Are you sure you want to delete this '${toDelete}'?`}
        buttonText={'Delete'}
      />
    </>
  );
};

TrashButton.propTypes = {
  index: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  toDelete: PropTypes.string.isRequired
};

const validateDoc = deptSpecializations =>
  Yup.object().shape({
    departmentId: Yup.number().required('Required'),
    medicalCouncilRegNo: Yup.string()
      .transform((value, originalValue) => {
        return originalValue === null || originalValue === undefined
          ? ''
          : value;
      })
      .required('Required'),
    degree: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        university: Yup.string().required('Required'),
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required')
      })
    ),
    fellowships: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required('Required'),
        name: Yup.string().required('Required')
      })
    ),
    experience: Yup.array().of(
      Yup.object().shape({
        organisation: Yup.string().required('Required'),
        designation: Yup.string().required('Required'),
        fromDate: Yup.date().required('Required'),
        toDate: Yup.date().required('Required')
      })
    ),
    papersPublished: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        publication: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        dateOfPublication: Yup.date().required('Required')
      })
    ),
    primarySpecialty: Yup.string()
      .transform((value, originalValue) => {
        return originalValue === null || originalValue === undefined
          ? ''
          : value;
      })
      .required('Required'),
    secondarySpecialty: Yup.string()
      .transform((value, originalValue) => {
        return originalValue === null || originalValue === undefined
          ? ''
          : value;
      })
      .required('Required'),
    displaySpecialtyArray: Yup.mixed().when('departmentId', {
      is: () => deptSpecializations.length > 0,
      then: Yup.mixed().test(
        'displaySpecialtyArray',
        'At least three specializations must be selected',
        function(value) {
          if (Array.isArray(value)) {
            return value.length >= 3;
          } else {
            return false;
          }
        }
      )
    }),
    dateSincePracticing: Yup.date()
      .transform((value, originalValue) => {
        return originalValue === null || originalValue === undefined
          ? undefined
          : value;
      })
      .required('Required'),
    approxNoOfDeliveries: Yup.number().nullable(),
    highlights: Yup.string().nullable()
  });

const DatePickerField = ({ field, form }) => {
  const selected = field.value ? moment(field.value, 'YYYY-MM-DD') : undefined;
  let placeholder;
  if (field.name.includes('fromDate')) {
    placeholder = 'From Date';
  } else if (field.name.includes('toDate')) {
    placeholder = 'To Date';
  } else if (field.name.includes('issuedYear')) {
    placeholder = 'Issued Year';
  } else if (field.name.includes('dateOfPublication')) {
    placeholder = 'Date of publication';
  } else {
    placeholder = 'Select Date';
  }

  return (
    <Col className="pr-1">
      <DatePicker
        onChange={v => form.setFieldValue(field.name, v.format('YYYY-MM-DD'))}
        selected={selected}
        dateFormat="D MMM, YYYY"
        scrollableYearDropdown
        showYearDropdown
        maxDate={moment()}
        placeholderText={placeholder}
        className="exp-input"
      />
      <ErrorMessage component="span" name={field.name} className="error" />
    </Col>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

const DateField = ({ field, form }) => {
  const selected = field.value ? moment(field.value, 'YYYY-MM-DD') : undefined;
  return (
    <Row>
      <Col>
        <DatePicker
          onChange={v => form.setFieldValue(field.name, v.format('YYYY-MM-DD'))}
          selected={selected}
          dateFormat="D MMMM, YYYY"
          scrollableYearDropdown
          showYearDropdown
          maxDate={moment()}
        />
      </Col>
    </Row>
  );
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const SelectDepartmentInput = ({ field, form, options, onChange }) => {
  const favorableValue = options.find(o => o.value === field.value);
  return (
    <Row>
      <Col>
        <Select
          value={favorableValue}
          onChange={option => {
            form.setFieldValue(field.name, option.value);
            onChange(option);
          }}
          options={options}
        />
      </Col>
    </Row>
  );
};

SelectDepartmentInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

const SplLookupInput = ({ field, form }) => {
  const value = field.value || [];
  return (
    <Row>
      <Col>
        <LookupSelect
          lookup="spl"
          isMulti={false}
          value={value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      </Col>
    </Row>
  );
};

SplLookupInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object
};

const EducationExperienceForm = ({ doctor, departments, specializations }) => {
  const initValue = omit(doctor, [
    '__typename',
    'entityId',
    'department',
    'photos',
    'cloudinaryId',
    'ltFirstSyncData',
    'ltLastSyncData',
    'ltCreatedAt',
    'ltUpdatedAt',
    'created_at',
    'updated_at',
    'entity',
    'siteCode'
  ]);
  const [updateDoctor, { loading: isLoading }] = useMutation(
    UPDATE_DOCTOR_MUTATION,
    {
      refetchQueries: () => ['SEARCH_DOCTORS', 'GET_DOCTOR'],
      onError: () => {
        displayError('something went wrong');
      },
      onCompleted: ({ doc }) => {
        displaySuccess(`${doc.name} updated successfully`);
      }
    }
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const cleanedValues = {
      ...values,
      degree: values.degree.map(item => {
        delete item.__typename;
        return item;
      }),
      fellowships: values.fellowships.map(item => {
        const cleanedItem = { ...item };
        delete cleanedItem.__typename;
        if (cleanedItem.type === 'award') {
          delete cleanedItem.fromDate;
          delete cleanedItem.toDate;
          delete cleanedItem.university;
        } else {
          delete cleanedItem.issuedYear;
          delete cleanedItem.issuedAuthority;
        }
        return cleanedItem;
      }),

      experience: values.experience.map(item => {
        delete item.__typename;
        return item;
      }),
      papersPublished: values.papersPublished.map(item => {
        delete item.__typename;
        return item;
      })
    };

    const { dateOfJoining, dateSincePracticing } = cleanedValues;
    if (!dateOfJoining || dateOfJoining.trim().length < 1) {
      delete cleanedValues.dateOfJoining;
    }
    if (!dateSincePracticing || dateSincePracticing.trim().length < 1) {
      delete cleanedValues.dateSincePracticing;
    }

    delete cleanedValues.isConfigured;

    if (typeof cleanedValues.hasOp !== 'boolean') {
      delete cleanedValues.hasOp;
    }
    if (typeof cleanedValues.hasOt !== 'boolean') {
      delete cleanedValues.hasOt;
    }
    if (typeof cleanedValues.hasIp !== 'boolean') {
      delete cleanedValues.hasIp;
    }
    if (typeof cleanedValues.isEnabled !== 'boolean') {
      delete cleanedValues.isEnabled;
    }
    if (typeof cleanedValues.isVisible !== 'boolean') {
      delete cleanedValues.isVisible;
    }
    if (!cleanedValues.ancCard) {
      cleanedValues.ancCard = '';
    }
    if (!cleanedValues.ancCardSettings) {
      cleanedValues.ancCardSettings = null;
    }
    if (!cleanedValues.websiteURL) {
      cleanedValues.websiteURL = '';
    }

    delete cleanedValues.hospitals;

    try {
      await validateDoc(deptSpecializations).validate(cleanedValues, {
        abortEarly: false
      });
      await updateDoctor({ variables: { docInput: cleanedValues } });
    } catch (validationErrors) {
      const errorMessage = validationErrors.errors.join(', ');
      displayError(`Validation failed: ${errorMessage}`);
    }
    setSubmitting(false);
  };

  initValue.degree.forEach(degree => {
    degree.fromDate = moment(degree.fromDate).format('YYYY-MM-DD');
    degree.toDate = moment(degree.toDate).format('YYYY-MM-DD');
  });

  initValue.fellowships.forEach(fellowship => {
    fellowship.fromDate = moment(fellowship.fromDate).format('YYYY-MM-DD');
    fellowship.toDate = moment(fellowship.toDate).format('YYYY-MM-DD');
    fellowship.issuedYear = moment(fellowship.issuedYear).format('YYYY-MM-DD');
  });

  initValue.experience.forEach(exp => {
    exp.fromDate = moment(exp.fromDate).format('YYYY-MM-DD');
    exp.toDate = moment(exp.toDate).format('YYYY-MM-DD');
  });

  initValue.papersPublished.forEach(paper => {
    paper.dateOfPublication = moment(paper.dateOfPublication).format(
      'YYYY-MM-DD'
    );
  });

  const [selectedDepartment, setSelectedDepartment] = useState(
    doctor.departmentId
  );
  const [filteredSpecializations, setFilteredSpecializations] = useState(
    specializations
  );
  const deptSpecializations = specializations.filter(
    specialization => specialization.departmentId === selectedDepartment
  );
  useEffect(() => {
    if (selectedDepartment) {
      const departmentSpecializations = specializations.filter(
        specialization => specialization.departmentId === selectedDepartment
      );
      setFilteredSpecializations(departmentSpecializations);
    }
    // eslint-disable-next-line
  }, [selectedDepartment]);

  const user = getLoggedInUser();

  return (
    <Formik
      initialValues={initValue}
      enableReinitialize
      validationSchema={validateDoc(deptSpecializations)}
      onSubmit={handleSubmit}
      render={({ values, handleSubmit }) => {
        return (
          <div className="hospital-form">
            <Row>
              <Col md="3">
                <label>Education (Courses/ Internships/ Residency)</label>
              </Col>
              <Col>
                <FieldArray name="degree">
                  {arrayHelpers => (
                    <>
                      {values.degree.map((degree, index) => (
                        <div key={index} className="row mb-2 no-gutters">
                          <Col className="pr-1">
                            <Field
                              name={`degree.${index}.name`}
                              placeholder="Degree name"
                            />
                            <ErrorMessage
                              component="span"
                              name={`degree.${index}.name`}
                              className="error"
                            />
                          </Col>
                          <Col className="pr-1">
                            <Field
                              name={`degree.${index}.university`}
                              placeholder="University/Institute"
                            />
                            <ErrorMessage
                              component="span"
                              name={`degree.${index}.university`}
                              className="error"
                            />
                          </Col>
                          <Field
                            name={`degree.${index}.fromDate`}
                            component={DatePickerField}
                          />
                          <Field
                            name={`degree.${index}.toDate`}
                            component={DatePickerField}
                          />
                          <TrashButton
                            index={index}
                            action={arrayHelpers.remove}
                            toDelete={'Degree'}
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        color="link"
                        onClick={() => {
                          arrayHelpers.push({
                            name: '',
                            university: '',
                            fromDate: '',
                            toDate: ''
                          });
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Other Qualifications</label>
              </Col>
              <Col>
                <FieldArray name="fellowships">
                  {arrayHelpers => (
                    <>
                      {values.fellowships.map((fellowship, index) => (
                        <div key={index} className="row mb-2 no-gutters">
                          <Col className="pr-1">
                            <Field
                              type="text"
                              name={`fellowships.${index}.type`}
                              component={({ field, form }) => {
                                const options = [
                                  { label: 'Diploma', value: 'diploma' },
                                  {
                                    label: 'Certification',
                                    value: 'certification'
                                  },
                                  { label: 'Fellowship', value: 'fellowship' },
                                  { label: 'Award', value: 'award' }
                                ];
                                const favorableValue = options.find(
                                  o => o.value === field.value
                                );
                                return (
                                  <Row>
                                    <Col>
                                      <Select
                                        value={favorableValue}
                                        onChange={option =>
                                          form.setFieldValue(
                                            field.name,
                                            option.value
                                          )
                                        }
                                        options={options}
                                        placeholder="Select Type"
                                      />
                                    </Col>
                                  </Row>
                                );
                              }}
                            />
                            <ErrorMessage
                              component="span"
                              name={`fellowships.${index}.type`}
                              className="error"
                            />
                          </Col>
                          {fellowship.type === 'award' && (
                            <>
                              <Col className="pr-1">
                                <Field
                                  name={`fellowships.${index}.name`}
                                  placeholder="Award Name"
                                />
                                <ErrorMessage
                                  component="span"
                                  name={`fellowships.${index}.name`}
                                  className="error"
                                />
                              </Col>
                              <Col className="pr-1">
                                <Field
                                  name={`fellowships.${index}.issuedAuthority`}
                                  placeholder="Issued Authority"
                                />
                              </Col>
                              <Field
                                name={`fellowships.${index}.issuedYear`}
                                component={DatePickerField}
                              />
                            </>
                          )}
                          {fellowship.type !== 'award' && (
                            <>
                              <Col className="pr-1">
                                <Field
                                  name={`fellowships.${index}.name`}
                                  placeholder={
                                    fellowship.type === 'diploma'
                                      ? 'Diploma Name'
                                      : fellowship.type === 'certification'
                                      ? 'Certification Name'
                                      : fellowship.type === 'fellowship'
                                      ? 'Fellowship Name'
                                      : 'Name'
                                  }
                                />
                                <ErrorMessage
                                  component="span"
                                  name={`fellowships.${index}.name`}
                                  className="error"
                                />
                              </Col>
                              <Col className="pr-1">
                                <Field
                                  name={`fellowships.${index}.university`}
                                  placeholder="University/Institue"
                                />
                              </Col>
                              <Field
                                name={`fellowships.${index}.fromDate`}
                                component={DatePickerField}
                              />
                              <Field
                                name={`fellowships.${index}.toDate`}
                                component={DatePickerField}
                              />
                            </>
                          )}
                          <TrashButton
                            index={index}
                            action={arrayHelpers.remove}
                            toDelete={'Qualification'}
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        color="link"
                        onClick={() => {
                          arrayHelpers.push({
                            type: '',
                            name: '',
                            fromDate: '',
                            toDate: '',
                            issuedAuthority: '',
                            issuedYear: ''
                          });
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Experience</label>
              </Col>
              <Col>
                <FieldArray name="experience">
                  {arrayHelpers => (
                    <>
                      {values.experience.map((experience, index) => (
                        <div key={index} className="row mb-2 no-gutters">
                          <Col className="pr-1">
                            <Field
                              name={`experience.${index}.organisation`}
                              placeholder="Organisation"
                            />
                            <ErrorMessage
                              component="span"
                              name={`experience.${index}.organisation`}
                              className="error"
                            />
                          </Col>
                          <Col className="pr-1">
                            <Field
                              name={`experience.${index}.designation`}
                              placeholder="Designation"
                            />
                            <ErrorMessage
                              component="span"
                              name={`experience.${index}.designation`}
                              className="error"
                            />
                          </Col>
                          <Field
                            name={`experience.${index}.fromDate`}
                            component={DatePickerField}
                          />
                          <Field
                            name={`experience.${index}.toDate`}
                            component={DatePickerField}
                          />
                          <TrashButton
                            index={index}
                            action={arrayHelpers.remove}
                            toDelete={'Qualification'}
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        color="link"
                        onClick={() => {
                          arrayHelpers.push({
                            organisation: '',
                            designation: '',
                            fromDate: '',
                            toDate: ''
                          });
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Papers Published</label>
              </Col>
              <Col>
                <FieldArray name="papersPublished">
                  {arrayHelpers => (
                    <>
                      {values.papersPublished.map((paper, index) => (
                        <div key={index} className="row mb-2 no-gutters">
                          <Col className="pr-1">
                            <Field
                              name={`papersPublished.${index}.name`}
                              placeholder="Name/Topic"
                            />
                            <ErrorMessage
                              component="span"
                              name={`papersPublished.${index}.name`}
                              className="error"
                            />
                          </Col>
                          <Col className="pr-1">
                            <Field
                              name={`papersPublished.${index}.publication`}
                              placeholder="Publication"
                            />
                            <ErrorMessage
                              component="span"
                              name={`papersPublished.${index}.publication`}
                              className="error"
                            />
                          </Col>
                          <Col className="pr-1">
                            <Field
                              name={`papersPublished.${index}.description`}
                              placeholder="Description"
                            />
                            <ErrorMessage
                              component="span"
                              name={`papersPublished.${index}.description`}
                              className="error"
                            />
                          </Col>
                          <Field
                            name={`papersPublished.${index}.dateOfPublication`}
                            component={DatePickerField}
                          />
                          <TrashButton
                            index={index}
                            action={arrayHelpers.remove}
                            toDelete={'Qualification'}
                          />
                        </div>
                      ))}
                      <Button
                        type="button"
                        color="link"
                        onClick={() => {
                          arrayHelpers.push({
                            name: '',
                            publication: '',
                            description: '',
                            dateOfPublication: ''
                          });
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>
                  Registration Number{' '}
                  <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
                </label>
              </Col>
              <Col>
                <Field type="text" name="medicalCouncilRegNo" />
                <ErrorMessage
                  component="span"
                  name="medicalCouncilRegNo"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Department</label>
              </Col>
              <Col>
                <Field
                  type="text"
                  name="departmentId"
                  component={SelectDepartmentInput}
                  options={departments.map(d => ({
                    label: d.name,
                    value: d.id
                  }))}
                  onChange={option => {
                    setSelectedDepartment(option.value);
                  }}
                />
                <ErrorMessage
                  component="span"
                  name="departmentId"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>
                  Primary Speciality{' '}
                  <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
                </label>
              </Col>
              <Col>
                <Field
                  type="text"
                  name="primarySpecialty"
                  component={SplLookupInput}
                />
                <ErrorMessage
                  component="span"
                  name="primarySpecialty"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>
                  Secondary Speciality{' '}
                  <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
                </label>
              </Col>
              <Col>
                <Field
                  type="text"
                  name="secondarySpecialty"
                  component={SplLookupInput}
                />
                <ErrorMessage
                  component="span"
                  name="secondarySpecialty"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Specialization</label>
              </Col>
              <Col>
                <Field
                  type="text"
                  name="displaySpecialtyArray"
                  component={({ field, form }) => {
                    if (
                      !deptSpecializations ||
                      deptSpecializations.length === 0
                    ) {
                      return <input type="text" {...field} />;
                    }
                    const selectedValues = filteredSpecializations.filter(
                      o => field.value && field.value.includes(o.name)
                    );
                    return (
                      <Row>
                        <Col>
                          <Select
                            value={selectedValues}
                            onChange={selectedOptions => {
                              const selectedValues = selectedOptions
                                ? selectedOptions.map(option => option.name)
                                : [];
                              form.setFieldValue(field.name, selectedValues);
                            }}
                            options={filteredSpecializations}
                            getOptionValue={option => option.name}
                            getOptionLabel={option => option.name}
                            isMulti
                          />
                        </Col>
                      </Row>
                    );
                  }}
                />
                <ErrorMessage
                  component="span"
                  name="displaySpecialtyArray"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>
                  Practicing Since{' '}
                  <span style={{ fontSize: '1.2em', color: 'red' }}>*</span>
                </label>
              </Col>
              <Col>
                <Field name="dateSincePracticing" component={DateField} />
                <ErrorMessage
                  component="span"
                  name="dateSincePracticing"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Years of Relevant Experience</label>
              </Col>
              <Col>
                <Field type="number" name="yearsOfExperience" />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Approx No.of deliveries</label>
              </Col>
              <Col>
                <Field type="number" name="approxNoOfDeliveries" />
                <ErrorMessage
                  component="span"
                  name="approxNoOfDeliveries"
                  className="error"
                />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <label>Highlights (Volunteering/ Social Work / Others)</label>
              </Col>
              <Col>
                <Field name="highlights">
                  {({ field }) => <TextArea {...field} />}
                </Field>
                <ErrorMessage
                  component="span"
                  name="highlights"
                  className="error"
                />
              </Col>
            </Row>
            {user.role !== 'marketing' && (
              <Row>
                <Col>
                  <div className="modal-footer">
                    <Button
                      type="button"
                      color="primary"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      Update Personal Info
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        );
      }}
    />
  );
};

EducationExperienceForm.propTypes = {
  doctor: PropTypes.object.isRequired,
  departments: PropTypes.array.isRequired,
  specializations: PropTypes.array
};

export default EducationExperienceForm;
