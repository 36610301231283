import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UPDATE_MULTIPLE_SCAN_PROCEDURE_MAPPING } from '../queries';
import { displaySuccess, logAmplitudeEvent } from '../utilities';
import SelectProcedureV3 from './SelectProcedureV3';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const EditMultipleScanProcedureMappingModal = props => {
  const { ScanProcedureDetails, isOpen, hideModal, onSave } = props;
  const initialValues = {
    ids: ScanProcedureDetails.map(item => item.id)
  };

  return (
    <Mutation
      mutation={UPDATE_MULTIPLE_SCAN_PROCEDURE_MAPPING}
      onCompleted={data => {
        onSave(data);
        displaySuccess(`${initialValues.ids.length} scans updated`);
        logAmplitudeEvent(
          'scans_updated',
          data.createScanProcedureMapping,
          true
        );
      }}
      refetchQueries={() => ['GET_SCAN_PROCEDURE_MAPPING']}
    >
      {(updateScan, { loading: updateLoading, error: updateError }) => {
        const errObj = updateError;
        const displayErr = errObj && errObj.message;
        const displayLoading = updateLoading;
        return (
          <Modal isOpen={isOpen} toggle={hideModal}>
            <ModalHeader toggle={hideModal}>
              Edit Scan Procedure Mapping
            </ModalHeader>
            <ModalBody>
              <div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={values => {
                    const payload = values;
                    payload.ids = initialValues.ids;
                    updateScan({
                      variables: { input: payload }
                    });
                  }}
                >
                  {formik => (
                    <div>
                      {errObj && (
                        <div className="error">
                          Error updating AMS Procedure. {displayErr}
                        </div>
                      )}
                      <div style={styles.formContainer}>
                        <div>AMS Procedure</div>
                        <Field
                          name={`procedureId`}
                          render={({ form, field }) => {
                            return (
                              <SelectProcedureV3
                                {...props}
                                value={field.value}
                                onChange={v =>
                                  form.setFieldValue(field.name, v)
                                }
                              />
                            );
                          }}
                        />
                        <div style={styles.buttonRow}>
                          <Button
                            onClick={formik.submitForm}
                            size="sm"
                            color="primary"
                            disabled={displayLoading}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </ModalBody>
          </Modal>
        );
      }}
    </Mutation>
  );
};

EditMultipleScanProcedureMappingModal.propTypes = {
  ScanProcedureDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default EditMultipleScanProcedureMappingModal;
