import React from 'react';
import { GET_ALL_HOSPITALS_FULL } from '../queries';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { logAmplitudeEvent } from '../utilities';

const HospitalSelector = ({
  onChange,
  value,
  cityId,
  arrayValues,
  ...props
}) => {
  logAmplitudeEvent('Quick_Book_Hospital_Select', { cityId }, true);

  return (
    <Query query={GET_ALL_HOSPITALS_FULL}>
      {({ data: { hospitals } = [] }) => {
        const aVals =
          arrayValues.value && arrayValues.value.getHospitals.length
            ? arrayValues.value.getHospitals
            : [];
        const opts =
          aVals && aVals.length
            ? aVals.map(item => ({ label: item.name, value: item.id }))
            : hospitals && hospitals.length
            ? hospitals
                .map(item =>
                  item.cityId === cityId || cityId === null
                    ? { label: item.name, value: item.id }
                    : null
                )
                .filter(item => (item !== null ? 1 : 0))
            : [];

        return (
          <Select
            {...props}
            options={opts}
            value={value ? value : ''}
            onChange={onChange}
          />
        );
      }}
    </Query>
  );
};

HospitalSelector.propTypes = {
  cityId: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  arrayValues: PropTypes.object
};

HospitalSelector.defaultProps = {
  onChange: () => {},
  arrayValues: {}
};

export default HospitalSelector;
