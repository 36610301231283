import React from 'react';
import { useQuery } from 'react-apollo';
import { GET_ALL_HOSPITALS } from '../queries';
import PropTypes from 'prop-types';

const HospitalNameUsingId = ({ id }) => {
  const { loading, data: { hospitals = [] } = {} } = useQuery(
    GET_ALL_HOSPITALS,
    {
      variables: { showAll: true }
    }
  );
  if (loading) return null;
  let h = hospitals.filter(e => (e.id === id ? e.name : ''));
  return <div>{h[0] && h[0].name ? h[0].name : ''}</div>;
};

HospitalNameUsingId.propTypes = {
  id: PropTypes.string
};

export default HospitalNameUsingId;
