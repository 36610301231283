import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';
import { showConfirmation } from '../ConfirmationDialogService';
import { useMutation } from 'react-apollo';
import { BLOCK_SLOTS } from '../queries';
import Loader from './Loader';
import { displaySuccess, displayError } from '../utilities';
import SlotCancelationReasons from './SlotCancelationReasons';
import { toMoment } from './ModifySlotContainer';
import ModifySlotAffectedBookingsToolBar from './ModifySlotAffectedBookingsToolBar';

const Highlight = styled.span`
  color: red;
`;

const OkCancel = styled.div`
  display: flex;
  & > button {
    margin-right: 1rem;
  }
`;

const Blue = styled.span`
  color: blue;
`;

const CancelationReason = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 1rem;
  }
`;

const ModifySlotBlockSlots = ({ onCancel, date, alteredSchedule }) => {
  const {
    start,
    end,
    slotPrototypeId,
    // isAltered,
    alteredStart,
    alteredEnd,
    // isAdhoc,
    // hospitalName,
    // isTatkalEnabled,
    isBlockAll
    // userName,
    // updatedAt
  } = alteredSchedule;

  const [isCancelBookings, setIsCancelBookings] = useState(false);
  const [reason, setReason] = useState();
  const [blockSlots, { loading }] = useMutation(BLOCK_SLOTS, {
    variables: {
      input: {
        slotPrototypeId,
        date,
        reason,
        cancelBookings: isCancelBookings,
        action: isBlockAll ? 'unblock' : 'block',
        onlyTatkal: false
      }
    },
    onCompleted: () => {
      displaySuccess(isBlockAll ? 'Schedule unblocked' : 'Schedule blocked');
      onCancel();
    },
    onError: e => displayError(e),
    refetchQueries: () => ['SEARCH_DOCTORS_EXTENDED']
  });

  const dateM = moment(date);
  // const dateYYYYMMDD = dateM.format('YYYYMMDD');
  const actualStartTime = alteredStart
    ? toMoment(alteredStart, dateM)
    : toMoment(start, dateM);
  const actualEndTime = alteredEnd
    ? toMoment(alteredEnd, dateM)
    : toMoment(end, dateM);

  const origStartStr = actualStartTime.format('h:mm A');
  const origEndStr = actualEndTime.format('h:mm A');

  let okButtonText = isCancelBookings
    ? 'Block and Cancel Bookings'
    : 'Block, do not cancel Bookings';
  const okButtonColor = isCancelBookings ? 'danger' : 'primary';

  if (isBlockAll) {
    okButtonText = 'Unblock Slots';
  }

  const onOk = () => {
    return showConfirmation(
      'Check Configuration',
      <ul style={{ textAlign: 'left' }}>
        <li>
          <strong>{isBlockAll ? 'Open all blocked' : 'Block all'}</strong> slots
          between{' '}
          <strong>
            {origStartStr} - {origEndStr}
          </strong>
        </li>
        <li>
          {isCancelBookings
            ? 'Cancel all the bookings in this duration'
            : 'Leave the bookings untouched'}
        </li>
      </ul>,
      () => blockSlots()
    );
  };

  const disabledOkButton = loading || !reason;
  return (
    <div>
      <h5>
        {isBlockAll ? 'Unblock' : 'Block'} all slots between{' '}
        <Highlight>
          {origStartStr} - {origEndStr}
        </Highlight>{' '}
        on <Blue>{dateM.format('ddd, DD MMM YYYY')}</Blue>
      </h5>
      <CancelationReason>
        <div>Reason</div>
        <div>
          <SlotCancelationReasons onChange={r => setReason(r)} />
        </div>
        {!reason && <span className="error">Required</span>}
      </CancelationReason>
      {!isBlockAll ? (
        <div>
          <input
            disabled={isBlockAll}
            type="checkbox"
            checked={isCancelBookings}
            onChange={e => setIsCancelBookings(e.target.checked)}
            id="cancelBookings"
          />{' '}
          <label htmlFor="cancelBookings">Cancel all the booking</label>
        </div>
      ) : null}

      <ModifySlotAffectedBookingsToolBar
        modification="blockAll"
        slotPrototypeId={slotPrototypeId}
        date={date}
        leaveOrphans={!isCancelBookings}
        enabled={!disabledOkButton}
      />
      <OkCancel>
        {loading && <Loader />}
        <Button disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={disabledOkButton}
          color={okButtonColor}
          onClick={onOk}
        >
          {okButtonText}
        </Button>
      </OkCancel>
    </div>
  );
};

ModifySlotBlockSlots.propTypes = {
  alteredSchedule: PropTypes.objectOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      slotPrototypeId: PropTypes.number.isRequired,
      isAltered: PropTypes.bool.isRequired,
      alteredStart: PropTypes.instanceOf(Date),
      alteredEnd: PropTypes.instanceOf(Date),
      isAdhoc: PropTypes.bool.isRequired,
      hospitalName: PropTypes.string,
      isTatkalEnabled: PropTypes.bool.isRequired,
      isBlockAll: PropTypes.bool
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default ModifySlotBlockSlots;
