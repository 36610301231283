import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { BOOKING_EVENT_HISTORY } from '../queries';
import { Table } from 'reactstrap';
import moment from 'moment';
import Loader from './Loader';

const BookingEvents = ({ bookingId }) => {
  return (
    <Query
      query={BOOKING_EVENT_HISTORY}
      variables={{ bookingId }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data: { eventHistory } = {} }) => {
        if (loading) return <Loader />;
        if (error) return <div className="error">{error}</div>;
        if (!eventHistory || eventHistory.length < 1) {
          return <div>No Events</div>;
        }

        return (
          <Table size="small" striped borderless responsive={false}>
            <thead>
              <tr>
                <th>Time/Event</th>
                <th>Detail</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {eventHistory.map(
                ({ created_at, event, description, eventCreator }, i) => {
                  return (
                    <tr key={`eh-${i}`}>
                      <td style={{ minWidth: '11rem' }}>
                        {moment(created_at).format('D MMM YYYY hh:mm A')}
                        <br />
                        {event}
                      </td>
                      <td>{description}</td>
                      <td style={{ minWidth: '10rem' }}>{eventCreator}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        );
      }}
    </Query>
  );
};

BookingEvents.propTypes = {
  bookingId: PropTypes.string.isRequired
};

export default BookingEvents;
