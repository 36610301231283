import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import { DROPDOWN_QUERIES } from '../queries';
import { data2options } from './ApptSearchForm';
import Loader from './Loader';

const findCommonCreateCSVName = (selectIdsArr, allOptsArr) => {
  return allOptsArr
    .filter(opt => selectIdsArr.includes(opt.value))
    .map(opt => opt.label)
    .join(', ');
};

const findCommonCreateProcedureNames = (selectIdsArr, allOptsArr) => {
  return allOptsArr
    .filter(opt => selectIdsArr.includes(opt.label))
    .map(opt => opt.label)
    .join(', ');
};

const AppliedFilterText = ({ searchCriteria }) => {
  const {
    hospitals: hids,
    departments: dpids,
    objectIds: dids,
    procedureNames: prs,
    languageNames: langs,
    date
  } = searchCriteria;
  return (
    <div>
      <Query query={DROPDOWN_QUERIES}>
        {({ error, loading, data }) => {
          if (loading) return <Loader />;
          if (error) return <span>Error: {error.message}</span>;

          let hstr = 'all hospitals';
          let dpstr = 'all department';
          let dstr = 'all doctors';
          let procedureStr = 'all procedures';
          let languageStr = 'all languages';
          let dateStr = 'today';
          const isToday = moment().isSame(date, 'date');
          if (!isToday) {
            dateStr = `${moment(date).format('ddd DD MMM YYYY')}.`;
          }

          const {
            hospitals,
            departments,
            doctors,
            procedures,
            languages
          } = data2options(data);

          if (hospitals.length < 2) {
            hstr = hospitals[0].label;
          } else {
            hstr = findCommonCreateCSVName(hids, hospitals);
          }
          if (dpids && dpids.length > 0)
            dpstr = findCommonCreateCSVName(dpids, departments);

          if (dids && dids.length > 0)
            dstr = findCommonCreateCSVName(dids, doctors);

          if (prs && prs.length > 0)
            procedureStr = findCommonCreateProcedureNames(prs, procedures);

          if (langs && langs.length > 0)
            languageStr = findCommonCreateCSVName(langs, languages);

          return (
            <p>
              Showing appointments from{' '}
              <span className="filtered-hl">{hstr}</span> on{' '}
              <span className="filtered-hl">{dpstr}</span> for{' '}
              <span className="filtered-hl">{languageStr}</span> on{' '}
              <span className="filtered-hl">{procedureStr}</span> for{' '}
              <span className="filtered-hl">{dstr}</span>
              {isToday ? ', ' : ' on '}
              <span className="filtered-hl">{dateStr}</span>
            </p>
          );
        }}
      </Query>
    </div>
  );
};

AppliedFilterText.propTypes = {
  searchCriteria: PropTypes.object.isRequired
};

export default AppliedFilterText;
