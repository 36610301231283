import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { BOOKING_MESSAGE_HISTORY } from '../queries';
import { Table, Badge } from 'reactstrap';
import moment from 'moment';
import Loader from './Loader';
import ResendMessage from './ResendMessage';

const BookingMessages = ({ bookingId }) => {
  return (
    <Query
      query={BOOKING_MESSAGE_HISTORY}
      variables={{ bookingId }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data: { messageHistory } = {} }) => {
        if (loading) return <Loader />;
        if (error) return <div className="error">{error}</div>;
        if (!messageHistory || messageHistory.length < 1) {
          return <div>No Messages</div>;
        }

        return (
          <Table size="small" striped borderless>
            <thead>
              <tr>
                <th>Time/Type</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {messageHistory.map(
                (
                  {
                    id,
                    type,
                    sendAt,
                    sentAt,
                    isSent,
                    email,
                    mobile,
                    emailSubject,
                    smsMessage,
                    emailPlainText,
                    parentMessageId
                  },
                  i
                ) => {
                  return (
                    <>
                      <tr key={`mh-${i}`}>
                        <td style={{ minWidth: '11rem' }}>
                          {moment(isSent ? sentAt : sendAt).format(
                            'D MMM YYYY hh:mm A'
                          )}
                          <br />
                          {type}
                          <br />
                          <ResendMessage id={id} />
                        </td>
                        <td>
                          <strong>To:&nbsp;</strong>
                          {mobile}{' '}
                          {parentMessageId && (
                            <Badge color="warning">RESENT</Badge>
                          )}
                          <br />
                          <strong>SMS:&nbsp;</strong>
                          {smsMessage}
                        </td>
                      </tr>
                      <tr key={`mh-${i}-1`}>
                        <td style={{ minWidth: '11rem' }}>
                          {moment(isSent ? sentAt : sendAt).format(
                            'D MMM YYYY hh:mm A'
                          )}
                          <br />
                          {type}
                        </td>
                        <td>
                          <strong>To:&nbsp;</strong>
                          {email}{' '}
                          {parentMessageId && (
                            <Badge color="warning">RESENT</Badge>
                          )}
                          <br />
                          <strong>Sub:&nbsp;</strong>
                          {emailSubject}
                          <br />
                          <strong>Message:&nbsp;</strong>
                          {emailPlainText}
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
            </tbody>
          </Table>
        );
      }}
    </Query>
  );
};

BookingMessages.propTypes = {
  bookingId: PropTypes.string.isRequired
};

export default BookingMessages;
