import React from 'react';
import { Mutation } from 'react-apollo';
import Loader from './Loader';
import { BULK_UPLOAD_USERS } from '../queries';
import { Table } from 'reactstrap';

const UploadAdminUsers = props => {
  return (
    <div>
      <h1>Upload bookings json data</h1>
      <Mutation mutation={BULK_UPLOAD_USERS}>
        {(fileUpload, { loading, data: { users } = {}, error }) => {
          // const res = 'no resp';
          return (
            <>
              <input
                type="file"
                onChange={event => {
                  const {
                    target: {
                      validity,
                      files: [file]
                    }
                  } = event;
                  // console.log(`fkkiles: ${JSON.stringify(file.name)}`);
                  validity.valid && fileUpload({ variables: { file } });
                }}
              />
              {loading && <Loader />}

              {users ? (
                <Table size="small" striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Hospitals</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(
                      ({ name, email, role, hospitals, result }, i) => (
                        <tr key={i}>
                          <td>{name}</td>
                          <td>{email}</td>
                          <td>{role}</td>
                          <td>{hospitals}</td>
                          <td>{result}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              ) : (
                'no data'
              )}
              <h3>Errors:</h3>

              <div>
                <pre>{error && <pre>{JSON.stringify(error)}</pre>}</pre>
              </div>
            </>
          );
        }}
      </Mutation>
    </div>
  );
};

UploadAdminUsers.propTypes = {};

export default UploadAdminUsers;
