import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import humanize from 'humanize-duration';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { showConfirmation } from '../ConfirmationDialogService';
import { useMutation } from 'react-apollo';
import { ADD_DELAY } from '../queries';
import { displaySuccess, displayError } from '../utilities';
import Loader from './Loader';
import { toMoment } from './ModifySlotContainer';
import ModifySlotAffectedBookingsToolBar from './ModifySlotAffectedBookingsToolBar';
import SlotCancelationReasons from './SlotCancelationReasons';

const steps = 15; //minutes
const delayOptions = [...Array(24).keys()].map(index => ({
  label: humanize(steps * (1 + index) * 60 * 1000),
  value: steps * (1 + index)
}));

const DelayDropDown = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const DelayDDTitle = styled.h5`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-right: 1rem;
`;

const DelayValues = styled.div`
  display: flex;
  & > * {
    margin-right: 2rem;
  }
`;

const SettingBoxes = styled.div`
  display: flex;
  padding: 1rem;
  border: 1px solid #cccccc;
  border-radius: 1rem;
  & > div {
    padding-right: 2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
`;

const OkCancel = styled.div`
  display: flex;
  justify-content: flex-end;
  & > * {
    margin-left: 1rem;
  }
`;

const Blue = styled.span`
  color: blue;
`;

const ModifySlotAddDelay = ({ onCancel, alteredSchedule, date }) => {
  const {
    start,
    end,
    slotPrototypeId,
    // isAltered,
    alteredStart,
    alteredEnd
    // isAdhoc,
    // hospitalName,
    // isTatkalEnabled,
    // isBlockAll,
    // userName,
    // updatedAt
  } = alteredSchedule;
  const [delayConf, setDelayConf] = useState({
    delayOpt: null,
    delayFromBeginning: true,
    delayFrom: null,
    onlyInform: false,
    reason: null
  });

  const {
    delayOpt,
    delayFromBeginning,
    onlyInform,
    delayFrom,
    reason
  } = delayConf;
  const input = {
    slotPrototypeId,
    date,
    onlyInform,
    reason
  };

  if (delayFrom) {
    input.delayBeginsAt = delayFrom.toDate();
  }

  if (delayOpt) {
    input.delayInMinutes = delayOpt.value;
  }

  const delayedSuccessfully = () => {
    displaySuccess('Delayed slots successfully.');
    onCancel();
  };

  const [addDelay, { loading }] = useMutation(ADD_DELAY, {
    variables: { input },
    onCompleted: () => delayedSuccessfully(),
    onError: e => displayError(e),
    refetchQueries: () => ['SEARCH_DOCTORS_EXTENDED']
  });

  const onDelaySelect = delayOpt => setDelayConf({ ...delayConf, delayOpt });
  const onReasonSelect = reason => setDelayConf({ ...delayConf, reason });
  const setDelayFrom = delayFrom => setDelayConf({ ...delayConf, delayFrom });
  const setDelayFromBeginning = v => {
    const delayFromBeginning = v.currentTarget.value === 'true';
    setDelayConf({ ...delayConf, delayFromBeginning });
  };
  const setOnlyInform = onlyInform =>
    setDelayConf({ ...delayConf, onlyInform });
  // console.log('delay conf: ', delayConf);

  const dateM = moment(date);
  const actualStartTime = alteredStart
    ? toMoment(alteredStart, dateM)
    : toMoment(start, dateM);
  const actualEndTime = alteredEnd
    ? toMoment(alteredEnd, dateM)
    : toMoment(end, dateM);

  const origStartStr = actualStartTime.format('h:mm A');
  const origEndStr = actualEndTime.format('h:mm A');

  let delayedStart = moment(actualStartTime);
  if (delayFromBeginning && delayOpt) {
    delayedStart = moment(actualStartTime).add(delayOpt.value, 'minutes');
  }

  const delayedEnd = delayOpt
    ? moment(actualEndTime).add(delayOpt.value, 'minutes')
    : moment(actualEndTime);

  const delayedStartStr = delayedStart.format('h:mm A');
  const delayedEndStr = delayedEnd.format('h:mm A');

  const minDelayStarts = moment(actualStartTime).add(steps, 'minutes');
  const maxDelayStarts = moment(actualEndTime).subtract(steps, 'minutes');

  const selectedDelayFrom = delayFrom || minDelayStarts;
  const onOk = () => {
    showConfirmation(
      'Check Configuration',
      <ul style={{ textAlign: 'left' }}>
        <li>
          Adds a delay of <strong>{delayOpt.label}</strong>
        </li>
        <li>
          Delay starts at{' '}
          <strong>
            {delayFromBeginning
              ? 'the begining'
              : `at ${selectedDelayFrom.format('h:mm A')}`}
          </strong>
        </li>
        <li>
          Bookings and slots are <strong>{onlyInform && 'not'} moved</strong>
        </li>
        <li>Affected customers are informed</li>
        <li>
          Doctor&apos;s timing changes from {origStartStr} - {origEndStr} to{' '}
          <strong>
            {delayedStartStr} - {delayedEndStr}
          </strong>
        </li>
      </ul>,
      addDelay
    );
  };

  const disabledOkButton = !delayOpt || loading || !reason;

  return (
    <Container>
      <h4>
        Add delay on <Blue>{dateM.format('ddd, DD MMM YYYY')}</Blue>
      </h4>
      <DelayValues>
        <DelayDropDown>
          <DelayDDTitle>Add a delay of</DelayDDTitle>
          <div style={{ minWidth: '15rem' }}>
            <Select options={delayOptions} onChange={onDelaySelect} />
          </div>
        </DelayDropDown>
        <DelayDropDown>
          <DelayDDTitle>Reason:</DelayDDTitle>
          <SlotCancelationReasons onChange={onReasonSelect} />
        </DelayDropDown>
        {!reason && <span className="error">Required</span>}
      </DelayValues>

      <div>
        Move slots and bookings on {dateM.format('ddd, DD MMM YYYY')} between{' '}
        <strong>
          {origStartStr} - {origEndStr}
        </strong>{' '}
        to{' '}
        <strong>
          {delayedStartStr} - {delayedEndStr}
        </strong>
        .
      </div>

      <SettingBoxes>
        <div>
          <h5>Change timing OR Just inform</h5>
          <div>
            <input
              type="radio"
              checked={!onlyInform}
              onChange={e => setOnlyInform(!e.currentTarget.checked)}
              id="moveBookings"
            />{' '}
            <label htmlFor="moveBookings">
              Move slots and bookings and send message
            </label>
          </div>
          <div>
            <input
              type="radio"
              checked={onlyInform}
              onChange={e => setOnlyInform(e.currentTarget.checked)}
              id="onlyInform"
            />{' '}
            <label htmlFor="onlyInform">
              Send message, do not move slots or bookings
            </label>
          </div>
        </div>
        <div>
          <h5>When to delay from</h5>
          <div>
            <div>
              <input
                type="radio"
                onChange={setDelayFromBeginning}
                checked={delayFromBeginning}
                value={true}
                id="beginning"
              />{' '}
              <label htmlFor="beginning">Delay from the beginning</label>
            </div>
            <div>
              <input
                type="radio"
                onChange={setDelayFromBeginning}
                checked={!delayFromBeginning}
                value={false}
                id="nobeginning"
              />{' '}
              <label htmlFor="nobeginning">Delay from</label>
              <div style={{ width: '8rem', display: 'inline-block' }}>
                <DatePicker
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm A"
                  minTime={minDelayStarts}
                  maxTime={maxDelayStarts}
                  selected={selectedDelayFrom}
                  onChange={setDelayFrom}
                  onChangeRaw={e => e.preventDefault()}
                  disabled={delayFromBeginning}
                />
              </div>
            </div>
          </div>
        </div>
      </SettingBoxes>
      <ModifySlotAffectedBookingsToolBar
        modification="delay"
        slotPrototypeId={slotPrototypeId}
        date={input.date}
        delayFrom={input.delayBeginsAt}
        delayInMinutes={input.delayInMinutes}
        leaveOrphans={onlyInform}
        enabled={!disabledOkButton}
      />
      <OkCancel>
        {loading && <Loader />}
        <Button disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disabledOkButton} color="primary" onClick={onOk}>
          OK
        </Button>
      </OkCancel>
    </Container>
  );
};

ModifySlotAddDelay.propTypes = {
  alteredSchedule: PropTypes.objectOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      slotPrototypeId: PropTypes.number.isRequired,
      isAltered: PropTypes.bool.isRequired,
      alteredStart: PropTypes.instanceOf(Date),
      alteredEnd: PropTypes.instanceOf(Date),
      isAdhoc: PropTypes.bool.isRequired,
      hospitalName: PropTypes.string,
      isTatkalEnabled: PropTypes.bool.isRequired,
      isBlockAll: PropTypes.bool
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
};

export default ModifySlotAddDelay;
