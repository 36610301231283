import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import Loader from './Loader';
import DocSettingTabs from './DocSettingTabs';
import { toast } from 'react-toastify';
import RoomStatus from './RoomStatus';
import { DEFAULT_DOC_MALE, DEFAULT_DOC_FEMALE } from '../constants';

export const tabs = {
  sch: 'Modify Schedule',
  vac: 'Manage Vacation'
  // sch1: 'Modify Schedule2'
};

//entity availability query moved to AlteredScheduledRenderer
//due to cyclic import
export const ENTITY_SCH_QUERY = gql`
  query ENTITY_SCH_QUERY($entityId: Int) {
    display: getEntityDisplayInfo(entityId: $entityId) {
      title
      subtitle
      type
      pic300
      objectId
      roomNo
      isInRoom
      gender
    }
    # slotTypes: getEntitySlotTypes(entityId: $entityId) {
    #   availability
    #   hospitalName
    #   name
    # }
  }
`;

const defaultDisplay = { roomNo: '', isInRoom: false };

class DocSettings extends Component {
  state = {
    activeTab: tabs.sch,
    date: moment()
  };

  toggleTab = activeTab => this.setState({ activeTab });

  handleScheduleSave = (data, index) => {
    const { newSchedules } = this.state;
    newSchedules.splice(index, 1);
    this.setState({ newSchedules });
    const { slotsAdded, slotsBlocked } = data;
    toast.info(`${slotsAdded} new slots added, ${slotsBlocked} slots blocked`);
  };

  handleDateChange = date => {
    this.setState({ date, newSchedules: [] });
  };

  render() {
    const { isOpen, onToggle, entityId, doctorName } = this.props;
    const { date, activeTab } = this.state;

    return (
      <Modal isOpen={isOpen} size="xl">
        <Query query={ENTITY_SCH_QUERY} variables={{ entityId }}>
          {({ loading, error, data = {} }) => {
            if (error) {
              //eslint-disable-next-line
              console.error('error entity_sch_query', error);
            }
            const { display, slotTypes } = data;
            const { title, subtitle, pic300, isInRoom, roomNo, gender } =
              display || defaultDisplay;
            let displayUrl = pic300;
            if (!displayUrl && gender === 'Male') {
              displayUrl = DEFAULT_DOC_MALE;
            } else if (!displayUrl) {
              displayUrl = DEFAULT_DOC_FEMALE;
            }
            // console.log(`slotType: ${JSON.stringify(slotTypes)}`);
            return (
              <React.Fragment>
                <ModalHeader toggle={onToggle}>Manage Schedule</ModalHeader>
                <ModalBody>
                  <Row>
                    {loading ? (
                      <Col sm={6}>
                        <Loader />
                      </Col>
                    ) : (
                      <React.Fragment>
                        <Col sm={1}>
                          <img
                            height={50}
                            width={50}
                            src={displayUrl}
                            alt={title}
                          />
                        </Col>
                        <Col sm={5}>
                          <h6 className={'inline-force'}>{title}</h6>{' '}
                          <span className="text-muted"> {subtitle}</span>
                        </Col>
                      </React.Fragment>
                    )}
                    <Col sm={6}>
                      <RoomStatus
                        entityId={entityId}
                        isInRoom={isInRoom}
                        roomNo={roomNo}
                        doctorName={doctorName}
                      />
                    </Col>
                    {/* <Col sm={3}>
                      <h5>Existing Schedule on</h5>
                    </Col>
                    <Col sm={2}>
                      <DatePicker
                        selected={date}
                        dateFormat="DD MMM YYYY"
                        minDate={moment()}
                        onChange={this.handleDateChange}
                      />
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col>
                      {slotTypes ? (
                        <SlotAvailabilityRenderer
                          slotTypes={slotTypes}
                          date={date}
                        />
                      ) : null}
                    </Col>
                  </Row> */}
                  <DocSettingTabs
                    activeTab={activeTab}
                    entityId={entityId}
                    date={date}
                    slotTypes={slotTypes}
                    switchTab={this.toggleTab}
                    doctorName={doctorName}
                  />
                </ModalBody>
              </React.Fragment>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

DocSettings.propTypes = {
  entityId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  doctorName: PropTypes.string
};

export default DocSettings;
