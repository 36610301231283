import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SelectProcedureLookup from '../components/SelectProcedureLookup';
import { UPDATE_MULTIPLE_SCANS_DATA } from '../queries';
import { displaySuccess, logAmplitudeEvent } from '../utilities';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const SelectInput = ({ label, field, form, options }) => {
  const favorableValue = options.find(o => o.value === field.value);
  return (
    <div style={{ width: '100%' }}>
      <label>{label}</label>
      <Select
        value={favorableValue}
        onChange={option => form.setFieldValue(field.name, option.value)}
        options={options}
      />
    </div>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]).isRequired
    })
  ).isRequired
};

const EditMultipleScansModal = props => {
  // eslint-disable-next-line react/prop-types
  const { scansDetails, isOpen, hideModal, onSave } = props;
  const initialValues = {
    itemIds: scansDetails.map(item => item.itemId)
  };

  return (
    <Mutation
      mutation={UPDATE_MULTIPLE_SCANS_DATA}
      onCompleted={data => {
        onSave(data);
        displaySuccess(`${initialValues.itemIds.length} scans updated`);
        logAmplitudeEvent('Scans_updated', data.updateMultipleScansData, true);
      }}
      refetchQueries={() => ['GET_SCAN_DATA']}
    >
      {(updateScan, { loading: updateLoading, error: updateError }) => {
        const errObj = updateError;
        const displayErr = errObj && errObj.message;
        const displayLoading = updateLoading;
        return (
          <Modal isOpen={isOpen} toggle={hideModal}>
            <ModalHeader toggle={hideModal}>Edit Scans Data</ModalHeader>
            <ModalBody>
              <div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={values => {
                    const inputData = {
                      ...values,
                      itemIds: initialValues.itemIds
                    };
                    if (values.rate && !isNaN(parseInt(values.rate))) {
                      inputData.rate = parseInt(values.rate);
                    }
                    updateScan({
                      variables: { input: inputData }
                    });
                  }}
                >
                  {formik => (
                    <div>
                      {errObj && (
                        <div className="error">
                          Error updating scan. {displayErr}
                        </div>
                      )}
                      <div style={styles.formContainer}>
                        <div>AMS Procedure</div>
                        <Field
                          name={`LtProcedure`}
                          render={({ form, field }) => {
                            return (
                              <SelectProcedureLookup
                                value={field.value}
                                onChange={v =>
                                  form.setFieldValue(field.name, v)
                                }
                                {...props}
                              />
                            );
                          }}
                        />
                        <div>Amount</div>
                        <div>
                          <Field name="rate" style={{ width: '100%' }} />
                        </div>
                        <div>Duration of Scan</div>
                        <div>
                          <Field
                            name="durationofScan"
                            style={{ width: '100%' }}
                          />
                        </div>
                        <div>Trimester Data</div>
                        <div>
                          <Field
                            name="trimisterData"
                            style={{ width: '100%' }}
                            options={[
                              { label: 'First Trimester', value: '1' },
                              { label: 'Second Trimester', value: '2' },
                              { label: 'Third Trimester', value: '3' }
                            ]}
                            component={SelectInput}
                          />
                        </div>
                        <div>App Enabled</div>
                        <div>
                          <Field
                            type="checkbox"
                            name="appEnabled"
                            checked={formik.values.appEnabled}
                          />
                        </div>
                        <div style={styles.buttonRow}>
                          <Button
                            onClick={formik.submitForm}
                            size="sm"
                            color="primary"
                            disabled={displayLoading}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </ModalBody>
          </Modal>
        );
      }}
    </Mutation>
  );
};

EditMultipleScansModal.propTypes = {
  scansDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default EditMultipleScansModal;
