import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import Select from 'react-select';
import { LOOKUP_PROCEDURES } from '../queries';
import { logAmplitudeEvent } from '../utilities';

const ProceduresSelect = ({
  isMulti,
  onChange,
  value,
  showOptions,
  ...props
}) => {
  return (
    <Query query={LOOKUP_PROCEDURES}>
      {({ loading, data: { procedures } = {} }) => {
        if (!procedures) procedures = [];
        procedures = procedures.map(({ id, name }) => ({
          value: id,
          label: name
        }));

        value =
          isMulti && value
            ? value.map(v =>
                procedures.find(procedure => procedure.label === v)
              )
            : value && procedures.find(procedure => procedure.label === value);
        return (
          <Select
            isMulti={isMulti}
            {...props}
            onChange={v => {
              onChange(isMulti ? v.map(v => v.label) : v.label);
              logAmplitudeEvent('Procedure_Select', {}, true);
            }}
            isLoading={loading}
            options={showOptions ? procedures : []}
            value={value}
          />
        );
      }}
    </Query>
  );
};

ProceduresSelect.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  showOptions: PropTypes.bool
};

ProceduresSelect.defaultProps = {
  isMulti: false,
  onChange: () => {},
  showOptions: true
};
export default ProceduresSelect;
