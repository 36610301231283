import React from 'react';
import PropTypes from 'prop-types';
import { FaLongArrowAltRight } from 'react-icons/fa';
import moment from 'moment';

const getBox = (isArrow, title, time, isError) => {
  let colorClass = 'event-pending';
  if (isError) {
    colorClass = 'event-error';
  } else if (time) {
    colorClass = 'event-done';
  }

  const timeStr = time ? moment(time).format('MMM DD, hh:mm A') : 'NA';
  return (
    <div className={`event-container ${colorClass}`}>
      <div>
        {isArrow ? (
          <span>
            <FaLongArrowAltRight />
          </span>
        ) : null}
      </div>
      <div className="event-title-box">
        <div className="event-text-box">{title}</div>
        <div className="event-text-box">{timeStr}</div>
      </div>
    </div>
  );
};

const Steps = props => {
  const {
    booked,
    cancelled,
    noShow,
    checkedIn,
    startConsultation,
    // endConsultation,
    checkedOut
  } = props;

  const prune = cancelled || noShow;

  return (
    <div className="event-outer-container">
      {getBox(false, 'Booked', booked, false)}
      {cancelled ? getBox(true, 'Cancelled', cancelled, true) : null}
      {noShow ? getBox(true, 'No Show', noShow, true) : null}
      {!prune ? getBox(true, 'Checked In', checkedIn, false) : null}
      {!prune ? getBox(true, 'Consulting', startConsultation, false) : null}
      {/* {!prune ? getBox(true, 'Done', endConsultation, false) : null} */}
      {!prune ? getBox(true, 'Checked Out', checkedOut, false) : null}
    </div>
  );
};

Steps.propTypes = {
  booked: PropTypes.string,
  cancelled: PropTypes.string,
  noShow: PropTypes.string,
  checkedIn: PropTypes.string,
  startConsultation: PropTypes.string,
  endConsultation: PropTypes.string,
  checkedOut: PropTypes.string
};

export default Steps;
