import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { MdVideocam } from 'react-icons/md';

const ToFrom = styled.span`
  background-color: ${props => (props.isAltered ? '#ccff33' : 'none')};
  padding: 0.1rem 0.2rem;
  font-size: 0.8rem;
  color: ${props => (props.noSchedule ? '#999999' : 'inherit')};
  border: ${props => (props.isAdhoc ? '1px dashed black' : 'none')};
  text-decoration: ${props => (props.isBlockAll ? 'line-through' : 'none')};
`;

const Timing = ({ schedule }) => {
  if (!schedule) {
    return <ToFrom noSchedule>No Schedule This Day</ToFrom>;
  }

  let displayStart = moment(schedule.start, 'HHmm').format('h:mm A');
  let displayEnd = moment(schedule.end, 'HHmm').format('h:mm A');
  let origStart = displayStart;
  let origEnd = displayEnd;
  const { isAltered, isAdhoc, isBlockAll, procedureNames } = schedule;

  if (isAltered) {
    displayStart = moment(schedule.alteredStart, 'HHmm').format('h:mm A');
    displayEnd = moment(schedule.alteredEnd, 'HHmm').format('h:mm A');
  }

  let title = undefined;
  if (isBlockAll) {
    title = 'Blocked';
  } else if (isAdhoc) {
    title = 'Ad-hoc Schedule';
  } else if (isAltered) {
    title = `Originally: ${origStart} - ${origEnd}`;
  }

  const showVideoCamIcon = () => {
    return (
      !isEmpty(procedureNames) &&
      Array.isArray(procedureNames) &&
      // eslint-disable-next-line react/prop-types
      procedureNames.some(name => `${name}`.toLowerCase().includes('video'))
    );
  };

  return (
    <>
      <ToFrom
        isAltered={isAltered}
        isBlockAll={isBlockAll}
        isAdhoc={isAdhoc}
        title={title}
      >
        {displayStart}&nbsp;&mdash;&nbsp;{displayEnd}
      </ToFrom>
      {showVideoCamIcon() ? <MdVideocam /> : null}
    </>
  );
};

Timing.propTypes = {
  schedule: PropTypes.objectOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      hospitalName: PropTypes.string,
      isAdhoc: PropTypes.bool,
      isAltered: PropTypes.bool,
      alteredStart: PropTypes.string,
      alteredEnd: PropTypes.string,
      procedureNames: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

const DayScheduleCollapse = ({ daySchedule }) => {
  if (!daySchedule || daySchedule.length < 1) {
    return <Timing />;
  }

  if (daySchedule.length === 1) {
    const ds = daySchedule[0];
    return <Timing schedule={ds} />;
  }
  return (
    <div>
      {daySchedule.map((ds, index) => (
        <Timing key={`timing-${index}`} schedule={ds} />
      ))}
    </div>
  );
};

// [
//   {
//     start: '2300',
//     end: '2345',
//     hospitalName: 'Cloudnine Hospital Bellandur',
//     isAdhoc: false,
//     isAltered: false,
//     alteredStart: null,
//     alteredEnd: null,
//     __typename: 'Schedule'
//   }
// ];

DayScheduleCollapse.propTypes = {
  daySchedule: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        hospitalName: PropTypes.string,
        isAdhoc: PropTypes.bool,
        isAltered: PropTypes.bool,
        alteredStart: PropTypes.string,
        alteredEnd: PropTypes.string,
        procedureNames: PropTypes.arrayOf(PropTypes.string)
      })
    )
  )
};

DayScheduleCollapse.defaultProps = {
  daySchedule: []
};

export default DayScheduleCollapse;
