import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import { GET_DOCTOR_CHARGES_BY_SITECODE } from '../queries';
import Pagination from '../components/Pagination';

const itemsPerPage = 10;

const DoctorChargesModal = ({
  isOpen,
  toggle,
  selectedHospitalDetails = {}
}) => {
  const sitecode = selectedHospitalDetails.siteCode || '';
  const hospitalName = selectedHospitalDetails.name || '';

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, error, data } = useQuery(GET_DOCTOR_CHARGES_BY_SITECODE, {
    skip: !sitecode,
    variables: { sitecode }
  });

  const _data = (data && data.getDoctorChargesBySiteCode) || [];

  const doctorChargesData = _data.filter(item => {
    const isMatching = !searchTerm
      ? true
      : `${item.service}`.toLowerCase().includes(searchTerm) ||
        `${item.charges}`.toLowerCase().includes(searchTerm);
    return isMatching;
  });

  const handleSearch = event => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const noMatches = doctorChargesData.length === 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = doctorChargesData.slice(startIndex, endIndex);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>Doctor Charges - {hospitalName}</ModalHeader>
      <ModalBody>
        <Input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="mb-3"
        />
        {noMatches ? (
          <h5>
            We couldn&apos;t find any matches. Please try gain with different
            term
          </h5>
        ) : (
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Item Id</th>
                <th>Service Type & Doctor Name</th>
                <th>Charges</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map(item => (
                <tr key={item.item_id}>
                  <td>{item.item_id}</td>
                  <td>{item.service}</td>
                  <td>{item.charges}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="w-100 d-flex justify-content-center">
          {doctorChargesData.length > itemsPerPage && !noMatches && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
              totalPages={Math.ceil(doctorChargesData.length / itemsPerPage)}
            />
          )}
        </div>
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DoctorChargesModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedHospitalDetails: PropTypes.object
};

export default DoctorChargesModal;
