import React from 'react';
import PropTypes from 'prop-types';
import { UPLOAD_DOC_PIC } from '../queries';
import { Mutation } from 'react-apollo';
import Loader from './Loader';
import { displayError } from '../utilities';

const styles = {
  img: { border: '3px solid white', borderRadius: '50%' },
  fileInput: { visibility: 'hidden', position: 'absolute' },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%'
  }
};

const UploadDP = ({ url, doctorId }) => {
  return (
    <Mutation mutation={UPLOAD_DOC_PIC}>
      {(uploadDP, { loading, data }) => {
        // console.log('data', data, 'error', error);
        const { pic } = data || {};
        const displayUrl = pic && pic.dp300 ? pic.dp300.url : url;
        return (
          <div style={{ position: 'relative' }}>
            <label htmlFor="image">
              <img
                src={displayUrl}
                height={150}
                width={150}
                style={styles.img}
                alt="Selected Doctor"
              />
              <input
                type="file"
                id="image"
                onChange={({
                  target: {
                    files: [imageFile]
                  }
                }) => {
                  if (imageFile.size <= 200000)
                    uploadDP({ variables: { imageFile, doctorId } });
                  else displayError('Image size cannot be more than 200KB');
                }}
                style={styles.fileInput}
              />
              <div className="btn btn-primary btn-xs upload-dp">CHANGE</div>
            </label>
            {loading && (
              <div style={styles.loader}>
                <Loader size={5} />
              </div>
            )}
          </div>
        );
      }}
    </Mutation>
  );
};

UploadDP.propTypes = {
  url: PropTypes.string.isRequired,
  doctorId: PropTypes.string.isRequired
};

export default UploadDP;
