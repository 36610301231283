import React, { useState } from 'react';
import { Table, Nav, NavItem, NavLink } from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import DoctorSelector from '../components/DoctorSelector';
import DateTimePicker from 'react-datepicker';
import RenderRowVacation from '../components/RenderRowVacation';
import moment from 'moment';
import { displayError } from '../utilities';
import { Query } from 'react-apollo';
import { GET_ALL_DOCS_IDS } from '../queries';

const Approval = () => {
  const [state, setstate] = useState('pending');
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [selectedDoc, setselectedDoc] = useState();
  const changeState = state => {
    setstate(state);
  };
  return (
    <AuthRequired pageName="approvals">
      <div style={{ margin: '1rem' }}>
        <h1>Vacation Approvals</h1>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 0.1 }} />
          <div style={{ flex: 0.6, marginRight: 20, marginLeft: 10 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 10 }}>
                From Date
                <DateTimePicker
                  placeholderText="From Date"
                  selected={fromDate}
                  onChange={date => {
                    if (
                      (toDate &&
                        moment(toDate).diff(date, 'millisecond') > 0) ||
                      !fromDate
                    ) {
                      setfromDate(date);
                    } else {
                      // displayError(`From Date cannot be greater than To Date`);
                      setfromDate(date);
                      settoDate(moment(date).add('day', 1));
                    }
                  }}
                  dateFormat="DD MMM YYYY"
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                To Date
                <DateTimePicker
                  placeholderText="To Date"
                  selected={toDate}
                  onChange={date => {
                    if (
                      (fromDate &&
                        moment(fromDate).diff(date, 'millisecond') < 0) ||
                      !toDate
                    ) {
                      settoDate(date);
                    } else {
                      displayError(`To Date cannot be smaller than From Date`);
                    }
                  }}
                  dateFormat="DD MMM YYYY"
                />
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginRight: 20, marginLeft: 10 }}>
            Select Doctor
            <DoctorSelector
              // value={selectedDoc}
              onChange={data => setselectedDoc(data.value)}
              placeholder="Select a Doctor"
            />
          </div>
          <div style={{ flex: 0.1 }} />
        </div>
        <Nav>
          <NavItem>
            <NavLink
              active={state === 'pending'}
              onClick={() => changeState('pending')}
              style={{ cursor: 'pointer' }}
            >
              Pending
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={state === 'approved'}
              onClick={() => changeState('approved')}
              style={{ cursor: 'pointer' }}
            >
              Approved
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={state === 'denied'}
              onClick={() => changeState('denied')}
              style={{ cursor: 'pointer' }}
            >
              Denied
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={state === 'all'}
              onClick={() => changeState('all')}
              style={{ cursor: 'pointer' }}
            >
              All
            </NavLink>
          </NavItem>
        </Nav>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Doctor Name</th>
              <th>Last Updated</th>
              <th>Vacation Timing</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <Query query={GET_ALL_DOCS_IDS}>
              {({ data: { getDoctors } = [] }) => (
                <RenderRowVacation
                  doc={selectedDoc ? selectedDoc : null}
                  state={state}
                  fromDate={fromDate}
                  toDate={toDate}
                  getDocs={getDoctors}
                />
              )}
            </Query>
          </tbody>
        </Table>
      </div>
    </AuthRequired>
  );
};

export default Approval;
