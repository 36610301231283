import moment from 'moment';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import DatePicker from 'react-datepicker';
import { Button, Col, Collapse, Container, Row, Table } from 'reactstrap';
import Loader from '../components/Loader';
import { EVENTS_QUERY } from '../queries';

const getBgCol = severity => {
  switch (severity) {
    case 'ERROR':
      return '#EDB321';
    case 'WARN':
      return 'yellow';
    default:
      return '';
  }
};

const initialQuery = {
  startTimestamp: moment()
    .startOf('day')
    .toDate(),
  endTimestamp: moment()
    .endOf('day')
    .toDate(),
  eventType: undefined,
  eventSeverity: undefined,
  pageSize: 100,
  nextKey: undefined
};

const SystemLogsTab = () => {
  const [selectedEvent, selectEvent] = useState(undefined);
  const [query, setQuery] = useState(initialQuery);
  const toggleDetail = id => {
    if (selectedEvent === id) selectEvent(undefined);
    else selectEvent(id);
  };

  return (
    <Container style={{ margin: '20px' }}>
      <div>
        <Row style={{ marginBottom: '2rem' }}>
          <Col md={3}>
            <DatePicker
              selected={moment(query.startTimestamp)}
              maxDate={moment()}
              dateFormat="DD MMM YYYY"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={date => {
                const startTimestamp = moment(date)
                  .startOf('day')
                  .toDate();
                const endTimestamp = moment(date)
                  .endOf('day')
                  .toDate();
                setQuery({ ...query, startTimestamp, endTimestamp });
              }}
            />
          </Col>
          <Col md={3}>
            <select
              defaultValue={query.eventSeverity}
              onChange={e =>
                setQuery({
                  ...query,
                  eventSeverity:
                    e.target.value === 'ALL' ? undefined : e.target.value
                })
              }
            >
              <option value={'ALL'}>All</option>
              <option value={'INFO'}>Info</option>
              <option value={'WARN'}>Warn</option>
              <option value={'ERROR'}>Error</option>
              <option value={'FATAL'}>Fatal</option>
            </select>
          </Col>
          <Col md={3}>
            <select
              defaultValue={query.eventType}
              onChange={e =>
                setQuery({
                  ...query,
                  eventType:
                    e.target.value === 'ALL' ? undefined : e.target.value
                })
              }
            >
              <option value={'ALL'}>All</option>
              <option value={'QW_TO_AMS_BOOKING_SYNC'}>QW → AMS Booking</option>
              <option value={'AMS_TO_QW_BOOKING_SYNC'}>AMS → QW Booking</option>
              <option value={'QW_TO_AMS_CANCELATION_SYNC'}>
                QW → AMS Cancelation
              </option>
              <option value={'AMS_TO_QW_CANCELATION_SYNC'}>
                AMS → QW Cancelation
              </option>
              <option value={'BOOKING_SLOT_MIGRATION'}>
                Booking Slot Migration
              </option>
              <option value={'NEW_SLOT_CREATION'}>New Slot Creation</option>
              <option value={'LT_DOCTOR_SYNC'}>LT Doctor Sync</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Query
            query={EVENTS_QUERY}
            variables={{ input: query }}
            notifyOnNetworkStatusChange
          >
            {({ loading, error, data, fetchMore }) => {
              if (loading) return <Loader />;

              if (error) return <span>Something went wrong.{error}</span>;
              const { events, nextKey } = data.events;
              if (!events || events.length < 1) {
                return <span>{"That's all, folks!"}</span>;
              }
              return (
                <div style={{ width: '100%' }}>
                  <Table>
                    <thead>
                      <tr>
                        <th>TIMESTAMP</th>
                        <th>SEVERITY</th>
                        <th>TYPE</th>
                        <th>NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map(event => {
                        const {
                          timestampMillis,
                          eventSeverity,
                          eventType,
                          name,
                          details,
                          id
                        } = event;
                        return (
                          <>
                            <tr
                              key={`${id}-title`}
                              style={{
                                backgroundColor: getBgCol(eventSeverity)
                              }}
                              onClick={() => toggleDetail(id)}
                            >
                              <td>
                                {moment(timestampMillis).format(
                                  'DD MMM YYYY HH:mm'
                                )}
                              </td>
                              <td>{eventSeverity}</td>
                              <td>{eventType}</td>
                              <td>{name}</td>
                            </tr>
                            <tr key={id}>
                              <td colSpan={4}>
                                <Collapse isOpen={selectedEvent === id}>
                                  <pre
                                    style={{
                                      overflow: 'auto',
                                      wordWrap: 'normal',
                                      width: '800px'
                                    }}
                                  >
                                    {details}
                                  </pre>
                                </Collapse>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Row style={{ marginBottom: '3rem' }}>
                    <Col md={{ size: 3 }}>{loading && <Loader />}</Col>
                    <Col md={{ size: 2, offset: 7 }}>
                      {nextKey ? (
                        <Button
                          color="primary"
                          disabled={loading}
                          onClick={() => {
                            delete nextKey.__typename;
                            return fetchMore({
                              query: EVENTS_QUERY,
                              variables: { input: { ...query, nextKey } },
                              updateQuery: (prev, { fetchMoreResult }) => {
                                fetchMoreResult.events.events = [
                                  ...prev.events.events,
                                  ...fetchMoreResult.events.events
                                ];
                                return fetchMoreResult;
                              }
                            });
                          }}
                        >
                          Load More
                        </Button>
                      ) : (
                        <span>{"That's all, folks!"}</span>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Query>
        </Row>
      </div>
    </Container>
  );
};
export default SystemLogsTab;
