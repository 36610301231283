import React from 'react';
import PropTypes from 'prop-types';
import { isPermitted } from '../permissions';

const CheckPermissions = ({ permissions, children }) => {
  let checkperm = permissions;
  if (!Array.isArray(permissions)) {
    checkperm = [permissions];
  }
  if (isPermitted(checkperm)) {
    return <>{children}</>;
  }

  return null;
};

CheckPermissions.propTypes = {
  permissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default CheckPermissions;
