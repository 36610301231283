import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import {
  displayError,
  displaySuccess,
  logAmplitudeEvent,
  getGraphQLErrString
} from '../utilities';
import Loader from './Loader';
import { getLoggedInUser } from '../permissions';
import SelectHospitalFromId from './SelectHospitalFromId';
import AuditLogModal from './AuditLogModal';

export const CREATE_VACATION = gql`
  mutation CREATE_VACATION($input: VacationInput) {
    createVacation(input: $input) {
      id
      startTime
      endTime
      entityId
      reason
      approvalStatus
      hospitalId
    }
  }
`;

const getInitStartTime = () => {
  return moment().startOf('day');
  // const st = moment();
  // const h = st.hour() + 1;
  // return st.set({ h, m: 0, s: 0, millisecond: 0 });
};
const getInitEndTime = () => {
  return moment().endOf('day');

  // const st = moment();
  // const h = st.hour() + 2;
  // return st.set({ h, m: 0, s: 0, millisecond: 0 });
};

const DateTimeRangeSelector = props => {
  const { entityId, vacation, onRangeAdd, doctorName } = props;
  const [startTime, setStartTime] = useState(getInitStartTime());
  const [endTime, setEndTime] = useState(getInitEndTime());
  const [showForm, setShowForm] = useState(false);
  const [reason, setReason] = useState('');
  const [hospitalId, sethospitalId] = useState('');
  const [vacationId, setVacationId] = useState(undefined);
  const [showTimeSelect, setShowTimeSelect] = useState(false);

  useEffect(() => {
    if (vacation) {
      setStartTime(moment(vacation.startTime));
      setEndTime(moment(vacation.endTime));
      setReason(vacation.reason);
      setShowForm(true);
      setVacationId(vacation.id);
    }
  }, [vacation]);

  const minDate = moment();
  const toggleForm = () => {
    //if toggling from open to close, reset
    // if (showForm) {
    reset();
    // }

    setShowForm(!showForm);
  };
  const reset = () => {
    setStartTime(getInitStartTime());
    setEndTime(getInitEndTime());
    setReason();
    setVacationId(undefined);
  };
  const postCreation = data => {
    sethospitalId('');
    // console.log('vacation created', data);
    const {
      createVacation: { approvalStatus }
    } = data;
    let message =
      approvalStatus === 0 ? 'Vacation request has been sent for approval' : '';
    // if (closedSlots > 0) {
    //   message = `${message} Slots closed: ${closedSlots}.`;
    // }
    // if (openedSlots > 0) {
    //   message = `${message} Slots added: ${openedSlots}.`;
    // }
    // message = `${message} Bookings affected: ${affectedBookings}.`;
    // //1. show success!
    displaySuccess(message);
    const eventProps = {
      doctorName,
      startDate: startTime
        ? moment(startTime).format('DD-MM-YYYY, hh:mm A')
        : '',
      endDate: endTime ? moment(endTime).format('DD-MM-YYYY, hh:mm A') : ''
    };
    logAmplitudeEvent(
      vacationId ? 'Modify_Vacation' : 'Added_Vacation',
      eventProps,
      true
    );

    //2. reset timer
    reset();

    //3. hide form
    setShowForm(false);

    //4. pass back the id
    onRangeAdd(data.createVacation);
  };

  const setStartTimeResetEndTime = newStartTime => {
    if (endTime.isSameOrBefore(newStartTime)) {
      let newEndTime;
      if (showTimeSelect) {
        newEndTime = moment(newStartTime).add(1, 'hour');
      } else {
        newEndTime = moment(newStartTime).endOf('day');
      }
      setEndTime(newEndTime);
    }
    let startOfDayStartTime;
    if (showTimeSelect) {
      startOfDayStartTime = moment(newStartTime);
    } else {
      startOfDayStartTime = moment(newStartTime).startOf('day');
    }
    setStartTime(startOfDayStartTime);
  };

  const checkAndSetEndTime = selectedEndTime => {
    let et;
    if (showTimeSelect) {
      et = moment(selectedEndTime);
    } else {
      et = moment(selectedEndTime).endOf('day');
    }

    if (et.isSameOrBefore(startTime)) {
      displayError('Vacation cannot end before it started.');
      return;
    }
    setEndTime(et);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={6}>
          <Button color="link" onClick={toggleForm} style={{ paddingLeft: 0 }}>
            {showForm ? <FaRegMinusSquare /> : <FaRegPlusSquare />}
            {` ${vacationId ? 'Edit' : 'Add New'} Vacation`}
          </Button>
        </Col>
        <Col sm={6}>
          <AuditLogModal entityId={entityId} type="VACATION" />
        </Col>
      </Row>
      {showForm && (
        <Mutation
          mutation={CREATE_VACATION}
          refetchQueries={() => ['GET_VACATIONS', 'SEARCH_DOCTORS_EXTENDED']}
          onError={error => {
            displayError(error);
            const eventProps = {
              doctorName,
              startDate: startTime
                ? moment(startTime).format('DD-MM-YYYY, hh:mm A')
                : '',
              endDate: endTime
                ? moment(endTime).format('DD-MM-YYYY, hh:mm A')
                : '',
              errorReason: getGraphQLErrString(error)
            };
            logAmplitudeEvent('Vacation_Failed', eventProps, true);
          }}
          onCompleted={postCreation}
        >
          {(createVacation, { loading }) => {
            // if (error) DisplayError(error);
            return (
              <React.Fragment>
                <Row>
                  <Col sm={6}>From</Col>
                  <Col sm={6}>To</Col>
                </Row>
                <Row>
                  <Col sm={6} className="form-row">
                    <DatePicker
                      disabled={loading}
                      selected={startTime}
                      selectsStart
                      startDate={startTime}
                      endDate={endTime}
                      onChange={setStartTimeResetEndTime}
                      showTimeSelect={showTimeSelect}
                      dateFormat={
                        showTimeSelect ? 'DD MMM YYYY h:mm A' : 'DD MMM YYYY'
                      }
                      minDate={minDate}
                    />
                  </Col>
                  <Col sm={6} className="form-row">
                    <DatePicker
                      disabled={loading}
                      selected={endTime}
                      selectsEnd
                      startDate={startTime}
                      endDate={endTime}
                      onChange={checkAndSetEndTime}
                      showTimeSelect={showTimeSelect}
                      dateFormat={
                        showTimeSelect ? 'DD MMM YYYY h:mm A' : 'DD MMM YYYY'
                      }
                      minDate={startTime}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: '0.5rem' }}>
                  <Col sm={4} className="form-row">
                    <input
                      disabled={loading}
                      type="text"
                      placeholder="Reason for vacation"
                      value={reason}
                      onChange={e => setReason(e.target.value)}
                    />
                  </Col>
                  <Col sm={4}>
                    <SelectHospitalFromId
                      hospitalIds={getLoggedInUser().hospitals}
                      onChange={data => sethospitalId(data)}
                      selected={hospitalId}
                    />
                  </Col>
                  <Col sm={4}>
                    <input
                      type="checkbox"
                      id="showtimetoo"
                      onClick={e => setShowTimeSelect(e.target.checked)}
                    />{' '}
                    <label htmlFor="showtimetoo">Select Time</label>{' '}
                  </Col>
                  <Col sm={2} className="text-right">
                    {loading ? <Loader size={5} inline /> : null}{' '}
                    <Button
                      disabled={loading}
                      color="primary"
                      onClick={() => {
                        if (hospitalId && hospitalId.value) {
                          logAmplitudeEvent(
                            'Vacation_Initiated',
                            {
                              doctorName,
                              startDate: moment(startTime).format(
                                'DD-MM-YYYY, hh:mm A'
                              ),
                              endDate: moment(endTime).format(
                                'DD-MM-YYYY, hh:mm A'
                              )
                            },
                            true
                          );
                          createVacation({
                            variables: {
                              input: {
                                id: vacationId,
                                startTime,
                                endTime,
                                entityId,
                                reason,
                                hospitalId:
                                  hospitalId && hospitalId.value
                                    ? hospitalId.value.id
                                    : null
                              }
                            }
                          });
                        } else {
                          displayError('Please select a Hospital');
                        }
                      }}
                    >
                      Check and Add
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            );
          }}
        </Mutation>
      )}
    </React.Fragment>
  );
};

DateTimeRangeSelector.propTypes = {
  entityId: PropTypes.number.isRequired,
  vacation: PropTypes.object,
  onRangeAdd: PropTypes.func.isRequired,
  doctorName: PropTypes.string
};

export default DateTimeRangeSelector;
