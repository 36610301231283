import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Query } from 'react-apollo';
import { LOOKUP_PROCEDURES } from '../queries';

const SelectProcedureLookup = ({ excludeProcedures, value, onChange }) => (
  <Query query={LOOKUP_PROCEDURES}>
    {({ loading, data: { procedures } = {} }) => {
      const opts = (procedures || [])
        .filter(p => !excludeProcedures.includes(p.name))
        .map(({ name }) => ({ label: name, value: name }));

      const selectedOpt = { label: value, value };
      return (
        <Select
          isLoading={loading}
          options={opts}
          value={selectedOpt}
          onChange={o => onChange(o.value)}
        />
      );
    }}
  </Query>
);

SelectProcedureLookup.propTypes = {
  excludeProcedures: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func
};

SelectProcedureLookup.defaultProps = {
  excludeProcedures: [],
  onChange: () => {}
};

export default SelectProcedureLookup;
