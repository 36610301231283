import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Query } from 'react-apollo';
import { GET_ALL_HOSPITALS } from '../queries';

const SelectHospital = ({
  isMulti,
  field,
  form,
  onChange,
  showAll,
  ...props
}) => {
  // console.log(field.name, field.value);
  const value = field.value || [];
  return (
    <Query query={GET_ALL_HOSPITALS} variables={{ showAll }}>
      {({ loading, data: { hospitals } = {}, error }) => {
        let options = [];
        let selectedOpts = [];
        if (hospitals) {
          options = hospitals.map(h => ({ label: h.name, value: h.id }));
          selectedOpts = Array.isArray(value)
            ? value.map(v => options.find(o => o.value === v))
            : options.find(o => o.value === value);
        }
        return (
          <Select
            {...props}
            isMulti={isMulti}
            value={selectedOpts}
            options={options}
            isLoading={loading}
            onChange={v => {
              const fieldValue = isMulti ? v.map(vv => vv.value) : v.value;
              form.setFieldValue(field.name, fieldValue);
              onChange(fieldValue);
            }}
          />
        );
      }}
    </Query>
  );
};

SelectHospital.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number),
  field: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  showAll: PropTypes.bool
};

SelectHospital.defaultProps = {
  isMulti: false,
  value: [],
  onChange: () => {},
  showAll: true
};

export default SelectHospital;
