import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
const PAYMENT_MODES = [
  'Online Credit Card',
  'Online Debit Card',
  'UPI',
  'Cash'
];

const SelectPaymentMode = props => {
  const { selectedPaymentMode, onPaymentModeSelect } = props;
  // console.log(props, onPaymentModeSelect);

  let payopts = PAYMENT_MODES.map(p => ({
    label: p,
    value: p
  }));

  const selectedOpt =
    payopts.find(({ value }) => value === selectedPaymentMode) || null;
  return (
    <>
      <Select
        placeholder={'Select a payment mode'}
        value={selectedOpt}
        onChange={e => {
          onPaymentModeSelect(e.value);
        }}
        options={payopts}
      />
    </>
  );
};

SelectPaymentMode.propTypes = {
  selectedPaymentMode: PropTypes.string,
  onPaymentModeSelect: PropTypes.func
};

export default SelectPaymentMode;
