import React from 'react';
import { LOCAL_USER_HOSPITALS } from '../queries';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { setDefaultHospitalId } from '../utilities';

/**
 * setGlobally true by default, sets to local query cache
 * setDefault false by default, sets the default hospital to local store
 *
 */
const MyHospitalSelector = ({ setGlobally, setDefault, onChange }) => {
  return (
    <Query query={LOCAL_USER_HOSPITALS}>
      {({ data: { selectedHospitals, allHospitals }, client }) => {
        const hospitalIdSelected = selectedHospitals[0];
        const opts = allHospitals.map(h => ({ label: h.name, value: h.id }));
        const selected = opts.find(o => o.value === hospitalIdSelected);

        const handleChange = opt => {
          const selectedHopsitalId = opt.value;

          if (setGlobally) {
            client.writeData({
              data: { selectedHospitals: [selectedHopsitalId] }
            });
          }

          if (setDefault) {
            setDefaultHospitalId(selectedHopsitalId);
          }

          onChange(selectedHopsitalId);
        };

        return (
          <Select
            options={opts}
            value={selected}
            onChange={opt => handleChange(opt)}
          />
        );
      }}
    </Query>
  );
};

MyHospitalSelector.propTypes = {
  setGlobally: PropTypes.bool,
  setDefault: PropTypes.bool,
  onChange: PropTypes.func
};

MyHospitalSelector.defaultProps = {
  setGlobally: true,
  setDefault: false,
  onChange: () => {}
};

export default MyHospitalSelector;
