import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FaCog, FaUmbrellaBeach } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { GiAmbulance } from 'react-icons/gi';
import DocSettings from './DocSettings';
import { DEFAULT_DOC_MALE, DEFAULT_DOC_FEMALE } from '../constants';
import classnames from 'classnames';
import InOutBadge from './InOutBadge';
import WeeklySchedulePopover from './WeeklySchedulePopover';
import styled from 'styled-components';
import DayScheduleCollapse from './DayScheduleCollapse';

const Outer = styled.div`
  border-radius: 4px;
  background-color: #f6f8fa;
  border: 1px solid #eaedef;
  padding: 5px;
  margin: 0.2rem 0;
`;
const DocInfo = styled.div`
  display: flex;
`;

const ImgBox = styled.div`
  height: 50px;
  width: 50px;
  position: relative;
`;

const VacationTag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: red;
  color: white;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileImg = styled.img`
  height: 50px;
  width: 50px;
`;

const ProfileContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-left: 0.4rem;
`;

const FloatingBoxTop = styled.span`
  position: absolute;
  right: 0;
  top: -0.75rem;
`;

const FloatingBoxBottom = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const RoomNo = styled.span`
  font-size: 0.8rem;
  color: ${props => (!props.isIn ? '#999' : '#666')};
  text-transform: uppercase;
  padding-right: 1rem;
  & > svg {
    vertical-align: baseline;
  }
`;

export default class DocBadgeExtended extends Component {
  constructor(props) {
    super(props);
    //    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle = e => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  onToggleSettings = e => {
    e.preventDefault();
    const { showDocSettings } = this.state;
    this.setState({ showDocSettings: !showDocSettings });
  };

  render() {
    const {
      doc,
      doc: {
        name,
        primarySpecialty,
        photos,
        entityId,
        isConfigured,
        entity,
        onVacation,
        daySchedule
      },
      onBook,
      onClick,
      isCallcenter,
      onEmergencyBook
    } = this.props;
    const { showDocSettings } = this.state;
    let { isInRoom = false, roomNo } = entity || {};
    let showRoom = true;
    if (!entity) {
      showRoom = false;
    }

    if (!roomNo || `${roomNo}`.trim().length < 1) {
      roomNo = 'NA';
    }

    const dpURL = photos && photos.dp300 ? photos.dp300.url : undefined;

    let displayUrl = dpURL;
    if (!dpURL && doc.gender === 'Male') {
      displayUrl = DEFAULT_DOC_MALE;
    } else if (!dpURL) {
      displayUrl = DEFAULT_DOC_FEMALE;
    }

    const actionWhenLinkClicked = onClick ? onClick : onBook;

    /*
[{"start":"2300","end":"2345","hospitalName":"Cloudnine Hospital Bellandur","isAdhoc":false,"isAltered":false,"alteredStart":null,"alteredEnd":null,"__typename":"Schedule"}]
    */

    return (
      <Outer
        className={classnames([
          { 'doc-unconfigured': !isConfigured },
          'doc-badge'
        ])}
      >
        <DocInfo>
          <ImgBox>
            <ProfileImg src={displayUrl} alt={name} />
            {onVacation && (
              <VacationTag>
                <FaUmbrellaBeach />
              </VacationTag>
            )}
          </ImgBox>
          <ProfileContainer>
            <FloatingBoxTop>
              {!isCallcenter ? (
                <Button size="sm" color="link" onClick={onEmergencyBook}>
                  <GiAmbulance color="red" size="1.3em" />
                </Button>
              ) : null}
              <WeeklySchedulePopover entityId={entityId} entityName={name} />
              {!isCallcenter ? (
                <Button size="sm" color="link" onClick={this.onToggleSettings}>
                  <FaCog />
                </Button>
              ) : null}
            </FloatingBoxTop>
            {showRoom && (
              <FloatingBoxBottom>
                <RoomNo isIn={!!isInRoom}>
                  <GoHome /> {roomNo}
                </RoomNo>
                <InOutBadge isInRoom={isInRoom} onLeave={onVacation} />
              </FloatingBoxBottom>
            )}

            {showDocSettings ? (
              <DocSettings
                isOpen={showDocSettings}
                onToggle={this.onToggleSettings}
                entityId={entityId}
                doctorName={name}
              />
            ) : null}
            <div>
              <Button
                className="doc-badge-title"
                color="link"
                onClick={actionWhenLinkClicked}
              >
                <h6>{name}</h6>
              </Button>
              <br />
              <small>{primarySpecialty}</small>
            </div>
          </ProfileContainer>
        </DocInfo>
        <DayScheduleCollapse daySchedule={daySchedule} />
      </Outer>
    );
  }
}

DocBadgeExtended.propTypes = {
  doc: PropTypes.object,
  onClick: PropTypes.func,
  onBook: PropTypes.func,
  onEmergencyBook: PropTypes.func,
  isCallcenter: PropTypes.bool
};
