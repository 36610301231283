import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field } from 'formik';
import Select from 'react-select';
import { Query, Mutation } from 'react-apollo';
import { GET_LAB_DATA, CREATE_LAB_DATA, UPDATE_LAB_DATA } from '../queries';
import { displaySuccess, logAmplitudeEvent } from '../utilities';
import isEmpty from 'lodash/isEmpty';
import SelectSiteCode from '../components/SelectSiteCode';

const styles = {
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridRowGap: '0.3rem'
  },
  spans2: { gridColumn: '1 / span 2' },
  buttonRow: { gridColumn: '1 / span 2', textAlign: 'right' }
};

const SelectLabsField = ({ field, form }) => {
  return (
    <Query query={GET_LAB_DATA} variables={{ showAll: true }}>
      {({ loading, error, data }) => {
        if (error) return <span>Error: cannot load Labs.</span>;
        let options = [];
        if (data && data.itemId) {
          options = data.itemId.map(({ name, id }) => ({
            label: name,
            value: id
          }));
        }
        const dopts = field.value
          ? field.value.map(v => options.find(h => h.value === v))
          : [];
        // console.log('h', options, field.value, dopts);

        // note: select has a key which generally is not required, the problem was that
        // defaultValue loads dynamically, during the first render there is no data so
        // it loads empty input box, when data comes back it does not update, adding a
        // key that changes when data arrives force reloads the component
        return (
          <Select
            key={dopts}
            isMulti
            placeholder="All Labs"
            name={field.labName}
            options={options}
            onChange={selectedOpts =>
              form.setFieldValue(field.labName, selectedOpts.map(o => o.value))
            }
            defaultValue={dopts}
            isLoading={loading}
          />
        );
      }}
    </Query>
  );
};

SelectLabsField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

const defaultInitValue = {
  homeCollection: false,
  rate: '',
  enable: false,
  itemId: '',
  labName: '',
  siteCode: '',
  ionicId: '',
  aboutInfo: '',
  turnaroundTime: ''
};

const LabModal = props => {
  const { labDetails, isOpen, hideModal, onSave } = props;
  let labDataEdit = isEmpty(labDetails) ? { ...defaultInitValue } : labDetails;
  let itemId = labDataEdit.itemId;
  return (
    <Mutation
      mutation={CREATE_LAB_DATA}
      onCompleted={createData => {
        onSave(createData);
        displaySuccess('New lab created');
      }}
      refetchQueries={() => ['GET_LAB_DATA']}
    >
      {(createLab, { loading, error }) => {
        return (
          <Mutation
            mutation={UPDATE_LAB_DATA}
            onCompleted={updateData => {
              onSave(updateData);
              displaySuccess('Lab updated');
              logAmplitudeEvent(
                'Lab_updated',
                updateData['updateLabData'],
                true
              );
            }}
            refetchQueries={() => ['GET_LAB_DATA']}
          >
            {(updateLab, { loading: updateLoading, error: updateError }) => {
              const errObj = error || updateError;
              const displayErr = errObj && errObj.message;
              const displayLoading = loading || updateLoading;
              return (
                <Modal isOpen={isOpen} toggle={hideModal}>
                  <ModalHeader toggle={hideModal}>
                    {itemId ? 'Edit Lab Data' : 'Create New Lab Data'}
                  </ModalHeader>
                  <ModalBody>
                    {/* <pre>{JSON.stringify(adminUser, null, 2)}</pre> */}
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={labDataEdit}
                        onSubmit={values => {
                          const inputData = {
                            ...values,
                            itemId: parseInt(values.itemId)
                            // rate: parseInt(values.rate)
                          };
                          // console.log('creating ', inputData);

                          if (!itemId) {
                            createLab({ variables: { input: inputData } });
                          } else {
                            // console.log('updating');
                            // const input = { itemId, ...values };
                            const input = inputData;
                            delete input.apiSecret;
                            delete input.__typename;
                            updateLab({
                              variables: { input }
                            });
                          }
                        }}
                      >
                        {formik => (
                          <div>
                            {error && (
                              <div className="error">
                                Error updating lab. {displayErr}
                              </div>
                            )}
                            {/* {data && <pre>{JSON.stringify(data, null, 2)}</pre>} */}
                            <div style={styles.formContainer}>
                              <div>Lab Name</div>
                              <div>
                                <Field
                                  name="labName"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Item Id</div>
                              <div>
                                <Field
                                  name="itemId"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>SiteCode</div>
                              <div className="modalSiteCode">
                                <Field
                                  name="siteCode"
                                  render={({ form, field }) => {
                                    return (
                                      <SelectSiteCode
                                        selectedSiteCode={field.value}
                                        onSiteCodeSelect={v =>
                                          form.setFieldValue(field.name, v)
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div>
                              <div>About Info</div>
                              <div>
                                <Field
                                  name="aboutInfo"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Turnaround Time</div>
                              <div>
                                <Field
                                  name="turnaroundTime"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>IonicId</div>
                              <div>
                                <Field
                                  name="ionicId"
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div>Amount</div>
                              <div>
                                <Field name="rate" style={{ width: '100%' }} />
                              </div>

                              <div>Enable</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="enable"
                                  checked={formik.values.enable}
                                />
                              </div>
                              <div>Home Collection</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="homeCollection"
                                  checked={formik.values.homeCollection}
                                />
                              </div>
                              <div>Favourite Lab</div>
                              <div>
                                <Field
                                  type="checkbox"
                                  name="favouriteLab"
                                  checked={formik.values.favouriteLab}
                                />
                              </div>
                              <div style={styles.buttonRow}>
                                <Button
                                  onClick={formik.submitForm}
                                  size="sm"
                                  color="primary"
                                  disabled={displayLoading}
                                >
                                  {itemId ? 'Update' : 'Create'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                  </ModalBody>
                </Modal>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

LabModal.propTypes = {
  labDetails: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func
};

export default LabModal;
