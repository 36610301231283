import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { API_ROOT } from '../constants';
import { displayError, displaySuccess } from '../utilities';

const SyncWithLT = ({ syncType }) => {
  const [loading, setLoading] = useState(false);

  const handleSync = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_ROOT}/${syncType}`, {
        method: 'GET'
      });
      if (response.ok) {
        displaySuccess(`${syncType}s synced successfully`);
      } else {
        throw new Error(
          `Something went wrong while syncing ${syncType}s with Lifetrenz`
        );
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <span style={{ marginRight: '50px' }}>
        <strong>Sync with Lifetrenz</strong>
      </span>
      <Button color="link" disabled={loading} onClick={handleSync}>
        <FaSync className={classnames({ spinme: loading })} />
      </Button>
    </div>
  );
};

SyncWithLT.propTypes = {
  syncType: PropTypes.string.isRequired
};

export default SyncWithLT;
