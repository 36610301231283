import fetch from 'isomorphic-fetch';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  S2S_CX_SERVER_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import DiscountInput, { discountTypes } from './DiscountInput';
import PaymentForm from './PaymentForm';
import SearchPatientByMobile from './SearchPatientByMobile';
import Veil from './Veil';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const PharmaBillingModal = props => {
  const { isOpen, toggle, selectedHospitalDetails, allDoctorDetails } = props;

  const [billingAmount, updateBillingAmount] = useState('');
  const [paymentData, updatePaymentData] = useState({});
  const [isPatientSelected, updateIsPatientSelected] = useState(false);
  const [userCompleteDetails, updateUserCompleteDetails] = useState();
  const [selectedPharmaDetails, updateSelectedPharmaDetails] = useState();
  const [selectedConsultingDoctor, updateSelectedConsultingDoctor] = useState(
    ''
  );
  const [selectedPatientAge, updateSelectedPatientAge] = useState('');
  const [selectedPatientGender, updateSelectedPatientGender] = useState('');
  const [
    shouldShowCreatePharmaInvoiceLoader,
    showCreatePharmaInvoiceLoader
  ] = useState(false);
  const [
    pharmaInvoiceGenerationSuccess,
    updatePharmaInvoiceGenerationSuccess
  ] = useState(false);
  const [pdfLink, updatePdfLink] = useState(null);

  //New State for discount
  const [discount, setDiscount] = useState('0');
  const [discountType, setDiscountType] = useState(
    discountTypes.find(item => item.value === 'percentage')
  );
  const [discountNotes, setDiscountNotes] = useState('');

  useEffect(() => {
    updateBillingAmount('');
    updatePaymentData({});
    updateIsPatientSelected(false);
    updateUserCompleteDetails();
    updateSelectedPharmaDetails();
    updateSelectedConsultingDoctor('');
    updateSelectedPatientAge('');
    updateSelectedPatientGender('');
    showCreatePharmaInvoiceLoader(false);
    updatePharmaInvoiceGenerationSuccess(false);
    updatePdfLink(null);

    if (isOpen && !selectedHospitalDetails.isCloudnineHosp) {
      getPharmaChargesZohoBooks();
    }
    // eslint-disable-next-line
  }, [isOpen, toggle]);

  let docListArr = null;

  if (isOpen && !selectedHospitalDetails.isCloudnineHosp) {
    docListArr = allDoctorDetails.map(doc => ({
      label: doc.name,
      value: doc.name
    }));
  } else {
    docListArr = null;
  }

  // function to fetch pharma charges configured in zoho books based on hospitalid and charge item type.
  const fetchPharmaCharges = () => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/get_charges`;

    let req_body = {
      cf_hospitalid: selectedHospitalDetails.id.toString(),
      cf_chargeitemtype: 'Pharma',
      siteCode: selectedHospitalDetails.siteCode
    };

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getPharmaChargesZohoBooks = async () => {
    try {
      const response = await fetchPharmaCharges();
      if (response && response.status && response.status === 1) {
        updateSelectedPharmaDetails(response.data[0]);
        logAmplitudeEvent(
          'pharma_charge_fetched_successfully',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Pharma'
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'pharma_charge_fetching_failed',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Pharma'
          },
          true
        );
      }
    } catch (error) {
      logAmplitudeEvent(
        'pharma_charge_fetching_failed',
        {
          hospitalId: selectedHospitalDetails.id,
          chargeType: 'Pharma'
        },
        true
      );
    }
  };

  // function to create pharma billing invoice based on get_charges API response and other customer payment details.
  const createPharmaInvoiceData = (
    siteCode,
    lineItemsList,
    referringDoctorName,
    finalSelectedHospital
  ) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      mpid: userCompleteDetails.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      gst_no: selectedHospitalDetails.gstNo,
      first_name: userCompleteDetails.userName,
      email: userCompleteDetails.userEmail,
      mobile: userCompleteDetails.userMobile,
      product_type: selectedPharmaDetails.product_type,
      sitecode: siteCode,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_address: selectedHospitalDetails.address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      line_items: lineItemsList,
      procedure: 'Pharma',
      logo_url: selectedHospitalDetails.logoUrl,
      notes: {
        billing_amount: billingAmount,
        referring_doc_name: referringDoctorName,
        selected_hospital_name: finalSelectedHospital.name,
        selected_clinic_address: selectedHospitalDetails.address,
        patient_age: selectedPatientAge,
        patient_gender: selectedPatientGender
      },
      discount: parseInt(discount),
      discountType: discountType.value
    };

    if (req_body.discount && discountNotes) {
      req_body.discountNotes = discountNotes;
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createPharmaPaymentInvoice = async () => {
    if (isEmpty(selectedPharmaDetails)) {
      displayError('Line item not found');
      return;
    }

    showCreatePharmaInvoiceLoader(true);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    const siteCode = selectedHospitalDetails.siteCode;

    let lineItemsList = [
      {
        item_id: selectedPharmaDetails.item_id,
        header_name: `${selectedPharmaDetails.item_name} invoice`,
        is_taxable: false,
        tax_exemption_id: selectedPharmaDetails.tax_exemption_id,
        name: selectedPharmaDetails.name,
        description: selectedPharmaDetails.description,
        hsn_or_sac: selectedPharmaDetails.hsn_or_sac,
        product_type: selectedPharmaDetails.product_type,
        rate: billingAmount,
        item_total: billingAmount
      }
    ];

    try {
      const response = await createPharmaInvoiceData(
        siteCode,
        lineItemsList,
        selectedConsultingDoctor.value,
        selectedHospitalDetails.name
      );
      if (
        response &&
        response.status &&
        response.status === 1 &&
        response.data &&
        response.data.s3pdflink
      ) {
        showCreatePharmaInvoiceLoader(false);
        updatePharmaInvoiceGenerationSuccess(true);
        displaySuccess('Pharma invoice generated successfully');
        updatePdfLink(response.data.s3pdflink);

        logAmplitudeEvent(
          'pharma_invoice_generated_successfully',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: selectedPharmaDetails.product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: 'Pharma',
            notes: {
              billing_amount: billingAmount
            },
            s3pdflink: response.data.s3pdflink
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'pharma_invoice_generation_failed',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: selectedPharmaDetails.product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItemsList,
            procedure: 'Pharma',
            notes: {
              billing_amount: billingAmount
            },
            response: response
          },
          true
        );
        showCreatePharmaInvoiceLoader(false);
        const emsg = `Pharma invoice generation failed`;
        throw new Error(emsg);
      }
    } catch (error) {
      logAmplitudeEvent(
        'pharma_invoice_generation_failed',
        {
          mpid: userCompleteDetails.mpid,
          salesperson_name: loggedInUser.name,
          reference_number: paymentData.transactionId
            ? paymentData.transactionId
            : 'N/A',
          txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
          payment_mode: paymentData.paymentMode,
          gst_no: selectedHospitalDetails.gstNo,
          first_name: userCompleteDetails.userName,
          email: userCompleteDetails.userEmail,
          mobile: userCompleteDetails.userMobile,
          product_type: selectedPharmaDetails.product_type,
          sitecode: siteCode,
          hospital_id: selectedHospitalDetails.id.toString(),
          hospital_name: selectedHospitalDetails.name,
          hospital_address: selectedHospitalDetails.address,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          line_items: lineItemsList,
          procedure: 'Pharma',
          notes: {
            billing_amount: billingAmount
          },
          error: error
        },
        true
      );
      showCreatePharmaInvoiceLoader(false);
      displayError(error);
    }
  };

  const handleUserData = selectedPatient => {
    if (!selectedPatient) {
      updateIsPatientSelected(false);
      return;
    } else {
      const userData = {
        mpid: selectedPatient.mpid,
        userName: selectedPatient.first_name,
        userCountry: selectedPatient.nationality_country,
        userMobile: selectedPatient.mobile,
        userEmail: selectedPatient.email
      };
      updateUserCompleteDetails(userData);
      let patientAge = calculateAge(selectedPatient.date_of_birth);
      updateSelectedPatientAge(patientAge);
      updateSelectedPatientGender(selectedPatient.gender);
      updateIsPatientSelected(true);
    }
  };

  // function to calculate patient age in years and months based on his date of birth.
  const calculateAge = dateString => {
    let today = new Date();
    let DOB = new Date(dateString);
    let totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    let years = today.getFullYear() - DOB.getFullYear();

    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    let months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months === 0) months = 11;
    } else {
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    let age = years + ' years ' + months + ' months ';
    return age;
  };

  const handleMobileNoChange = () => updateIsPatientSelected(true);

  const handleOnClose = () => {
    updateBillingAmount('');
    updatePaymentData({});
    updateIsPatientSelected(false);
    updateUserCompleteDetails();
    updateSelectedPharmaDetails();
    updateSelectedConsultingDoctor('');
    updateSelectedPatientAge('');
    updateSelectedPatientGender('');
    showCreatePharmaInvoiceLoader(false);
    updatePharmaInvoiceGenerationSuccess(false);
    updatePdfLink(null);
  };

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };

  const createPharmaInvoice = async () => {
    createPharmaPaymentInvoice();
  };

  const viewPharmaInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Pharma Billing</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Pharma Billing Details'}</CardHeader>
                <Collapse isOpen={true}>
                  <CardBody>
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Search by mobile<div style={{ color: 'red' }}>*</div>:
                      </Col>
                      <Col xs={8}>
                        <SearchPatientByMobile
                          onChange={handleUserData}
                          onMobileNoChange={handleMobileNoChange}
                        />
                      </Col>
                    </Row>
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Billing amount<div style={{ color: 'red' }}>*</div>:
                      </Col>
                      <Col xs={8}>
                        <input
                          type="text"
                          placeholder="Enter billing amount"
                          onChange={event => {
                            const pattern = /^[0-9\b]+$/;
                            if (
                              (event.target.value === '' ||
                                pattern.test(event.target.value)) &&
                              event.target.value.length <= 4
                            ) {
                              updateBillingAmount(event.target.value);
                              if (docListArr && docListArr.length === 1) {
                                updateSelectedConsultingDoctor(docListArr[0]);
                              }
                            }
                          }}
                          value={billingAmount}
                        />
                      </Col>
                    </Row>
                    <Row className="form-row">
                      <Col sm={1} md={2} lg={3} />
                      <Col sm={11} md={10} lg={9}>
                        <DiscountInput
                          labelProps={{ style: { fontWeight: 700 } }}
                          discount={discount}
                          setDiscount={setDiscount}
                          discountType={discountType}
                          setDiscountType={setDiscountType}
                          discountNotes={discountNotes}
                          setDiscountNotes={setDiscountNotes}
                          totalAmount={billingAmount}
                        />
                      </Col>
                    </Row>
                    {isOpen && !selectedHospitalDetails.isCloudnineHosp ? (
                      <>
                        <Row className="form-row">
                          <Col
                            xs={4}
                            className="text-right"
                            style={{
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: 6
                            }}
                          >
                            Consulting doctor
                            <div style={{ color: 'red' }}>*</div>:
                          </Col>
                          <Col xs={8}>
                            <Select
                              placeholder="Select consulting doctor"
                              value={
                                docListArr && docListArr.length === 1
                                  ? docListArr[0]
                                  : selectedConsultingDoctor
                              }
                              onChange={doc => {
                                updateSelectedConsultingDoctor(doc);
                              }}
                              options={docListArr}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    isPatientSelected &&
                    selectedConsultingDoctor !== '' &&
                    billingAmount &&
                    billingAmount.length >= 1 &&
                    userCompleteDetails
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreatePharmaInvoiceLoader ||
                        pharmaInvoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreatePharmaInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          if (
                            (!paymentData.transactionId ||
                              paymentData.transactionId.length < 5) &&
                            paymentData.paymentMode !== 'Cash'
                          ) {
                            displayError(
                              'Please provide a valid transaction id, minimum 5 digits !!!'
                            );
                            return;
                          } else {
                            showConfirmation(
                              'Create Pharma Invoice Confirmation',
                              'Are you sure you want to create pharma invoice?',
                              () => createPharmaInvoice()
                            );
                          }
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewPharmaInvoice}>
            View Pharma Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PharmaBillingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedHospitalDetails: PropTypes.object,
  allDoctorDetails: PropTypes.array
};

export default PharmaBillingModal;
