import React from 'react';
import PropTypes from 'prop-types';
import { TiWeatherShower } from 'react-icons/ti';
import { FaUsers, FaParking, FaUserNurse } from 'react-icons/fa';
import {
  GiTripleNeedle,
  GiHospital,
  GiFireflake,
  GiTrophy,
  GiThink,
  GiCalendar,
  GiBowlingStrike,
  GiStorkDelivery,
  GiPocketWatch
} from 'react-icons/gi';

const all1 = (
  <h4 className="muted-text">
    <TiWeatherShower /> No appointments! Must be bad weather.
  </h4>
);
const all2 = (
  <h4 className="muted-text">
    <GiCalendar /> {`No booking. Do people think it's a holiday?`}
  </h4>
);

const booked1 = (
  <h4 className="muted-text">
    <FaUsers /> No booking... do you hear the commotion at booking desk?
  </h4>
);

const booked2 = (
  <h4 className="muted-text">
    <GiBowlingStrike /> No booking remaining, the PR should work harder!
  </h4>
);

const checkedIn1 = (
  <h4 className="muted-text">
    <FaParking /> None checked in. Self parking could be challenging. Wait some?
  </h4>
);

const checkedIn2 = (
  <h4 className="muted-text">
    <GiPocketWatch /> No check-ins? Is being on time out of fashion?
  </h4>
);

const consulting1 = (
  <h4 className="muted-text">
    <GiTripleNeedle /> None consulting. Trypanophobia is too real for some.
  </h4>
);

const consulting2 = (
  <h4 className="muted-text">
    <GiThink /> No running consultation? May be they are too shy to enter the
    room.
  </h4>
);

const checkedout1 = (
  <h4 className="muted-text">
    <GiHospital /> No check outs? Does the hospital smell too nice today?
  </h4>
);

const checkedout2 = (
  <h4 className="muted-text">
    <FaUserNurse /> No check outs? You know, the staff is extra friendly today.
  </h4>
);

const cancelled1 = (
  <h4 className="muted-text">
    <GiFireflake /> No cancelation! This should be a good news.
  </h4>
);

const cancelled2 = (
  <h4 className="muted-text">
    <GiStorkDelivery /> No cancelation, Stork Delivery Service is just a fable.
  </h4>
);

const noShow1 = (
  <h4 className="muted-text">
    <GiTrophy /> No one said no to our service. We must be good.
  </h4>
);

const noShow2 = (
  <h4 className="muted-text">
    <GiStorkDelivery /> No no-show yet. Hopefully storks are not influencing the
    upcomings.
  </h4>
);

const states = {
  All: [all1, all2],
  Booked: [booked1, booked2],
  'Checked In': [checkedIn1, checkedIn2],
  'Start Consultation': [consulting1, consulting2],
  // 'End Consultation',
  'Checked Out': [checkedout1, checkedout2],
  Cancelled: [cancelled1, cancelled2],
  'No Show': [noShow1, noShow2]
};

const FunnyNoAppointmentMessage = ({ appointmentState }) => {
  const msgs = states[appointmentState] || states.All;
  const select = Math.floor(msgs.length * Math.random());
  return msgs[select];
};

FunnyNoAppointmentMessage.propTypes = {
  appointmentState: PropTypes.string
};

export default FunnyNoAppointmentMessage;
