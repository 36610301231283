import React from 'react';
import { STATIC_BASE_URL } from '../constants';
/*

*/

const Veil = () => (
  <div className="veil">
    <img id="veil-image" src={`${STATIC_BASE_URL}/spinner.gif`} alt="Loading" />
  </div>
);

export default Veil;
