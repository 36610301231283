import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import { UPDATE_RESERVE_SLOT } from '../queries';
import { showConfirmation } from '../ConfirmationDialogService';
import { FaUserLock } from 'react-icons/fa';

const ReserveSlot = props => {
    const { id, startTime } = props;
    let msg = "Are you sure you want to reserve slot at " + moment(startTime).format('h:mm A') + "?";
    const confirm = reservedSlot => {
        showConfirmation('Confirm Reservation?', msg, () =>
            reservedSlot({ variables: { id: id, isReserved: true } })
        )
    };
    return (
        < Mutation
            mutation={UPDATE_RESERVE_SLOT}
            variables={{ id: id, isReserved: true }
            }
            refetchQueries={() => ['SLOTS_QUERY']}
            onError={e => {
                displayError(e)
            }}
            onCompleted={data => {
                displaySuccess('Slot is reserved');
                let eventProps = { ...props, time: moment(startTime).format('h:mm A') };
                logAmplitudeEvent('Reserve_Slot', eventProps, true);
            }}
        >
            {(reservedSlot, { loading, error, data }) => {
                return (
                    <span title="Reserve">
                        <FaUserLock onClick={() => confirm(reservedSlot)}
                            color="blue" />
                    </span>
                );
            }}
        </ Mutation >
    )
};

ReserveSlot.propTypes = {
    id: PropTypes.string,
    companionType: PropTypes.number.isRequired,
    isReserved: PropTypes.bool,
    startTime: PropTypes.instanceOf(Date),
    entityId: PropTypes.number,
    hospitalId: PropTypes.number,
    procedureName: PropTypes.string
};

export default ReserveSlot;
