import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
const LT_PROCEDURES = ['Consultation', 'Follow Up Consultation'];

const SelectProcedureV2 = props => {
  const {
    onProcedureSelect,
    selectedProcedure,
    procedureList,
    selectedHospitalDetails
  } = props;

  const listOfProcedures = [...procedureList];
  let procedureListArr = [];

  if (selectedHospitalDetails.chargeMaster === 'zoho_books') {
    procedureListArr = listOfProcedures.map(p => ({
      label: p,
      value: p
    }));
  } else {
    procedureListArr = LT_PROCEDURES.map(p => ({
      label: p,
      value: p
    }));
  }

  const selectedOpt =
    procedureListArr.find(({ value }) => value === selectedProcedure) || null;
  return (
    <>
      <Select
        placeholder={'Select a procedure'}
        value={selectedOpt}
        onChange={e => {
          onProcedureSelect(e.value);
        }}
        options={procedureListArr}
      />
    </>
  );
};

SelectProcedureV2.propTypes = {
  onProcedureSelect: PropTypes.func,
  selectedProcedure: PropTypes.string,
  procedureList: PropTypes.array,
  selectedHospitalDetails: PropTypes.object
};

export default SelectProcedureV2;
