import React from 'react';
import PropTypes from 'prop-types';
// import Select from 'react-select';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import { Row, Col } from 'reactstrap';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const displayDayMap = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday'
};
const weeks = ['first', 'second', 'third', 'fourth', 'fifth'];
const ALL_WEEKS = [1, 2, 3, 4, 5];
const daysConf2Opts = (daysConf = {}) => {
  // This checks of every element of ALL_WEEKS is in weeksArray
  const isAllWeekSelected = (selectedWeeksArray = []) =>
    ALL_WEEKS.every(w => selectedWeeksArray.includes(w));
  const isThisWeekSelected = (thisWeek, selectedWeeksArray = []) =>
    selectedWeeksArray.includes(thisWeek);

  return days.map(d => {
    const label = displayDayMap[d];
    return {
      label,
      checked: isAllWeekSelected(daysConf[d]),
      value: { [d]: ALL_WEEKS },
      children: weeks.map((w, i) => ({
        label: `Every ${w} ${label}`,
        value: { [d]: i + 1 },
        checked: isThisWeekSelected(i + 1, daysConf[d])
      }))
    };
  });
};

const selectedOpts2DaysConf = (selectedOpts = []) => {
  let daysConf = {};
  selectedOpts.forEach(opt => {
    //all weeks selected
    if (opt._depth === 0) {
      daysConf = { ...daysConf, ...opt.value };
    } else {
      const dayOfTheWeek = Object.keys(opt.value)[0];
      const weekOfTheMonth = opt.value[dayOfTheWeek];
      if (daysConf[dayOfTheWeek]) {
        daysConf[dayOfTheWeek].push(weekOfTheMonth);
      } else {
        daysConf[dayOfTheWeek] = [weekOfTheMonth];
      }
    }
  });

  if (Object.keys(daysConf).length < 1) {
    return undefined;
  } else {
    return daysConf;
  }
};

const DaysConfInput = ({ daysConf, onChange, ...props }) => {
  return (
    <div>
      <Row>
        <Col>
          <DropdownTreeSelect
            showPartiallySelected
            className="tree-select"
            data={daysConf2Opts(daysConf)}
            onChange={(_, selectedNodes) => {
              const daysConfUpdated = selectedOpts2DaysConf(selectedNodes);
              // console.log(JSON.stringify(daysConfUpdated, null, 2));
              onChange(daysConfUpdated);
            }}
            {...props}
          />
        </Col>
      </Row>
    </div>
  );
};

DaysConfInput.propTypes = {
  daysConf: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

DaysConfInput.defaultProps = {
  daysConf: {},
  onChange: () => {}
};

export default DaysConfInput;
