import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { FaTrash } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';
import { DELETE_MULTIPLE_SCANS_DATA } from '../queries';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

const getMessage = () => (
  <>
    <span>
      Permanently delete the selected scans?
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteMultipleScans = ({ itemIds, onDeleteCompleted }) => {
  const confirm = deleteMultipleScans => {
    showConfirmation('Delete Scans?', getMessage(), () =>
      deleteMultipleScans({ variables: { itemIds } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_MULTIPLE_SCANS_DATA}
      onCompleted={() => {
        displaySuccess(`${itemIds.length} scans deleted`);
        logAmplitudeEvent(
          'Scans_deleted',
          { deletedScans: itemIds.join(', ') },
          true
        );
        onDeleteCompleted();
      }}
      onError={e => {
        displayError(e);
        onDeleteCompleted();
      }}
      refetchQueries={() => ['GET_SCAN_DATA']}
    >
      {(deleteMultipleScans, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteMultipleScans)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteMultipleScans.propTypes = {
  itemIds: PropTypes.arrayOf(PropTypes.number),
  onDeleteCompleted: PropTypes.func.isRequired
};

export default DeleteMultipleScans;
