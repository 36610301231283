import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Query } from 'react-apollo';
import { GET_ALL_DEPARTMENTS } from '../queries';
import { logAmplitudeEvent } from '../utilities';

const DepartmentsSelect = ({
  isMulti,
  onChange,
  value,
  showOptions,
  ...props
}) => {
  return (
    <Query query={GET_ALL_DEPARTMENTS}>
      {({ loading, data: { departments } = {} }) => {
        if (!departments) departments = [];
        departments = departments.map(({ id, name }) => ({
          label: name,
          value: id
        }));

        value =
          isMulti && value
            ? value.map(v => departments.find(d => d.value === v))
            : value && departments.find(d => d.value === value);
        return (
          <Select
            isMulti={isMulti}
            {...props}
            onChange={v => {
              onChange(isMulti ? v.map(v => v.value) : v.value);
              logAmplitudeEvent('Quick_Book_Department_Select', {}, true);
            }}
            isLoading={loading}
            options={showOptions ? departments : []}
            value={value}
          />
        );
      }}
    </Query>
  );
};

DepartmentsSelect.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  showOptions: PropTypes.bool
};

DepartmentsSelect.defaultProps = {
  isMulti: false,
  onChange: () => {},
  showOptions: true
};
export default DepartmentsSelect;
