import classnames from 'classnames';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { FaCog } from 'react-icons/fa';
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  PopoverHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover
} from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import ScanChargeMasterTab from '../components/ScanChargeMasterTab';
import ScanDetailsMappingTab from '../components/ScanDetailsMappingTab';
import SyncWithLT from '../components/SyncWithLT';
import { GET_LOGGED_IN_USER } from '../queries';

const styles = {
  page: {
    margin: '1rem'
  },
  toolbar: {
    marginBottom: '1rem',
    marginTop: '1rem'
  }
};

const Scans = () => {
  const {
    data: { me }
  } = useQuery(GET_LOGGED_IN_USER);
  const isCustomerSupport = me.role === 'customersupport';
  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  return (
    <AuthRequired pageName="scans">
      <div style={styles.page}>
        <Row>
          <Col sm={3}>
            <h1>Scans</h1>
          </Col>
          {!isCustomerSupport && (
            <Col className="text-right">
              <Button color="link" id="scanSettings">
                <FaCog />
              </Button>
              <UncontrolledPopover
                trigger="legacy"
                placement="bottom"
                target="scanSettings"
              >
                <PopoverHeader>Scans Settings</PopoverHeader>
                <PopoverBody>
                  <SyncWithLT syncType="scan" />
                </PopoverBody>
              </UncontrolledPopover>
            </Col>
          )}
        </Row>
        {!isCustomerSupport && (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === '1'
                  })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Scan Charge Master
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: currentActiveTab === '2'
                  })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Scan Details Mapping
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
              <TabPane tabId="1">
                <ScanChargeMasterTab />
              </TabPane>
              <TabPane tabId="2">
                <ScanDetailsMappingTab />
              </TabPane>
            </TabContent>
          </>
        )}
        {isCustomerSupport && <ScanChargeMasterTab />}
      </div>
    </AuthRequired>
  );
};
export default Scans;
