import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { logAmplitudeEvent } from '../utilities';

const TimingsSelect = ({ isMulti, onChange, value, showOptions, ...props }) => {
  logAmplitudeEvent('Quick_Book_Timing_Select', true);

  const timings = [
    {
      label: '08:00 - 09:00',
      value: '08:00'
    },
    {
      label: '09:00 - 10:00',
      value: '09:00'
    },
    {
      label: '10:00 - 11:00',
      value: '10:00'
    },
    {
      label: '11:00 - 12:00',
      value: '11:00'
    },
    {
      label: '12:00 - 13:00',
      value: '12:00'
    },
    {
      label: '13:00 - 14:00',
      value: '13:00'
    },
    {
      label: '14:00 - 15:00',
      value: '14:00'
    },
    {
      label: '15:00 - 16:00',
      value: '15:00'
    },
    {
      label: '16:00 - 17:00',
      value: '16:00'
    },
    {
      label: '17:00 - 18:00',
      value: '17:00'
    },
    {
      label: '18:00 - 19:00',
      value: '18:00'
    },
    {
      label: '19:00 - 20:00',
      value: '19:00'
    },
    {
      label: '20:00 - 21:00',
      value: '20:00'
    }
  ];

  value =
    isMulti && value
      ? value.map(v => timings.find(d => d.value === v))
      : value && timings.find(d => d.value === value);
  return (
    <Select
      isMulti={isMulti}
      {...props}
      onChange={v => onChange(isMulti ? v.map(v => v.value) : v.value)}
      options={showOptions ? timings : []}
      value={value}
    />
  );
};

TimingsSelect.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  showOptions: PropTypes.bool
};

TimingsSelect.defaultProps = {
  isMulti: false,
  onChange: () => {},
  showOptions: true
};
export default TimingsSelect;
