import React, { useState } from 'react';
import { Col, Row, Button, Modal } from 'reactstrap';
import { Query } from 'react-apollo';
import { GET_ALL_HOSPITALS_FULL } from '../queries';
import Select from 'react-select';
import HospitalEditForm from './HospitalEditForm';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import DoctorRankingList from './DoctorRankingList';
import AddHospitalType from './AddHospitalType';

const newHospital = {
  name: '',
  address: '',
  lat: '',
  lng: '',
  cityId: '',
  type: '',
  departments: '',
  location: '',
  pinCode: '',
  cityName: '',
  zone: '',
  services: '',
  beds: '',
  siteCode: '',
  fertilitySiteCode: '',
  seoSlug: '',
  tags: '',
  isCloudnineHosp: false,
  isAppEnabled: false,
  isPharmaEnabled: false,
  isLabEnabled: false,
  isVaccinationEnabled: false,
  logoUrl: '',
  callCentreNo: '',
  appSupportEmail: '',
  gstNo: '',
  chargeMaster: '',
  zohoOrgId: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  hospitalTypeId: ''
};

const HospitalForm = () => {
  const [selectedH, setSelectedH] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [hospitalTypeModal, setHospitalTypeModal] = useState(false);

  const [isNew, setIsNew] = useState(false);

  const editingNow = isNew
    ? { ...newHospital }
    : selectedH && selectedH.hospital;

  const toggleHospitalTypeModal = () => {
    setHospitalTypeModal(state => !state);
  };

  const handleSave = savedHospital => {
    setIsNew(false);
    setSelectedH({
      label: savedHospital.name,
      value: savedHospital.id,
      hospital: savedHospital
    });
  };
  const setIsNewHandle = () => {
    if (!isNew) {
      setActiveTab(0);
    }
    setIsNew(!isNew);
  };

  return (
    <div>
      <h1>Hospitals</h1>
      <Row>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ minWidth: '30rem' }}>
              <Query query={GET_ALL_HOSPITALS_FULL}>
                {({ loading, data: { hospitals } = {} }) => {
                  let options = [];
                  if (hospitals) {
                    options = hospitals.map(h => ({
                      label: h.name,
                      value: h.id,
                      hospital: h
                    }));
                  }
                  return (
                    <Select
                      value={selectedH}
                      options={options}
                      isLoading={loading}
                      onChange={v => setSelectedH(v)}
                      isDisabled={isNew}
                    />
                  );
                }}
              </Query>
            </div>
            <div>
              <Button color="link" onClick={() => setIsNewHandle()}>
                {isNew ? 'Back' : 'New Hospital'}
              </Button>
            </div>
            <div>
              <Button color="link" onClick={toggleHospitalTypeModal}>
                New Hospital Type
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <br />

      <Nav tabs>
        <NavItem style={{ cursor: 'pointer' }}>
          <NavLink
            style={{ color: '#007bff' }}
            className={activeTab === 0 ? 'active' : ''}
            onClick={() => setActiveTab(0)}
          >
            Hospital Details
          </NavLink>
        </NavItem>
        <NavItem style={{ cursor: 'pointer' }}>
          <NavLink
            style={{ color: isNew ? 'grey' : '#007bff' }}
            isDisabled={isNew}
            className={activeTab === 1 ? 'active' : ''}
            onClick={() => {
              if (!isNew) {
                setActiveTab(1);
              }
            }}
          >
            Doctor&apos;s Ranking
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <Row>
            <Col>
              <br />
              {isNew ? <h3>New Hospital</h3> : null}
              <HospitalEditForm
                hospital={editingNow}
                onSave={handleSave}
                isNew={isNew}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={1}>
          <Row>
            <Col>
              <br />
              {selectedH && selectedH.hospital ? (
                <DoctorRankingList
                  isActiveTab={activeTab}
                  hospital={selectedH && selectedH.hospital}
                />
              ) : (
                <h3>Please select a hospital to edit</h3>
              )}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <Modal isOpen={hospitalTypeModal} toggle={toggleHospitalTypeModal}>
        <div className="p-4">
          <AddHospitalType handleClose={toggleHospitalTypeModal} />
        </div>
      </Modal>
    </div>
  );
};

export default HospitalForm;
