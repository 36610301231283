import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
// import remove from 'lodash/remove';
// import findIndex from 'lodash/findIndex';
// import 'react-select/dist/react-select.css';

// const allOptions = { label: 'All', value: '*' };

/**
 * Inspired by:
 * 1. Formik with React-Select: https://codesandbox.io/s/jvz577mo73
 * 2. Select All in React-Select: https://medium.com/@alex_escalante/react-select-alloptionoptions-with-a-single-click-1ebf5a33fe31
 */
export default class TagsSelect extends Component {
  state = { selectedValues: [] };
  handleChange = value => {
    const { onChange, name } = this.props;

    /*
    console.log(
      'values selected',
      JSON.stringify(value),
      value.length,
      options
    );

    //check if is has all options
    const allOptionIndex = findIndex(value, v => v.value === '*');
    const hasAllOption = allOptionIndex > -1;
    //if "all" just got appended
    const isAllOptionSelectedJustNow = allOptionIndex === value.length - 1;
    const actualSelectedOptionsCount = hasAllOption
      ? value.length - 1
      : value.length;

    let selectedValues;
    if (options.length === 1) {
      selectedValues = options;
    } else if (
      actualSelectedOptionsCount === options.length ||
      actualSelectedOptionsCount === 0 ||
      isAllOptionSelectedJustNow
    ) {
      selectedValues = [allOptions];
      this.setState({ selectedValues });
    } else {
      remove(value, v => v.value === '*');
      selectedValues = value;
      this.setState({ selectedValues });
    }

    //if all is selected return empty array, right?
    let retVal = selectedValues;
    if (
      selectedValues &&
      selectedValues.length === 1 &&
      selectedValues[0].value === '*'
    ) {
      retVal = [];
    }*/

    // console.log('retVal', value);
    this.setState({ selectedValues: value });
    onChange(name, value);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;
    onBlur(name, true);
  };

  render() {
    const { options, isMulti, placeholder } = this.props;
    // let customAllOptions = allOptions;
    // if (allLabel && allLabel.trim().length > 0) {
    //   customAllOptions = { ...allOptions, label: allLabel };
    // }
    // console.log('>>>> ', customAllOptions);

    // const opts = [customAllOptions, ...options];
    const opts = options;

    return (
      <Select
        options={opts}
        isMulti={isMulti}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={placeholder}
        value={this.state.selectedValues}
        isClearable
      />
    );
  }
}

TagsSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ),
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object)
};
