import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col, Button } from 'reactstrap';
import TextArea from 'react-autosize-textarea';
import { Mutation } from 'react-apollo';
import { REPORT_ERROR } from '../queries';
import { displaySuccess } from '../utilities';
import * as Yup from 'yup';

const phoneRegExp = /^[6-9]\d{9}$/;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3, 'Name too small')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Invalid mobile number')
    .required('Mobile number is required'),
  description: Yup.string()
    .trim()
    .min(10, 'Please write a note more than 5 words')
    .required('A description is required')
});

const SendError = ({ errorMessage, errorObject, onSent }) => {
  return (
    <Mutation
      mutation={REPORT_ERROR}
      onCompleted={() => {
        displaySuccess(
          'Your report has been sent, please give us 24 hours to get back to you',
          false
        );
        onSent();
      }}
    >
      {(sendReport, { loading }) => {
        return (
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              name: '',
              phone: '',
              email: '',
              location: '',
              description: ''
            }}
            onSubmit={({ name, email, phone, location, description }) => {
              const msg =
                `NAME: ${name}\n` +
                `CONTACT: ${email} (${phone})\n` +
                `LOCATION: ${location}\n` +
                `--------------------------\n` +
                `DESCRIPTION: ${description}\n`;
              sendReport({
                variables: {
                  input: {
                    message: msg,
                    error: errorMessage,
                    extraDetail: JSON.stringify(errorObject, null, 2),
                    replyToEmail: email,
                    replyToPhone: phone
                  }
                }
              });
            }}
            render={({ handleSubmit, values, handleChange }) => {
              return (
                <Form disabled={loading}>
                  <Container>
                    <Row className="form-row">
                      <Col>
                        <Field type="text" name="name" placeholder="Name" />
                        <ErrorMessage
                          component="span"
                          className="error"
                          name="name"
                        />
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Phone (required)"
                        />
                        <ErrorMessage
                          component="span"
                          className="error"
                          name="phone"
                        />
                      </Col>
                    </Row>
                    <Row className="form-row">
                      <Col>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email (required)"
                        />
                        <ErrorMessage
                          component="span"
                          className="error"
                          name="email"
                        />
                      </Col>
                      <Col>
                        <Field
                          type="text"
                          name="location"
                          placeholder="Hospital/Location"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label htmlFor="error-message">DESCRIPTION</label>
                        <br />
                        <TextArea
                          id="error-message"
                          className="input"
                          onChange={handleChange}
                          name="description"
                          value={values.description}
                          rows={3}
                          placeholder="Please provide some description (at least 10 words)"
                        />

                        <ErrorMessage
                          component="span"
                          className="error"
                          name="description"
                        />
                      </Col>
                    </Row>
                    <div className="modal-footer">
                      <Button
                        color="primary"
                        onClick={handleSubmit}
                        disabled={
                          loading || values.description.trim().length < 10
                        }
                      >
                        SEND REPORT
                      </Button>
                    </div>
                  </Container>
                </Form>
              );
            }}
          />
        );
      }}
    </Mutation>
  );
};

SendError.propTypes = {
  errorMessage: PropTypes.string,
  errorObject: PropTypes.object,
  onSent: PropTypes.func
};

SendError.defaultProps = {
  onSent: () => {}
};

export default SendError;
