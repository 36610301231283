import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Button, Col, Row } from 'reactstrap';
import Loader from '../components/Loader';
import { DROPDOWN_QUERIES } from '../queries';
import DatePicker1 from './DatePicker1';
import TagsSelect from './TagsSelect';

const PREFIX_REGEX = /(Mr|MR|Ms|Miss|Mrs|Dr|Sir)(\.?)\s/;

export const data2options = data => {
  const r = {
    hospitals: [],
    departments: [],
    doctors: [],
    procedures: [],
    languages: []
  };
  if (!data) {
    return r;
  }

  Object.entries(data).forEach(
    ([key, arr]) => (r[key] = arr.map(o => ({ label: o.name, value: o.id })))
  );
  return r;
};

export default class ApptSearchForm extends Component {
  state = {
    selDepts: []
  };

  normalizeFilters = formData => {
    const { onFilter } = this.props;
    if (!Array.isArray(formData.hospitals)) {
      formData.hospitals = formData.hospitals ? [formData.hospitals] : [];
    }
    const filters = Object.entries(formData).reduce(
      (accumulator, [key, value]) => {
        //make sure it is something coming from tagsselect which has
        //[{label, value},...] shape
        //if so convert it into [value,...] shape
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          value[0].label &&
          value[0].value
        ) {
          // TODO: FIX ANNOYING doctors to objectIds rename
          // make upstream objectIds
          // the problem here is if array size is zero we never
          // get here, so we need to remove doctors before return
          if (key === 'doctors') {
            accumulator['objectIds'] = value.map(v => v.value);
          } else {
            accumulator[key] = value.map(v => v.value);
          }
        } else {
          accumulator[key] = value;
        }
        return accumulator;
      },
      {}
    );

    //TODO: we should not have this kind of breaking logic should not have the line
    // below fix the TODO in reduce above!
    delete filters.doctors;

    onFilter(filters);
  };
  render() {
    const { selDepts } = this.state;

    return (
      <Query query={DROPDOWN_QUERIES}>
        {({ error, loading, data }) => {
          if (loading) return <Loader />;
          if (error) return <span>Error: {error.message}</span>;

          const alldocs = data.doctors;
          const doctorsF =
            selDepts.length > 0
              ? alldocs.filter(d => selDepts.includes(d.departmentId))
              : alldocs;

          doctorsF.sort((a, b) =>
            a.name
              .replace(PREFIX_REGEX, '')
              .localeCompare(b.name.replace(PREFIX_REGEX, ''))
          );
          const filteredData = { ...data, doctors: doctorsF };
          const { hospitals, departments, doctors } = data2options(
            filteredData
          );

          return (
            <Formik
              initialValues={{
                date: moment(),
                hospitals: [],
                departments: [],
                doctors: []
              }}
              onSubmit={this.normalizeFilters}
              render={props => {
                const {
                  values,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Row className="form-row">
                      <Col sm={12}>
                        <DatePicker1
                          name="date"
                          selectedDate={values.date}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </Col>
                    </Row>

                    <Row className="form-row">
                      <Col sm={12}>
                        <TagsSelect
                          name="hospitals"
                          options={hospitals}
                          isMulti={false}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          placeholder="All Hospitals"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row">
                      <Col sm={12}>
                        <TagsSelect
                          name="departments"
                          options={departments}
                          isMulti={true}
                          onChange={(n, v) => {
                            this.setState({ selDepts: v.map(o => o.value) });
                            setFieldValue(n, v);
                          }}
                          onBlur={setFieldTouched}
                          placeholder="All Departments"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row">
                      <Col sm={12}>
                        <TagsSelect
                          name="doctors"
                          options={doctors}
                          isMulti={true}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          placeholder="All Doctors"
                        />
                      </Col>
                    </Row>

                    <Row className="form-row">
                      <Col>
                        <Button type="submit" style={{ width: '100%' }}>
                          Filter
                        </Button>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            />
          );
        }}
      </Query>
    );
  }
}

ApptSearchForm.propTypes = {
  onFilter: PropTypes.func,
  searchCriteria: PropTypes.object.isRequired
};
