import fetch from 'isomorphic-fetch';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import styled from 'styled-components';
import { showConfirmation } from '../ConfirmationDialogService';
import {
  CX_SERVER_ACCESS_TOKEN,
  CX_SERVER_API_KEY,
  CX_SERVER_BASE_URL,
  S2S_CX_SERVER_API_KEY
} from '../constants';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';
import DiscountInput, { discountTypes } from './DiscountInput';
import PaymentForm from './PaymentForm';
import SearchPatientByMobile from './SearchPatientByMobile';
import Veil from './Veil';

const DivTopMargin = styled.div`
  margin-top: 10px;
`;

const ScanBookingModal = props => {
  const {
    isOpen,
    toggle,
    selectedHospitalDetails,
    allHospitalDetails,
    allDoctorDetails
  } = props;

  const [radiologistName, updateRadiologistName] = useState('');
  const [appointmentDate, updateAppointmentDate] = useState(moment());
  const [totalAmount, updateTotalAmount] = useState();
  const [paymentData, updatePaymentData] = useState({});
  const [isPatientSelected, updateIsPatientSelected] = useState(false);
  const [userCompleteDetails, updateUserCompleteDetails] = useState();
  const [scanCompleteDetails, updateScanCompleteDetails] = useState([]);
  const [selectedScanDetails, updateSelectedScanDetails] = useState([]);
  const [selectedReferringDoctor, updateSelectedReferringDoctor] = useState('');
  const [selectedHospital, updateSelectedHospital] = useState('');
  const [selectedPatientAge, updateSelectedPatientAge] = useState('');
  const [selectedPatientGender, updateSelectedPatientGender] = useState('');
  const [
    shouldShowCreateScanInvoiceLoader,
    showCreateScanInvoiceLoader
  ] = useState(false);
  const [
    scanInvoiceGenerationSuccess,
    updateScanInvoiceGenerationSuccess
  ] = useState(false);
  const [pdfLink, updatePdfLink] = useState(null);

  //New State for discount
  const [discount, setDiscount] = useState('0');
  const [discountType, setDiscountType] = useState(
    discountTypes.find(item => item.value === 'percentage')
  );
  const [discountNotes, setDiscountNotes] = useState('');

  useEffect(() => {
    updateRadiologistName('');
    updateAppointmentDate(moment());
    updateTotalAmount();
    updatePaymentData({});
    updateIsPatientSelected(false);
    updateUserCompleteDetails();
    updateScanCompleteDetails([]);
    updateSelectedScanDetails([]);
    updateSelectedReferringDoctor('');
    updateSelectedHospital('');
    updateSelectedPatientAge('');
    updateSelectedPatientGender('');
    showCreateScanInvoiceLoader(false);
    updateScanInvoiceGenerationSuccess(false);
    updatePdfLink(null);

    if (isOpen) {
      getScanChargesZohoBooks();
    }
    // eslint-disable-next-line
  }, [isOpen, toggle]);

  let searchedTextValue = null;
  let docListArr = null;
  let filteredHospitalDetails = null;
  let filteredHospitalNameArr = null;

  if (isOpen) {
    docListArr = allDoctorDetails.map(doc => ({
      label: doc.name,
      value: doc.id
    }));

    let selectedHospital = [];
    selectedHospital.push(selectedHospitalDetails);

    filteredHospitalDetails = allHospitalDetails.hospitals.filter(hospital => {
      return selectedHospital.some(data => {
        return data.cityName === hospital.cityName && hospital.isCloudnineHosp;
      });
    });

    filteredHospitalNameArr = filteredHospitalDetails.map(hospital => ({
      label: hospital.name,
      value: hospital.name
    }));
  } else {
    docListArr = null;
    filteredHospitalDetails = null;
    filteredHospitalNameArr = null;
  }
  // function to fetch scan charges configured in zoho books based on hospitalid and charge item type.
  const fetchScanCharges = () => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/get_charges`;

    let req_body = {};

    if (searchedTextValue && searchedTextValue.length === 1) {
      req_body = {
        cf_hospitalid: selectedHospitalDetails.id.toString(),
        cf_chargeitemtype: 'Scan',
        name_startswith: searchedTextValue,
        siteCode: selectedHospitalDetails.siteCode
      };
    } else {
      req_body = {
        cf_hospitalid: selectedHospitalDetails.id.toString(),
        cf_chargeitemtype: 'Scan',
        siteCode: selectedHospitalDetails.siteCode
      };
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const getScanChargesZohoBooks = async () => {
    try {
      const response = await fetchScanCharges();
      if (response && response.status && response.status === 1) {
        updateScanCompleteDetails(response.data);
        logAmplitudeEvent(
          'scan_charge_fetched_successfully',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Scan'
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'scan_charge_fetching_failed',
          {
            hospitalId: selectedHospitalDetails.id,
            chargeType: 'Scan'
          },
          true
        );
      }
    } catch (error) {
      logAmplitudeEvent(
        'scan_charge_fetching_failed',
        {
          hospitalId: selectedHospitalDetails.id,
          chargeType: 'Scan'
        },
        true
      );
    }
  };

  // function to create customer’s scan payment invoice based on get_charges API response and other customer payment details.
  const createScanInvoiceData = (
    siteCode,
    lineItemsList,
    radiologistName,
    referringDoctor,
    finalSelectedHospital
  ) => {
    const url = `${CX_SERVER_BASE_URL}/clinix/v2/create_invoice`;
    let loggedInUser = JSON.parse(localStorage.getItem('user'));

    const req_body = {
      mpid: userCompleteDetails.mpid,
      salesperson_name: loggedInUser.name,
      reference_number: paymentData.transactionId
        ? paymentData.transactionId
        : 'N/A',
      txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
      payment_mode: paymentData.paymentMode,
      gst_no: selectedHospitalDetails.gstNo,
      first_name: userCompleteDetails.userName,
      email: userCompleteDetails.userEmail,
      mobile: userCompleteDetails.userMobile,
      product_type: lineItemsList[0].product_type,
      sitecode: siteCode,
      hospital_id: selectedHospitalDetails.id.toString(),
      hospital_name: selectedHospitalDetails.name,
      hospital_address: selectedHospitalDetails.address,
      hospital_email: selectedHospitalDetails.appSupportEmail,
      line_items: lineItemsList,
      procedure: 'Scan',
      ams_doctor_id: referringDoctor.value,
      logo_url: selectedHospitalDetails.logoUrl,
      doctor_name: radiologistName,
      notes: {
        doctor_name: radiologistName,
        referring_doc_name: referringDoctor.label,
        selected_hospital_name: finalSelectedHospital.name,
        selected_hospital_address: finalSelectedHospital.address,
        selected_clinic_address: selectedHospitalDetails.address,
        patient_age: selectedPatientAge,
        patient_gender: selectedPatientGender,
        date_of_appointment: moment(appointmentDate).format('DD-MM-YYYY')
      },
      discount: parseInt(discount),
      discountType: discountType.value
    };

    if (req_body.discount && discountNotes) {
      req_body.discountNotes = discountNotes;
    }

    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'API-Key': `${CX_SERVER_API_KEY}`,
        'Access-Token': `${CX_SERVER_ACCESS_TOKEN}`,
        Authorization: `Bearer ${S2S_CX_SERVER_API_KEY}`
      },
      body: JSON.stringify(req_body)
    };

    return fetch(url, fetchOpts).then(response => response.json());
  };

  const createScanPaymentInvoice = async () => {
    const lineItems = [];
    for (const selectedScan of selectedScanDetails) {
      const arr = selectedScan.value.split('::');
      const id = arr[0];
      const scanDetails = scanCompleteDetails.find(item => item.item_id === id);
      if (scanDetails)
        lineItems.push({
          item_id: scanDetails.item_id,
          header_name: `${scanDetails.item_name} invoice`,
          is_taxable: false,
          tax_exemption_id: scanDetails.tax_exemption_id,
          name: scanDetails.name,
          description: scanDetails.description,
          hsn_or_sac: scanDetails.hsn_or_sac,
          product_type: scanDetails.product_type,
          rate: scanDetails.rate,
          item_total: scanDetails.item_total
        });
    }

    if (!lineItems.length) {
      return;
    }

    showCreateScanInvoiceLoader(true);
    let loggedInUser = JSON.parse(localStorage.getItem('user'));
    const siteCode = selectedHospitalDetails.siteCode;

    let finalSelectedHospital = filteredHospitalDetails.find(
      hospital => hospital.name === selectedHospital.value
    );

    try {
      const response = await createScanInvoiceData(
        siteCode,
        lineItems,
        siteCode === 'BJH' ? selectedReferringDoctor.label : radiologistName,
        selectedReferringDoctor,
        finalSelectedHospital
      );
      if (response && response.status && response.status === 1) {
        showCreateScanInvoiceLoader(false);
        updateScanInvoiceGenerationSuccess(true);
        displaySuccess('Scan invoice generated successfully');
        updatePdfLink(response.data.s3pdflink);

        logAmplitudeEvent(
          'scan_invoice_generated_successfully',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: lineItems[0].product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItems,
            procedure: 'Scan',
            notes: {
              doctor_name: radiologistName,
              date_of_appointment: moment(appointmentDate).format('DD-MM-YYYY')
            },
            s3pdflink: response.s3pdflink
          },
          true
        );
      } else {
        logAmplitudeEvent(
          'scan_invoice_generation_failed',
          {
            mpid: userCompleteDetails.mpid,
            salesperson_name: loggedInUser.name,
            reference_number: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            txnid: paymentData.transactionId
              ? paymentData.transactionId
              : 'N/A',
            payment_mode: paymentData.paymentMode,
            gst_no: selectedHospitalDetails.gstNo,
            first_name: userCompleteDetails.userName,
            email: userCompleteDetails.userEmail,
            mobile: userCompleteDetails.userMobile,
            product_type: lineItems[0].product_type,
            sitecode: siteCode,
            hospital_id: selectedHospitalDetails.id.toString(),
            hospital_name: selectedHospitalDetails.name,
            hospital_address: selectedHospitalDetails.address,
            hospital_email: selectedHospitalDetails.appSupportEmail,
            line_items: lineItems,
            procedure: 'Scan',
            notes: {
              doctor_name: radiologistName,
              date_of_appointment: moment(appointmentDate).format('DD-MM-YYYY')
            },
            response: response
          },
          true
        );
        showCreateScanInvoiceLoader(false);
        const emsg = `Scan invoice generation failed`;
        throw new Error(emsg);
      }
    } catch (error) {
      logAmplitudeEvent(
        'scan_invoice_generation_failed',
        {
          mpid: userCompleteDetails.mpid,
          salesperson_name: loggedInUser.name,
          reference_number: paymentData.transactionId
            ? paymentData.transactionId
            : 'N/A',
          txnid: paymentData.transactionId ? paymentData.transactionId : 'N/A',
          payment_mode: paymentData.paymentMode,
          gst_no: selectedHospitalDetails.gstNo,
          first_name: userCompleteDetails.userName,
          email: userCompleteDetails.userEmail,
          mobile: userCompleteDetails.userMobile,
          product_type: lineItems[0].product_type,
          sitecode: siteCode,
          hospital_id: selectedHospitalDetails.id.toString(),
          hospital_name: selectedHospitalDetails.name,
          hospital_address: selectedHospitalDetails.address,
          hospital_email: selectedHospitalDetails.appSupportEmail,
          line_items: lineItems,
          procedure: 'Scan',
          notes: {
            doctor_name: radiologistName,
            date_of_appointment: moment(appointmentDate).format('DD-MM-YYYY')
          },
          error: error
        },
        true
      );
      showCreateScanInvoiceLoader(false);
      displayError(error);
    }
  };

  const handleUserData = selectedPatient => {
    if (!selectedPatient) {
      updateIsPatientSelected(false);
      return;
    } else {
      const userData = {
        mpid: selectedPatient.mpid,
        userName: selectedPatient.first_name,
        userCountry: selectedPatient.nationality_country,
        userMobile: selectedPatient.mobile,
        userEmail: selectedPatient.email
      };
      updateUserCompleteDetails(userData);
      let patientAge = calculateAge(selectedPatient.date_of_birth);
      updateSelectedPatientAge(patientAge);
      updateSelectedPatientGender(selectedPatient.gender);
      updateIsPatientSelected(true);
    }
  };

  // function to calculate patient age in years and months based on his date of birth.
  const calculateAge = dateString => {
    let today = new Date();
    let DOB = new Date(dateString);
    let totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    let years = today.getFullYear() - DOB.getFullYear();

    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    let months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months === 0) months = 11;
    } else {
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    let age = years + ' years ' + months + ' months ';
    return age;
  };

  const handleMobileNoChange = () => updateIsPatientSelected(true);

  const handleOnClose = () => {
    updateRadiologistName('');
    updateAppointmentDate(moment());
    updateTotalAmount();
    updatePaymentData({});
    showCreateScanInvoiceLoader(false);
    updateScanInvoiceGenerationSuccess(false);
    updatePdfLink(null);
  };

  const updatePaymentDetails = (key, value) => {
    const updatedPayData = { ...paymentData, [key]: value };
    if (key === 'cardNumber' && /^[0-9]+$/.test(value))
      updatedPayData[key] = parseInt(value);
    updatePaymentData(updatedPayData);
  };

  const createScanInvoice = async () => {
    createScanPaymentInvoice();
  };

  const viewScanInvoice = () => {
    const url = pdfLink;
    window.open(url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Scan Booking</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Row style={{ marginTop: '1rem' }}>
                <Col sm={6}>
                  <Select
                    options={scanCompleteDetails.map(item => ({
                      label: item.item_name,
                      value: `${item.item_id}::${item.rate}`
                    }))}
                    isMulti
                    placeholder="Select scan/scans"
                    value={selectedScanDetails}
                    onChange={selected => {
                      if (selected.length) {
                        const amt = selected.reduce((prev, curr) => {
                          const arr = curr.value.split('::');
                          const rate = parseInt(arr[1]);
                          return prev + rate;
                        }, 0);
                        updateTotalAmount(amt);
                      } else {
                        updateTotalAmount(0);
                      }
                      updateSelectedScanDetails(selected);
                    }}
                    isSearchable={true}
                  />
                </Col>
                <Col sm={6}>
                  {!isEmpty(selectedScanDetails) && totalAmount ? (
                    <h6 style={{ fontWeight: 'bold', marginTop: 7 }}>
                      Amount: &#x20B9; {totalAmount}
                    </h6>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <DiscountInput
                discount={discount}
                setDiscount={setDiscount}
                discountType={discountType}
                setDiscountType={setDiscountType}
                discountNotes={discountNotes}
                setDiscountNotes={setDiscountNotes}
                totalAmount={totalAmount}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Patient Details'}</CardHeader>
                <Collapse
                  isOpen={
                    totalAmount && parseFloat(totalAmount) >= 0 ? true : false
                  }
                >
                  <CardBody>
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Search by mobile<div style={{ color: 'red' }}>*</div>:
                      </Col>
                      <Col xs={8}>
                        <SearchPatientByMobile
                          onChange={handleUserData}
                          onMobileNoChange={handleMobileNoChange}
                        />
                      </Col>
                    </Row>
                    {selectedHospitalDetails &&
                    selectedHospitalDetails.siteCode !== 'BJH' ? (
                      <Row className="form-row">
                        <Col
                          xs={4}
                          className="text-right"
                          style={{
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 6
                          }}
                        >
                          Radiologist name<div style={{ color: 'red' }}>*</div>:
                        </Col>
                        <Col xs={8}>
                          <input
                            type="text"
                            placeholder="Enter radiologist name"
                            onChange={e =>
                              updateRadiologistName(e.target.value)
                            }
                            value={radiologistName}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    {isOpen ? (
                      <>
                        <Row className="form-row">
                          <Col
                            xs={4}
                            className="text-right"
                            style={{
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: 6
                            }}
                          >
                            Consulting doctor
                            <div style={{ color: 'red' }}>*</div>:
                          </Col>
                          <Col xs={8}>
                            <Select
                              placeholder="Select consulting doctor"
                              value={selectedReferringDoctor}
                              onChange={doc => {
                                updateSelectedReferringDoctor(doc);
                              }}
                              options={docListArr}
                            />
                          </Col>
                        </Row>
                        <Row className="form-row">
                          <Col
                            xs={4}
                            className="text-right"
                            style={{
                              fontWeight: 'bold',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              marginTop: 6
                            }}
                          >
                            Select Hospital<div style={{ color: 'red' }}>*</div>
                            :
                          </Col>
                          <Col xs={8}>
                            <Select
                              placeholder="Select hospital"
                              value={selectedHospital}
                              onChange={hospital => {
                                updateSelectedHospital(hospital);
                              }}
                              options={filteredHospitalNameArr}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <Row className="form-row">
                      <Col
                        xs={4}
                        className="text-right"
                        style={{
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: 6
                        }}
                      >
                        Date of appointment<div style={{ color: 'red' }}>*</div>
                        :
                      </Col>
                      <Col xs={8}>
                        <DatePicker
                          selected={appointmentDate}
                          onSelect={date => {
                            updateAppointmentDate(date);
                          }}
                          dateFormat="D MMMM YYYY"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader>{'Enter Payment Details'}</CardHeader>
                <Collapse
                  isOpen={
                    isPatientSelected &&
                    appointmentDate &&
                    !isEmpty(selectedReferringDoctor) &&
                    !isEmpty(selectedHospital) &&
                    (selectedHospitalDetails.siteCode === 'BJH' ||
                      radiologistName.length > 2) &&
                    !isEmpty(userCompleteDetails) &&
                    totalAmount &&
                    parseFloat(totalAmount) >= 0
                  }
                >
                  <CardBody>
                    <fieldset
                      disabled={
                        shouldShowCreateScanInvoiceLoader ||
                        scanInvoiceGenerationSuccess
                      }
                    >
                      {shouldShowCreateScanInvoiceLoader && <Veil />}
                      <PaymentForm
                        paymentData={paymentData}
                        onChange={updatePaymentDetails}
                        onSubmit={() => {
                          if (
                            (!paymentData.transactionId ||
                              paymentData.transactionId.length < 5) &&
                            paymentData.paymentMode !== 'Cash'
                          ) {
                            displayError(
                              'Please provide a valid transaction id, minimum 5 digits !!!'
                            );
                            return;
                          } else {
                            showConfirmation(
                              'Create Scan Invoice Confirmation',
                              'Are you sure you want to create scan invoice?',
                              () => createScanInvoice()
                            );
                          }
                        }}
                      />
                    </fieldset>
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        {pdfLink ? (
          <Button color="success" onClick={viewScanInvoice}>
            View Scan Invoice
          </Button>
        ) : null}
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ScanBookingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  selectedHospitalDetails: PropTypes.object,
  allHospitalDetails: PropTypes.object,
  allDoctorDetails: PropTypes.array
};

export default ScanBookingModal;
