import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroupAddon,
  InputGroup,
  UncontrolledTooltip
} from 'reactstrap';
import { FaSearch, FaRegQuestionCircle } from 'react-icons/fa';
import { Query } from 'react-apollo';
import { SEARCH_BOOKINGS, SEARCH_BOOKINGS_POLLING_INTERVAL } from '../queries';
import Loader from '../components/Loader';
import PropTypes from 'prop-types';
import moment from 'moment';
import AppointmentList from '../components/AppointmentList';
import DatePicker from 'react-datepicker';
import DocBanner from '../components/DocBanner';
import { BookingStatusTabs } from '../components/BookingStatusTabs';
import BookingModal from '../components/BookingModal';
import BookingInfoModal from '../components/BookingInfoModal';
import CustomerRxModal from '../components/CustomerRxModal';
import ApptFilter, {
  APPT_FILTER,
  sortBooking
} from '../components/SortFilterAppt';
import MyHospitalSelector from '../components/MyHospitalSelector';
import { withUserData } from '../components/withUserData';
import FunnyNoAppointmentMessage from '../components/FunnyNoAppointmentMessage';
import UpdateMpidModal from '../components/UpdateMpidModal';
import CustomerDetailsModal from '../components/CustomerDetailsModal';
import LabsAndScansPrescribedModal from '../components/LabsAndScansPrescribedModal';

const DoccHomeInner = ({ doctor, selectedHospitals }) => {
  // --- STATE MGMT ---
  const [filter, setFilter] = useState({
    // searchString: '',
    // showBookingHistory: false,
    appointmentState: undefined,
    date: moment(),
    objectIds: [doctor.id]
  });

  const [showCustomerRxModal, setShowCustomerRxModal] = useState(false);
  const [userMobileForRxModal, setUserMobileForRxModal] = useState(null);
  const [userMPIDForRxModal, setUserMPIDForRxModal] = useState(null);
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState(
    false
  );
  const [userMPIDForCustomerModal, setUserMPIDForCustomerModal] = useState(
    null
  );
  const [
    showLabsAndScansPrescribedModal,
    setShowLabsAndScansPrescribedModal
  ] = useState(false);
  const [
    userMPIDForLabsAndScansModal,
    setUserMPIDForLabsAndScansModal
  ] = useState(null);
  const [
    sitecodeForLabsAndScansModal,
    setSitecodeForLabsAndScansModal
  ] = useState(null);
  const [
    appointmentIdForLabsAndScansModal,
    setAppointmentIdForLabsAndScansModal
  ] = useState(null);
  const [
    doctorIdForLabsAndScansModal,
    setDoctorIdForLabsAndScansModal
  ] = useState(null);

  const [
    bookingIdForUpdateMpidModal,
    setBookingIdForUpdateMpidModal
  ] = useState(null);
  const [showUpdateMpidModal, setShowUpdateMpidModal] = useState(false);
  const [uiSettings, setUiSettings] = useState({
    isBookingModalOpen: false,
    apptFilter: APPT_FILTER.WaitTime
  });
  const [mpidForInfoModal, setMpidForInfoModal] = useState(null);

  const setFilterAttr = (key, value) => setFilter({ ...filter, [key]: value });
  const setUiSetting = (key, value) =>
    setUiSettings({
      ...uiSettings,
      [key]: value
    });

  const toggleBookingModal = () =>
    setUiSetting('isBookingModalOpen', !uiSettings.isBookingModalOpen);
  const showBookingInfoModal = (bookingId, mpidForInfoModal) => {
    setUiSetting('showInfoBookingId', bookingId);
    setMpidForInfoModal(mpidForInfoModal);
  };
  const hideBookingInfoModal = () =>
    setUiSetting('showInfoBookingId', undefined);
  const setApptFilter = filter => setUiSetting('apptFilter', filter);
  const setUserDetailsForRxModal = (
    userMobileForRxModal,
    userMPIDForRxModal
  ) => {
    setShowCustomerRxModal(true);
    setUserMobileForRxModal(userMobileForRxModal);
    setUserMPIDForRxModal(userMPIDForRxModal);
  };
  const toggleCustomerRxModal = () =>
    setShowCustomerRxModal(!showCustomerRxModal);
  const setDetailsForCustomerModal = userMPIDForCustomerModal => {
    setShowCustomerDetailsModal(true);
    setUserMPIDForCustomerModal(userMPIDForCustomerModal);
  };
  const toggleCustomerDetailsModal = () =>
    setShowCustomerDetailsModal(!showCustomerDetailsModal);
  const setDetailsForLabsAndScansPrescribedModal = (
    userMPIDForLabsAndScansModal,
    sitecodeForLabsAndScansModal,
    appointmentIdForLabsAndScansModal,
    doctorIdForLabsAndScansModal
  ) => {
    setShowLabsAndScansPrescribedModal(true);
    setUserMPIDForLabsAndScansModal(userMPIDForLabsAndScansModal);
    setSitecodeForLabsAndScansModal(sitecodeForLabsAndScansModal);
    setAppointmentIdForLabsAndScansModal(appointmentIdForLabsAndScansModal);
    setDoctorIdForLabsAndScansModal(doctorIdForLabsAndScansModal);
  };
  const toggleLabsAndScansPrescribedModal = () =>
    setShowLabsAndScansPrescribedModal(!showLabsAndScansPrescribedModal);
  const setDataForUpdateMpidModal = bookingId => {
    setBookingIdForUpdateMpidModal(bookingId);
    setShowUpdateMpidModal(true);
  };
  const toggleUpdateMpidModal = () => {
    setShowUpdateMpidModal(!showUpdateMpidModal);
  };
  // --- END OF STATE MGMT ---

  const { showBookingHistory, date, appointmentState } = filter;
  const {
    searchString,
    showInfoBookingId,
    isBookingModalOpen,
    apptFilter
  } = uiSettings;
  const { id, entityId } = doctor;
  const defaultHospitalId =
    selectedHospitals && selectedHospitals.length > 0 && selectedHospitals[0];

  const searchBookingFilter = { ...filter, hospitals: selectedHospitals };
  return (
    <Container style={{ marginTop: '1rem', marginBottom: '3rem' }} fluid>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ padding: 0, margin: '0 1rem 0 0' }}>Date</h4>
          <div>
            <DatePicker
              selected={date}
              onSelect={d => setFilterAttr('date', d)}
              dateFormat="D MMM YYYY"
            />
          </div>
        </Col>
        <Col>
          <MyHospitalSelector setDefault />
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ margin: '0 1rem' }}>
            <InputGroup>
              <Input
                placeholder="Search"
                onKeyUp={e => setUiSetting('searchString', e.target.value)}
                maxLength={10}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="info"
                  onClick={() => {} /*this.kickoffBackendSearch*/}
                >
                  <FaSearch />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>

          <FaRegQuestionCircle id="help-search-box" />
          <UncontrolledTooltip placement="right" target="help-search-box">
            TIP: Press enter or click the magnifying glass to search booking
            history of a mobile number
          </UncontrolledTooltip>
        </Col>
        <Col md={3}>
          <div className="text-right">
            <Button
              color="primary"
              onClick={toggleBookingModal}
              disabled={!defaultHospitalId}
            >
              Book Appointment
            </Button>
            {!defaultHospitalId && (
              <span style={{ marginLeft: '0.375rem' }}>
                <FaRegQuestionCircle id="booking-disabled" />
                <UncontrolledTooltip placement="left" target="booking-disabled">
                  Your slots are not configured. Use &quot;Report Bug&quot; link
                  above to raise your concerns.
                </UncontrolledTooltip>
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col>
          <BookingStatusTabs
            variables={{ input: searchBookingFilter }}
            onSelect={state => setFilterAttr('appointmentState', state)}
            selected={appointmentState}
          />
          <Query
            query={SEARCH_BOOKINGS}
            variables={{
              input: searchBookingFilter
            }}
            fetchPolicy="network-only"
            pollInterval={SEARCH_BOOKINGS_POLLING_INTERVAL}
            notifyOnNetworkStatusChange
          >
            {({
              data: { getBookings } = {},
              networkStatus,
              stopPolling,
              startPolling,
              loading: loadingBookings
            }) => {
              const statusBar = (
                <Row>
                  <Col>{networkStatus < 7 ? <Loader size={5} /> : null}</Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <ApptFilter onSelect={setApptFilter} filter={apptFilter} />
                  </Col>
                </Row>
              );

              const isFilterByPhoneHasData =
                searchString && searchString.trim().length > 0;
              const hasServerReturnedAnyAppts =
                getBookings && getBookings.length > 0;

              let dataDisplay;
              if (!isFilterByPhoneHasData && !hasServerReturnedAnyAppts) {
                dataDisplay = (
                  <FunnyNoAppointmentMessage
                    appointmentState={appointmentState}
                  />
                );
              } else {
                let filteredBooking = getBookings;
                if (isFilterByPhoneHasData) {
                  // if name or phone matches
                  filteredBooking = getBookings.filter(
                    b =>
                      b.userMobile.indexOf(searchString) > -1 ||
                      b.userName
                        .toLowerCase()
                        .indexOf(searchString.toLowerCase()) > -1
                  );
                }

                const noBookingFound =
                  !filteredBooking || filteredBooking.length < 1;

                filteredBooking = sortBooking(
                  filteredBooking,
                  apptFilter,
                  date
                );

                if (!showBookingHistory && noBookingFound) {
                  // dataDisplay = (
                  //   <LoadBookingFromQW
                  //     phone={searchString}
                  //     date={date}
                  //     displayDetail={showBookingInfoModal}
                  //   />
                  // );
                } else {
                  const noFilteredRes =
                    !filteredBooking || filteredBooking.length < 1;
                  if (showBookingHistory && noFilteredRes && !loadingBookings) {
                    dataDisplay = (
                      <h6
                        className="text-muted"
                        style={{ textAlign: 'center' }}
                      >
                        No records found. May be we are looking for the
                        non-existent?
                      </h6>
                    );
                  } else {
                    dataDisplay = (
                      <AppointmentList
                        apptList={filteredBooking}
                        searchPhone={searchString}
                        displayDetail={showBookingInfoModal}
                        stopPolling={stopPolling}
                        startPolling={startPolling}
                        onBook={toggleBookingModal}
                        customerRxDetails={setUserDetailsForRxModal}
                        updateMpid={setDataForUpdateMpidModal}
                        customerPersonalDetails={setDetailsForCustomerModal}
                        labsAndScansPrescribed={
                          setDetailsForLabsAndScansPrescribedModal
                        }
                      />
                    );
                  }
                }
              }
              return (
                <>
                  {statusBar}
                  {showBookingHistory && (
                    <h4 className="text-muted" style={{ textAlign: 'center' }}>
                      Showing booking history of {searchString}
                    </h4>
                  )}
                  {dataDisplay}
                </>
              );
            }}
          </Query>
        </Col>
        <Col md={3}>
          <DocBanner doctor={doctor} />
        </Col>
      </Row>
      <BookingModal
        doctorId={id}
        entityId={entityId}
        hospitalId={defaultHospitalId}
        isOpen={isBookingModalOpen}
        toggle={toggleBookingModal}
      />
      <UpdateMpidModal
        isOpen={showUpdateMpidModal}
        toggle={toggleUpdateMpidModal}
        bookingId={bookingIdForUpdateMpidModal}
      />
      {showInfoBookingId && (
        <BookingInfoModal
          isOpen={!!showInfoBookingId}
          toggle={hideBookingInfoModal}
          bookingId={showInfoBookingId}
          mpid={mpidForInfoModal}
        />
      )}
      {showCustomerRxModal && (
        <CustomerRxModal
          isOpen={showCustomerRxModal}
          toggle={toggleCustomerRxModal}
          userMobile={userMobileForRxModal}
          userMPID={userMPIDForRxModal}
        />
      )}
      {showCustomerDetailsModal && (
        <CustomerDetailsModal
          isOpen={showCustomerDetailsModal}
          toggle={toggleCustomerDetailsModal}
          userMPID={userMPIDForCustomerModal}
        />
      )}
      {showLabsAndScansPrescribedModal && (
        <LabsAndScansPrescribedModal
          isOpen={showLabsAndScansPrescribedModal}
          toggle={toggleLabsAndScansPrescribedModal}
          userMPID={userMPIDForLabsAndScansModal}
          sitecode={sitecodeForLabsAndScansModal}
          appointmentId={appointmentIdForLabsAndScansModal}
          doctorId={doctorIdForLabsAndScansModal}
        />
      )}
    </Container>
  );
};

DoccHomeInner.propTypes = {
  doctor: PropTypes.object.isRequired,
  selectedHospitals: PropTypes.arrayOf(PropTypes.number),
  hospitals: PropTypes.arrayOf(PropTypes.number),
  allHospitals: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    )
  )
};

export default withUserData(DoccHomeInner);
