import { gql } from 'apollo-boost';
import {
  API_ROOT,
  USER_SERVICE_URL,
  S2S_USER_SERVICES_API_KEY
} from '../constants';
import { getLoggedInUser } from '../permissions';
import { graphqlApiRequest } from '../utilities/services';

/**
 *

      daySchedule {
        start
        end
        hospitalName
        isAdhoc
      }
      onVacation
      leaves {
        start
        end
        reason
      }
 *
 */
export const SEARCH_DOCTORS = gql`
  query SEARCH_DOCTORS($searchInput: DoctorSearchInput) {
    getDoctors(input: $searchInput) {
      id
      name
      gender
      primarySpecialty
      entityId
      isConfigured
      globalRank
      photos {
        dp300 {
          url
        }
      }
      entity {
        isInRoom
      }
    }
  }
`;
export const SEARCH_DOCTORS_EXTENDED = gql`
  query SEARCH_DOCTORS_EXTENDED($searchInput: DoctorExtendedSearchInput) {
    docs: getDoctorsExtended(input: $searchInput) {
      id
      name
      gender
      primarySpecialty
      entityId
      isConfigured
      globalRank
      photos {
        dp300 {
          url
        }
      }
      entity {
        isInRoom
        roomNo
      }
      daySchedule {
        start
        end
        hospitalName
        isAdhoc
        isAltered
        alteredStart
        alteredEnd
        isBlockAll
        procedureNames
      }
      onVacation
      leaves {
        start
        end
        reason
      }
    }
  }
`;

export const SEARCH_DOCTORS_DD = gql`
  query SEARCH_DOCTORS_DD($searchInput: DoctorSearchInput) {
    getDoctors(input: $searchInput) {
      id
      name
      gender
      profileStatus
      photos {
        dp300 {
          url
        }
      }
    }
  }
`;

export const SEARCH_QUICKBOOKING_DOCTORS = gql`
  query SEARCH_QUICKBOOKING_DOCTORS($searchInput: DoctorSearchInput) {
    getDoctors(input: $searchInput) {
      id
      name
      entityId
      department
      getHospitals {
        id
        name
      }
    }
  }
`;

export const SEARCH_QUICKBOOKING_TIMING_DOCTORS = gql`
  query SEARCH_QUICKBOOKING_TIMING_DOCTORS($searchInput: QuickBookInput) {
    getDoctorsQuickBook(input: $searchInput) {
      id
      name
      entityId
      department
      getHospitals {
        id
        name
      }
    }
  }
`;

export const SEARCH_BOOKINGS_POLLING_INTERVAL = 20000;
export const SEARCH_BOOKINGS = gql`
  query SEARCH_BOOKINGS($input: BookingSearchInput) {
    getBookings(input: $input) {
      objectName
      id
      objectId
      entityId
      hospitalId
      mpid
      slotId
      slotType
      slotTime
      userQuestions
      userName
      userEmail
      userMobile
      userGender
      userDoB
      appointmentState
      created_at
      checkinTS
      noShowTS
      startConsultationTS
      endConsultationTS
      checkoutTS
      cancelledTS
      procedureName
      isWalkIn
      isTatkal
      isReserved
      rescheduledFrom
      ltInvoicePdfUrl
      ltReceiptId
    }
  }
`;

export const GET_LATEST_DOC_SLOT = gql`
  query GET_LATEST_DOC_SLOT($input: SlotSearchInput) {
    latestSlot: getLatestDocSlot(input: $input) {
      id
      startTime
      type
      hospitalId
      cityId
      objectName
      specializations
    }
  }
`;

export const GET_BOOKINGS_STATUS = gql`
  query GET_BOOKINGS_STATUS($input: BookingSearchInput) {
    bookingsStatus: getBookingsStatus(input: $input) {
      status
      count
    }
  }
`;

export const SLOTS_QUERY = gql`
  query SLOTS_QUERY(
    $entityId: Int!
    $absoluteStartDate: DateTime
    $relativeStartDay: Int
    $numDays: Int
    $procedureName: String!
    $hospitalId: Int!
  ) {
    getEntitySlots(
      entityId: $entityId
      absoluteStartDate: $absoluteStartDate
      relativeStartDay: $relativeStartDay
      numDays: $numDays
      procedureName: $procedureName
      hospitalId: $hospitalId
    ) {
      date
      slots {
        startTime
        id
        leverageRatio
        bookedSlotsCount
        isAvailable
        unavailabilityReason
        companionSlotType
        isReserved
      }
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CREATE_BOOKING($createBooking: BookingInput) {
    createBooking(input: $createBooking) {
      id
      objectName
      procedureName
      slotTime
      userName
    }
  }
`;

export const GET_APPROVING_AUTHORITY = gql`
  query GET_APPROVING_AUTHORITY($hospitalId: Int) {
    getVacationAuthorizers(hospitalId: $hospitalId) {
      name
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query GET_LOGGED_IN_USER {
    me {
      email
      id
      name
      hospitals
      role
      canApproveVacations
      canApproveProtocols
      canApproveDoctors
    }

    hospitals: getHospitals(showAll: true) {
      id
      name
    }

    roles: getRoles {
      value
      label
    }
  }
`;

export const LOG_IN = gql`
  mutation LOG_IN($email: String, $password: String) {
    adminLogin(email: $email, password: $password) {
      id
      email
      name
      token
      hospitals
      role
      canApproveDoctors
      entity {
        entityId
        objectId
        objectType
      }
    }
  }
`;

export const UPLOAD_SLOTS_EXCEL = gql`
  mutation UPLOAD_SLOTS_EXCEL($file: Upload!) {
    uploadSlotsExcel(file: $file) {
      success
      failure
      errors
    }
  }
`;

export const UPDATE_BOOKING_STATE = gql`
  mutation UPDATE_BOOKING_STATE(
    $bookingId: String!
    $state: APPOINTMENT_STATE!
    $reason: String
  ) {
    booking: setAppointmentState(
      bookingId: $bookingId
      state: $state
      reason: $reason
    ) {
      id
      appointmentState
      created_at
      checkinTS
      noShowTS
      startConsultationTS
      endConsultationTS
      checkoutTS
      cancelledTS
      cancelationReason
      objectName
      procedureName
      slotTime
      userName
    }
  }
`;

export const UNDO_LAST_BOOKING_STATE = gql`
  mutation UNDO_LAST_BOOKING_STATE($bookingId: String) {
    booking: undoAppointmentLastState(bookingId: $bookingId) {
      id
      appointmentState
      created_at
      checkinTS
      noShowTS
      startConsultationTS
      endConsultationTS
      checkoutTS
      cancelledTS
    }
  }
`;

export const UPLOAD_QWBOOKINGS_JSON = gql`
  mutation UPLOAD_QWBOOKINGS_JSON($file: Upload!) {
    uploadQwBookingJson(file: $file) {
      totalRecords
      success
      failure
      cancelledBookings
      errors {
        message
        data
      }
    }
  }
`;

export const BULK_UPLOAD_DOC_PROFILE = gql`
  mutation BULK_UPLOAD_DOC_PROFILE($file: Upload!) {
    bulkUpdateDocProfilesExcel(file: $file) {
      totalRecords
      success
      failure
      errors
    }
  }
`;

export const BULK_UPLOAD_LT_PROFILE = gql`
  mutation BULK_UPLOAD_LT_PROFILE($file: Upload!) {
    offlineSyncLtDocs(file: $file)
  }
`;

export const BULK_UPLOAD_USERS = gql`
  mutation BULK_UPLOAD_USERS($file: Upload!) {
    users: bulkUploadUsers(file: $file) {
      name
      email
      role
      hospitals
      result
    }
  }
`;

export const GET_BOOKINGS_AFFECTED_BY_VACATION = gql`
  query GET_BOOKINGS_AFFECTED_BY_VACATION($vacationId: Int) {
    affectedBookings: getBookingsAffectedByVacation(vacationId: $vacationId) {
      userName
      userMobile
      id
      slotTime
    }
  }
`;

export const GET_PROCEDURES = gql`
  query GET_PROCEDURES($entityId: Int!, $hospitalId: Int!) {
    getProcedures(entityId: $entityId, hospitalId: $hospitalId)
  }
`;

export const EVENTS_QUERY = gql`
  query EVENTS_QUERY($input: EventFilter) {
    events(input: $input) {
      events {
        dateYYYYMMDD
        timestampMillis
        eventType
        eventSeverity
        name
        details
        origin
        id
      }
      nextKey {
        dateYYYYMMDD
        id
      }
    }
  }
`;

export const GET_SLOT_PROTOS = gql`
  query GET_SLOT_PROTOS($entityId: Int!) {
    getSlotPrototypes(entityId: $entityId) {
      hospitalName
      slotPrototypes {
        id
        startTime
        endTime
        daysConf {
          sun
          mon
          tue
          wed
          thu
          fri
          sat
        }
        isReserved
        isWalkInEnabled
        isTatkalEnabled
        tatkalWindow
        regularToTatkalRatio
        maxBookAheadDays
        leverageRatio
        expiresOn
        shouldRecreateSlots
        procedures {
          name
          slotDuration
        }
      }
    }
  }
`;

export const GET_ENTITY_SCH = gql`
  query GET_ENTITY_SCH($entityId: Int!, $on: Date!, $hospitalId: Int) {
    schedule: getSchedule(
      entityId: $entityId
      on: $on
      hospitalId: $hospitalId
    ) {
      YYYYMMDD
      timeRanges {
        start
        end
        slotPrototypeId
        isAltered
        alteredStart
        alteredEnd
        isAdhoc
        hospitalName
        isTatkalEnabled
        isBlockAll
        userName
        updatedAt
        actionLog
        hasTatkalSlots
      }
    }
  }
`;

export const GET_DOCTOR_CHARGES_BY_SITECODE = gql`
  query GET_DOCTOR_CHARGES_BY_SITECODE($sitecode: String!) {
    getDoctorChargesBySiteCode(sitecode: $sitecode) {
      service
      item_id
      charges
    }
  }
`;

export const DROPDOWN_QUERIES = gql`
  query DROPDOWN_QUERIES {
    hospitals: getHospitals {
      id
      name
    }
    departments: getDepartments {
      id
      name
    }
    doctors: getDoctors {
      id
      name
      departmentId
    }
    procedures: getProceduresLookup {
      id
      name
    }
    languages: lookup(type: lang) {
      id: label
      name: value
    }
  }
`;

export const ALTER_SCHEDULE = gql`
  mutation ALTER_SCHEDULE($newSchedule: AlteredScheduleInput) {
    alterSchedule(alteration: $newSchedule) {
      affectedSlots {
        slotsAdded
        slotsBlocked
      }
      alteredSchedule {
        id
        slotPrototypeId
        date
        startTime
        endTime
        isCreateNewSlots
        isNotifyAffectedBookings
        reason
        isTatkalEnabled
        isBlockAll
      }
    }
  }
`;

export const SEARCH_ADMIN_USERS = gql`
  query SEARCH_ADMIN_USERS($input: SearchAdminUsersInput) {
    adminUsers: searchAdminUsers(input: $input) {
      total
      pageSize
      currentPage
      users {
        id
        name
        email
        role
        hospitals
        hasApiAccess
        apiSecret
        canApproveVacations
        canApproveProtocols
        canApproveDoctors
        isConcierge
        mobile
      }
    }
    hospitals: getHospitals(showAll: true) {
      id
      name
    }
  }
`;

export const GET_ALL_HOSPITALS = gql`
  query GET_ALL_HOSPITALS($showAll: Boolean) {
    hospitals: getHospitals(showAll: $showAll) {
      id
      name
      siteCode
      isCloudnineHosp
      gstNo
      address
      appSupportEmail
      logoUrl
      cityName
      chargeMaster
      zohoOrgId
      settings {
        chargeMaster {
          invoiceGeneratorForConsultation
        }
      }
    }
  }
`;

export const GET_ALL_DEPARTMENTS = gql`
  query GET_ALL_DEPARTMENTS {
    departments: getDepartments {
      id
      name
    }
  }
`;

export const GET_ALL_SPECIALIZATIONS = gql`
  query GET_ALL_SPECIALIZATIONS {
    specializations: getSpecializations {
      id
      name
      departmentId
    }
  }
`;

export const RESET_API_SECRET = gql`
  mutation RESET_API_SECRET($userId: String!) {
    newApiSecret: regenerateApiSecret(userId: $userId)
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation CREATE_ADMIN_USER($input: AdminUserInput) {
    adminSignUp(input: $input) {
      id
      name
      email
      role
      hospitals
      hasApiAccess
      apiSecret
      canApproveVacations
      canApproveProtocols
      canApproveDoctors
      isConcierge
      mobile
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation UPDATE_ADMIN_USER($input: UpdateAdminUserInput!) {
    updateAdminUser(input: $input) {
      id
      name
      email
      role
      hospitals
      hasApiAccess
      apiSecret
      canApproveVacations
      canApproveProtocols
      canApproveDoctors
      isConcierge
      mobile
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation RESET_USER_PASSWORD($userId: String!) {
    resetPassword(userId: $userId)
  }
`;

export const ENTITY_LOGIN_CREATION = gql`
  mutation ENTITY_LOGIN_CREATION(
    $entityId: Int!
    $email: String!
    $name: String!
    $role: String!
  ) {
    checkAndSignupEntity(
      entityId: $entityId
      email: $email
      name: $name
      role: $role
    ) {
      isCreated
      message
    }
  }
`;

export const UPLOAD_DOC_PIC = gql`
  mutation UPLOAD_DOC_PIC($imageFile: Upload!, $doctorId: String!) {
    pic: uploadDoctorPic(imageFile: $imageFile, doctorId: $doctorId) {
      dp300 {
        url
      }
    }
  }
`;

export const SYNC_WITH_LT = gql`
  mutation SYNC_WITH_LT($date: Date!) {
    syncRes: syncLifetrenzDocs(date: $date) {
      newDocs
      updatedDocs
      date
    }
  }
`;

export const GET_SLOT_PROTOTYPE = gql`
  query GET_SLOT_PROTOTYPE($slotPrototypeId: Int!) {
    slotPrototype: getSlotPrototype(slotPrototypeId: $slotPrototypeId) {
      id
      hospitalId
      startTime
      endTime
      daysConf {
        sun
        mon
        tue
        wed
        thu
        fri
        sat
      }
      isReserved
      isWalkInEnabled
      isTatkalEnabled
      isEnabled
      entityId
      tatkalWindow
      regularToTatkalRatio
      maxBookAheadDays
      leverageRatio
      expiresOn
      procedures {
        name
        maxBookings
        slotDuration
        qwProcedureId
        qwPerformProcedureId
      }
      slotPattern
      companionLeverageRatio
    }
  }
`;

export const CREATE_SLOT_PROTOTYPE = gql`
  mutation CREATE_SLOT_PROTOTYPE($slotProto: SlotProtoInput!) {
    createSlotPrototype(slotProto: $slotProto) {
      id
      hospitalId
      startTime
      endTime
      daysConf {
        sun
        mon
        tue
        wed
        thu
        fri
        sat
      }
      isReserved
      isWalkInEnabled
      isTatkalEnabled
      isEnabled
      entityId
      tatkalWindow
      regularToTatkalRatio
      maxBookAheadDays
      leverageRatio
      procedures {
        name
        maxBookings
        slotDuration
      }
      slotPattern
    }
  }
`;

export const PREVIEW_SLOTS_GENERATION = gql`
  query PREVIEW_SLOTS_GENERATION($slotProto: SlotProtoInput!) {
    previewGeneratedSlots(slotProto: $slotProto) {
      slots {
        compoundSlotId
        startTime
        slotDuration
        isTatkal
        isWalkIn
      }
      slotsUtility {
        slotDuration
        minRequired
        created
      }
    }
  }
`;

export const UPDATE_SLOT_PROTOTYPE = gql`
  mutation UPDATE_SLOT_PROTOTYPE($slotProto: SlotProtoUpdateInput!) {
    updateSlotPrototype(slotProto: $slotProto) {
      id
      hospitalId
      startTime
      endTime
      daysConf {
        sun
        mon
        tue
        wed
        thu
        fri
        sat
      }
      isReserved
      isWalkInEnabled
      isTatkalEnabled
      isEnabled
      entityId
      tatkalWindow
      regularToTatkalRatio
      maxBookAheadDays
      leverageRatio
      procedures {
        name
        maxBookings
        slotDuration
      }
      slotPattern
    }
  }
`;

export const WEEKLY_SCHEDULE = gql`
  query WEEKLY_SCHEDULE($entityId: Int!) {
    weeklySchedule: getEntitySchedule(entityId: $entityId) {
      hospital
      timing {
        day
        hours {
          startTime
          endTime
        }
      }
    }
  }
`;

export const RESCHEDULE_BOOKING = gql`
  mutation RESCHEDULE_BOOKING($bookingId: String!, $slotId: String!) {
    rescheduleBooking(bookingId: $bookingId, slotId: $slotId) {
      id
      rescheduledFrom
      slotTime
    }
  }
`;

export const HAS_ENTITY_LOGIN = gql`
  query HAS_ENTITY_LOGIN($entityId: Int!) {
    hasEntityLogin(entityId: $entityId)
  }
`;

// export const FIND_AND_UPDATE_FROM_QW = gql`
//   query FIND_AND_UPDATE_FROM_QW($mobile: String!, $date: Date!) {
//     bookings: findAndSyncBookingFromQw(mobile: $mobile, date: $date) {
//       objectName
//       id
//       mpid
//       slotId
//       slotType
//       slotTime
//       userQuestions
//       userName
//       userMobile
//       userGender
//       userDoB
//       appointmentState
//       created_at
//       checkinTS
//       noShowTS
//       startConsultationTS
//       endConsultationTS
//       checkoutTS
//       cancelledTS
//       procedureName
//     }
//   }
// `;

export const GET_ROLES = gql`
  query GET_ROLES {
    roles: getRoles {
      value
      label
    }
  }
`;

export const LOOKUP = gql`
  query LOOKUP($type: LOOKUP_TYPE) {
    lookups: lookup(type: $type) {
      label
      value
    }
  }
`;

export const SLOT_PROTO_DELETION_AFFECTED_BOOKINGS = gql`
  query SLOT_PROTO_DELETION_AFFECTED_BOOKINGS($slotPrototypeId: Int!) {
    bookings: futureBookingsBySlotProto(slotPrototypeId: $slotPrototypeId) {
      userName
      userMobile
      userEmail
      slotTime
    }
  }
`;

export const DELETE_SLOT_PROTO = gql`
  mutation DELETE_SLOT_PROTO(
    $slotPrototypeId: Int!
    $cancelBookings: Boolean!
  ) {
    isDeleted: deleteSlotPrototype(
      slotPrototypeId: $slotPrototypeId
      cancelBookings: $cancelBookings
    )
  }
`;

export const LOCAL_USER_HOSPITALS = gql`
  query LOCAL_USER_HOSPITALS {
    hospitals: userHospitals @client
    allHospitals @client {
      id
      name
    }
    selectedHospitals @client
  }
`;

export const BOOKING_EVENT_HISTORY = gql`
  query BOOKING_EVENT_HISTORY($bookingId: String!) {
    eventHistory(bookingId: $bookingId) {
      bookingId
      event
      description
      eventCreator
      adminUserId
      created_at
    }
  }
`;

export const BOOKING_MESSAGE_HISTORY = gql`
  query BOOKING_MESSAGE_HISTORY($bookingId: String!) {
    messageHistory(bookingId: $bookingId) {
      id
      type
      bookingId
      sendAt
      sentAt
      isSent
      email
      mobile
      emailSubject
      smsMessage
      emailPlainText
      parentMessageId
    }
  }
`;

export const DELETE_ADMIN_USER = gql`
  mutation DELETE_ADMIN_USER($userId: String!) {
    deleteAdminUser(userId: $userId)
  }
`;

export const REPORT_ERROR = gql`
  mutation REPORT_ERROR($input: ErrorReportInput!) {
    reportError(input: $input)
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UPDATE_BOOKING($input: BookingUpdateInput!) {
    booking: updateBooking(input: $input) {
      id
      userName
      userQuestions
      rating5
      mpid
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($newPassword: String!) {
    changePassword(newPassword: $newPassword)
  }
`;

export const UPDATE_MARKETING_INFO = gql`
  mutation UPDATE_MARKETING_INFO(
    $doctorId: String!
    $marketingInfo: MarketingInfoInput!
  ) {
    updateMarketingInfo(doctorId: $doctorId, marketingInfo: $marketingInfo) {
      name
      gender
      awards
      membership
      languages
      services
      seoTitle
      seoDescription
      seoSlug
    }
  }
`;

export const GET_ALL_HOSPITALS_FULL = gql`
  query GET_ALL_HOSPITALS_FULL {
    hospitals: getHospitals(showAll: true) {
      id
      name
      address
      lat
      lng
      cityId
      type
      departments
      location
      pinCode
      cityName
      zone
      services
      beds
      siteCode
      fertilitySiteCode
      seoSlug
      isCloudnineHosp
      logoUrl
      callCentreNo
      appSupportEmail
      gstNo
      chargeMaster
      zohoOrgId
      addressLine1
      addressLine2
      addressLine3
      isAppEnabled
      isPharmaEnabled
      isLabEnabled
      isVaccinationEnabled
      hospitalTypeId
      hospitalTypeName
      settings {
        chargeMaster {
          invoiceGeneratorForConsultation
        }
      }
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query GET_ALL_CITIES {
    cities: getCities {
      name
      id
      address
      logoUrl
    }
  }
`;

export const GET_ALL_SITECODES = gql`
  query GET_ALL_SITECODES {
    siteCodes: getSiteCodes {
      id
      sitecode
      description
    }
  }
`;

export const CREATE_CITY = gql`
  mutation CREATE_CITY($city: CityInput!) {
    city: createCity(city: $city) {
      id
      name
      address
    }
  }
`;

export const UPDATE_CITY = gql`
  mutation UPDATE_CITY($cityId: Int!, $city: CityInput!) {
    city: updateCity(cityId: $cityId, city: $city) {
      id
      name
      address
    }
  }
`;

export const CREATE_SITECODE = gql`
  mutation CREATE_SITECODE($sitecode: SiteCodeInput!) {
    sitecode: createSiteCode(sitecode: $sitecode) {
      id
      sitecode
      description
    }
  }
`;

export const UPDATE_SITECODE = gql`
  mutation UPDATE_SITECODE($sitecodeId: Int!, $sitecode: SiteCodeInput!) {
    sitecode: updateSiteCode(sitecodeId: $sitecodeId, sitecode: $sitecode) {
      id
      sitecode
      description
    }
  }
`;

export const GET_ALL_CHARGE_MASTERS = gql`
  query GET_ALL_CHARGE_MASTERS {
    chargeMasters: getChargeMasters {
      name
      value
    }
  }
`;

export const GET_ALL_ZOHO_ORGANIZATIONS = gql`
  query GET_ALL_ZOHO_ORGANIZATIONS {
    zohoOrganizations: getZohoOrganisations {
      name
      id
    }
  }
`;

export const UPDATE_HOSPITAL_FULL = gql`
  mutation UPDATE_HOSPITAL_FULL(
    $hospitalId: Int!
    $hospital: UpdateHospitalFull!
  ) {
    hospital: updateHospital(hospitalId: $hospitalId, hospital: $hospital) {
      id
      name
      address
      lat
      lng
      cityId
      type
      departments
      location
      pinCode
      cityName
      zone
      services
      beds
      siteCode
      fertilitySiteCode
      seoSlug
      isCloudnineHosp
      logoUrl
      callCentreNo
      appSupportEmail
      gstNo
      chargeMaster
      zohoOrgId
      addressLine1
      addressLine2
      addressLine3
      isAppEnabled
      isPharmaEnabled
      isLabEnabled
      isVaccinationEnabled
      hospitalTypeId
      settings {
        chargeMaster {
          invoiceGeneratorForConsultation
        }
      }
    }
  }
`;

export const CREATE_HOSPITAL = gql`
  mutation CREATE_HOSPITAL($hospital: UpdateHospitalFull!) {
    hospital: createHospital(hospital: $hospital) {
      id
      name
      address
      lat
      lng
      cityId
      type
      departments
      location
      pinCode
      cityName
      zone
      services
      beds
      siteCode
      fertilitySiteCode
      seoSlug
      isCloudnineHosp
      logoUrl
      callCentreNo
      appSupportEmail
      gstNo
      chargeMaster
      zohoOrgId
      addressLine1
      addressLine2
      addressLine3
      hospitalTypeId
      settings {
        chargeMaster {
          invoiceGeneratorForConsultation
        }
      }
    }
  }
`;

export const ME = gql`
  query ME {
    me {
      id
      entity {
        objectId
      }
    }
  }
`;

export const UPDATE_DOCTOR_MUTATION = gql`
  mutation UPDATE_DOCTOR_MUTATION($docInput: DoctorUpdateInput) {
    doc: updateDoctor(input: $docInput) {
      id
      lifetrenzId
      qwid
      languages
      dateOfJoining
      altDepartment
      altDesignationType
      aboutMe
      awards
      cloudinaryId
      seoTitle
      seoDescription
      membership
      globalRank
      dateSincePracticing
      department
      departmentId
      email
      entityId
      gender
      id
      medicalCouncilRegNo
      name
      ancCard
      ancCardSettings
      notesAbbrevations
      websiteURL
      phoneNumber
      photos {
        dp300 {
          url
        }
      }
      primarySpecialty
      qualification
      secondarySpecialty
      services
      tags
      isConfigured
      ltFirstSyncData
      ltLastSyncData
      ltCreatedAt
      ltUpdatedAt
      created_at
      updated_at
      hasOp
      hasIp
      hasOt
      isEnabled
      isVisible
      siteCode
      seoSlug
      displaySpecialty
      displaySpecialtyArray
      profileStatus
      degree {
        name
        university
        fromDate
        toDate
      }
      fellowships {
        type
        name
        university
        fromDate
        toDate
        issuedAuthority
        issuedYear
      }
      experience {
        organisation
        designation
        fromDate
        toDate
      }
      papersPublished {
        name
        publication
        dateOfPublication
        description
      }
      approxNoOfDeliveries
      highlights
      yearsOfExperience
    }
  }
`;

export const GET_DOCTOR_WITH_HOSP = gql`
  query GET_DOCTOR_WITH_HOSP($id: String!) {
    doctor: getDoctor(id: $id) {
      id
      lifetrenzId
      entityId
      name
      type
      email
      phoneNumber
      gender
      primarySpecialty
      secondarySpecialty
      department
      departmentId
      services
      tags
      qualification
      medicalCouncilRegNo
      awards
      ancCard
      ancCardSettings
      notesAbbrevations
      websiteURL
      photos {
        dp300 {
          url
        }
      }
      aboutMe
      cloudinaryId
      qwid
      altDesignation
      altDesignationType
      altDepartment
      dateOfJoining
      languages
      seoTitle
      seoDescription
      membership
      dateSincePracticing
      globalRank
      isConfigured
      getHospitals {
        id
        name
      }
      ltFirstSyncData
      ltLastSyncData
      ltCreatedAt
      ltUpdatedAt
      created_at
      updated_at
      hasOp
      hasIp
      hasOt
      isEnabled
      isVisible
      siteCode
      entity {
        id
        roomNo
        isInRoom
      }
      seoSlug
    }
  }
`;

export const RESEND_MESSAGE = gql`
  mutation RESEND_MESSAGE($messageId: Int!) {
    resendMessage(messageId: $messageId) {
      id
      type
      bookingId
      sendAt
      sentAt
      isSent
      email
      mobile
      emailSubject
      smsMessage
      emailPlainText
      parentMessageId
    }
  }
`;

export const LOOKUP_PROCEDURES = gql`
  query LOOKUP_PROCEDURES {
    procedures: getProceduresLookup {
      id
      name
    }
  }
`;

export const GET_SLOT = gql`
  query GET_SLOT($Slot: SlotSearchInput) {
    slots: getSlots(input: $Slot) {
      startTime
      id
      hospitalId
      entityId
      objectName
      hospitalSiteCode
      procedures
    }
  }
`;

export const CREATE_SLOTS = gql`
  mutation CREATE_SLOTS($entityId: Int!) {
    createSlots(entityId: $entityId)
  }
`;

export const SEND_BOOKING_REMINDER = gql`
  mutation SEND_BOOKING_REMINDER($bookingId: String!) {
    sendBookingRemider(bookingId: $bookingId)
  }
`;

export const UPDATE_DOCTOR_PROFILE_LOGS = gql`
  mutation UPDATE_DOCTOR_PROFILE_LOGS(
    $DoctorProfileLogsInput: DoctorProfileLogsInput!
  ) {
    updateDoctorProfileLogs(input: $DoctorProfileLogsInput)
  }
`;

export const SEND_BOOKING_PRESCRIPTION = gql`
  mutation SEND_BOOKING_PRESCRIPTION($bookingId: String!) {
    sendPrescription(bookingId: $bookingId)
  }
`;

export const ADD_DELAY = gql`
  mutation ADD_DELAY($input: AddDelayInput!) {
    addDelay(input: $input) {
      id
      slotPrototypeId
      date
      startTime
      endTime
      isTatkalEnabled
      isBlockAll
      actionLog
    }
  }
`;

export const BLOCK_SLOTS = gql`
  mutation BLOCK_SLOTS($input: BlockSlotsInput!) {
    blockSlots(input: $input) {
      id
      slotPrototypeId
      date
      startTime
      endTime
      isTatkalEnabled
      isBlockAll
      updated_at
    }
  }
`;

export const CHANGE_TIMING = gql`
  mutation CHANGE_TIMING($input: ChangeTimingInput!) {
    changeTiming(input: $input) {
      id
      slotPrototypeId
      date
      startTime
      endTime
      isTatkalEnabled
      isBlockAll
      updated_at
    }
  }
`;

export const MODIFY_SLOT_AFFECTED_BOOKINGS = gql`
  query MODIFY_SLOT_AFFECTED_BOOKINGS($input: AffectedBookingsInput!) {
    affectedBookings(input: $input) {
      affectedBookings {
        userName
        userMobile
        slotTime
        isPaid
        procedureName
      }
      info
    }
  }
`;

export const APPROVE_VACATION = gql`
  mutation APPROVE_VACATION(
    $vacationId: Int!
    $approvalState: VACATION_STATUS!
  ) {
    vacationApprovals(vacationId: $vacationId, approvalState: $approvalState) {
      entityId
      startTime
      endTime
      reason
      id
      approvalStatus
    }
  }
`;

export const GET_VACATIONS = gql`
  query GET_VACATIONS(
    $entityId: Int
    $approvalStatus: VacationStatusAvailable
    $vacationFrom: DateTime
    $vacationTo: DateTime
  ) {
    getVacations(
      entityId: $entityId
      approvalStatus: $approvalStatus
      vacationFrom: $vacationFrom
      vacationTo: $vacationTo
    ) {
      entityId
      entityName
      startTime
      endTime
      reason
      id
      approvalStatus
      isAutoApproved
      updated_at
      hospitalId
    }
  }
`;

export const GET_ALL_DOCS_IDS = gql`
  query {
    getDoctors(input: { name: "" }) {
      entityId
      getHospitals {
        id
      }
    }
  }
`;

export const GET_AUDIT_LOG = gql`
  query GET_AUDIT_LOG($input: ActionHistoryInput) {
    log: getAuditLog(input: $input) {
      id
      entityId
      targetId
      targetType
      action
      userName
      userId
      created_at
    }
  }
`;

export const GET_AUDIT_LOGS_IN_DATE_RANGE = gql`
  query GET_AUDIT_LOGS_IN_DATE_RANGE($input: getActionHistoryInput) {
    auditLogs: getAuditLogsInDateRange(input: $input) {
      id
      entityId
      targetId
      targetType
      action
      userName
      userId
      created_at
      meta
      targetName
    }
  }
`;

export const GET_ENTITY_METADATA = gql`
  query GET_ENTITY_METADATA(
    $entityId: Int!
    $hospitalId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    data: getEntityAvailabilityMetadata(
      entityId: $entityId
      hospitalId: $hospitalId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      isAvailable
      typeOfAvaiablity
      displayText
      timings {
        startTime
        endTime
      }
    }
  }
`;

export const CREATE_SLOT_EMERGENCY_AND_BOOK = gql`
  mutation CREATE_SLOT_EMERGENCY_AND_BOOK(
    $entityId: Int!
    $hospitalId: Int!
    $procedure: String!
    $userName: String!
    $userMobile: String!
    $userMpid: String
  ) {
    createSlotUrgentBooking(
      entityId: $entityId
      hospitalId: $hospitalId
      procedure: $procedure
      userName: $userName
      userMobile: $userMobile
      userMpid: $userMpid
    ) {
      id
      objectName
      procedureName
      slotTime
      userName
    }
  }
`;

export const UPDATE_LAB_DATA = gql`
  mutation UPDATE_LAB_DATA($input: LabInput!) {
    updateLabData(input: $input) {
      homeCollection
      rate
      enable
      itemId
      labName
      siteCode
      ionicId
      aboutInfo
      favouriteLab
      turnaroundTime
    }
  }
`;

export const UPDATE_MULTIPLE_LABS_DATA = gql`
  mutation UPDATE_MULTIPLE_LABS_DATA($input: BulkLabInput!) {
    updateMultipleLabsData(input: $input) {
      homeCollection
      rate
      enable
      itemId
      labName
      siteCode
      ionicId
      aboutInfo
      favouriteLab
      turnaroundTime
    }
  }
`;

export const GET_LAB_DATA = gql`
  query GET_LAB_DATA($input: LabInput) {
    getLabDetails(input: $input) {
      homeCollection
      rate
      enable
      itemId
      labName
      siteCode
      ionicId
      aboutInfo
      favouriteLab
      turnaroundTime
    }
  }
`;

export const CREATE_LAB_DATA = gql`
  mutation CREATE_LAB_DATA($input: LabInput) {
    createLabData(input: $input) {
      homeCollection
      rate
      enable
      itemId
      labName
      siteCode
      ionicId
      aboutInfo
      favouriteLab
      turnaroundTime
    }
  }
`;

export const UPDATE_SCAN_DATA = gql`
  mutation UPDATE_SCAN_DATA($input: ScanInput!) {
    updateScanData(input: $input) {
      itemId
      siteCode
      aboutInfo
      rate
      scanName
      enable
      waitingTime
      durationofScan
      trimisterData
      weekData
      emailTemplate
      smsTemplate
      LtProcedure
      favouriteScan
      appEnabled
      scanType
      descriptionShort
      recommendationType
    }
  }
`;

export const UPDATE_MULTIPLE_SCANS_DATA = gql`
  mutation UPDATE_MULTIPLE_SCANS_DATA($input: BulkScansInput!) {
    updateMultipleScansData(input: $input) {
      itemId
      siteCode
      aboutInfo
      rate
      scanName
      enable
      waitingTime
      durationofScan
      trimisterData
      weekData
      emailTemplate
      smsTemplate
      LtProcedure
      favouriteScan
      appEnabled
    }
  }
`;

export const CREATE_SCAN_PROCEDURE_MAPPING = gql`
  mutation CREATE_SCAN_PROCEDURE_MAPPING($input: ScanProcedureMappingInput!) {
    createScanProcedureMapping(input: $input) {
      id
      scanName
      procedureId
      procedureName
    }
  }
`;

export const UPDATE_MULTIPLE_SCAN_PROCEDURE_MAPPING = gql`
  mutation UPDATE_MULTIPLE_SCAN_PROCEDURE_MAPPING(
    $input: BulkScanProcedureMappingInput!
  ) {
    updateMultipleScanProcedureMapping(input: $input) {
      id
      scanName
      procedureId
      procedureName
    }
  }
`;

export const GET_SCAN_DATA = gql`
  query GET_SCAN_DATA($input: ScanInput) {
    getScanDetails(input: $input) {
      itemId
      siteCode
      aboutInfo
      rate
      scanName
      enable
      waitingTime
      durationofScan
      trimisterData
      weekData
      emailTemplate
      smsTemplate
      LtProcedure
      favouriteScan
      appEnabled
      updatedBy
      updatedAt
      scanType
      descriptionShort
      procedureScanType
      procedureWeekData
      procedureAboutInfo
      procedureTrimisterData
      procedureDescriptionShort
      procedureRecommendationType
      procedureDurationofScan
      recommendationType
    }
  }
`;

export const GET_SCAN_PROCEDURE_MAPPING = gql`
  query GET_SCAN_PROCEDURE_MAPPING {
    getScanProcedureMappings {
      id
      scanName
      procedureId
      procedureName
      descriptionShort
      scanType
      trimisterData
      weekData
      aboutInfo
      recommendationType
      durationofScan
      updatedBy
      updatedAt
    }
  }
`;

export const CREATE_SCAN_DATA = gql`
  mutation CREATE_SCAN_DATA($input: ScanInput) {
    createScanData(input: $input) {
      itemId
      siteCode
      aboutInfo
      rate
      scanName
      enable
      waitingTime
      durationofScan
      trimisterData
      weekData
      emailTemplate
      smsTemplate
      LtProcedure
      favouriteScan
      descriptionShort
      scanType
    }
  }
`;

export const DELETE_LAB_DATA = gql`
  mutation DELETE_LAB_DATA($itemId: Int!) {
    deleteLabData(itemId: $itemId)
  }
`;

export const DELETE_MULTIPLE_LABS_DATA = gql`
  mutation DELETE_MULTIPLE_LABS_DATA($itemIds: [Int!]!) {
    deleteMultipleLabsData(itemIds: $itemIds)
  }
`;

export const DELETE_SCAN_DATA = gql`
  mutation DELETE_SCAN_DATA($itemId: Int!) {
    deleteScanData(itemId: $itemId)
  }
`;

export const DELETE_MULTIPLE_SCANS_DATA = gql`
  mutation DELETE_MULTIPLE_SCANS_DATA($itemIds: [Int!]!) {
    deleteMultipleScansData(itemIds: $itemIds)
  }
`;

export const DELETE_SCAN_PROCEDURE_MAPPING = gql`
  mutation DELETE_SCAN_PROCEDURE_MAPPING($scanName: String!) {
    deleteScanProcedureMapping(scanName: $scanName)
  }
`;

export const DELETE_MULTIPLE_SCAN_PROCEDURE_MAPPING = gql`
  mutation DELETE_MULTIPLE_SCAN_PROCEDURE_MAPPING($scanNames: [String!]!) {
    deleteMultipleScanProcedureMapping(scanNames: $scanNames)
  }
`;

export const GET_HOSPITAL_BY_ID = gql`
  query GET_HOSPITAL_BY_ID($id: Int!) {
    hospitals: getHospitalById(id: $id) {
      id
      siteCode
      name
      cityId
      cityName
      chargeMaster
      zohoOrgId
      settings {
        chargeMaster {
          invoiceGeneratorForConsultation
        }
      }
    }
  }
`;

export const UPDATE_RESERVE_SLOT = gql`
  mutation UPDATE_RESERVE_SLOT($id: String, $isReserved: Boolean) {
    updateReserveSlot(id: $id, isReserved: $isReserved) {
      id
    }
  }
`;

export const FORGOT_USER_PASSWORD = gql`
  mutation FORGOT_USER_PASSWORD($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const UPLOAD_LOGO = gql`
  mutation UPLOAD_LOGO($imageFile: Upload!, $hospitalId: Int!) {
    uploadLogo(imageFile: $imageFile, hospitalId: $hospitalId)
  }
`;

export const UPLOAD_CITY_LOGO = gql`
  mutation UPLOAD_CITY_LOGO($imageFile: Upload!, $cityId: Int!) {
    uploadCityLogo(imageFile: $imageFile, cityId: $cityId)
  }
`;

export const GET_DOCTOR_RANKING = gql`
  query getDoctorsRanking($input: RankingSearchInput) {
    getDoctorsRanking(input: $input) {
      ranking
      doctorId
      doctorName
    }
  }
`;
export const UPDATE_DOCTOR_RANKING = gql`
  mutation UPDATE_DOCTOR_RANKING($input: UpdatedRankingInput) {
    updateDoctorsRanking(input: $input) {
      ranking
      doctorId
      doctorName
    }
  }
`;
export const GET_PROTOCOLS = gql`
  query GET_PROTOCOLS(
    $hospitalId: Int!
    $question: String
    $approved: Boolean
  ) {
    getProtocols(
      hospitalId: $hospitalId
      question: $question
      approved: $approved
    ) {
      id
      title
      answer
      updatedBy
      updated_at
      approvedBy
      approved
    }
  }
`;

export const CREATE_PROTOCOL = gql`
  mutation CREATE_PROTOCOL($protocols: ProtocolsInput!) {
    createProtocol(protocols: $protocols) {
      title
      answer
      updatedBy
    }
  }
`;

export const UPDATE_PROTOCOL = gql`
  mutation UPDATE_PROTOCOL($protocolsId: Int!, $protocols: ProtocolsInput!) {
    updateProtocol(protocolsId: $protocolsId, protocols: $protocols) {
      title
      answer
      updatedBy
    }
  }
`;

export const APPROVE_PROTOCOL = gql`
  mutation APPROVE_PROTOCOL($protocolsId: Int!, $protocols: ProtocolsInput!) {
    approveProtocol(protocolsId: $protocolsId, protocols: $protocols) {
      title
      answer
      updatedBy
    }
  }
`;

export const GET_ALL_HOSPITALTYPES = gql`
  query GET_ALL_HOSPITALTYPES {
    getHospitalTypes {
      id
      name
    }
  }
`;

export const CREATE_HOSPITAL_TYPE = gql`
  mutation CREATE_HOSPITAL_TYPE($name: String!) {
    createHospitalType(name: $name) {
      id
      name
    }
  }
`;

const UserServiceGqlApi = async (query, variables) => {
  const url = USER_SERVICE_URL;
  return graphqlApiRequest(url, query, variables, null);
};

const AmsGqlApi = async (query, variables) => {
  const url = `${API_ROOT}/graphql`;
  const token = localStorage.getItem('token');
  return graphqlApiRequest(url, query, variables, token);
};

export const getLobbyDiscountCoupons = async () => {
  const query = `
    query getDiscountCoupons($input: DiscountSearchInput!) {
      getDiscountCoupons(input: $input) {
          ltdiscountname
          ltitemid
          sitecode
      }
    }
  `;

  const variables = {
    input: {
      isCorporate: false
    }
  };

  return UserServiceGqlApi(query, variables);
};

export const GET_LOGGED_IN_USER_DETAILS = gql`
  query GetLoggedInUserDetails {
    me {
      email
      id
      name
      hospitals
      role
    }

    hospitals: getHospitals(showAll: false, cloudnineHospitalsOnly: true) {
      id
      name
      siteCode
    }
  }
`;

export const getDxReports = (hospitalId, startDate, endDate, departmentId) => {
  const req_body = {
    query: `
      query GetDxReports($input: DxReportsInput!) {
        getDxReports(input: $input) {
          adoptionPercentage
          data {
            administeredVaccines {
              vaccine {
                name
              }
            }
            booking {
              objectName
              slotTime
              userMobile
              userName
              appointmentState
            }
            labs {
              name
            }
            medicines {
              name
            }
            prescriptionLink
            scans {
              name
            }
          }
        }
      }
    `,
    variables: {
      input: {
        hospitalId,
        startDate,
        endDate,
        departmentId
      }
    }
  };

  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': S2S_USER_SERVICES_API_KEY || ''
    },
    body: JSON.stringify(req_body)
  };

  return fetch(USER_SERVICE_URL, fetchOpts)
    .then(response => response.json())
    .then(data => data.data)
    .catch(err => {
      return err;
    });
};

export const fetchCouponDetails = async mpid => {
  const query = `
    query GetCouponDetails($input: CouponListInput!) {
      getCouponDetails(input: $input) {
        id
        couponcode
        requestid
        created_at
        createdby
        expirydate
        redeemedat
        comment
        isredeemable
      }
    }
  `;

  const variables = { input: { mpid, type: 'MPID' } };

  const response = await UserServiceGqlApi(query, variables);
  return response.data;
};

export const createMpidCoupon = async (formData, mpid, user) => {
  const {
    enteredCouponCount,
    selectedLtItemId,
    selectedStartDate,
    selectedExpiryDate,
    enteredComment,
    departments
  } = formData;

  const query = `
    mutation createCoupon($input: CreateCouponInput!) {
      createCoupon(input: $input) {
        couponcode
      }
    }
  `;

  const variables = {
    input: {
      mpid,
      startdate: selectedStartDate,
      ltitemid: selectedLtItemId,
      email: user.email,
      mobile: user.mobile,
      createdby: getLoggedInUser().name,
      expirydate: selectedExpiryDate,
      comment: enteredComment,
      couponnos: parseInt(enteredCouponCount),
      details: {
        departmentIds: departments.map(dept => dept.id)
      },
      type: 'MPID'
    }
  };

  return UserServiceGqlApi(query, variables);
};

export const deleteCoupon = async (id, user) => {
  const query = `
    mutation DeleteCoupon( $id: String!,$user: String!) {
      deleteCoupon( id: $id ,user: $user) {
        message
      }
    }
  `;

  const variables = {
    id: id,
    user: user
  };

  return UserServiceGqlApi(query, variables);
};

export const createDocCoupon = async formData => {
  const {
    enteredCouponCount,
    selectedLtItemId,
    selectedStartDate,
    selectedExpiryDate,
    enteredComment,
    selectedDoc
  } = formData;

  const query = `
    mutation createCoupon($input: CreateCouponInput!) {
      createCoupon(input: $input) {
        couponcode
      }
    }
  `;

  const variables = {
    input: {
      startdate: selectedStartDate,
      ltitemid: selectedLtItemId,
      createdby: getLoggedInUser().name,
      expirydate: selectedExpiryDate,
      comment: enteredComment,
      couponnos: parseInt(enteredCouponCount),
      details: {
        doctorId: selectedDoc.id
      },
      type: 'DOCTOR'
    }
  };

  return await UserServiceGqlApi(query, variables);
};

export const fetchPatientDetails = async mpid => {
  const query = `
    query GetPatientDetails($mpid: String!) {
      getPatientDetails(input: { mpid: $mpid }) {
        mpid
        email
        first_name
        mobile
      }
    }
  `;
  const variables = { mpid };
  return await UserServiceGqlApi(query, variables);
};

export const fetchAllDoctors = async siteCode => {
  const query = `
    query getDoctors($input: DoctorSearchInput!) {
      getDoctors(input: $input) {
        name
        primarySpecialty
        siteCode
        id
        departmentId
        entity {
          departmentId
        }
        department
      }
    }
  `;

  const variables = {
    input: {
      siteCode: siteCode
    }
  };

  return AmsGqlApi(query, variables);
};
