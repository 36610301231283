import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Container, Button, Collapse } from 'reactstrap';
import { FaMobileAlt } from 'react-icons/fa';
import moment from 'moment';
import Loader from './Loader';
// import RegisterPayment from './RegisterPayment';
// import PaymentBreakupTooltip from './PaymentBreakupTooltip';
import Steps from './Steps';
import ActionButtons from './ActionButtonsPatientBadge';
import SlotList from './SlotList';
import RescheduleBar from './RescheduleBar';
import EditableBookingField from './EditableBookingField';

const styles = {
  doctorHeader: { display: 'inline' },
  notPaid: { color: 'red' },
  paid: { color: 'blue' },
  row: { marginTop: '1rem' },
  marginLeft2: { marginLeft: '2rem' }
};

/**
 * get approx chanrge, bring it back
 *       getApproxCharge {
        item_list {
          item_name
          total
        }
        total_payable
        isApprox
      }
 *
 */
export const DISPLAY_BOOKING_QUERY = gql`
  query DISPLAY_BOOKING_QUERY($bookingId: String) {
    booking: getBooking(id: $bookingId) {
      id
      mpid
      prospectId
      slotId
      userQuestions
      rating5
      doctorInstruction
      isPaid
      paymentDetails
      source
      amount
      paymentMode
      created_at
      updated_at
      entityId
      hospitalId
      procedureName
      objectName
      objectType
      objectId
      userName
      userMobile
      userCountry
      userEmail
      slotTime
      slotDuration
      appointmentState
      checkinTS
      noShowTS
      startConsultationTS
      endConsultationTS
      checkoutTS
      cancelledTS
    }
  }
`;
const BookingInfo = props => {
  const [showSlots, setShowSlots] = useState(false);
  const [slotListBeginDate, setSlotListBegineDate] = useState(null);
  const [rescheduleToSlot, setRescheduleToSlot] = useState(null);

  const { bookingId } = props;

  const moveSlotBeginDate = (currentDate, byNumberOfDays) => {
    const currentBeginDate = moment(currentDate);
    currentBeginDate.add(byNumberOfDays, 'days');
    setSlotListBegineDate(currentBeginDate);
  };
  const rescheduleNextClicked = currentDate =>
    moveSlotBeginDate(currentDate, 5);
  const reschedulePrevClicked = currentDate =>
    moveSlotBeginDate(currentDate, -5);

  const rescheduleDone = () => {
    setShowSlots(false);
    setSlotListBegineDate(null);
    setRescheduleToSlot(null);
  };

  return (
    <Query query={DISPLAY_BOOKING_QUERY} variables={{ bookingId }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;

        const {
          booking,
          booking: {
            entityId,
            hospitalId,
            procedureName,
            // getApproxCharge,
            // isPaid,
            userMobile,
            mpid,
            slotTime,
            slotDuration,
            objectName,
            prospectId,
            created_at,
            checkinTS,
            noShowTS,
            startConsultationTS,
            endConsultationTS,
            checkoutTS,
            cancelledTS
          }
        } = data;

        const isCanceledOrNoShow = cancelledTS || noShowTS;
        const showReschedule = !checkinTS && !isCanceledOrNoShow;

        // TODO: FIX PAYMENT BUTTON!!!
        // let breakupNode = !isPaid ? (
        //   <span>Tp be paid</span>
        // ) : // <PaymentBreakupTooltip
        // //   idOfTarget="paymentNode"
        // //   item_list={getApproxCharge.item_list}
        // // />
        // null;

        // TODO: FIX PAYMENT BUTTON!!!
        // const paymentDecoration = isPaid ? ['paid'] : ['not-paid'];
        // if (getApproxCharge.item_list !== null) {
        //   paymentDecoration.push('has-breakup');
        // }

        // TODO: FIX PAYMENT BUTTON!!!
        let amountNode = null; /*(
          <React.Fragment>
            <span id="paymentNode" className={paymentDecoration.join(' ')}>
              <FaMoneyBillAlt />{' '}
              {`${getApproxCharge.total_payable} ${
                getApproxCharge.isApprox ? '(approx)' : ''
              } ${isPaid ? 'PAID' : 'TO BE PAID'}`}
            </span>
            {breakupNode}
          </React.Fragment>
        );*/

        //If paid or cancelled do not show pay button, right?
        // TODO: FIX PAYMENT BUTTON!!!
        const paymentComponent = null;
        // isPaid || isCanceledOrNoShow ? null : (
        //   <RegisterPayment booking={booking} />
        // );

        const showRescheduleListFrom = slotListBeginDate || moment(slotTime);
        const rescheduleToSlotId = rescheduleToSlot && rescheduleToSlot.id;
        const rescheduleToDateTime =
          rescheduleToSlot && rescheduleToSlot.startTime;

        return (
          <Container>
            <Row>
              <Col sm={9}>
                <h2>
                  <EditableBookingField
                    booking={booking}
                    field={'userName'}
                    placeholder="Patient Name"
                  />
                </h2>
                <div>
                  <span className="text-muted">
                    MPI: {mpid ? mpid : 'Not Available'}{' '}
                  </span>
                </div>
                <div>
                  <span className="text-muted">Prospect ID: {prospectId}</span>
                </div>
                <div>
                  <span>
                    <EditableBookingField
                      booking={booking}
                      field="userQuestions"
                      placeholder="Notes"
                      clickToEditText="Write a note"
                    />
                  </span>
                </div>
                <hr />
              </Col>
              <Col sm={3} className="text-right">
                <FaMobileAlt />
                <span>{` ${userMobile}`}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 style={styles.doctorHeader}>{`${objectName} `}</h4>
                {'on '}
                <span className="appt-time" style={{ fontWeight: 'bold' }}>
                  {`${moment(slotTime).format('ddd DD MMM YYYY hh:mm A')}`}
                </span>
                {` for ${slotDuration} min`}
                <Button
                  color="link"
                  onClick={() => setShowSlots(!showSlots)}
                  disabled={!showReschedule}
                >
                  {showSlots ? 'Hide Reschedule' : 'Reschedule'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Collapse isOpen={showSlots}>
                  <RescheduleBar
                    bookingId={bookingId}
                    slotId={rescheduleToSlotId}
                    slotTime={rescheduleToDateTime}
                    onRescheduleDone={rescheduleDone}
                  />
                  <SlotList
                    startDate={showRescheduleListFrom}
                    entityId={entityId}
                    onSlotSelect={setRescheduleToSlot}
                    procedureName={procedureName}
                    hospitalId={hospitalId}
                    onPrevClick={() =>
                      reschedulePrevClicked(showRescheduleListFrom)
                    }
                    onNextClick={() =>
                      rescheduleNextClicked(showRescheduleListFrom)
                    }
                  />
                </Collapse>
              </Col>
            </Row>
            <Row>
              <Col>
                {amountNode} {paymentComponent}
              </Col>
            </Row>

            <Row style={styles.row}>
              <Col>
                <Steps
                  booked={created_at}
                  cancelled={cancelledTS}
                  noShow={noShowTS}
                  checkedIn={checkinTS}
                  startConsultation={startConsultationTS}
                  endConsultation={endConsultationTS}
                  checkedOut={checkoutTS}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <ActionButtons
                    booking={booking}
                    btnColor={'secondary'}
                    isNoShowBtnEye={false}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Query>
  );
};

BookingInfo.propTypes = { bookingId: PropTypes.string };
export default BookingInfo;
