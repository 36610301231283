import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  FaSort,
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaToggleOn
} from 'react-icons/fa';
import { GiRank3 } from 'react-icons/gi';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { logAmplitudeEvent } from '../utilities';

export const DOC_FILTERS = {
  a2z: 'Name A to Z',
  z2a: 'Name Z to A',
  inFirst: 'Availability',
  rank: 'Preset'
};

const DocFilterSelector = ({ onSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(DOC_FILTERS.rank);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const itemClicked = sortBy => {
    setSelectedSort(sortBy);
    logAmplitudeEvent('Doctors_List_Sort_By_Order', { sortBy: sortBy }, true);
    onSelect(sortBy);
  };
  return (
    <div>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
        <DropdownToggle color="link">
          {selectedSort} <FaSort />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>SORT BY</DropdownItem>
          <DropdownItem onClick={() => itemClicked(DOC_FILTERS.a2z)}>
            <FaSortAlphaDown /> {DOC_FILTERS.a2z}
          </DropdownItem>
          <DropdownItem onClick={() => itemClicked(DOC_FILTERS.z2a)}>
            <FaSortAlphaUp /> {DOC_FILTERS.z2a}
          </DropdownItem>
          <DropdownItem onClick={() => itemClicked(DOC_FILTERS.inFirst)}>
            <FaToggleOn /> {DOC_FILTERS.inFirst}
          </DropdownItem>
          <DropdownItem onClick={() => itemClicked(DOC_FILTERS.rank)}>
            <GiRank3 /> {DOC_FILTERS.rank}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

DocFilterSelector.propTypes = {
  onSelect: PropTypes.func
};

export default DocFilterSelector;
