import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { GET_ENTITY_METADATA } from '../queries';
import Loader from './Loader';

const NoSlotReason = ({ hospitalId, entityId, startDate, endDate }) => {
  const { loading, data: { data = [] } = {} } = useQuery(GET_ENTITY_METADATA, {
    variables: { hospitalId, entityId, startDate, endDate }
  });
  if (loading) {
    return <Loader />;
  }
  return (
    <div>{data.length && data[0].displayText ? data[0].displayText : ''}</div>
  );
};

NoSlotReason.propTypes = {
  entityId: PropTypes.number,
  hospitalId: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default NoSlotReason;
