import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, Input, Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import { useMutation } from 'react-apollo';
import { CREATE_HOSPITAL_TYPE, GET_ALL_HOSPITALTYPES } from '../queries';
import { toast } from 'react-toastify';

const AddHospitalType = ({ handleClose }) => {
  const [createHospitalType] = useMutation(CREATE_HOSPITAL_TYPE, {
    refetchQueries: [{ query: GET_ALL_HOSPITALTYPES }],
    onCompleted: () => {
      formik.setSubmitting(false);
      toast.success('Hospital type created successfully');
      handleClose();
    },
    onError: () => {
      formik.setSubmitting(false);
      toast.error('Something went wrong!');
    }
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },

    onSubmit: values => {
      createHospitalType({ variables: { name: values.name } });
    },
    validationSchema
  });

  const handleResetForm = () => {
    formik.resetForm();
  };

  return (
    <div>
      <h4>Hospital Type</h4>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Name</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              style={{ maxWidth: '30rem' }}
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />
            {formik.errors.name && formik.touched.name ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.name}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row
          className="justify-content-end mt-4"
          style={{ padding: '0px 15px' }}
        >
          <Button type="button" color="primary" onClick={handleResetForm}>
            RESET
          </Button>
          <Button
            className="ml-3"
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
          >
            SAVE
          </Button>
        </Row>
      </form>
    </div>
  );
};

AddHospitalType.propTypes = {
  handleClose: PropTypes.func
};
export default AddHospitalType;
