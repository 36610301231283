const convertToCSV = data => {
  const csvRows = [];
  const headers = [
    'TIMESTAMP',
    'TARGET ID',
    'TARGET TYPE',
    'ACTION',
    'ENTITY ID',
    'DONE BY',
    'META',
    'TARGET NAME'
  ];
  csvRows.push(headers.join(','));
  for (const item of data) {
    const metaJSON = JSON.stringify(item.meta);
    const values = [
      item.created_at,
      item.targetId,
      item.targetType,
      item.action,
      item.entityId,
      item.userName,
      metaJSON,
      item.targetName
    ];
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

export const handleExportCSV = data => {
  if (data && data.length > 0) {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'auditLogs_data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
