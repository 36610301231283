import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import { FaTrash } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { showConfirmation } from '../ConfirmationDialogService';
import { DELETE_MULTIPLE_LABS_DATA } from '../queries';
import { displayError, displaySuccess, logAmplitudeEvent } from '../utilities';

const getMessage = () => (
  <>
    <span>
      Permanently delete the selected labs?
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteMultipleLabs = ({ itemIds, onDeleteCompleted }) => {
  const confirm = deleteMultipleLabs => {
    showConfirmation('Delete Labs?', getMessage(), () =>
      deleteMultipleLabs({ variables: { itemIds } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_MULTIPLE_LABS_DATA}
      onCompleted={() => {
        displaySuccess(`${itemIds.length} labs deleted`);
        logAmplitudeEvent(
          'Labs_deleted',
          { deletedLabs: itemIds.join(', ') },
          true
        );
        onDeleteCompleted();
      }}
      onError={e => {
        displayError(e);
        onDeleteCompleted();
      }}
      refetchQueries={() => ['GET_LAB_DATA']}
    >
      {(deleteMultipleLabs, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteMultipleLabs)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteMultipleLabs.propTypes = {
  itemIds: PropTypes.arrayOf(PropTypes.number),
  onDeleteCompleted: PropTypes.func.isRequired
};

export default DeleteMultipleLabs;
