import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datepicker';
import styled from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Collapse
} from 'reactstrap';
import moment from 'moment';
import DepartmentsSelect from './DepartmentsSelect';
import CitySelector from './CitySelector';
import HospitalSelector from './HospitalSelector';
import BookApptForm from './BookApptForm';
import DoctorSelector from './DoctorSelector';
import SelectProcedure from './SelectProcedure';
import { FaRedo } from 'react-icons/fa';
import SlotList from './SlotList';
import TimingsSelect from './TimingsSelect';

const SpacedContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
`;
const TextCenter = styled.div`
  text-align: center;
`;
const DivTopMargin = styled.div`
  margin-top: 10px;
`;
const FlexEndContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
`;

const QuickBookingModal = props => {
  const { isOpen, toggle } = props;
  const [selectedDate, updateSelectedDate] = useState(moment());
  const [step, updateStep] = useState(0);
  const [searchType, updatesearchType] = useState(0);
  const [selectedCity, updateSelectedCity] = useState();
  const [selectedHospital, updateSelectedHospital] = useState();
  const [selectedDepartment, updateSelectedDepartment] = useState();
  const [selectedSlot, updateSelectedSlot] = useState();
  const [selectedEntityId, updateSelectedEntityId] = useState();
  const [selectedProcedure, updateSelectedProcedure] = useState();
  const [selectedDoctor, updateSelectedDoctor] = useState();
  const [selectedTiming, updateSelectedTiming] = useState();
  const handleOnClose = () => {
    updateSelectedDate(moment());
    updateStep(0);
    updateSelectedCity();
    updateSelectedHospital();
    updateSelectedDepartment();
    updateSelectedSlot();
    updateSelectedProcedure();
    updateSelectedDoctor();
    updatesearchType(0);
    updateSelectedTiming();
  };
  const resetData = () => {
    updateStep(0);
    updateSelectedHospital();
    updateSelectedDepartment();
    updateSelectedSlot();
    updateSelectedProcedure();
    updateSelectedDoctor();
    updateSelectedTiming();
    // updatesearchType(0);
  };
  const handleSearchType = step => {
    if (searchType !== step) {
      resetData();
    }
    updatesearchType(step);
  };
  const handleCitySelection = city => {
    updateSelectedCity(city);
    resetData();
  };
  const handleHospitalSelection = hospital => {
    if (searchType === 1) updateSelectedProcedure();
    else if (searchType === 2) {
      updateSelectedDoctor();
      updateSelectedEntityId();
    }
    updateSelectedHospital(hospital);
  };
  const handleDeptSelection = dept => {
    updateSelectedDepartment(dept);
  };
  const handleTimingSelection = time => {
    updateSelectedTiming(time);
  };
  const onSlotSelection = item => {
    updateSelectedSlot(item);
    updateStep(2);
  };
  const handleDoctorSelection = item => {
    if (searchType === 1) updateSelectedHospital();
    if (searchType === 2) updateSelectedProcedure();
    updateSelectedEntityId(item.value.entityId);
    updateSelectedDoctor(item);
  };

  const jumpDateBy = days => {
    const startDate = selectedDate;
    let newDate;
    newDate = moment(startDate).add(days, 'days');
    updateSelectedDate(newDate);
  };
  const loadPrevSlots = () => {
    if (selectedDate.isSame(moment(), 'date')) {
      return;
    }
    jumpDateBy(-5);
  };
  const loadNextSlots = () => jumpDateBy(5);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      onClosed={handleOnClose}
      keyboard={false}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Quick Appointment Booking</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col sm={11}>
              <Row>
                <Col sm={4}>
                  <SpacedContainer className="row">
                    <Col sm={12}>Date</Col>
                  </SpacedContainer>
                  <Row>
                    <Col sm={12}>
                      <DateTimePicker
                        placeholderText="Select Date"
                        selected={selectedDate}
                        minDate={moment()}
                        dateFormat="DD MMM YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={date => updateSelectedDate(moment(date))}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <SpacedContainer className="row">
                    <Col sm={12}>City</Col>
                  </SpacedContainer>
                  <Row>
                    <Col sm={12}>
                      <CitySelector
                        value={selectedCity}
                        onChange={handleCitySelection}
                        placeholder="City"
                      />
                    </Col>
                  </Row>
                </Col>
                {selectedCity ? (
                  <Col
                    sm={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      paddingLeft: 40
                    }}
                  >
                    <Row>
                      <TextCenter>
                        <input
                          type="radio"
                          id="sbd"
                          onClick={() => handleSearchType(1)}
                          checked={searchType === 1}
                        />{' '}
                        <label style={{ marginTop: 5 }} htmlFor="sbd">
                          Search by Doctor
                        </label>
                      </TextCenter>
                    </Row>
                    <Row>
                      <TextCenter>
                        <input
                          type="radio"
                          id="sbh"
                          onClick={() => handleSearchType(2)}
                          checked={searchType === 2}
                        />{' '}
                        <label style={{ marginTop: 5 }} htmlFor="sbh">
                          Search by Hospital
                        </label>
                      </TextCenter>
                    </Row>
                  </Col>
                ) : null}
              </Row>
              {searchType === 1 ? (
                <Row>
                  {/* search by doctor */}
                  <Col sm={3}>
                    <SpacedContainer className="row">
                      <Col sm={12}>Doctor</Col>
                    </SpacedContainer>
                    <Row>
                      <Col sm={12}>
                        <DoctorSelector
                          onChange={handleDoctorSelection}
                          value={selectedDoctor ? selectedDoctor : ''}
                          // hospitalIds={
                          //   selectedHospital && selectedHospital.value
                          //     ? [selectedHospital.value]
                          //     : null
                          // }
                          // departmentIds={
                          //   selectedDepartment ? [selectedDepartment] : null
                          // }
                          cityId={
                            selectedCity && selectedCity.value
                              ? selectedCity.value
                              : null
                          }
                          placeholder="Doctor"
                        />
                      </Col>
                    </Row>
                  </Col>
                  {selectedDoctor ? (
                    <Col sm={3}>
                      <SpacedContainer className="row">
                        <Col sm={12}>Hospital</Col>
                      </SpacedContainer>
                      <Row>
                        <Col sm={12}>
                          <HospitalSelector
                            cityId={
                              selectedCity && selectedCity.value
                                ? selectedCity.value
                                : null
                            }
                            value={selectedHospital}
                            onChange={handleHospitalSelection}
                            placeholder="Hospital"
                            arrayValues={selectedDoctor}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                  {/* {selectedDoctor && selectedHospital ? (
                    <Col sm={3}>
                      <SpacedContainer className="row">
                        <Col sm={12}>Department</Col>
                      </SpacedContainer>
                      <Row>
                        <Col sm={12}>
                          <DepartmentsSelect
                            onChange={handleDeptSelection}
                            value={selectedDepartment ? selectedDepartment : ''}
                            placeholder="Department"
                            value={
                              selectedDoctor.value &&
                              selectedDoctor.value.department
                                ? selectedDoctor.value.department
                                : ''
                            }
                            showOptions={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null} */}
                  {selectedDoctor && selectedHospital ? (
                    <Col sm={3}>
                      <SpacedContainer className="row">
                        <Col sm={12}>Procedure</Col>
                      </SpacedContainer>
                      <Row>
                        <Col sm={12}>
                          <SelectProcedure
                            hospitalId={
                              selectedHospital ? selectedHospital.value : ''
                            }
                            entityId={selectedEntityId}
                            selectedProcedure={selectedProcedure}
                            onProcedureSelect={selectedProcedure => {
                              updateSelectedProcedure(selectedProcedure);
                              updateStep(1);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
              ) : searchType === 2 ? (
                <Row>
                  {/* search by hospital */}
                  <Col sm={3}>
                    <SpacedContainer className="row">
                      <Col sm={12}>Hospital</Col>
                    </SpacedContainer>
                    <Row>
                      <Col sm={12}>
                        <HospitalSelector
                          cityId={
                            selectedCity && selectedCity.value
                              ? selectedCity.value
                              : null
                          }
                          value={selectedHospital}
                          onChange={handleHospitalSelection}
                          placeholder="Hospital"
                        />
                      </Col>
                    </Row>
                  </Col>
                  {selectedHospital ? (
                    <>
                      <Col sm={3}>
                        <SpacedContainer className="row">
                          <Col sm={12}>Department</Col>
                        </SpacedContainer>
                        <Row>
                          <Col sm={12}>
                            <DepartmentsSelect
                              onChange={handleDeptSelection}
                              value={
                                selectedDepartment ? selectedDepartment : ''
                              }
                              placeholder="Department"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <SpacedContainer className="row">
                          <Col sm={12}>Timings</Col>
                        </SpacedContainer>
                        <Row>
                          <Col sm={12}>
                            <TimingsSelect
                              onChange={handleTimingSelection}
                              value={selectedTiming ? selectedTiming : ''}
                              placeholder="Timings"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={3}>
                        <SpacedContainer className="row">
                          <Col sm={12}>Doctor</Col>
                        </SpacedContainer>
                        <Row>
                          <Col sm={12}>
                            <DoctorSelector
                              onChange={handleDoctorSelection}
                              value={selectedDoctor ? selectedDoctor : ''}
                              hospitalIds={
                                selectedHospital && selectedHospital.value
                                  ? [selectedHospital.value]
                                  : null
                              }
                              departmentIds={
                                selectedDepartment ? [selectedDepartment] : null
                              }
                              cityId={
                                selectedCity && selectedCity.value
                                  ? selectedCity.value
                                  : null
                              }
                              timings={selectedTiming ? [selectedTiming] : null}
                              dateSelected={
                                selectedDate
                                  ? [
                                      moment(selectedDate).format(
                                        'DD - MM - YYYY'
                                      )
                                    ]
                                  : null
                              }
                              placeholder="Doctor"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : null}
                  {selectedHospital && selectedDoctor ? (
                    <Col sm={3}>
                      <SpacedContainer className="row">
                        <Col sm={12}>Procedure</Col>
                      </SpacedContainer>
                      <Row>
                        <Col sm={12}>
                          <SelectProcedure
                            hospitalId={
                              selectedHospital ? selectedHospital.value : ''
                            }
                            entityId={selectedEntityId}
                            selectedProcedure={selectedProcedure}
                            onProcedureSelect={selectedProcedure => {
                              updateSelectedProcedure(selectedProcedure);
                              updateStep(1);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Row>
              ) : null}
            </Col>
            {searchType === 1 || searchType === 2 ? (
              <>
                <FlexEndContainer sm={1} className="col">
                  <FaRedo onClick={resetData} style={{ cursor: 'pointer' }} />
                </FlexEndContainer>
                {/* <FlexEndContainer sm={1} className="col">
                  <Row>
                    <Button onClick={() => updateStep(1)}>Go</Button>
                  </Row>
                </FlexEndContainer> */}
              </>
            ) : null}
          </Row>
          <Row>
            <Col>
              <DivTopMargin className="card">
                <CardHeader onClick={() => updateStep(1)}>
                  {'Select Slots'}
                </CardHeader>
                <Collapse isOpen={step === 1}>
                  <CardBody>
                    {searchType === 1 ? (
                      !selectedEntityId ? (
                        <span>Please Select Doctor</span>
                      ) : !selectedHospital ? (
                        <span>Please Select Hospital</span>
                      ) : !selectedProcedure ? (
                        <span>Please Select Procedure</span>
                      ) : (
                        <SlotList
                          startDate={selectedDate}
                          entityId={selectedEntityId}
                          onSlotSelect={onSlotSelection}
                          procedureName={selectedProcedure}
                          hospitalId={
                            selectedHospital && selectedHospital.value
                          }
                          onPrevClick={loadPrevSlots}
                          onNextClick={loadNextSlots}
                        />
                      )
                    ) : searchType === 2 ? (
                      !selectedHospital ? (
                        <span>Please Select Hospital</span>
                      ) : !selectedEntityId ? (
                        <span>Please Select Doctor</span>
                      ) : !selectedProcedure ? (
                        <span>Please Select Procedure</span>
                      ) : (
                        <SlotList
                          startDate={selectedDate}
                          entityId={selectedEntityId}
                          onSlotSelect={onSlotSelection}
                          procedureName={selectedProcedure}
                          hospitalId={
                            selectedHospital && selectedHospital.value
                          }
                          onPrevClick={loadPrevSlots}
                          onNextClick={loadNextSlots}
                        />
                      )
                    ) : null}
                  </CardBody>
                </Collapse>
              </DivTopMargin>
            </Col>
          </Row>
          {selectedProcedure !== 'Video Consultation' ? (
            <Row>
              <Col>
                <DivTopMargin className="card">
                  <CardHeader>{'Enter Patient Details'}</CardHeader>
                  <Collapse isOpen={step === 2}>
                    <CardBody>
                      <BookApptForm
                        hospitalId={
                          selectedHospital ? selectedHospital.value : undefined
                        }
                        selectedSlot={selectedSlot}
                        procedureName={selectedProcedure}
                        mode="quickbook"
                      />
                    </CardBody>
                  </Collapse>
                </DivTopMargin>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="error text-right mt-2 font-weight-bold">
                {'Note: If you are booking a Video Consultation, ' +
                  "please ask the customer to book it on It's Our Baby app."}
              </Col>
            </Row>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          CLOSE
        </Button>
      </ModalFooter>
    </Modal>
  );
};

QuickBookingModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default QuickBookingModal;
