import React, { useEffect, useState } from 'react';
import { Button, Input, Form } from 'reactstrap';
import { createDocCoupon, fetchAllDoctors } from '../queries';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';
import ShowCreatedCouponModal from './ShowCreatedCouponModal';
import PropTypes from 'prop-types';
import { logAmplitudeEvent, commonCouponFormData } from '../utilities';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

const initialFormData = {
  enteredCouponCount: '1',
  ...commonCouponFormData,
  selectedDoc: ''
};

const DocCouponForm = ({ coupons, resetFlag }) => {
  const [doctors, setDoctors] = useState([]);
  const [createdCoupon, setCreatedCoupon] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (resetFlag) {
      setFormData(initialFormData);
    }
  }, [resetFlag]);

  const toggle = () => {
    setCreatedCoupon(null);
    setshowModal(!showModal);
    setFormData(initialFormData);
    setDoctors([]);
  };

  const handleFetchDoctors = async siteCode => {
    try {
      const resp = await fetchAllDoctors(siteCode);
      if (
        resp.data &&
        resp.data.getDoctors &&
        resp.data.getDoctors.length > 0
      ) {
        setDoctors(resp.data.getDoctors);
      }
    } catch (err) {
      toast.error(err, { autoClose: 3000 });
    }
  };

  const handleFormChange = e => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangeSelect = (name, e) => {
    if (name === 'selectedLtItemId') {
      const enteredComment = coupons.newCouponsMap[e.value] || '';
      setFormData({
        ...formData,
        selectedLtItemId: e.value,
        enteredComment: enteredComment
      });
    } else if (name === 'selectedDoc') {
      const selectedValue = e.value;
      const selectedDoc = doctors.find(doc => doc.id === selectedValue);
      setFormData({
        ...formData,
        selectedDoc: selectedDoc
      });
    } else if (name === 'selectedSiteCode') {
      handleFetchDoctors(e.value);
      setFormData({
        ...formData,
        selectedSiteCode: e.value
      });
    }
  };

  const validationSchema = Yup.object({
    enteredCouponCount: Yup.string().required('Please enter coupon count.'),
    selectedLtItemId: Yup.string().required('Please select an offer.'),
    selectedSiteCode: Yup.string().required('Please select a site code'),
    selectedDoc: Yup.object({ id: Yup.string() })
      .required('Please select a doctor')
      .nullable()
  });

  const handleLogEvent = message => {
    logAmplitudeEvent(
      message,
      {
        formData
      },
      true
    );
  };

  const handleSubmitToCreate = async () => {
    setIsButtonDisabled(true);

    try {
      const response = await createDocCoupon(formData);
      if (response && response.data && response.data.createCoupon) {
        toast.success('Doctor Coupon created successfully', {
          autoClose: 3000
        });
        handleLogEvent('Doctor Coupon created successfully');
        setCreatedCoupon(response.data.createCoupon);
        setshowModal(true);
      } else if (response && response.errors[0]) {
        handleLogEvent(
          `Failed to create Doctor coupon,${response.errors[0].message}`
        );
        toast.error(
          `Failed to create Doctor coupon,${response.errors[0].message}`,
          { autoClose: 3000 }
        );
      } else {
        handleLogEvent('Failed to create Doctor coupon');
        toast.error('Failed to create Doctor coupon', { autoClose: 3000 });
      }
    } catch (err) {
      handleLogEvent('Failed to create Doctor coupon');
      toast.error(`Failed to create Doctor coupon, ${err}`, {
        autoClose: 3000
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    setErrors({});

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      handleSubmitToCreate();
    } catch (error) {
      const newErrors = {};
      error.inner.forEach(err => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    }
  };

  const handleDateChange = (name, selectedDate) => {
    setFormData({
      ...formData,
      [name]: selectedDate.format('YYYY-MM-DD')
    });
  };

  let LtOptions;
  if (coupons && coupons.newCouponCodeSortedTupleArray) {
    LtOptions = coupons.newCouponCodeSortedTupleArray.map(([key, value]) => ({
      value: key,
      label: value
    }));
  }

  let siteCodeOptions;
  if (coupons && coupons.newSiteCodeSortedTupleArray) {
    siteCodeOptions = coupons.newSiteCodeSortedTupleArray.map(coupon => ({
      value: coupon[0],
      label: coupon[1]
    }));
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: '40px',
      minHeight: '40px'
    })
  };

  return (
    <div>
      <div className="align-items-center">
        <h1>Doctor Coupons</h1>
      </div>
      {coupons && coupons.newCouponCodeSortedTupleArray ? (
        <div>
          <h4>Enter details of coupon codes to be generated</h4>
          <br />
          <Form onSubmit={handleSubmitForm}>
            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="enteredCouponCount" style={{ width: '20%' }}>
                    Coupon Count
                  </label>
                  <Input
                    type="text"
                    id="enteredCouponCount"
                    style={{ width: '80%', height: '40px' }}
                    name="enteredCouponCount"
                    placeholder="Enter Coupon Count"
                    defaultValue={formData.enteredCouponCount}
                    readOnly
                  />
                </div>
                <div>
                  {errors.enteredCouponCount && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.enteredCouponCount}
                    </div>
                  )}
                </div>
              </div>
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedLtItemId" style={{ width: '20%' }}>
                    Offer
                  </label>
                  <div style={{ width: '80%' }}>
                    <Select
                      styles={customStyles}
                      id="selectedLtItemId"
                      value={
                        formData.selectedLtItemId
                          ? LtOptions.find(
                              option =>
                                option.value === formData.selectedLtItemId
                            )
                          : ''
                      }
                      onChange={e => handleChangeSelect('selectedLtItemId', e)}
                      options={LtOptions}
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedLtItemId && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedLtItemId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedSiteCode" style={{ width: '20%' }}>
                    Site Code
                  </label>
                  <div style={{ width: '80%' }}>
                    <Select
                      id="selectedSiteCode"
                      styles={customStyles}
                      value={
                        formData.selectedSiteCode
                          ? siteCodeOptions.find(
                              option =>
                                option.value === formData.selectedSiteCode
                            )
                          : ''
                      }
                      onChange={e => handleChangeSelect('selectedSiteCode', e)}
                      options={siteCodeOptions}
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedSiteCode && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedSiteCode}
                    </div>
                  )}
                </div>
              </div>

              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedStartDate" style={{ width: '20%' }}>
                    Start Date
                  </label>
                  <div style={{ width: '80%' }}>
                    <DatePicker
                      className="date-field"
                      selected={moment(formData.selectedStartDate)}
                      onChange={date =>
                        handleDateChange('selectedStartDate', date)
                      }
                      dateFormat="ddd, DD MMM YYYY"
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedStartDate && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedStartDate}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedExpiryDate" style={{ width: '20%' }}>
                    Expiry Date
                  </label>
                  <div style={{ width: '80%' }}>
                    <DatePicker
                      className="date-field"
                      selected={moment(formData.selectedExpiryDate)}
                      onChange={date =>
                        handleDateChange('selectedExpiryDate', date)
                      }
                      dateFormat="ddd, DD MMM YYYY"
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedExpiryDate && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedExpiryDate}
                    </div>
                  )}
                </div>
              </div>
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="selectedDoc" style={{ width: '20%' }}>
                    Doctor
                  </label>
                  <div style={{ width: '80%' }}>
                    <Select
                      id="selectedDoc"
                      styles={customStyles}
                      value={
                        formData.selectedDoc
                          ? {
                              value: formData.selectedDoc.id,
                              label: `${formData.selectedDoc.name} (${formData.selectedDoc.primarySpecialty})`
                            }
                          : ''
                      }
                      onChange={e => handleChangeSelect('selectedDoc', e)}
                      options={
                        doctors &&
                        doctors.map(doc => ({
                          value: doc.id,
                          label: `${doc.name} (${doc.primarySpecialty})`
                        }))
                      }
                    />
                  </div>
                </div>
                <div>
                  {errors.selectedDoc && (
                    <div className="error" style={{ marginLeft: '20%' }}>
                      {errors.selectedDoc}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="sub-container">
                <div className="d-flex align-items-center">
                  <label htmlFor="enteredComment" style={{ width: '20%' }}>
                    Comment
                  </label>
                  <Input
                    type="text"
                    id="enteredComment"
                    style={{ width: '80%', height: '40px' }}
                    name="enteredComment"
                    placeholder="Enter Comment"
                    value={formData.enteredComment}
                    onChange={e => handleFormChange(e)}
                  />
                </div>
              </div>
              <div>
                {errors.enteredComment && (
                  <div className="error" style={{ marginLeft: '20%' }}>
                    {errors.enteredComment}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-3 text-right">
              <Button color="primary" type="submit" disabled={isButtonDisabled}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      ) : null}

      <ShowCreatedCouponModal
        createdCoupons={createdCoupon}
        showModal={showModal}
        toggle={toggle}
      />
    </div>
  );
};

DocCouponForm.propTypes = {
  coupons: PropTypes.shape({
    newCouponsMap: PropTypes.object,
    newCouponCodeSortedTupleArray: PropTypes.arrayOf(PropTypes.array),
    newSiteCodeSortedTupleArray: PropTypes.arrayOf(PropTypes.array)
  }).isRequired,
  resetFlag: PropTypes.bool
};

export default DocCouponForm;
