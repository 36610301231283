import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { DELETE_ADMIN_USER } from '../queries';
import { showConfirmation } from '../ConfirmationDialogService';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';

const getMessage = (name, email) => (
  <>
    <span>
      Permanently delete the account of{' '}
      <strong style={{ color: 'red' }}>
        {name}({email})
      </strong>
      ?<br />
      <br />
      <strong>THIS PROCESS IS IRREVERSIBLE!</strong>
      <br />
      <small>ANY APP DEPEND ON THIS ACCOUNT WILL BECOME USELESS</small>
    </span>
  </>
);

const DeleteUser = ({ userId, name, email }) => {
  const confirm = deleteUser => {
    showConfirmation('Delete User?', getMessage(name, email), () =>
      deleteUser({ variables: { userId } })
    );
  };

  return (
    <Mutation
      mutation={DELETE_ADMIN_USER}
      onCompleted={() => {
        displaySuccess(`${name} is deleted`);
        logAmplitudeEvent(
          'User_deleted',
          {
            deletedUserName: `${name}`,
            deletedUserId: `${userId}`,
            deletedUserEmail: `${email}`
          },
          true
        );
      }}
      onError={e => displayError(e)}
      refetchQueries={() => ['SEARCH_ADMIN_USERS']}
    >
      {(deleteUser, { loading }) => {
        return (
          <Button
            disabled={loading}
            color="link"
            onClick={() => confirm(deleteUser)}
          >
            <FaTrash />
          </Button>
        );
      }}
    </Mutation>
  );
};

DeleteUser.propTypes = {
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default DeleteUser;
