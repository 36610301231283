import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Collapse
} from 'reactstrap';
import { Query, Mutation } from 'react-apollo';
import { GET_LOGGED_IN_USER, CHANGE_PASSWORD } from '../queries';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FaEye, FaEyeSlash, FaUserCog } from 'react-icons/fa';
import * as Yup from 'yup';
import Loader from './Loader';
import { displaySuccess, logAmplitudeEvent } from '../utilities';

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .min(8, 'New password is too short')
    .required('New password is required')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain minimum 8 Characters, 1 Uppercase, 1 Number and 1 Special Case Character'
    ),
  confirmPassword: Yup.string()
    .trim()
    .min(8, 'Confirm password is too short')
    .when('newPassword', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'Both password need to be the same'
      )
    })
    .required('Confirm password is required')
});

export const FormikPasswordField = ({ form, field, ...props }) => {
  const [isPlainText, setIsPlainText] = useState(false);
  const toggle = () => setIsPlainText(!isPlainText);

  return (
    <InputGroup>
      <Input
        type={isPlainText ? 'text' : 'password'}
        className={isPlainText ? 'form-row' : 'form-row passwordFont'}
        {...props}
        value={field.value}
        onChange={e => form.setFieldValue(field.name, e.target.value)}
      />
      <InputGroupAddon addonType="append">
        <Button onClick={toggle}>
          {isPlainText ? <FaEyeSlash /> : <FaEye />}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

FormikPasswordField.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object
};

const LoggedInUserSettings = ({ isOpen, toggle }) => {
  const [showForm, setShowForm] = useState(false);

  const passwordChanged = () => {
    logAmplitudeEvent('Reset_Password', {}, true);
    setShowForm(false);
    displaySuccess('Password is updated');
    //Without this setTimeout(), amplitude events will not be logged
    //as page will refresh before the API call is made to Amplitude
    setTimeout(
      () => {
        localStorage.clear();
        window.location.reload();
      },
      2500
    );

  };
  return (
    <Query query={GET_LOGGED_IN_USER}>
      {({ data: { me, hospitals, roles } }) => {
        const hs =
          me.hospitals.length === 0 || me.hospitals.length === hospitals.length
            ? 'All'
            : hospitals
              .filter(h => me.hospitals.includes(h.id))
              .map(h => h.name)
              .join(', ');
        return (
          <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
              <FaUserCog /> USER SETTINGS
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={3}>Name</Col>
                <Col>{me.name}</Col>
              </Row>
              <Row>
                <Col sm={3}>Login E-mail</Col>
                <Col>{me.email}</Col>
              </Row>
              <Row>
                <Col sm={3}>Role</Col>
                <Col>{roles.find(r => r.value === me.role).label}</Col>
              </Row>
              <Row>
                <Col sm={3}>Hospitals</Col>
                <Col>{hs}</Col>
              </Row>

              <Mutation
                mutation={CHANGE_PASSWORD}
                onCompleted={passwordChanged}
              >
                {(changePassword, { loading, error }) => {
                  return (
                    <Formik
                      initialValues={{ newPassword: '', confirmPassword: '' }}
                      validationSchema={PasswordSchema}
                      onSubmit={v => {
                        const { newPassword: n } = v;
                        changePassword({
                          variables: {
                            newPassword: n.trim()
                          }
                        });
                      }}
                      render={({ resetForm }) => (
                        <>
                          <Row>
                            <Col>
                              <Button
                                color="link"
                                style={{ paddingLeft: '0' }}
                                onClick={() => {
                                  setShowForm(!showForm);
                                  resetForm();
                                }}
                              >
                                {`${showForm ? 'Hide ' : ''}Change Password`}
                              </Button>
                            </Col>
                          </Row>
                          <Collapse isOpen={showForm}>
                            <Row>
                              <Col>
                                <Form>
                                  {error && (
                                    <Row>
                                      <Col className="error">
                                        {error.graphQLErrors &&
                                          error.graphQLErrors[0].message}
                                      </Col>
                                    </Row>
                                  )}
                                  <Row style={{ paddingBottom: '0.2rem' }}>
                                    <Col sm={4}>New Password</Col>
                                    <Col>
                                      <Field
                                        name="newPassword"
                                        component={FormikPasswordField}
                                      />
                                      <ErrorMessage
                                        className="error"
                                        name="newPassword"
                                        component="span"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={4}>Confirm Password</Col>
                                    <Col>
                                      <Field
                                        name="confirmPassword"
                                        component={FormikPasswordField}
                                      />
                                      <ErrorMessage
                                        className="error"
                                        name="confirmPassword"
                                        component="span"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Button disabled={loading} type="submit">
                                        UPDATE
                                      </Button>
                                      {loading && <Loader size={5} inline />}
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                          </Collapse>
                        </>
                      )}
                    />
                  );
                }}
              </Mutation>
            </ModalBody>
          </Modal>
        );
      }}
    </Query>
  );
};
LoggedInUserSettings.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default LoggedInUserSettings;
