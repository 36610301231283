import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Table
} from 'reactstrap';
import { useQuery } from 'react-apollo';
import { GET_AUDIT_LOG } from '../queries';
import Loader from './Loader';
import moment from 'moment';

const AuditLogModal = props => {
  const { type, entityId, style } = props;
  const [isOpen, setisOpen] = useState(false);
  const toggle = () => setisOpen(!isOpen);
  const handleOnClose = () => setisOpen(false);
  const { loading, data: { log = [] } = {} } = useQuery(GET_AUDIT_LOG, {
    variables: {
      input: {
        type,
        entityId
      }
    },
    fetchPolicy: 'network-only'
  });
  return (
    <>
      <Button
        color="link"
        onClick={() => setisOpen(!isOpen)}
        id="auditlogs"
        style={style}
      >
        {` Action History `}
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        onClosed={handleOnClose}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Audit Logs</ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <Container>
            <Table size="small" striped borderless responsive={false}>
              <thead>
                <tr>
                  <th style={{ width: '30%' }}>Time/Event</th>
                  <th>Action</th>
                  <th>By</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>
                      <Loader />
                    </td>
                  </tr>
                ) : !log.length ? (
                  <tr style={{ textAlign: 'center' }}>
                    <td colSpan={3}>No Logs Found</td>
                  </tr>
                ) : (
                  log.map(({ action, userName, created_at }, index) => (
                    <tr key={index}>
                      <td>
                        {moment(created_at).format('DD MMM YYYY hh:mm A')}
                      </td>
                      <td>{action}</td>
                      <td>{userName}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            CLOSE
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

AuditLogModal.propTypes = {
  type: PropTypes.string,
  entityId: PropTypes.number,
  style: PropTypes.object
};

export default AuditLogModal;
