import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const blockingOpts = [
  { label: 'Emergency', value: 'Emergency' },
  { label: 'On Leave', value: 'On Leave' },
  { label: 'Schedule Changed', value: 'Schedule Changed' },
  { label: 'Delayed', value: 'Delayed' },
  { label: 'Overbooked', value: 'Overbooked' },
  { label: 'Availability Restored', value: 'Availability Restored' }
];

const SlotCancelationReasons = ({ onChange }) => {
  const [selected, setSelected] = useState();
  return (
    <div style={{ minWidth: '15rem' }}>
      <Select
        value={selected}
        options={blockingOpts}
        onChange={o => {
          setSelected(o);
          onChange(o.value);
        }}
      />
    </div>
  );
};

SlotCancelationReasons.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default SlotCancelationReasons;
