import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import AuthRequired from '../components/AuthRequired';
import DocCouponForm from '../components/DocCouponForm';
import MpidCouponForm from '../components/MpidCouponForm';
import { getLobbyDiscountCoupons } from '../queries';
import { toast } from 'react-toastify';

const TABS = {
  doc: 'Doctors Coupons',
  mpid: 'Mpid Coupons'
};

const Coupons = () => {
  const [activeTab, setActiveTab] = useState(TABS.mpid);
  const [coupons, setCoupons] = useState({
    newCouponsMap: null,
    newCouponCodeSortedTupleArray: null,
    newSiteCodeSortedTupleArray: null
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLobbyDiscountCoupons();
        const couponArr = data.data.getDiscountCoupons;
        const newCouponsMap = {};
        const newSiteCodeMap = {};

        couponArr.forEach(val => {
          newCouponsMap[val.ltitemid] = val.ltdiscountname;
          newSiteCodeMap[val.sitecode] = val.sitecode;
        });

        const couponCodeSortedTupleArray = Object.entries(newCouponsMap);
        couponCodeSortedTupleArray.sort(function(a, b) {
          return a[1].localeCompare(b[1]);
        });

        newSiteCodeMap['S56'] = 'S56';
        newSiteCodeMap['C9-AECS'] = 'C9-AECS';

        const siteCodeSortTupleArray = Object.entries(newSiteCodeMap);
        siteCodeSortTupleArray.sort(function(a, b) {
          return a[1].localeCompare(b[1]);
        });

        setCoupons({
          couponArr,
          newCouponsMap,
          newCouponCodeSortedTupleArray: couponCodeSortedTupleArray,
          newSiteCodeSortedTupleArray: siteCodeSortTupleArray
        });
      } catch (error) {
        toast.error(`Please reload the page, ${error}`, { autoClose: 3000 });
      }
    };

    fetchData();
  }, []);

  const handleTabClick = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <AuthRequired pageName="coupons">
      <div className="settings-page">
        <div className="settings-sidebar">
          <Nav tabs vertical pills className="page-left-menu">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TABS.mpid })}
                onClick={() => handleTabClick(TABS.mpid)}
              >
                {TABS.mpid}
              </NavLink>
              <NavLink
                className={classnames({ active: activeTab === TABS.doc })}
                onClick={() => handleTabClick(TABS.doc)}
              >
                {TABS.doc}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="settings-main">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={TABS.mpid}>
              <MpidCouponForm
                coupons={coupons}
                resetFlag={activeTab !== TABS.mpid}
              />
            </TabPane>
            <TabPane tabId={TABS.doc}>
              <DocCouponForm
                coupons={coupons}
                resetFlag={activeTab !== TABS.doc}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </AuthRequired>
  );
};

export default Coupons;
