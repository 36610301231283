import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { GET_ALL_CITIES } from '../queries';
import Select from 'react-select';

const CitySelectorFormik = ({ field, form }) => {
  const { value: cityId, name } = field;
  return (
    <Query query={GET_ALL_CITIES}>
      {({ data: { cities = [] } = {} }, loading) => {
        const opts = cities.map(({ id, name }) => ({ value: id, label: name }));
        const value = opts.find(c => c.value === cityId);
        return (
          <Select
            value={value}
            options={opts}
            isLoading={loading}
            onChange={v => form.setFieldValue(name, v.value)}
          />
        );
      }}
    </Query>
  );
};

CitySelectorFormik.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

export default CitySelectorFormik;
