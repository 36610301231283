import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

export const discountTypes = [
  {
    value: 'percentage',
    label: 'Percentage(%)'
  },
  {
    value: 'flat',
    label: 'Flat(₹)'
  }
];

const DiscountInput = props => {
  const {
    discountType,
    setDiscountType,
    discount,
    setDiscount,
    discountNotes,
    setDiscountNotes,
    labelProps,
    totalAmount
  } = props;

  const handleDiscountChange = event => {
    if (!event || !event.target || !event.target.value) {
      setDiscount('');
      return;
    }

    const regex = /^[0-9]+$/;
    if (event.target.value.match(regex)) {
      if (
        discountType.value === 'percentage' &&
        parseInt(event.target.value) <= 100
      ) {
        setDiscount(event.target.value);
      } else if (
        totalAmount &&
        discountType.value === 'flat' &&
        parseInt(event.target.value) <= totalAmount
      ) {
        setDiscount(event.target.value);
      }
    }
  };

  const handleDiscountBlur = () => {
    if (!discount) {
      setDiscount('0');
    }
  };

  const handleDiscountTypeChange = value => {
    setDiscount('0');
    setDiscountType(value);
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <h6 className="mr-3 mb-0" {...labelProps}>
          Discount:
        </h6>
        <input
          className="mr-2"
          style={{ width: '5em' }}
          type="text"
          id="discount"
          onChange={handleDiscountChange}
          onBlur={handleDiscountBlur}
          value={discount}
        />
        <div style={{ minWidth: '11em' }}>
          <Select
            value={discountType}
            options={discountTypes}
            onChange={handleDiscountTypeChange}
          />
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        <h6 className="mr-3 mb-0" {...labelProps}>
          Discount Notes:
        </h6>
        <textarea
          className="mr-2"
          id="discountNote"
          rows="3"
          cols="40"
          onChange={event => setDiscountNotes(event.target.value)}
          value={discountNotes}
        />
      </div>
    </div>
  );
};

DiscountInput.propTypes = {
  discount: PropTypes.string.isRequired,
  setDiscount: PropTypes.func.isRequired,
  discountType: PropTypes.object.isRequired,
  setDiscountType: PropTypes.func.isRequired,
  discountNotes: PropTypes.string.isRequired,
  setDiscountNotes: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
  labelProps: PropTypes.object
};

export default DiscountInput;
