import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    userHospitals: [Int]!
    selectedHospitals: [Int]!
    allHospitals: [Hospital!]!
  }
`;

export const resolvers = {};
