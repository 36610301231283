import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Mutation } from 'react-apollo';
import { showConfirmation } from '../ConfirmationDialogService';
import { displaySuccess, displayError, logAmplitudeEvent } from '../utilities';
import { RESET_USER_PASSWORD } from '../queries';

const ResetPassword = ({ adminUser: { name, email, id } }) => {
  return (
    <Mutation
      mutation={RESET_USER_PASSWORD}
      onCompleted={() => {
        displaySuccess('Password is reset, email is sent');
        logAmplitudeEvent(
          'User_password_reset',
          { userName: `${name}`, userId: `${id}`, userEmail: `${email}` },
          true
        );
      }}
      onError={e => displayError(e)}
    >
      {(resetPassword, { loading }) => (
        <Button
          color="link"
          onClick={() =>
            showConfirmation(
              'Reset Password',
              <span>
                Do you want to reset the password of{' '}
                <strong style={{ color: 'blue' }}>
                  {name} ({email})
                </strong>
                ?
              </span>,
              () => resetPassword({ variables: { userId: id } })
            )
          }
          disabled={loading}
        >
          Reset Password
        </Button>
      )}
    </Mutation>
  );
};

ResetPassword.propTypes = {
  adminUser: PropTypes.object.isRequired
};

export default ResetPassword;
