import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Row, Col } from 'reactstrap';
import {
  CREATE_PROTOCOL,
  UPDATE_PROTOCOL,
  GET_PROTOCOLS,
  APPROVE_PROTOCOL,
  GET_ALL_HOSPITALS
} from '../queries';
import { useMutation, Query } from 'react-apollo';
import { toast } from 'react-toastify';
import Select from 'react-select';

const AddProtocol = ({ faq, isEditing, onChange, approve, hospital }) => {
  const [title, setTitle] = useState('');
  const [answer, setAnswer] = useState('');
  const [selectedH, setSelectedH] = useState(hospital ? hospital : {});

  function getOptionLabel({ name }) {
    return name;
  }

  function getOptionValue({ id }) {
    return id;
  }

  const [createProtocol, { loading: createProtocolLoader }] = useMutation(
    CREATE_PROTOCOL,
    {
      refetchQueries: [
        {
          query: GET_PROTOCOLS,
          variables: { approved: true, hospitalId: hospital && hospital.id }
        }
      ]
    }
  );
  const [updateProtocol, { loading: updateProtocolLoader }] = useMutation(
    UPDATE_PROTOCOL,
    {
      refetchQueries: [
        {
          query: GET_PROTOCOLS,
          variables: { approved: true, hospitalId: hospital && hospital.id }
        }
      ]
    }
  );
  const [approveProtocol, { loading: approveProtocolLoader }] = useMutation(
    APPROVE_PROTOCOL,
    {
      refetchQueries: [
        {
          query: GET_PROTOCOLS,
          variables: { approved: true, hospitalId: hospital && hospital.id }
        },
        {
          query: GET_PROTOCOLS,
          variables: { approved: false, hospitalId: hospital && hospital.id }
        }
      ]
    }
  );
  useEffect(() => {
    if (isEditing || approve) {
      setTitle(faq.title);
      setAnswer(faq.answer);
    }
    if (hospital) {
      setSelectedH(hospital);
    }
    // eslint-disable-next-line
  }, [isEditing, approve, hospital]);

  async function submitHandler() {
    if (!selectedH) {
      toast.info('Please select hospital first', { autoClose: 3000 });
      return;
    }
    if (!title.trim() || !answer.trim()) {
      toast.info('Invalid input', { autoClose: 1500 });
      return;
    }
    const isCreate = !isEditing;
    const fn = isCreate ? createProtocol : updateProtocol;
    const params = {
      variables: {
        protocols: {
          title,
          answer,
          hospitalId: selectedH.id
        },
        protocolsId: faq.id
      }
    };

    const { data, error } = await fn(params);

    if (data) {
      const msg = `Protocol ${
        isCreate ? 'created' : 'updated'
      } successfully and sent for approval`;
      toast.success(msg, { autoClose: 3000 });
    } else if (error) {
      toast.error('Something went wrong', {
        autoClose: 3000
      });
    }

    setTitle('');
    setAnswer('');
    onChange();
  }

  async function approveHandler() {
    if (!title.trim() || !answer.trim()) {
      toast.info('Invalid input', { autoClose: 1500 });
      return;
    }
    const { data, error } = await approveProtocol({
      variables: {
        protocols: {
          title,
          answer
        },
        protocolsId: faq.id
      }
    });
    if (data) {
      toast.success('Protocol approved successfully', { autoClose: 3000 });
    } else if (error) {
      toast.error('Something went wrong', { autoClose: 3000 });
    }

    setTitle('');
    setAnswer('');
    onChange();
  }

  return (
    <>
      <h1>{`${isEditing ? 'Edit' : approve ? 'Approve' : 'Add'} Protocols`}</h1>
      <Row>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ minWidth: '30rem' }}>
              <Query query={GET_ALL_HOSPITALS}>
                {({ loading, data: { hospitals } = {} }) => {
                  return (
                    <Select
                      value={hospital}
                      options={hospitals}
                      isLoading={loading}
                      onChange={v => setSelectedH(v)}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={getOptionValue}
                    />
                  );
                }}
              </Query>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#dee2e6',
          marginTop: 20,
          padding: 10,
          borderRadius: 5
        }}
      >
        <div style={{ margin: 10 }}>
          <h2>Question/Title</h2>
          <Input
            style={{ height: 50 }}
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <h3>Answer</h3>
          <Input
            type="textarea"
            style={{ height: 100 }}
            value={answer}
            onChange={e => {
              setAnswer(e.target.value);
            }}
          />
        </div>
        <Button
          color="primary"
          style={{
            alignItems: 'center',
            maxWidth: 80,
            margin: 10,
            marginTop: 20
          }}
          disabled={
            createProtocolLoader ||
            updateProtocolLoader ||
            approveProtocolLoader
          }
          onClick={approve ? approveHandler : submitHandler}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

AddProtocol.propTypes = {
  hospital: PropTypes.object,
  faq: PropTypes.object,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  approve: PropTypes.bool
};

export default AddProtocol;
