import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Query, useQuery } from 'react-apollo';
import { FaEdit } from 'react-icons/fa';
import { Badge, Button, Table } from 'reactstrap';
import DeleteMultipleScans from '../pages/DeleteMultipleScans';
import DeleteScan from '../pages/DeleteScan';
import { GET_LOGGED_IN_USER, GET_SCAN_DATA } from '../queries';
import { handleExportCSV, sortTable } from '../utilities/scans';
import { OrderIcon, ShowCheckBox } from './Common';
import EditMultipleScansModal from './EditMultipleScansModal';
import Loader from './Loader';
import Pagination from './Pagination';
import ScanModal from './ScanModal';
import SelectSiteCode from './SelectSiteCode';
import Tooltip from './Tooltip';
import { trimisterOptions } from '../utilities';

const itemsPerPage = 10;

const styles = {
  toolbar: {
    marginBottom: '1rem',
    marginTop: '1rem'
  }
};

const ScanChargeMasterTab = () => {
  const [scan, setScan] = useState();
  const [searchCriteria, setSearchCriteria] = useState({
    enable: true
  });
  const [sorting, setSorting] = useState({ column: 'scanName', order: 'asc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteCompleted, setDeleteCompleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: { me }
  } = useQuery(GET_LOGGED_IN_USER);
  const isCustomerSupport = me.role === 'customersupport';

  const hideScanModal = () => setScan(undefined);
  const scanEditHandler = scan => {
    setScan(scan);
  };

  useEffect(() => {
    if (deleteCompleted) {
      setSelectedRows([]);
      setDeleteCompleted(false);
    }
  }, [deleteCompleted]);

  const isSelected = rowData => {
    return selectedRows.some(row => row.itemId === rowData.itemId);
  };

  const toggleRowSelection = rowData => {
    if (isSelected(rowData)) {
      setSelectedRows(
        selectedRows.filter(row => row.itemId !== rowData.itemId)
      );
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const hideBulkEdit = () => {
    setSelectedRows([]);
  };
  const bulkEditHandler = selectedRows => {
    setSelectedRows([...selectedRows]);
  };

  const handleSorting = column => {
    return () => {
      setSorting(prev => ({
        ...prev,
        column,
        order: prev.order === 'asc' ? 'desc' : 'asc'
      }));
    };
  };

  return (
    <div>
      <div style={styles.toolbar}>
        {/* <Button color="primary" size="sm" onClick={() => scanEditHandler({})}>
          Add New
        </Button>{' '} */}
        <Formik
          initialValues={searchCriteria}
          onSubmit={values => {
            const { scanName, siteCode, deleted, appEnabled } = values;
            let searchC = {};
            if (scanName && scanName.trim().length > 3) {
              searchC.scanName = scanName;
            }
            if (siteCode) {
              searchC.siteCode = siteCode;
            }

            const { orderBy, isAsc } = searchCriteria;
            const s = {
              orderBy,
              isAsc,
              appEnabled,
              enable: !deleted,
              ...searchC
            };
            setSearchCriteria(s);
            setCurrentPage(1);
          }}
          render={() => (
            <Form>
              <div className="labScanHeader">
                <Field name="scanName" placeholder="Scan Name" />
                <Field
                  name="siteCode"
                  render={({ field, form }) => (
                    <SelectSiteCode
                      selectedSiteCode={field.value}
                      onSiteCodeSelect={v => form.setFieldValue(field.name, v)}
                    />
                  )}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem'
                  }}
                >
                  <div
                    className="form-check form-check-inline"
                    id="deletedScanCheckBox"
                    style={{ fontWeight: 600, gap: '5px' }}
                  >
                    <Field
                      name="deleted"
                      type="checkbox"
                      id="deletedScanCkeckBoxInput"
                    />
                    <label
                      htmlFor="deletedScanCkeckBoxInput"
                      className="form-check-label"
                    >
                      Deleted
                    </label>
                  </div>
                  <Tooltip
                    target="deletedScanCheckBox"
                    message="This will show scans deleted from LT"
                    placement="bottom"
                  />
                  <div
                    className="form-check form-check-inline"
                    id="appEnabledCheckBox"
                    style={{ fontWeight: 600, gap: '5px' }}
                  >
                    <Field
                      name="appEnabled"
                      id="appEnabledCkeckBoxInput"
                      type="checkbox"
                    />
                    <label
                      htmlFor="appEnabledCkeckBoxInput"
                      className="form-check-label"
                    >
                      App Enabled
                    </label>
                  </div>
                  <Tooltip
                    target="appEnabledCheckBox"
                    message="This will show scans enabled for app"
                    placement="bottom"
                  />
                  <Button
                    color="primary"
                    type="submit"
                    style={{ flex: 'auto' }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
      {!searchCriteria.siteCode ? (
        <h3>Please select Hospital</h3>
      ) : (
        <Query query={GET_SCAN_DATA} variables={{ input: searchCriteria }}>
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) return <div>Error: {error}</div>;
            if (!data || !data.getScanDetails) {
              return <div>No scan data found</div>;
            }
            const allData = data.getScanDetails;
            if (allData.length === 0) {
              return <h3>No Scan Data Available</h3>;
            }
            const totalPages = Math.ceil(allData.length / itemsPerPage);
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const finalData = sortTable(sorting, allData).slice(
              startIndex,
              endIndex
            );
            return (
              <>
                {!searchCriteria.enable && (
                  <Badge
                    style={{
                      marginRight: '2rem',
                      padding: '0.5rem 1rem'
                    }}
                    color="danger"
                  >
                    Showing Deleted Scans
                  </Badge>
                )}
                <Button
                  style={{
                    float: 'right',
                    color: 'black',
                    backgroundColor: 'khaki',
                    fontWeight: 'bold',
                    marginRight: '2rem',
                    marginBottom: '1rem'
                  }}
                  size="sm"
                  onClick={() => handleExportCSV(allData)}
                >
                  Export as CSV
                </Button>
                {selectedRows.length > 0 && (
                  <div>
                    {selectedRows.length === 1
                      ? '1 entry selected - '
                      : `${selectedRows.length} entries selected - `}
                    <Button
                      color="link"
                      onClick={() => {
                        bulkEditHandler(selectedRows);
                        setEditModalOpen(true);
                      }}
                    >
                      <FaEdit />
                    </Button>{' '}
                    <DeleteMultipleScans
                      itemIds={selectedRows.map(row => row.itemId)}
                      onDeleteCompleted={() => {
                        setDeleteCompleted(true);
                      }}
                    />
                  </div>
                )}
                <EditMultipleScansModal
                  isOpen={editModalOpen}
                  hideModal={() => {
                    setEditModalOpen(false);
                  }}
                  scansDetails={selectedRows}
                  onSave={() => {
                    setEditModalOpen(false);
                    hideBulkEdit();
                  }}
                />
                <div>
                  <Table size="sm" striped responsive>
                    <thead>
                      <tr>
                        {!isCustomerSupport && (
                          <th>
                            <input
                              type="checkbox"
                              onChange={() => {
                                if (selectedRows.length === allData.length) {
                                  setSelectedRows([]);
                                } else {
                                  setSelectedRows([...allData]);
                                }
                              }}
                              checked={selectedRows.length === allData.length}
                            />
                          </th>
                        )}
                        <th onClick={handleSorting('scanName')}>
                          Name{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="scanName" />
                          </span>
                        </th>
                        <th onClick={handleSorting('itemId')}>
                          Item Id{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="itemId" />
                          </span>
                        </th>
                        <th onClick={handleSorting('LtProcedure')}>
                          AMS Procedure{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="LtProcedure" />
                          </span>
                        </th>
                        <th onClick={handleSorting('rate')}>
                          Amount{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="rate" />
                          </span>
                        </th>
                        <th onClick={handleSorting('appEnabled')}>
                          AppEnabled
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="appEnabled" />
                          </span>
                        </th>
                        <th onClick={handleSorting('enable')}>
                          Deleted
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="enable" />
                          </span>
                        </th>
                        <th onClick={handleSorting('scanType')}>
                          Scan Type{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="scanType" />
                          </span>
                        </th>
                        <th onClick={handleSorting('recommendationType')}>
                          Recommendation Type{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon
                              {...sorting}
                              sortCol="recommendationType"
                            />
                          </span>
                        </th>
                        <th onClick={handleSorting('trimisterData')}>
                          Trimester{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="trimisterData" />
                          </span>
                        </th>
                        <th onClick={handleSorting('weekData')}>
                          Scan Week{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="weekData" />
                          </span>
                        </th>
                        <th onClick={handleSorting('durationofScan')}>
                          Scan Duration{' '}
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="durationofScan" />
                          </span>
                        </th>
                        <th onClick={handleSorting('updatedBy')}>
                          UpdatedBy
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="updatedBy" />
                          </span>
                        </th>
                        <th onClick={handleSorting('updatedBy')}>
                          UpdatedAt
                          <span style={{ color: 'grey' }}>
                            <OrderIcon {...sorting} sortCol="updatedAt" />
                          </span>
                        </th>
                        {!isCustomerSupport && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {finalData ? (
                        finalData.map(data => {
                          const updatedTrimisterData = trimisterOptions.find(
                            opt => opt.value === data.trimisterData
                          );
                          const updatedProcedureTrimisterData = trimisterOptions.find(
                            opt => opt.value === data.procedureTrimisterData
                          );
                          return (
                            <tr key={data.itemId}>
                              {!isCustomerSupport && (
                                <td>
                                  <input
                                    type="checkbox"
                                    onChange={() => toggleRowSelection(data)}
                                    checked={isSelected(data)}
                                  />
                                </td>
                              )}
                              <td style={{ maxWidth: '150px' }}>
                                {data.scanName}
                              </td>
                              <td>{data.itemId}</td>
                              <td style={{ maxWidth: '150px' }}>
                                {data.LtProcedure}
                              </td>
                              <td>{data.rate}</td>
                              <td>
                                <ShowCheckBox
                                  enable={data.appEnabled}
                                  color="green"
                                />
                              </td>
                              <td>
                                <ShowCheckBox
                                  enable={!data.enable}
                                  color="red"
                                />
                              </td>
                              <td>{data.scanType || data.procedureScanType}</td>
                              <td>
                                {data.recommendationType ||
                                  data.procedureRecommendationType}
                              </td>
                              <td>
                                {(updatedTrimisterData &&
                                  updatedTrimisterData.label) ||
                                  (updatedProcedureTrimisterData &&
                                    updatedProcedureTrimisterData.label)}
                              </td>
                              <td>{data.weekData || data.procedureWeekData}</td>
                              <td>
                                {data.durationofScan ||
                                  data.procedureDurationofScan}
                              </td>
                              <td>{data.updatedBy}</td>
                              <td>
                                {data.updatedAt
                                  ? moment(data.updatedAt).format(
                                      'MMM Do YYYY, h:mm a'
                                    )
                                  : ''}
                              </td>
                              {!isCustomerSupport && (
                                <td>
                                  <Button
                                    color="link"
                                    onClick={() => scanEditHandler(data)}
                                  >
                                    <FaEdit />
                                  </Button>
                                  <DeleteScan
                                    itemId={parseInt(data.itemId)}
                                    name={data.scanName}
                                    siteCode={data.siteCode}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <h3>No Scan Data Available</h3>
                      )}
                    </tbody>
                  </Table>
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                />
              </>
            );
          }}
        </Query>
      )}

      <ScanModal
        isOpen={!!scan}
        hideModal={hideScanModal}
        scanDetails={scan}
        onSave={hideScanModal}
      />
    </div>
  );
};

export default ScanChargeMasterTab;
