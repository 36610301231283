import React, { Component } from 'react';
import ApptInner from './ApptInner';
import AuthRequired from '../components/AuthRequired';

export default class Appointments extends Component {
  render() {
    return (
      <AuthRequired pageName="appointments">
        <ApptInner />
      </AuthRequired>
    );
  }
}
