import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useQuery } from 'react-apollo';
import { GET_ALL_HOSPITALS } from '../queries';

const SelectHospitalFromId = ({ hospitalIds, selected, onChange }) => {
  const { loading, data: { hospitals = [] } = {} } = useQuery(
    GET_ALL_HOSPITALS,
    {
      variables: { showAll: true }
    }
  );
  return (
    <Select
      isLoading={loading}
      loadingMessage={'fetching data'}
      placeholder={loading ? '' : 'Select Hospital'}
      options={
        hospitalIds.length
          ? hospitals
              .filter(item => hospitalIds.includes(item.id))
              .map(item => ({ label: item.name, value: item }))
          : hospitals.map(item => ({ label: item.name, value: item }))
      }
      value={selected}
      onChange={opt => onChange(opt)}
    />
  );
};

SelectHospitalFromId.propTypes = {
  hospitalIds: PropTypes.array,
  selected: PropTypes.object,
  onChange: PropTypes.func
};

export default SelectHospitalFromId;
