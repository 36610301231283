import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, Input, Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { useMutation } from 'react-apollo';
import {
  CREATE_CITY,
  UPDATE_CITY,
  GET_ALL_CITIES,
  UPLOAD_CITY_LOGO
} from '../queries';
import { toast } from 'react-toastify';

const AddOrEditCity = props => {
  const { mode, city, handleClose } = props;

  const [imageFile, setImageFile] = useState({ file: null, preview: null });

  const [createCity] = useMutation(CREATE_CITY, {
    refetchQueries: [{ query: GET_ALL_CITIES }],
    onCompleted: handleUpdateComplete,
    onError: handleErrorWhileUpdating
  });
  const [updateCity] = useMutation(UPDATE_CITY, {
    refetchQueries: [{ query: GET_ALL_CITIES }],
    onCompleted: handleUpdateComplete,
    onError: handleErrorWhileUpdating
  });

  const [uploadLogo] = useMutation(UPLOAD_CITY_LOGO, {
    refetchQueries: [{ query: GET_ALL_CITIES }],
    onCompleted: handleUploadLogoComplete,
    onError: handleErrorWhileUpdating
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address: ''
    },
    onSubmit: values => {
      if (mode === 'edit')
        updateCity({ variables: { cityId: city.value, city: values } });
      else createCity({ variables: { city: values } });
    },
    validationSchema
  });

  function handleUploadLogoComplete() {
    formik.setSubmitting(false);
    if (mode === 'edit') {
      toast.success('City updated successfully');
    } else {
      toast.success('City created successfully');
      handleClose();
    }
  }

  function handleUpdateComplete(data) {
    if (imageFile.file) {
      if (!isEmpty(data) && !isEmpty(data.city) && data.city.id)
        uploadLogo({
          variables: { imageFile: imageFile.file, cityId: data.city.id }
        });
      else toast.error('Something went wrong!');
    } else {
      formik.setSubmitting(false);
      if (mode === 'edit') {
        toast.success('City updated successfully');
      } else {
        toast.success('City created successfully');
        handleClose();
      }
    }
  }

  function handleErrorWhileUpdating() {
    formik.setSubmitting(false);
    toast.error('Something went wrong!');
  }

  const handleResetForm = () => {
    formik.resetForm();
  };

  useEffect(() => {
    if (mode === 'edit') {
      if (!isEmpty(city) && city.obj.name)
        formik.setFieldValue('name', city.obj.name);
      else formik.setFieldValue('name', '');
      if (!isEmpty(city) && city.obj.address)
        formik.setFieldValue('address', city.obj.address);
      else formik.setFieldValue('address', '');
    }
    // eslint-disable-next-line
  }, [city]);

  useEffect(() => {
    if (imageFile.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(ps => {
          return {
            ...ps,
            preview: reader.result
          };
        });
      };
      reader.readAsDataURL(imageFile.file);
    }
  }, [imageFile.file]);

  const handleImageChange = event => {
    const file = event.target.files[0];
    if (file) {
      setImageFile({ file: file, preview: null });
    }
  };

  return (
    <div>
      <h4>{mode === 'edit' ? 'Edit ' : 'Add '} City</h4>
      <form onSubmit={formik.handleSubmit}>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Name</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              style={{ maxWidth: '30rem' }}
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />
            {formik.errors.name && formik.touched.name ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.name}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Featured Image</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <div className="d-flex">
              {(!isEmpty(city) && city.obj.logoUrl) || imageFile.preview ? (
                <img
                  className="mr-2"
                  style={{
                    maxWidth: '50px',
                    maxHeight: '50px'
                  }}
                  src={
                    imageFile.preview
                      ? imageFile.preview
                      : !isEmpty(city) && city.obj.logoUrl
                      ? `data:image/png;base64, ${city.obj.logoUrl}`
                      : null
                  }
                  alt=""
                />
              ) : null}
              <Input
                type="file"
                style={{ maxWidth: '30rem' }}
                onChange={handleImageChange}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={3} lg={4}>
            <label>Centers</label>
          </Col>
          <Col sm={12} md={9} lg={8}>
            <Input
              type="text"
              name="address"
              onChange={formik.handleChange}
              value={formik.values.address}
              onBlur={formik.handleBlur}
            />
            {formik.errors.address && formik.touched.address ? (
              <div className="mt-2" style={{ color: 'red', fontSize: '14px' }}>
                {formik.errors.address}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row
          className="justify-content-end mt-4"
          style={{ padding: '0px 15px' }}
        >
          <Button type="button" color="primary" onClick={handleResetForm}>
            RESET
          </Button>
          <Button
            className="ml-3"
            type="submit"
            color="primary"
            disabled={formik.isSubmitting}
          >
            SAVE
          </Button>
        </Row>
      </form>
    </div>
  );
};

AddOrEditCity.propTypes = {
  city: PropTypes.object,
  mode: PropTypes.oneOf(['edit', 'add']).isRequired,
  handleClose: PropTypes.func
};

export default AddOrEditCity;
