import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Button } from 'reactstrap';
import { LOCAL_USER_HOSPITALS } from '../queries';
import { setDefaultHospitalId } from '../utilities';
import DatePicker1 from './DatePicker1';
import DepartmentsSelect from './DepartmentsSelect';
import LookupSelect from './LookupSelect';
import ProceduresSelect from './ProceduresSelect';
import SelectDoctors from './SelectDoctors';
import SelectHospital from './SelectHospital';

const ApptSearchFormV2 = ({ onFilter }) => {
  const [filter, setFilter] = useState({});
  const { hospitalId, departments: dids, procedures, languages } = filter;
  const departments = dids || [];
  const processSubmit = (
    { date, hospitalId, departments, objectIds, procedures, languages },
    client
  ) => {
    const apptSearchFilter = {
      date: date.toDate(),
      departments: departments || [],
      objectIds: objectIds || [],
      procedureNames: procedures || [],
      languageNames: languages || []
    };
    client.writeData({
      data: { selectedHospitals: [hospitalId] }
    });
    setDefaultHospitalId(hospitalId);
    onFilter(apptSearchFilter);
  };

  return (
    <div>
      <Query query={LOCAL_USER_HOSPITALS}>
        {({ data: { selectedHospitals }, client }) => {
          const hospitalIdSelected = hospitalId || selectedHospitals[0];
          const hospitalIds = [hospitalIdSelected];

          return (
            <Formik
              initialValues={{ hospitalId: hospitalIdSelected, date: moment() }}
              onSubmit={v => processSubmit(v, client)}
              render={({ values, setFieldValue }) => (
                <Form>
                  <div>DATE</div>
                  <div className="appt-filter">
                    <DatePicker1
                      name="date"
                      selectedDate={values.date}
                      onChange={setFieldValue}
                    />
                  </div>

                  <div>HOSPITAL</div>
                  <div className="appt-filter">
                    <Field
                      name="hospitalId"
                      component={SelectHospital}
                      isMulti={false}
                      onChange={hospitalId =>
                        setFilter({ ...filter, hospitalId })
                      }
                      placeholder="All Hospitals"
                      showAll={false}
                    />
                  </div>

                  <div>DEPARTMENTS</div>
                  <div className="appt-filter">
                    <Field
                      isMulti
                      name="departments"
                      component={({ form }) => (
                        <DepartmentsSelect
                          placeholder="All Departments"
                          onChange={d => {
                            setFilter({ ...filter, departments: d });
                            form.setFieldValue('departments', d);
                          }}
                          value={departments}
                          isMulti
                        />
                      )}
                    />
                  </div>

                  <div>LANGUAGES</div>
                  <div className="appt-filter">
                    <Field
                      isMulti
                      name="languages"
                      component={({ form }) => (
                        <LookupSelect
                          lookup="lang"
                          placeholder="All Languages"
                          isMulti
                          value={languages}
                          onChange={language => {
                            setFilter({ ...filter, languages: language });
                            form.setFieldValue('languages', language);
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>PROCEDURES</div>
                  <div className="appt-filter">
                    <Field
                      isMulti
                      name="procedures"
                      component={({ form }) => (
                        <ProceduresSelect
                          placeholder="All Procedures"
                          onChange={procedure => {
                            setFilter({ ...filter, procedures: procedure });
                            form.setFieldValue('procedures', procedure);
                          }}
                          value={procedures}
                          isMulti
                        />
                      )}
                    />
                  </div>

                  <div>DOCTORS</div>
                  <div className="appt-filter">
                    <Field
                      isMulti
                      name="objectIds"
                      component={SelectDoctors}
                      hospitalIds={hospitalIds}
                      departmentIds={departments}
                      languages={languages}
                      procedures={procedures}
                      placeholder="All Doctors"
                    />
                  </div>
                  <div>&nbsp;</div>
                  <div className="appt-filter">
                    <Button
                      color="primary"
                      type="submit"
                      style={{ width: '100%' }}
                    >
                      FILTER
                    </Button>
                  </div>
                </Form>
              )}
            />
          );
        }}
      </Query>
    </div>
  );
};

ApptSearchFormV2.propTypes = {
  onFilter: PropTypes.func
};

ApptSearchFormV2.defaultProps = {
  onFilter: () => {}
};
export default ApptSearchFormV2;
