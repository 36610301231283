import React from 'react';
import { Query } from 'react-apollo';
import { GET_BOOKINGS_STATUS } from '../queries';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { logAmplitudeEvent } from '../utilities';

const stts = [
  'All',
  'Booked',
  'Checked In',
  'Start Consultation',
  // 'End Consultation',
  'Checked Out',
  'Cancelled',
  'No Show'
];

const renderTabs = (a, selected, onSelect) => {
  const tabName = selected || 'All';
  return (
    <Nav tabs>
      {stts.map(s => (
        <NavItem key={s}>
          <NavLink
            className={classnames({
              active: tabName === s
            })}
            onClick={() => {
              s === 'All' ? onSelect() : onSelect(s);
              logAmplitudeEvent(
                'Selected_Appointments_Tab',
                {
                  tabName: s
                },
                true
              );
            }}
          >
            {`${s === 'Start Consultation' ? 'Consulting' : s} ${a[s] || 0}`}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export const BookingStatusTabs = props => (
  <Query
    query={GET_BOOKINGS_STATUS}
    variables={props.variables}
    fetchPolicy="network-only"
    pollInterval={20000}
  >
    {({ data: { bookingsStatus } = {} }) => {
      if (!bookingsStatus) return renderTabs([]);
      const mappedCount = bookingsStatus.reduce((acc, v) => {
        acc[v.status] = v.count;
        return acc;
      }, {});

      mappedCount.All = bookingsStatus.reduce(
        (counter, entry) => counter + entry.count,
        0
      );

      return renderTabs(mappedCount, props.selected, props.onSelect);
    }}
  </Query>
);

BookingStatusTabs.propTypes = {
  variables: PropTypes.object,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};
