import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classNames from 'classnames';
import { tabs } from './DocSettings';
import DocSettingMultiDayScheduleTab from './DocSettingMultiDayScheduleTab';
// import EditSchedule from './EditSchedule';
import ModifySlotContainer from './ModifySlotContainer';
// import DocSettingDayScheduleTab from './DocSettingDayScheduleTab';

const DocSettingTabs = props => {
  const { activeTab, entityId, date, slotTypes, switchTab, doctorName } = props;
  return (
    <React.Fragment>
      <Nav tabs style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === tabs.sch
            })}
            onClick={() => switchTab(tabs.sch)}
          >
            <span
              className={classNames({
                activeTab: activeTab === tabs.sch,
                inactiveTab: activeTab !== tabs.sch
              })}
            >
              {tabs.sch}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === tabs.vac
            })}
            onClick={() => switchTab(tabs.vac)}
          >
            <span
              className={classNames({
                activeTab: activeTab === tabs.vac,
                inactiveTab: activeTab !== tabs.vac
              })}
            >
              {tabs.vac}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === tabs.sch1
            })}
            onClick={() => switchTab(tabs.sch1)}
          >
            <span
              className={classNames({
                activeTab: activeTab === tabs.sch1,
                inactiveTab: activeTab !== tabs.sch1
              })}
            >
              {tabs.sch1}
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* <TabPane tabId={tabs.sch}>
          <EditSchedule entityId={entityId} />
        </TabPane> */}
        <TabPane tabId={tabs.sch}>
          <ModifySlotContainer entityId={entityId} />
        </TabPane>
        <TabPane tabId={tabs.vac}>
          <DocSettingMultiDayScheduleTab
            entityId={entityId}
            date={date}
            slotTypes={slotTypes}
            doctorName={doctorName}
          />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

DocSettingTabs.propTypes = {
  entityId: PropTypes.number.isRequired,
  date: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  slotTypes: PropTypes.arrayOf(PropTypes.object),
  switchTab: PropTypes.func.isRequired,
  doctorName: PropTypes.string
};

export default DocSettingTabs;
