import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button, Row, Col } from 'reactstrap';
import { FaSync } from 'react-icons/fa';
import { Mutation } from 'react-apollo';
import classnames from 'classnames';
import { displayError, displaySuccess } from '../utilities';
import { SYNC_WITH_LT } from '../queries';

const SyncDocsWithLT = () => {
  const [date, setDate] = useState(moment());
  return (
    <>
      <Row>
        <Col>
          <span>
            <strong>Sync with Lifetrenz</strong>
          </span>
        </Col>
      </Row>
      <Mutation
        mutation={SYNC_WITH_LT}
        onError={e => {
          console.error(`Something went wrong while syncing`, e);
          displayError('Something went wrong while syncing doctor data');
        }}
        onCompleted={({ syncRes: { date, updatedDocs, newDocs } }) => {
          displaySuccess(
            `${updatedDocs} doctors updated and ${newDocs} doctors added from ${moment(
              date
            ).format('DD MMM, YYYY')}`
          );
        }}
      >
        {(syncDocs, { loading }) => {
          return (
            <Row>
              <Col>
                <DatePicker
                  selected={date}
                  onChange={v => setDate(v)}
                  dateFormat="DD MMM, YYYY"
                />
              </Col>
              <Col>
                <Button
                  color="link"
                  disabled={loading}
                  onClick={() =>
                    syncDocs({ variables: { date: date.format('YYYY-MM-DD') } })
                  }
                >
                  <FaSync className={classnames({ spinme: loading })} />
                </Button>
              </Col>
            </Row>
          );
        }}
      </Mutation>
    </>
  );
};

export default SyncDocsWithLT;
