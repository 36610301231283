import React from 'react';
import { FaCheckSquare, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export const ShowCheckBox = ({ enable = false, color }) => {
  if (!enable) {
    return null;
  }
  return (
    <Button color="link">
      <FaCheckSquare color={color} />
    </Button>
  );
};

ShowCheckBox.propTypes = {
  enable: PropTypes.bool,
  color: PropTypes.string
};

export const OrderIcon = ({ column, sortCol, order }) => {
  if (sortCol !== column || order !== 'asc') return <FaAngleDown />;
  return <FaAngleUp />;
};

OrderIcon.propTypes = {
  column: PropTypes.string.isRequired,
  sortCol: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired
};

export default OrderIcon;
