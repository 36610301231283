import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import PatientBadge from './patientBadge';
import { Query } from 'react-apollo';
import { LOCAL_USER_HOSPITALS } from '../queries';

const AppointmentList = ({
  apptList,
  searchPhone,
  displayDetail,
  stopPolling,
  startPolling,
  onBook,
  createInvoice,
  createScanInvoice,
  updateMpid,
  customerRxDetails,
  customerPersonalDetails,
  labsAndScansPrescribed
}) => {
  return (
    <Query query={LOCAL_USER_HOSPITALS}>
      {({ data: { allHospitals } = {} }) => {
        const hospitalsIdNameMap = {};
        if (allHospitals) {
          allHospitals.forEach(
            ({ id, name }) => (hospitalsIdNameMap[id] = name)
          );
        }

        return (
          <List
            itemData={apptList}
            height={700}
            itemSize={140}
            itemCount={apptList.length}
          >
            {({ index, data, style }) => (
              <div style={{ ...style, paddingRight: '1rem' }}>
                <PatientBadge
                  booking={data[index]}
                  searchPhone={searchPhone}
                  displayDetail={displayDetail}
                  stopPolling={stopPolling}
                  startPolling={startPolling}
                  onBook={onBook}
                  hospitalsIdNameMap={hospitalsIdNameMap}
                  createInvoice={createInvoice}
                  createScanInvoice={createScanInvoice}
                  updateMpid={updateMpid}
                  customerRxDetails={customerRxDetails}
                  customerPersonalDetails={customerPersonalDetails}
                  labsAndScansPrescribed={labsAndScansPrescribed}
                />
              </div>
            )}
          </List>
        );
      }}
    </Query>
  );
};

AppointmentList.propTypes = {
  apptList: PropTypes.arrayOf(PropTypes.object),
  searchPhone: PropTypes.string,
  displayDetail: PropTypes.func,
  stopPolling: PropTypes.func,
  startPolling: PropTypes.func,
  onBook: PropTypes.func,
  createInvoice: PropTypes.func,
  createScanInvoice: PropTypes.func,
  updateMpid: PropTypes.func,
  customerRxDetails: PropTypes.func,
  customerPersonalDetails: PropTypes.func,
  labsAndScansPrescribed: PropTypes.func
};

AppointmentList.defaultProps = {
  stopPolling: () => {},
  startPolling: () => {},
  createInvoice: () => {},
  createScanInvoice: () => {},
  updateMpid: () => {}
};
export default AppointmentList;
